import React, { useState } from 'react'
import './GenericShape.css'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { Box, IconButton, Tooltip } from '@mui/material'
import { deleteShape } from '../../../store/actions'
import { useDispatch } from 'react-redux'
import TrashRed from '../../../assets/icons/TrashRed.svg'
import { ReorderPicker } from '../../common/ReorderPicker/ReorderPicker'

export const EditGenericShapeMenu = ({ shape, handleDelete }) => {
    const dispatch = useDispatch()
    const [deleteOpen, setDeleteOpen] = useState(false)

    const handleDeleteShape = () => {
        handleDelete()
        dispatch(deleteShape(shape.id))
        setDeleteOpen(false)
    }

    return (
        <Box className={'shape-menu'} id={'shape-menu'}>
            <Tooltip title={'Delete'}>
                <IconButton onClick={() => setDeleteOpen(true)}>
                    <img src={TrashRed} alt={'delete'} />
                </IconButton>
            </Tooltip>
            <ReorderPicker objectId={shape.id} origin={'top'} tooltip={'Reorder element'} />
            <DeleteDialog
                handleCancel={() => setDeleteOpen(false)}
                title={'Shape'}
                type={'shape'}
                open={deleteOpen}
                handleDelete={handleDeleteShape}
            />
        </Box>
    )
}
