import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../../api/api'
import { Server } from '../../../../api/Server'
import Dialog from '../../../common/Dialog/Dialog'
import Selector from '../../../common/Selector/Selector'
import Button from '../../../common/Button/Button'
import {
    Box,
    Modal,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
} from '@mui/material'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'

const LoadDiagram = ({ isOpen, closeModal, loadDiagram }) => {
    const [selectedDiagram, setSelectedDiagram] = useState('')
    const [error, setError] = useState('')
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const apiPath = isGuestMode ? 'guest-ui_information' : 'ui_information'
    const { postData: postUIInformationCall } = useApi(
        `https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`
    )

    const [server, setServer] = useState(new Server(workspaceID, userID, token))
    const [snapshotDiagrams, setSnapshotDiagrams] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer)
    }, [userID, token, workspaceID])

    useEffect(() => {
        if (snapshotDiagrams.length === 0 && isOpen) {
            setIsLoading(true)
            loadDiagramSnapshots()
                .then(() => setIsLoading(false))
                .catch((error) => {
                    // Handle or log error
                    setIsLoading(false)
                })
        }
    }, [isOpen]) // Empty dependency array to run only on component mount

    useEffect(() => {
        if (snapshotDiagrams.length > 0) {
            setSelectedDiagram(snapshotDiagrams[0].label)
        }
    }, [snapshotDiagrams])

    const loadDiagramSnapshots = async () => {
        const action = {
            action: 'listProcessDiagramPreferences',
            userID: userID,
            workspaceID: workspaceID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postUIInformationCall(action)
        if (result) {
            setSnapshotDiagrams(
                result.body.diagramNames
                    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
                    .map((x) => ({
                        label: x.trim(), // Trim whitespace for consistent sorting and display
                        value: x.trim(),
                    }))
            )
            setIsLoading(false)
        }
    }

    const handleLoadDiagram = () => {
        loadDiagram(selectedDiagram)
    }

    const handleChange = (event) => {
        setSelectedDiagram(event.target.value)
    }

    // Actions for the modal
    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={closeModal} className="primary-button" text="Cancel" />
            <Button
                onClick={handleLoadDiagram}
                className="primary-button button-purple"
                text="Load Diagram"
            />
        </Box>
    )

    // console.log({snapshotDiagrams})

    return (
        <Dialog
            onClose={closeModal}
            open={isOpen}
            title="Select a Diagram to Load"
            actions={actions}
            fullWidth
        >
            {!isLoading ? (
                <Selector
                    value={selectedDiagram || ''}
                    label="Select a Diagram"
                    native
                    onChange={handleChange}
                >
                    {snapshotDiagrams.map((diagram) => (
                        <option key={diagram.value} value={diagram.label}>
                            {diagram.label}
                        </option>
                    ))}
                </Selector>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ScoopLoader size={64} />
                </Box>
            )}
        </Dialog>
    )
}

export default LoadDiagram
