import { useCallback, useEffect, useState } from 'react'
import { FONT_FAMILIES, FONT_WEIGHTS, TOOLTIP_DEFAULT_VALUES } from '../styleConsts'
import { Box } from '@mui/material'
import { Slider } from '../../../../../common/Slider/Slider'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'
import { debounce, cloneDeep } from 'lodash'

export const TooltipConfig = ({ config, setConfig, chartPreferences }) => {
    const [fontSize, setFontSize] = useState(TOOLTIP_DEFAULT_VALUES.textStyle.fontSize)
    const [borderWidth, setBorderWidth] = useState(TOOLTIP_DEFAULT_VALUES.borderWidth)

    useEffect(() => {
        setBorderWidth(getTooltipPropValue('borderWidth'))
        setFontSize(getTooltipTextStylePropValue('fontSize'))
    }, [config])

    const debouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.tooltip[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const debouncedTextStyleFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.tooltip.textStyle[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const getTooltipPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.tooltip, prop))
            return config.styleOverrides.tooltip[prop]
        if (propExistsOnObject(chartPreferences.tooltip, prop))
            return chartPreferences.tooltip[prop]
        return TOOLTIP_DEFAULT_VALUES[prop]
    }

    const getTooltipTextStylePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.tooltip.textStyle, prop))
            return config.styleOverrides.tooltip.textStyle[prop]
        if (propExistsOnObject(chartPreferences.tooltip?.textStyle, prop))
            return chartPreferences.tooltip.textStyle[prop]
        return TOOLTIP_DEFAULT_VALUES.textStyle[prop]
    }

    const handleDebounceTooltipTextStylePropChange = (prop, value, setter) => {
        debouncedTextStyleFunc(prop, value)
        setter(value)
    }

    const handleTooltipTextStylePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.tooltip.textStyle[prop] = value
        setConfig(newConfig)
    }

    const handleDebounceTooltipPropChange = (prop, value, setter) => {
        debouncedFunc(prop, value)
        setter(value)
    }

    const handleTooltipPropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.tooltip[prop] = value
        setConfig(newConfig)
    }

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            {renderSelector(
                'Font family',
                getTooltipTextStylePropValue('fontFamily'),
                (v) => handleTooltipTextStylePropChange('fontFamily', v),
                FONT_FAMILIES
            )}
            {renderSelector(
                'Font weight',
                getTooltipTextStylePropValue('fontWeight'),
                (v) => handleTooltipTextStylePropChange('fontWeight', v),
                FONT_WEIGHTS
            )}
            <Slider
                label={`Font size: ${fontSize}px`}
                value={fontSize}
                min={0}
                max={32}
                step={1}
                onChange={(v) =>
                    handleDebounceTooltipTextStylePropChange('fontSize', v, setFontSize)
                }
            />
            {renderColorPicker('Font color', getTooltipTextStylePropValue('color'), (v) =>
                handleTooltipTextStylePropChange('color', v)
            )}
            <Slider
                label={`Border width: ${borderWidth}px`}
                value={borderWidth}
                min={0}
                max={32}
                step={1}
                onChange={(v) => handleDebounceTooltipPropChange('borderWidth', v, setBorderWidth)}
            />
            {renderColorPicker('Border color', getTooltipPropValue('borderColor'), (v) =>
                handleTooltipPropChange('borderColor', v)
            )}
            {renderColorPicker('Background color', getTooltipPropValue('backgroundColor'), (v) =>
                handleTooltipPropChange('backgroundColor', v)
            )}
        </Box>
    )
}
