import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import DefaultUserIcon from '../../assets/icons/default_user.png'
import { useUserInfo } from '../../api/hooks/user/useUserInfo'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function UserProfileModal({ open, handleClose }) {
    const { user } = useUserInfo()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="user-profile-modal"
            aria-describedby="user-profile-information"
        >
            <Box sx={style}>
                <Avatar
                    // alt={userInfo.userFirstName + userInfo.userLastName}
                    src={user?.thumbnail || `url(${DefaultUserIcon})`}
                    sx={{ width: 56, height: 56, mx: 'auto' }}
                />
                <Typography
                    id="user-profile-modal"
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{ mt: 2 }}
                >
                    {user.firstName + ' ' + user.lastName}
                </Typography>
                <Typography id="user-profile-information" sx={{ mt: 2, mb: 3 }} align="center">
                    {user.userName}
                </Typography>
                <Button onClick={handleClose} fullWidth variant="outlined">
                    Close
                </Button>
                <Typography
                    sx={{
                        mt: 2,
                        fontSize: '0.75rem',
                        color: 'gray',
                        position: 'absolute',
                        bottom: 5,
                        right: 5,
                    }}
                >
                    {process.env.REACT_APP_PRODUCT_VERSION}
                </Typography>
            </Box>
        </Modal>
    )
}
