import { useState } from 'react'
import Dialog from '../../../../common/Dialog/Dialog'
import Button from '../../../../common/Button/Button'
import { Box, Typography } from '@mui/material'
import { TypeBox } from '../TypeBox'

const BlendingMethodDialog = ({ onClose, setStep }) => {
    const [active, setActive] = useState('Blended')

    return (
        <Dialog
            open
            onClose={() => {
                onClose()
                setStep(0)
            }}
            actions={
                <>
                    <Button className={'button-grey small'} onClick={() => setStep(1)}>
                        Back
                    </Button>
                    <Button
                        disabled={active.length === 0}
                        className={'button-purple small'}
                        onClick={() => setStep(active === 'Blended' ? 3 : 4)}
                    >
                        Continue
                    </Button>
                </>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                }}
            >
                <Typography sx={{ color: '#201024', fontSize: '24px', fontWeight: 600 }}>
                    Select method
                </Typography>
                <Typography
                    sx={{
                        display: 'inline',
                        textAlign: 'center',
                        width: '75%',
                        color: '#635566',
                    }}
                >
                    Select the method of data aggregation you'd like to use. For more information,{' '}
                    <a href="#" style={{ color: '#BF0A46' }}>
                        watch the video.
                    </a>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <TypeBox
                        title={'Blended'}
                        description={
                            'Select and filter columns from multiple existing datasets ' +
                            'and blend together to create a new blended dataset.'
                        }
                        active={active}
                        setActive={setActive}
                    />
                    <TypeBox
                        title={'Aggregated'}
                        description={
                            'Select and filter columns from a single existing dataset to use in a new aggregated dataset.'
                        }
                        active={active}
                        setActive={setActive}
                    />
                </Box>
            </Box>
        </Dialog>
    )
}

export default BlendingMethodDialog
