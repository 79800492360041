import Dialog from './Dialog'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, MenuItem, Typography } from '@mui/material'
import Selector from '../Selector/Selector'
import { ScoopColorPicker } from '../ScoopColorPicker/ScoopColorPicker'
import { useDispatch, useSelector } from 'react-redux'
import { setShowEditElementWrapper } from '../../../store/actions/uiActions'
import { TabContext, TabPanel } from '@mui/lab'
import Button from '../Button/Button'
import { OBJECT_TYPES } from '../../Objects/types'
import { updateObjectStyle } from '../../../store/actions'
import { Tab } from '../Tab/Tab'
import Checkbox from '../Checkbox/Checkbox'
import { Switch } from '../Switch/Switch'

const borderRadiusOptions = ['0px', '2px', '4px', '5px', '8px', '12px', '16px', '24px', '32px']
const borderWidthOptions = ['0px', '1px', '2px', '3px', '4px', '5px', '6px', '8px', '10px', '12px']
const borderStyleOptions = [
    'solid',
    'dotted',
    'dashed',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset',
    'none',
]

const boxShadowOptions = [
    { label: 'None', value: 'none' },
    { label: 'Default', value: '0px 2px 10px 0px rgba(0,0,0,0.15)' },
    { label: 'Extra small', value: '0px 0px 2px 0px rgba(0,0,0,0.75)' },
    { label: 'Small', value: '0px 0px 5px 0px rgba(0,0,0,0.75)' },
    { label: 'Medium', value: '0px 0px 10px 0px rgba(0,0,0,0.75)' },
    { label: 'Large', value: '0px 0px 15px 0px rgba(0,0,0,0.75)' },
    { label: 'Extra Large', value: '0px 0px 20px 0px rgba(0,0,0,0.75)' },
    { label: 'Inner', value: 'inset 0px 0px 10px 0px rgba(0,0,0,0.75)' },
]

const opacityOptions = ['1', '0.9', '0.8', '0.7', '0.6', '0.5', '0.4', '0.3', '0.2', '0.1']

const initialStyles = {
    borderRadius: '5px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#D0CDD1',
    boxShadow: boxShadowOptions.find((option) => option.label === 'Default').value,
    backgroundColor: '#ffffff',
    opacity: '1',
}

export const EditMenuDialog = ({}) => {
    const showElementWrapper = useSelector((state) => state.ui.showEditElementWrapper)
    const dispatch = useDispatch()
    const [tabValue, setTabValue] = useState('0')
    const objects = useSelector((state) => state.objects)
    const [applyAll, setApplyAll] = useState(false)

    const elementType = useMemo(() => {
        return objects.find((obj) => obj.id === showElementWrapper[1])?.type
    }, [showElementWrapper])

    const getInitialStyles = () => {
        let globalStyles
        const objectID = showElementWrapper[1]
        if (objectID !== undefined) {
            globalStyles = objects.find((obj) => obj.id === objectID)?.wrapperStyles
        } else {
            globalStyles = objects.find(
                (obj) => obj?.wrapperStyles?.override === false
            )?.wrapperStyles
        }
        if (globalStyles && Object.keys(globalStyles).length > 0) {
            let styles = {
                borderRadius: globalStyles.borderRadius,
                borderWidth: globalStyles.borderWidth,
                borderStyle: globalStyles.borderStyle,
                borderColor: globalStyles.borderColor,
                boxShadow: globalStyles.boxShadow,
                backgroundColor: globalStyles.backgroundColor,
                opacity: globalStyles.opacity,
                showTitle: globalStyles.showTitle,
            }
            if (elementType === 'SheetletElement') {
                styles.cellColor = globalStyles.cellColor
            }
            return styles
        }
        if (elementType === 'SheetletElement') {
            return {
                ...initialStyles,
                cellColor: '#ffffff',
            }
        } else {
            return initialStyles
        }
    }

    const [styles, setStyles] = useState(getInitialStyles())

    useEffect(() => {
        setStyles(getInitialStyles())
    }, [showElementWrapper, objects])

    const boxStyles = useMemo(() => {
        return {
            ...styles,
            width: '432px',
            height: '312px',
            padding: '12px 12px 12px 12px',
        }
    }, [styles])

    const handleChange = (key, value) => {
        setStyles({
            ...styles,
            [key]: value,
        })
    }

    const renderSelector = (name, label, options) => {
        return (
            <Selector
                value={styles[name]}
                label={label}
                sx={{ height: 36 }}
                labelClassName={'selector-label-bold'}
                onChange={(e) => handleChange(name, e.target.value)}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                    </MenuItem>
                ))}
            </Selector>
        )
    }

    const handleClose = () => {
        dispatch(setShowEditElementWrapper([false, undefined]))
        setStyles(initialStyles)
        setTabValue('0')
        setApplyAll(false)
    }

    const handleSave = () => {
        const objectOverrideId = showElementWrapper[1]

        const updateStyles = (obj, override) => {
            const updatedStyles = {
                override,
                ...styles,
            }
            dispatch(updateObjectStyle(obj.id, updatedStyles))
            obj.wrapperStyles = updatedStyles
        }

        objects.forEach((obj) => {
            if (
                obj.type === OBJECT_TYPES.SHEETLET ||
                obj.type === OBJECT_TYPES.PROCESS ||
                obj.type === OBJECT_TYPES.INSIGHT
            ) {
                if (applyAll) {
                    updateStyles(obj, false)
                } else {
                    if (objectOverrideId) {
                        if (obj.id === objectOverrideId) {
                            updateStyles(obj, true)
                        }
                    } else {
                        if (
                            obj.wrapperStyles.override === false ||
                            obj.wrapperStyles.override === undefined
                        ) {
                            updateStyles(obj, false)
                        }
                    }
                }
            }
        })

        handleClose()
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'} className={'primary-button'}></Button>
            <Button
                onClick={handleSave}
                className={'primary-button button-purple'}
                text={'Save'}
            ></Button>
        </Box>
    )

    return (
        <Dialog
            open={showElementWrapper[0]}
            onClose={handleClose}
            title="Edit Element"
            style={{ width: 800 }}
            actions={actions}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tab
                            tabs={[
                                { key: '0', label: 'Border' },
                                { key: '1', label: 'Background' },
                                { key: '2', label: 'Title' },
                            ]}
                            value={tabValue}
                            onChange={(e, newValue) => setTabValue(newValue)}
                        />
                    </Box>
                    <TabPanel value={'0'}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                            }}
                        >
                            <Box sx={{ width: 100 }}>
                                {renderSelector(
                                    'borderRadius',
                                    'Border Radius',
                                    borderRadiusOptions
                                )}
                            </Box>
                            <Box sx={{ width: 100 }}>
                                {renderSelector('borderWidth', 'Border Width', borderWidthOptions)}
                            </Box>
                            <Box sx={{ width: 100 }}>
                                {renderSelector('borderStyle', 'Border Style', borderStyleOptions)}
                            </Box>
                            <Box sx={{ width: 'auto' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                    Border Color
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 36,
                                    }}
                                >
                                    <ScoopColorPicker
                                        value={styles.borderColor}
                                        onChange={(color) => handleChange('borderColor', color)}
                                        showLabel
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={'1'}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                            }}
                        >
                            <Box sx={{ width: 100 }}>
                                <Selector
                                    value={styles.boxShadow}
                                    label="Box Shadow"
                                    sx={{ height: 36 }}
                                    labelClassName={'selector-label-bold'}
                                    onChange={(e) => handleChange('boxShadow', e.target.value)}
                                >
                                    {boxShadowOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Selector>
                            </Box>
                            <Box sx={{ width: 100 }}>
                                {renderSelector('opacity', 'Opacity', opacityOptions)}
                            </Box>
                            <Box sx={{ width: 'auto' }}>
                                <Typography
                                    className={'inter'}
                                    sx={{ fontSize: 12, fontWeight: 'bold' }}
                                >
                                    Background Color
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 36,
                                    }}
                                >
                                    <ScoopColorPicker
                                        value={styles.backgroundColor}
                                        onChange={(color) => handleChange('backgroundColor', color)}
                                        showLabel
                                    />
                                </Box>
                            </Box>
                            {elementType === 'SheetletElement' && (
                                <Box sx={{ width: 'auto' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Default cell background
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 36,
                                        }}
                                    >
                                        <ScoopColorPicker
                                            value={styles.cellColor}
                                            onChange={(color) => handleChange('cellColor', color)}
                                            showLabel
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={'2'}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                            }}
                        >
                            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                                <Typography className={'inter style-config-label'}>Show</Typography>
                                <Switch
                                    checked={styles.showTitle}
                                    onClick={() => handleChange('showTitle', !styles.showTitle)}
                                />
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
                <Box sx={boxStyles}>
                    {styles.showTitle && <Typography className={'inter'}>Title</Typography>}
                </Box>
            </Box>
            {!showElementWrapper[1] && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '0 24px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            fontColor: '#201024',
                        }}
                    >
                        Apply to all
                    </Typography>
                    <Checkbox
                        label={'Apply style to all objects'}
                        checked={applyAll}
                        onClick={() => setApplyAll(!applyAll)}
                    />
                </Box>
            )}
        </Dialog>
    )
}
