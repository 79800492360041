import React, { useEffect, useState } from 'react'
import './ZoomControls.css'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import MagnifyingGlassMinus from '../../../assets/icons/MagnifyingGlassMinus.svg'
import MagnifyingGlassMinusPink from '../../../assets/icons/MagnifyingGlassMinusPink.svg'
import MagnifyingGlassPlus from '../../../assets/icons/MagnifyingGlassPlus.svg'
import MagnifyingGlassPlusPink from '../../../assets/icons/MagnifyingGlassPlusPink.svg'
import { setZoom } from '../../../store/actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../common/Input/Input'
import _ from 'lodash'

export const ZoomControls = ({ width, clearSelection }) => {
    const dispatch = useDispatch()
    const objects = useSelector((state) => state.objects)
    const zoom = useSelector((state) => state.ui.zoom)
    const maxScroll = useSelector((state) => state.ui.maxScroll)
    const container = document.getElementById('scoop-canvas')
    const canvas = document.getElementById('canvas-scrollable')
    const zoomPercentage = Math.round(zoom * 100)
    const [isEditing, setIsEditing] = useState(false)
    const [zoomInput, setZoomInput] = useState(zoomPercentage)

    const shouldZoomToCenter = () => {
        if (!container || !canvas || !objects) return false
        const rect = canvas.getBoundingClientRect()
        const canvasRightBound = rect.right
        const threshold = 0.3 * rect.width
        return objects.some((object) => {
            const objectRightBound = (object.x + object.width / 2) * zoom - rect.width / 2
            const distanceFromRight = canvasRightBound - objectRightBound
            return distanceFromRight <= threshold || distanceFromRight <= 0
        })
    }

    useEffect(() => {
        setZoomInput(Math.round(zoom * 100))
    }, [zoom])

    useEffect(() => {
        const blockScrollAndZoom = (e) => {
            e.preventDefault()
            if (e.deltaY > 0) handleZoom('out', true)
            if (e.deltaY < 0) handleZoom('in', true)
        }

        const handleWheel = (e) => {
            if (e.ctrlKey && e.deltaMode === 0) {
                blockScrollAndZoom(e)
            }
        }

        const handleCtrlDown = (e) => {
            if (e.ctrlKey) canvas.addEventListener('wheel', blockScrollAndZoom)
        }

        const handleCtrlUp = (e) => {
            if (e.keyCode === 17) canvas.removeEventListener('wheel', blockScrollAndZoom)
        }

        if (canvas) {
            canvas.addEventListener('wheel', handleWheel)
            window.addEventListener('keydown', handleCtrlDown)
            window.addEventListener('keyup', handleCtrlUp)
        }

        return () => {
            if (canvas) {
                canvas.removeEventListener('wheel', handleWheel)
                window.removeEventListener('keydown', handleCtrlDown)
                window.removeEventListener('keyup', handleCtrlUp)
            }
        }
    }, [canvas])

    const handleZoomChange = (e) => {
        if (e.target.value === '') {
            setZoomInput('')
            return
        }
        const newZoom = parseFloat(e.target.value)
        setZoomInput(newZoom)
    }

    const handleZoomSubmit = () => {
        if (zoomInput === '') {
            setZoomInput(zoomPercentage)
            setIsEditing(false)
            return
        }
        const newZoom = zoomInput / 100
        //Round up to integer
        if (zoomInput === _.ceil(zoom * 100)) {
            setIsEditing(false)
            return
        }
        const action = newZoom > zoom ? 'in' : 'out'
        handleZoom(action, false)
        setIsEditing(false)
    }

    const handleZoom = (action, fromEvent) => {
        clearSelection()
        const rect = container?.getBoundingClientRect()
        let previousZoom
        if (fromEvent)
            previousZoom = parseFloat(container?.style.transform.split('(')[1].split(')')[0])
        else previousZoom = zoom

        const currentCenterX = window.innerWidth / 2
        const currentCenterY = window.innerHeight / 2
        const centerXRelToGrid = (currentCenterX - rect?.left) / previousZoom
        const centerYRelToGrid = (currentCenterY - rect?.top) / previousZoom
        let newZoom
        const zoomStep = fromEvent ? 0.025 : 0.05

        if (action === 'in') {
            if (previousZoom !== zoomInput / 100 && !fromEvent) {
                if (previousZoom < 0.05) {
                    newZoom = Math.max(0.05, parseFloat((zoomInput / 100).toFixed(2)))
                } else {
                    newZoom = Math.min(1.5, parseFloat((zoomInput / 100).toFixed(2)))
                }
            } else {
                if (previousZoom < 0.05) {
                    newZoom = Math.max(0.05, parseFloat((zoomInput / 100).toFixed(2)))
                } else {
                    newZoom = Math.min(1.5, parseFloat((previousZoom + zoomStep).toFixed(2)))
                }
            }
        } else {
            if (previousZoom !== zoomInput / 100 && !fromEvent) {
                if (previousZoom > 0.05)
                    newZoom = Math.max(0.05, parseFloat((zoomInput / 100).toFixed(2)))
                else newZoom = previousZoom
            } else {
                newZoom = parseFloat((previousZoom - zoomStep).toFixed(2))
            }
            const newMaxScrollX = width - window.innerWidth - (width - Math.round(width * newZoom))
            if (newMaxScrollX < 0) {
                newZoom = window.innerWidth / width
            }
        }

        const newScrollLeft = centerXRelToGrid * newZoom - window.innerWidth / 2
        const newScrollTop = centerYRelToGrid * newZoom - window.innerHeight / 2

        if (shouldZoomToCenter() && !fromEvent) {
            canvas.scrollTo({
                left: newScrollLeft,
                top: newScrollTop + 115,
                behavior: 'instant',
            })
        }
        if (fromEvent && newZoom > 0.2) {
            canvas.scrollTo({
                left: newScrollLeft,
                top: newScrollTop + 115,
                behavior: 'instant',
            })
        }

        dispatch(setZoom(newZoom))
        setZoomInput(Math.round(newZoom * 100))
    }

    return (
        <Box className={'zoom-controls'}>
            <IconButton onClick={() => handleZoom('out')} disabled={maxScroll === 0}>
                <img
                    src={zoom < 1 ? MagnifyingGlassMinusPink : MagnifyingGlassMinus}
                    alt={'zoom-minus'}
                />
            </IconButton>
            <Box className={'percentage-box'}>
                {isEditing ? (
                    <Input
                        disableMargin
                        type={'number'}
                        value={zoomInput}
                        onChange={handleZoomChange}
                        onBlur={handleZoomSubmit}
                        autoFocus
                    />
                ) : (
                    <Typography sx={{ margin: '0 8px' }} onClick={() => setIsEditing(true)}>
                        {zoomPercentage}%
                    </Typography>
                )}
            </Box>

            <IconButton onClick={() => handleZoom('in')} disabled={zoom === 2}>
                <img
                    src={zoom > 1 ? MagnifyingGlassPlusPink : MagnifyingGlassPlus}
                    alt={'zoom-plus'}
                />
            </IconButton>
        </Box>
    )
}
