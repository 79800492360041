import {
    ADD_ARROW,
    ADD_FRAME,
    ADD_OBJECTS,
    ADD_PROMPT,
    ADD_TEXT_EDITOR,
    APPLY_LOADED_OBJECTS_STATE,
    BRING_TO_FRONT,
    DELETE_ARROW,
    DELETE_FRAME,
    DELETE_PROMPT,
    DELETE_SHAPE,
    DELETE_TEXT_EDITOR,
    REMOVE_OBJECTS,
    SEND_TO_BACK,
    SET_TEXT_EDITOR_TABLE_RANGE,
    UPDATE_ALL_FRAMES_BACKGROUND_IMAGE,
    UPDATE_ARROW,
    UPDATE_FRAME_BACKGROUND_COLOR,
    UPDATE_FRAME_BACKGROUND_IMAGE,
    UPDATE_FRAME_HIDDEN,
    UPDATE_FRAME_POSITION,
    UPDATE_FRAME_PRESENTATION_INDEX,
    UPDATE_FRAME_SIZE,
    UPDATE_FRAME_TITLE,
    UPDATE_GENERIC_SHAPE_POSITION,
    UPDATE_GENERIC_SHAPE_SIZE,
    UPDATE_PROMPT_POSITION,
    UPDATE_PROMPT_PROPS,
    UPDATE_PROMPT_SIZE,
    UPDATE_TEXT_EDITOR,
} from '../actions'

import {
    ADD_KPI,
    DELETE_KPI,
    UPDATE_KPI_POSITION,
    UPDATE_KPI_SIZE,
    UPDATE_KPI_TITLE,
} from '../actions'
import { KPIClass } from '../../components/Objects/KPI/KPIClass'

import {
    ADD_Worksheet,
    DELETE_Worksheet,
    UPDATE_Worksheet_POSITION,
    UPDATE_Worksheet_SIZE,
    UPDATE_Worksheet_TITLE,
} from '../actions'

import { WorksheetClass } from '../../components/Objects/Worksheet/WorksheetClass'

import {
    ADD_INSIGHT,
    DELETE_INSIGHT,
    EDIT_INSIGHT,
    UPDATE_INSIGHT_POSITION,
    UPDATE_INSIGHT_SIZE,
    UPDATE_INSIGHT_TITLE,
    UPDATE_INSIGHT_CONTENT,
    ADD_SHEETLET,
    DELETE_SHEETLET,
    EDIT_SHEETLET,
    UPDATE_SHEETLET_POSITION,
    UPDATE_SHEETLET_SIZE,
    UPDATE_SHEETLET_TITLE,
    UPDATE_SHEETLET_CONTENT,
    UPDATE_SHEETLET_GRID,
    UPDATE_SHEETLET_HEADERS,
    UPDATE_OBJECT_STYLE,
    ADD_PROCESSDIAGRAM,
    DELETE_PROCESSDIAGRAM,
    EDIT_PROCESSDIAGRAM,
    UPDATE_PROCESSDIAGRAM_POSITION,
    UPDATE_PROCESSDIAGRAM_SIZE,
    UPDATE_PROCESSDIAGRAM_TITLE,
    UPDATE_PROCESSDIAGRAM_CONTENT,
    ADD_IMAGE,
    UPDATE_IMAGE_POSITION,
    UPDATE_IMAGE_SIZE,
    DELETE_IMAGE,
    EDIT_IMAGE,
    UPDATE_IMAGE_CONTENT,
    ADD_VIDEO,
    UPDATE_VIDEO_POSITION,
    UPDATE_VIDEO_SIZE,
    DELETE_VIDEO,
    EDIT_VIDEO,
    UPDATE_VIDEO_CONTENT,
} from '../actions'

import { UPDATE_ELEMENT_TITLE } from '../actions'

import { InsightClass } from '../../components/Objects/Insight/InsightClass'
import { SheetletClass } from '../../components/Objects/Sheetlet/SheetletClass'
import { TextEditorClass } from '../../components/Objects/TextEditor/TextEditorClass'
import { ProcessDiagramClass } from '../../components/Objects/ProcessDiagram/ProcessDiagramClass'
import { ImageClass } from '../../components/Objects/Image/ImageClass'
import { VideoClass } from '../../components/Objects/Video/VideoClass'
import { PromptClass } from '../../components/Objects/Prompt/PromptClass'
import { FrameClass } from '../../components/Objects/Frame/FrameClass'
import { OBJECT_TYPES } from '../../components/Objects/types'

const initialState = []

// TODO - UNIFY ALL THE UPDATE SIZE AND POSITIONS FUNCTIONALITY

const objectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_TO_BACK:
        case BRING_TO_FRONT:
            const reorderObject = state.filter((obj) => obj.id === action.payload.id)[0]
            const frames = state.filter((obj) => obj.type === OBJECT_TYPES.FRAME)
            const rest = state.filter(
                (obj) => obj.id !== action.payload.id && obj.type !== OBJECT_TYPES.FRAME
            )
            return action.type === SEND_TO_BACK
                ? [...frames, reorderObject, ...rest]
                : [...frames, ...rest, reorderObject]
        case APPLY_LOADED_OBJECTS_STATE:
            return action.payload
        case ADD_KPI:
            return [
                ...state,
                new KPIClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height
                ),
            ]
        case DELETE_KPI:
            return state.filter((obj) => obj.id !== action.payload.id)

        case UPDATE_KPI_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_KPI_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_KPI_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )

        case ADD_Worksheet:
            return [
                ...state,
                new WorksheetClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height
                ),
            ]

        case DELETE_Worksheet:
            return state.filter((obj) => obj.id !== action.payload.id)

        case UPDATE_Worksheet_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_Worksheet_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_Worksheet_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )

        case ADD_INSIGHT:
            return [
                ...state,
                new InsightClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content,
                    action.payload.wrapperStyles
                ),
            ]

        case DELETE_INSIGHT:
            return state.filter((obj) => obj.id !== action.payload.id)

        case EDIT_INSIGHT:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                      }
                    : obj
            )

        case UPDATE_INSIGHT_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_INSIGHT_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_INSIGHT_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )

        case UPDATE_INSIGHT_CONTENT:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, content: action.payload.content } : obj
            )

        case ADD_SHEETLET:
            return [
                ...state,
                new SheetletClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content,
                    action.payload.wrapperStyles
                ),
            ]

        case DELETE_SHEETLET:
            return state.filter((obj) => obj.id !== action.payload.id)

        case EDIT_SHEETLET:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                      }
                    : obj
            )

        case UPDATE_SHEETLET_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_SHEETLET_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_SHEETLET_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )

        case UPDATE_SHEETLET_CONTENT:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, content: action.payload.content } : obj
            )

        case UPDATE_SHEETLET_GRID:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? { ...obj, shouldHideGrid: action.payload.shouldHideGrid }
                    : obj
            )

        case UPDATE_SHEETLET_HEADERS:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          shouldHideHeaders: action.payload.shouldHideHeaders,
                      }
                    : obj
            )

        case UPDATE_OBJECT_STYLE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? { ...obj, wrapperStyles: action.payload.wrapperStyles }
                    : obj
            )

        case ADD_TEXT_EDITOR:
            return [
                ...state,
                new TextEditorClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content,
                    action.payload.border
                ),
            ]

        case DELETE_TEXT_EDITOR:
            return state.filter((obj) => obj.id !== action.payload.id)

        case UPDATE_TEXT_EDITOR:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          content: action.payload.content,
                          border: action.payload.border,
                      }
                    : obj
            )

        case SET_TEXT_EDITOR_TABLE_RANGE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          worksheetID: action.payload.worksheetId,
                          namedRange: action.payload.selectedWorksheetRange,
                          fitData: action.payload.fitData,
                      }
                    : obj
            )

        case ADD_ARROW:
            return [...state, action.payload]

        case DELETE_ARROW:
            return state.filter((obj) => obj.id !== action.payload.id)

        case UPDATE_ARROW:
            return state.map((obj) => {
                if (obj.id === action.payload.id) {
                    const temp = { ...obj }
                    temp[action.payload.update.key] = action.payload.update.value
                    return temp
                } else {
                    return obj
                }
            })

        case ADD_PROCESSDIAGRAM:
            return [
                ...state,
                new ProcessDiagramClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content,
                    action.payload.wrapperStyles
                ),
            ]

        case DELETE_PROCESSDIAGRAM:
            return state.filter((obj) => obj.id !== action.payload.id)

        case EDIT_PROCESSDIAGRAM:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                      }
                    : obj
            )

        case UPDATE_PROCESSDIAGRAM_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_PROCESSDIAGRAM_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_PROCESSDIAGRAM_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )

        case UPDATE_PROCESSDIAGRAM_CONTENT:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, content: action.payload.content } : obj
            )

        case ADD_IMAGE:
            return [
                ...state,
                new ImageClass(
                    action.payload.id,
                    'My Image',
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content
                ),
            ]

        case DELETE_IMAGE:
            return state.filter((obj) => obj.id !== action.payload.id)

        case EDIT_IMAGE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                      }
                    : obj
            )

        case UPDATE_IMAGE_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_IMAGE_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_IMAGE_CONTENT:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, content: action.payload.content } : obj
            )

        case ADD_VIDEO:
            return [
                ...state,
                new VideoClass(
                    action.payload.id,
                    'My Video',
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.content
                ),
            ]

        case DELETE_VIDEO:
            return state.filter((obj) => obj.id !== action.payload.id)

        case EDIT_VIDEO:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                      }
                    : obj
            )

        case UPDATE_VIDEO_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )

        case UPDATE_VIDEO_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )

        case UPDATE_VIDEO_CONTENT:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, content: action.payload.content } : obj
            )

        case ADD_OBJECTS:
            return [...state, ...action.payload]
        case REMOVE_OBJECTS:
            const deletedFrames = state.filter(
                (obj) => action.payload.includes(obj.id) && obj.type === OBJECT_TYPES.FRAME
            )
            const updatedState = state.filter(
                (obj) => !deletedFrames.some((deleted) => deleted.id === obj.id)
            )
            updatedState.forEach((obj) => {
                if (obj.type === OBJECT_TYPES.FRAME) {
                    const shift = deletedFrames.filter(
                        (deleted) => deleted.presentationIndex < obj.presentationIndex
                    ).length
                    if (shift > 0) obj.presentationIndex -= shift
                }
            })
            return updatedState.filter((obj) => !action.payload.includes(obj.id))
        case ADD_PROMPT:
            return [
                ...state,
                new PromptClass(
                    action.payload.id,
                    action.payload.x,
                    action.payload.y,
                    action.payload.width,
                    action.payload.height,
                    action.payload.promptProps
                ),
            ]
        case DELETE_PROMPT:
            return state.filter((obj) => obj.id !== action.payload.id)
        case UPDATE_PROMPT_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )
        case UPDATE_PROMPT_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )
        case UPDATE_PROMPT_PROPS:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          promptProps: action.payload.props,
                      }
                    : obj
            )
        case ADD_FRAME:
            const lastFrame = state.filter((obj) => obj.type === OBJECT_TYPES.FRAME)[0]
            return [
                new FrameClass(
                    action.payload.id,
                    action.payload.title,
                    action.payload.x,
                    action.payload.y,
                    lastFrame?.width || action.payload.width,
                    lastFrame?.height || action.payload.height,
                    lastFrame?.backgroundColor || action.payload.backgroundColor,
                    action.payload.presentationIndex,
                    action.payload.hidden,
                    lastFrame?.backgroundImage || action.payload.backgroundImage
                ),
                ...state,
            ]
        case DELETE_FRAME:
            const deletedFrame = state.filter((obj) => obj.id === action.payload.id)[0]
            return state
                .filter((obj) => obj.id !== action.payload.id)
                .map((obj) => {
                    if (
                        obj.type === OBJECT_TYPES.FRAME &&
                        obj.presentationIndex > deletedFrame.presentationIndex
                    ) {
                        return {
                            ...obj,
                            presentationIndex: obj.presentationIndex - 1,
                        }
                    } else {
                        return obj
                    }
                })
        case UPDATE_FRAME_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )
        case UPDATE_FRAME_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )
        case UPDATE_FRAME_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )
        case UPDATE_FRAME_HIDDEN:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, hidden: action.payload.hidden } : obj
            )
        case UPDATE_FRAME_BACKGROUND_COLOR:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          backgroundColor: action.payload.backgroundColor,
                      }
                    : obj
            )
        case UPDATE_FRAME_BACKGROUND_IMAGE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? { ...obj, backgroundImage: action.payload.imageUrl }
                    : obj
            )
        case UPDATE_ALL_FRAMES_BACKGROUND_IMAGE:
            return state.map((obj) =>
                obj.type === OBJECT_TYPES.FRAME
                    ? { ...obj, backgroundImage: action.payload.imageUrl }
                    : obj
            )
        case UPDATE_FRAME_PRESENTATION_INDEX:
            const result = action.payload.result
            const draggedFrame = state
                .filter((obj) => obj.type === OBJECT_TYPES.FRAME)
                .filter((frame) => frame.id === parseFloat(result.draggableId.split('-')[1]))[0]
            const offset = result.destination.index - draggedFrame.presentationIndex
            return state.map((obj) => {
                if (obj.type === OBJECT_TYPES.FRAME) {
                    if (obj.id === draggedFrame.id)
                        return {
                            ...obj,
                            presentationIndex: result.destination.index,
                        }
                    if (
                        offset > 0 &&
                        obj.presentationIndex > draggedFrame.presentationIndex &&
                        obj.presentationIndex <= result.destination.index
                    )
                        return {
                            ...obj,
                            presentationIndex: obj.presentationIndex - 1,
                        }
                    if (
                        offset < 0 &&
                        obj.presentationIndex < draggedFrame.presentationIndex &&
                        obj.presentationIndex >= result.destination.index
                    )
                        return {
                            ...obj,
                            presentationIndex: obj.presentationIndex + 1,
                        }
                    return obj
                } else {
                    return obj
                }
            })
        case UPDATE_GENERIC_SHAPE_POSITION:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          x: action.payload.position.x,
                          y: action.payload.position.y,
                      }
                    : obj
            )
        case UPDATE_GENERIC_SHAPE_SIZE:
            return state.map((obj) =>
                obj.id === action.payload.id
                    ? {
                          ...obj,
                          width: action.payload.size.width,
                          height: action.payload.size.height,
                      }
                    : obj
            )
        case DELETE_SHAPE:
            return state.filter((obj) => obj.id !== action.payload.id)
        case UPDATE_ELEMENT_TITLE:
            return state.map((obj) =>
                obj.id === action.payload.id ? { ...obj, title: action.payload.title } : obj
            )
        default:
            return state
    }
}

export default objectReducer
