// index.js is the entry point for the auth API module.`
import { Auth } from 'aws-amplify'

export const login = (body) => {
    return Auth.signIn(body.username, body.password)
}

export const resendToken = (body) => {
    return Auth.resendSignUp(body.username)
}

export const signUp = (body) => {
    return Auth.signUp({
        username: body.username,
        password: body.password,
        attributes: {
            email: body.email,
        },
    })
}

export const confirmSignUp = (body) => {
    return Auth.confirmSignUp(body.username, body.code)
}

export const signOut = () => {
    return Auth.signOut()
}

export const initiateForgotPassword = (body) => {
    return Auth.forgotPassword(body.username)
}

export const submitNewPassword = (body) => {
    return Auth.forgotPasswordSubmit(body.username, body.code, body.new_password)
}

export const getCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser()
}

export const refreshToken = () => {
    return Auth.currentSession()
}

export const federatedSignIn = (provider) => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    const amplifyConfig = {
        Auth: {
            mandatorySignIn: true,
            region: 'us-west-2',
            userPoolId: 'us-west-2_4RyltqyJ7',
            identityPoolId: 'us-west-2:cc279e26-fc27-4b69-ab62-b4f5817d615b',
            userPoolWebClientId: '76atr62rn4iuk8uhj8r8gt39ms',
            oauth: {
                domain: 'auth.scoop-analytics.com',
                scope: ['openid', 'email', 'profile'],
                redirectSignIn: baseUrl + '/googlelogin',
                redirectSignOut: baseUrl + '/logout',
                responseType: 'code',
                identityProviders: ['Google'],
            },
        },
    }
    Auth.configure(amplifyConfig)
    Auth.federatedSignIn()
}
