import { useCallback, useEffect } from 'react'
import { OBJECT_TYPES } from '../components/Objects/types'

const useKeyboardShortcuts = (keyCombination, callback) => {
    const keyHandler = useCallback(
        (event) => {
            const { ctrlKey, metaKey, key } = event
            if (
                (ctrlKey || metaKey || keyCombination === 'Backspace') &&
                key?.toLowerCase() === keyCombination.toLowerCase()
            ) {
                callback()
            }
        },
        [keyCombination, callback]
    )

    useEffect(() => {
        window.addEventListener('keydown', keyHandler)
        return () => window.removeEventListener('keydown', keyHandler)
    }, [keyHandler])
}

export const transformCopiedObjects = (objects, zoom, currentFrames) => {
    const canvasScrollElement = document.getElementById('canvas-scrollable')
    const lastPresentationIndex = currentFrames.length - 1
    let presentationPointer = 0
    return objects.copyObjects.map((obj) => {
        if (obj.type === OBJECT_TYPES.ARROW) {
            return {
                ...obj,
                id: Math.random(),
                endInitialPosition: {
                    x:
                        15 +
                        obj.endInitialPosition.x +
                        (canvasScrollElement ? canvasScrollElement.scrollLeft / zoom : 0) -
                        objects.scrollLeft / zoom,
                    y:
                        15 +
                        obj.endInitialPosition.y +
                        (canvasScrollElement ? canvasScrollElement.scrollTop / zoom : 0) -
                        objects.scrollTop / zoom,
                },
                startInitialPosition: {
                    x:
                        15 +
                        obj.startInitialPosition.x +
                        (canvasScrollElement ? canvasScrollElement.scrollLeft / zoom : 0) -
                        objects.scrollLeft / zoom,
                    y:
                        15 +
                        obj.startInitialPosition.y +
                        (canvasScrollElement ? canvasScrollElement.scrollTop / zoom : 0) -
                        objects.scrollTop / zoom,
                },
            }
        }
        if (obj.type === OBJECT_TYPES.FRAME) {
            presentationPointer++
            return {
                ...obj,
                id: Math.random(),
                x:
                    15 +
                    obj.x +
                    (canvasScrollElement ? canvasScrollElement.scrollLeft / zoom : 0) -
                    objects.scrollLeft / zoom,
                y:
                    15 +
                    obj.y +
                    (canvasScrollElement ? canvasScrollElement.scrollTop / zoom : 0) -
                    objects.scrollTop / zoom,
                presentationIndex: lastPresentationIndex + presentationPointer,
            }
        }
        if (obj.type === OBJECT_TYPES.GENERIC_SHAPE && obj.content.includes('drawObject')) {
            const split = obj.content.split('id=')
            const idReplace = split[1].split(' ')
            idReplace.shift()
            return {
                ...obj,
                id: Math.random(),
                content: split[0] + 'id=' + "'" + Math.random() + "'" + ' ' + idReplace.join(' '),
                x:
                    15 +
                    obj.x +
                    (canvasScrollElement ? canvasScrollElement.scrollLeft / zoom : 0) -
                    objects.scrollLeft / zoom,
                y:
                    15 +
                    obj.y +
                    (canvasScrollElement ? canvasScrollElement.scrollTop / zoom : 0) -
                    objects.scrollTop / zoom,
            }
        }
        return {
            ...obj,
            id: Math.random(),
            x:
                15 +
                obj.x +
                (canvasScrollElement ? canvasScrollElement.scrollLeft / zoom : 0) -
                objects.scrollLeft / zoom,
            y:
                15 +
                obj.y +
                (canvasScrollElement ? canvasScrollElement.scrollTop / zoom : 0) -
                objects.scrollTop / zoom,
        }
    })
}

export default useKeyboardShortcuts
