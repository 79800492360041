// InsightEdit.js
import React, { useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Divider from '@mui/material/Divider'
import DeleteIcon from '@mui/icons-material/Delete'

import { useDispatch } from 'react-redux'
import { deleteInsight } from '../../../store/actions'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { OpenDialog } from '../../Insights/OpenDialog'
import { useSelector } from 'react-redux'
import { loadFromSavedInsight, saveInsight } from './InsightAPI'
import { useApi } from '../../../api/api'
import SaveChangesDialog from '../../common/Dialog/SaveChangesDialog'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import PaletteIcon from '@mui/icons-material/Palette'
import { setShowEditElementWrapper } from '../../../store/actions/uiActions'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}))

const modalBoxStyle = {
    position: 'absolute',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '95vh',
    backgroundColor: '#27142c',
    boxShadow: 24,
}

export default function InsightEdit({
    setIsMenuOpen,
    config,
    chartState,
    setConfig,
    isChartASkeleton,
    setIsChartASkeleton,
    server,
    title,
    id,
    insightKey,
    handleTitleChange,
    workspaceMetadata,
    insightPrompts,
    updateInsight,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isAlertOpen, setIsAlertOpen] = React.useState(false)
    const [loadOpen, setLoadOpen] = React.useState(false)
    const [selectedItemToLoad, setSelectedItemToLoad] = React.useState(null)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

    // Get auth details from the state
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const { postData } = useApi() // Use the useApi hook here
    const dispatch = useDispatch()
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const { canvasID } = useParams()

    const handleClick = (event) => {
        setIsMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        const actionType = event.actionType
        if (actionType === 'delete') {
            setIsAlertOpen(true)
        } else if (actionType === 'edit') {
            navigate(ROUTES.EXPLORER, {
                state: {
                    editmodeConfig: config,
                    canvasID: canvasID,
                    insightKey: insightKey,
                    insightName: title,
                },
            })
        } else if (actionType === 'load') {
            setLoadOpen(true)
        } else {
            setIsMenuOpen(false)
        }
        setAnchorEl(null)
    }

    const handleDelete = () => {
        dispatch(deleteInsight(id))
        setIsMenuOpen(false)
        setIsAlertOpen(false)
        setAnchorEl(null)
    }

    const handleLoadFromSavedInsight = (insight) => {
        if (insight) {
            handleTitleChange({ target: { value: insight.insightName } })
            loadFromSavedInsight(
                insight,
                setConfig,
                chartState,
                setIsChartASkeleton,
                dispatch,
                id,
                selectedItemToLoad,
                workspaceID,
                insightPrompts,
                workspaceMetadata,
                title !== 'My Chart'
            )
        }
    }

    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize="medium" color="info" />
                </IconButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => dispatch(setShowEditElementWrapper([true, id]))}
                        disableRipple
                    >
                        <PaletteIcon />
                        Style
                    </MenuItem>
                    {!isChartASkeleton && (
                        <MenuItem
                            onClick={(event) => {
                                event.actionType = 'edit'
                                handleClose(event)
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Edit
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'load'
                            handleClose(event)
                        }}
                        disableRipple
                    >
                        <FileOpenIcon />
                        Load
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'delete'
                            handleClose(event)
                        }}
                        disableRipple
                    >
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </StyledMenu>
            </div>
            {isAlertOpen && (
                <DeleteDialog
                    open={isAlertOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    title={title}
                    handleCancel={() => setIsAlertOpen(false)}
                    handleDelete={handleDelete}
                    type={'Insight'}
                />
            )}
            <OpenDialog
                setAnchor={setAnchorEl}
                open={loadOpen}
                setOpen={setLoadOpen}
                setInsight={handleLoadFromSavedInsight}
                workspaceMetadata={workspaceMetadata}
                server={server}
                setSelectedItemToLoad={setSelectedItemToLoad}
            />
        </>
    )
}
