import React from 'react'
import './InsightsGridDrillCell.css'

const InsightsGridDrillCell = ({ onClick, value }) => {
    return (
        <span className={'cell-value'} onClick={(e) => onClick(e, value)}>
            {value}
        </span>
    )
}

export default InsightsGridDrillCell
