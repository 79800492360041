import { Box, DialogContentText } from '@mui/material'
import * as React from 'react'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

import Dialog from '../../common/Dialog/Dialog'
import Button from '../../common/Button/Button'
import { ScoopDatePicker } from '../../common/DatePicker/ScoopDatePicker'
import { useState } from 'react'

export function DateFilter({
    isOpen,
    closeModal,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleApplyDateChange,
}) {
    if (!startDate || !endDate) {
        startDate = moment().subtract(12, 'months').format('MM/DD/YYYY')
        endDate = moment().format('MM/DD/YYYY')
        setStartDate(moment().subtract(12, 'months').format('MM/DD/YYYY'))
        setEndDate(moment().format('MM/DD/YYYY'))
    }

    const [from, setFrom] = React.useState(new Date(startDate))
    const [to, setTo] = React.useState(new Date(endDate))

    /*
    React.useEffect(() => {
        setFrom(moment(startDate));
        setTo(moment(endDate));
    }, [startDate, endDate])*/

    function handleClose(event) {
        closeModal(false)
    }

    function handleSave() {
        console.log(from, to)
        handleApplyDateChange(from, to)
        closeModal(false)
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'}></Button>
            <Button onClick={handleSave} className={'button-purple'} text={'Apply'}></Button>
        </Box>
    )

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            title={'Date Range for Snapshots'}
            actions={actions}
        >
            <Box
                sx={{
                    display: 'flex',
                    m: 0,
                    mt: 2,
                    mb: 2,
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', gap: '12px', width: '100%' }}>
                    <ScoopDatePicker
                        label="From"
                        value={from}
                        onChange={(newValue) => setFrom(newValue)}
                        sx={{ mr: 2 }} // Add margin to the right of the 'From' DatePicker
                    />
                    <ScoopDatePicker
                        label="To"
                        value={to}
                        onChange={(newValue) => setTo(newValue)}
                        // If you want, you can also add margin to the left: sx={{ ml: 2 }}
                    />
                    {/*<ScoopDatePicker
                        label="From"
                        value={from}
                        onChange={(newValue) => setFrom(newValue)}
                    />
                    <ScoopDatePicker
                        label="To"
                        value={to}
                        onChange={(newValue) => setTo(newValue)}
                    />*/}
                </Box>
            </Box>
        </Dialog>
    )
}
