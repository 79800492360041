import React, { useEffect, useState } from 'react'
import { signOut } from '../../../api/auth'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { setAuthenticated } from '../../../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'

export const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    signOut().then(() => {
        dispatch(setAuthenticated(false))
        navigate(ROUTES.CANVAS)
    })
}
