import React from 'react'
import Button from '../Button/Button'
import { Typography } from '@mui/material'
import Dialog from './Dialog'
import { useDispatch } from 'react-redux'
import { toggleSwitchWorkspaceModal } from '../../../store/actions/uiActions'

export const TourAlertDialog = ({ open, setOpen, item }) => {
    const dispatch = useDispatch()

    const handleSwitchWorkspace = () => {
        setOpen(false)
        dispatch(toggleSwitchWorkspaceModal(true))
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            actions={[
                <Button className={'small'} onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button className={'button-purple small'} onClick={handleSwitchWorkspace}>
                    Switch workspace
                </Button>,
            ]}
            title={'Unable to create ' + item}
        >
            <Typography
                className={'inter'}
            >{`You cannot add a new ${item} to a Tour workspace.`}</Typography>
            <Typography
                className={'inter'}
            >{`Please switch to your personal workspace to add your own ${item}!`}</Typography>
        </Dialog>
    )
}
