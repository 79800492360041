import React, { useEffect, useState, useMemo } from 'react'

import { useApi } from '../../../api/api'
import { useDropzone } from 'react-dropzone'
import { Provider, useDispatch, useSelector } from 'react-redux'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Divider,
    Box,
    TextField,
    Select,
    MenuItem,
    LinearProgress,
    Menu,
    Input,
} from '@mui/material'
import { set } from 'lodash'
import Dialog from '../../common/Dialog/Dialog'
import Button from '../../common/Button/Button'

import { styled, alpha } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { deleteVideo, updateVideoContent } from '../../../store/actions'
import DeleteDialog from '../../common/Dialog/DeleteDialog'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}))

function createYoutubeEmbedURL(url) {
    // Check if the URL is already an embed URL
    if (url.includes('/embed/')) {
        return url
    }

    // Extract the video ID from the URL
    const videoIdMatch = url.match(/(?:\?v=|&v=|\/v\/)([^&?\/]+)/)

    // Check if a video ID was found
    if (videoIdMatch && videoIdMatch.length > 1) {
        const videoId = videoIdMatch[1]
        // Return the formatted embed URL
        return `https://www.youtube.com/embed/${videoId}`
    } else {
        // Return null or an appropriate response if no video ID is found
        return null
    }
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
}

const focusedStyle = {
    borderColor: '#2196f3',
}

const acceptStyle = {
    borderColor: '#00e676',
}

const rejectStyle = {
    borderColor: '#ff1744',
}

export default function VideoEdit({ isMenuOpen, setIsMenuOpen, id }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const menuRef = React.useRef()
    const buttonRef = React.useRef()
    const [isAlertOpen, setIsAlertOpen] = React.useState(false)
    const dispatch = useDispatch()

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setIsMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        const actionType = event.actionType
        if (actionType === 'edit') {
            setIsLoadDialogOpen(true)
        } else if (actionType === 'delete') {
            setIsAlertOpen(true)
        } else {
            setIsMenuOpen(false)
            setAnchorEl(null)
        }
    }

    const handleDelete = () => {
        // console.log("Deleting Video: " + id);
        dispatch(deleteVideo(id))
        setIsMenuOpen(false)
        setIsAlertOpen(false)
        setAnchorEl(null)
    }

    const handleSaveVideo = () => {
        dispatch(
            updateVideoContent(id, {
                videoURL: createYoutubeEmbedURL(videoURL),
            })
        )
        setIsMenuOpen(false)
        setIsLoadDialogOpen(false)
    }

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        let timer
        if (progress > 0 && progress < 100) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + 3, 100))
            }, 1000)
        } else if (progress >= 100) {
            clearInterval(timer)
        }
        return () => {
            clearInterval(timer)
        }
    }, [progress])

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')
    const [isLoadDialogOpen, setIsLoadDialogOpen] = useState(false)
    const [videoURL, setVideoURL] = useState('')

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setOpenSnackbar(true)
    }

    const dialogActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={() => setIsLoadDialogOpen(false)} color="primary" text={'Cancel'} />
            <Button
                onClick={handleSaveVideo}
                autoFocus
                text={'Upload'}
                disabled={videoURL.length < 10}
                className={'primary-button button-purple'}
            />
        </Box>
    )

    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize="large" color="info" />
                </IconButton>
                {isMenuOpen && (
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={(event) => {
                                event.actionType = 'edit'
                                handleClose(event)
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Edit
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                            onClick={(event) => {
                                event.actionType = 'delete'
                                handleClose(event)
                            }}
                            disableRipple
                        >
                            <DeleteIcon />
                            Delete
                        </MenuItem>
                    </StyledMenu>
                )}
            </div>

            <Dialog
                sx={{ '& .MuiFormControl-root': { gap: '8px !important' } }}
                maxWidth={false}
                style={{ minWidth: '800px' }}
                title={
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Typography
                            sx={{
                                color: '#201024',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '110%',
                                letterSpacing: '-0.4px',
                            }}
                        >
                            Add video
                        </Typography>
                    </Box>
                }
                onClose={() => setIsLoadDialogOpen(false)}
                open={isLoadDialogOpen}
                actions={dialogActions}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#979099',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                            letterSpacing: '-0.24px',
                        }}
                    >
                        Paste a URL to a YouTube or Loom video
                    </Typography>
                    <Input value={videoURL} onChange={(e) => setVideoURL(e.target.value)} />
                </Box>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <DeleteDialog
                open={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title="this video"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                type={'Video'}
                handleCancel={() => setIsAlertOpen(false)}
                handleDelete={handleDelete}
            />
        </>
    )
}
