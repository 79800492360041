import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { propExistsOnObject, renderColorPicker } from '../utils'
import Plus from '../../../../../../assets/icons/Plus.svg'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { ScoopTheme } from '../../../../../Insights/Style'
import DotsSixVerticalGrey from '../../../../../../assets/icons/DotsSixVerticalGrey.svg'
import { StrictModeDroppable } from '../../../../../Objects/Frame/FrameDrawer'
import { Switch } from '../../../../../common/Switch/Switch'
import { cloneDeep } from 'lodash'

export const ColorConfig = ({ config, setConfig, theme, server }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(theme?.colorScheme.darkTheme)

    const getBackgroundColor = () => {
        if (propExistsOnObject(config.styleOverrides, 'backgroundColor'))
            return config.styleOverrides.backgroundColor
        if (theme?.colorScheme) return theme.colorScheme.backgroundColor
        return ScoopTheme.backgroundColor
    }

    const getColorPalette = () => {
        if (propExistsOnObject(config.styleOverrides, 'color')) return config.styleOverrides.color
        if (theme?.colorScheme) return theme.colorScheme.colors.map((c) => c.val)
        return ScoopTheme.color
    }

    const handleBackgroundColorChange = (color) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.backgroundColor = color
        setConfig(newConfig)
    }

    const handlePaletteChange = (newColor, index) => {
        const newPalette = [...getColorPalette()]
        newPalette[index] = newColor
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.color = newPalette
        setConfig(newConfig)
    }

    const onDragEnd = (result) => {
        if (!result.destination) return

        const newPalette = Array.from(getColorPalette())
        const [moved] = newPalette.splice(result.source.index, 1)
        newPalette.splice(result.destination.index, 0, moved)
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.color = newPalette
        setConfig(newConfig)
    }

    const toggleTheme = () => {
        server.postData(
            {
                action: 'putObject',
                class: 'scoop.presentation.Theme',
                value: {
                    themeID: theme.themeID,
                    canvasID: theme.canvasID,
                    themeName: theme.themeName,
                    fonts: theme.fonts,
                    chartPreferences: theme.chartPreferences,
                    colorScheme: {
                        ...theme.colorScheme,
                        darkTheme: !isDarkTheme,
                    },
                },
            },
            () => setIsDarkTheme(!isDarkTheme)
        )
    }

    return (
        <>
            <Box className={'column'} sx={{ gap: '8px' }}>
                {theme && (
                    <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                        <Switch checked={isDarkTheme} onClick={() => toggleTheme()} />
                        <Typography className={'inter style-config-label'}>Dark theme</Typography>
                    </Box>
                )}
            </Box>
            <Box className={'column'} sx={{ gap: '16px' }}>
                {renderColorPicker(
                    'Background color',
                    getBackgroundColor(),
                    handleBackgroundColorChange
                )}
            </Box>
            {config.view === 'chart' && (
                <Box
                    className={'column'}
                    sx={{
                        borderTop: '1px solid #E6E4E6',
                        gap: '8px',
                        padding: '16px 0',
                    }}
                >
                    <Box className={'row'} sx={{ justifyContent: 'space-between' }}>
                        <Typography className={'style-config-label'}>Chart colors</Typography>
                        <img style={{ width: '18px' }} src={Plus} alt={'Plus'} />
                    </Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId={'droppable'}>
                            {(provided) => (
                                <Box
                                    className={'column'}
                                    sx={{ gap: '0' }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {getColorPalette().map((color, index) => (
                                        <Draggable
                                            key={color}
                                            draggableId={'draggable-' + color}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Box
                                                    className={'row'}
                                                    sx={{
                                                        gap: '4px',
                                                        marginBottom: '4px',
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <img
                                                        style={{
                                                            cursor: 'grab',
                                                        }}
                                                        src={DotsSixVerticalGrey}
                                                        alt={'drag'}
                                                    />
                                                    {renderColorPicker(false, color, (newColor) =>
                                                        handlePaletteChange(newColor, index)
                                                    )}
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                </Box>
            )}
        </>
    )
}
