// InsightElement.js
import React, { useEffect, useState, useRef } from 'react'
import chartPlaceholder from './chart-placeholder.png' // Import the PNG file
import Input from '@mui/material/Input'
import { useDispatch } from 'react-redux'
import {
    deleteInsight,
    updateInsightPosition,
    updateInsightSize,
    updateInsightTitle,
} from '../../../store/actions'
import InsightEdit from './InsightEdit'
import ChartState from '../../../components/Insights/ChartState'
import { Insight } from '../../Insights/Insight'
import { Server } from '../../../api/Server'
import { loadFromSavedInsight, fetchInsight } from './InsightAPI'
import { useApi } from '../../../api/api'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'
import { useSelector } from 'react-redux'
import Link from '../../../assets/icons/Link.svg'
import LinkBreak from '../../../assets/icons/LinkBreak.svg'
import TrashRed from '../../../assets/icons/TrashRed.svg'
import PencilSimple from '../../../assets/icons/PencilSimple.svg'
import ArrowSquareIn from '../../../assets/icons/ArrowSquareIn.svg'
import { OBJECT_TYPES } from '../types'
import { setLastEditedPrompt, togglePromptObject } from '../../../store/actions/promptActions'
import { Box, IconButton } from '@mui/material'
import { ElementInfo } from '../../common/ElementInfo/ElementInfo'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { OpenDialog } from '../../Insights/OpenDialog'
import { ROUTES } from '../../../router/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { ScoopTheme } from '../../Insights/Style'
import { NewInsightComponent } from '../../Insights/NewInsightComponent'

const InsightElement = ({
    id,
    title,
    initialPosition,
    initialSize,
    content,
    workspaceID,
    userID,
    token,
    workspaceMetadata,
    setInsightsMaterialized,
    multiProps,
}) => {
    const insightKey = content.insightKey
    const insightId = `InsightElement-${id}`
    const ariaLabel = { 'aria-label': 'description' }
    const navigate = useNavigate()
    const { canvasID } = useParams()
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token))
    const { postData } = useApi()
    const dispatch = useDispatch()
    const activeMode = useSelector((state) => state.ui.activeMode)
    const objects = useSelector((state) => state.objects)
    const [config, setConfig] = React.useState(ChartState.getDefaultConfig())
    const [chartState, setChartState] = React.useState(new ChartState(server, config, setConfig))
    const [isChartASkeleton, setIsChartASkeleton] = React.useState(true) //when there is no chart data to display
    const [inputTitle, setInputTitle] = useState(title) // Local state for the input title
    const [isEditMode, setIsEditMode] = useState(false) // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [editInsightOpen, setEditInsightOpen] = useState(false)
    const promptSelectingObjects = useSelector((state) => state.prompt.promptSelectingObjects)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const lastEditedPrompt = useSelector((state) => state.prompt.lastEditedPrompt)
    const updatedWorksheet = useSelector((state) => state.prompt.updatedWorksheet)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const guestPrompts = useSelector((state) => state.auth.guestPrompts)
    const updatedStyles = useSelector(
        (state) => state.objects.find((obj) => obj.id === id)?.wrapperStyles || {}
    )
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [openDialogOpen, setOpenDialogOpen] = useState(false)
    const [selectedItemToLoad, setSelectedItemToLoad] = useState(null)

    useEffect(() => {
        if (workspaceMetadata) {
            setChartState(() => {
                const cs = chartState
                cs.workspaceMetadata = workspaceMetadata
                return cs
            })
        }
    }, [workspaceMetadata])

    useEffect(() => {
        setInputTitle(title)
    }, [title])

    const getInsightPrompts = () => {
        let prompts
        if (isGuestMode && guestPrompts.length > 0) prompts = guestPrompts
        else prompts = objects.filter((obj) => obj.type === OBJECT_TYPES.PROMPT)
        return prompts
            .filter((prompt) => prompt.promptProps?.objects.includes(insightId))
            .map((prompt) => prompt.promptProps.prompt)
            .filter((prompt) => prompt !== null)
    }

    const fetchingRef = useRef(new Set())

    const forceExplorerDataRefresh = useSelector((state) => state.ui.forceRefresh) // Note the corrected property name
    const forceExplorerDataRefreshWorksheetID = useSelector((state) => state.ui.worksheetID) // Note the corrected property name

    const hasFetched = useRef(false)

    // monitor changing of a chart state and if it shows sign of not having meaningul data, display a skeleton chart
    React.useEffect(() => {
        if (typeof chartState.categories == 'undefined') {
            setIsChartASkeleton(true)
        } else {
            setIsChartASkeleton(false)
        }
    }, [chartState])

    const updateInsight = () => {
        if (!hasFetched.current && insightKey) {
            hasFetched.current = true
            fetchInsight(insightKey, postData)
                .then((result) => {
                    loadFromSavedInsight(
                        result,
                        setConfig,
                        chartState,
                        setIsChartASkeleton,
                        dispatch,
                        id,
                        insightKey,
                        workspaceID,
                        getInsightPrompts(),
                        workspaceMetadata,
                        title !== 'My Chart'
                    )
                    if (setInsightsMaterialized)
                        setInsightsMaterialized((prevInsights) => [...prevInsights, insightKey])
                })
                .catch((error) => {
                    console.error('Error fetching insight:', error)
                })
                .finally(() => {
                    fetchingRef.current.delete(insightKey)
                })
        }
    }

    useEffect(() => {
        updateInsight()
    }, []) // Runs once on component mount

    useEffect(() => {
        if (lastEditedPrompt?.objects.includes(insightId)) {
            hasFetched.current = false
            updateInsight()
            dispatch(setLastEditedPrompt(null))
        }
    }, [lastEditedPrompt])

    useEffect(() => {
        if (updatedWorksheet === config.worksheetID) {
            hasFetched.current = false
            updateInsight()
        }
    }, [updatedWorksheet])

    // if call to force refresh insights is received, refresh but only for worksheetIDs that match
    useEffect(() => {
        // console.log(">>>received refresh command, chartState:", chartState?.config?.worksheetID);
        // console.log(">>>received refresh command, worksheetID:", forceExplorerDataRefreshWorksheetID);
        if (
            chartState?.config?.worksheetID &&
            chartState?.config?.worksheetID == forceExplorerDataRefreshWorksheetID
        ) {
            hasFetched.current = false
            updateInsight()
        }
    }, [forceExplorerDataRefresh])

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value)
        dispatch(updateInsightTitle(id, e.target.value)) // Dispatch the UPDATE_INSIGHT_TITLE event
    }

    React.useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [userID, token, workspaceID])

    const updateInsightPos = (position) => {
        dispatch(updateInsightPosition(id, position))
    }

    const updateInsightSz = (size) => {
        dispatch(updateInsightSize(id, size))
    }

    const promptSelected = promptSelectingObjects.includes(insightId)
    const [theme, setTheme] = useState('')

    const getTheme = () => {
        const themeID = chartState?.config.themeID
        if (themeID) {
            if (themeID === 'defaultTheme') {
                return {
                    themeID: 'defaultTheme',
                    themeName: 'Default theme',
                    colorScheme: {
                        backgroundColor: '#FFFFFF',
                        colors: ScoopTheme.color,
                        darkTheme: false,
                    },
                }
            }
            if (workspaceMetadata) {
                let theme = workspaceMetadata.themes.find((t) => t.themeID === themeID)
                if (theme) return theme
            }
        }
        return undefined
    }

    const handleDelete = () => {
        dispatch(deleteInsight(id))
        setDeleteOpen(false)
    }

    const handleLoadFromSavedInsight = (insight) => {
        if (insight) {
            handleTitleChange({ target: { value: insight.insightName } })
            loadFromSavedInsight(
                insight,
                setConfig,
                chartState,
                setIsChartASkeleton,
                dispatch,
                id,
                selectedItemToLoad,
                workspaceID,
                getInsightPrompts(),
                workspaceMetadata,
                title !== 'My Chart'
            )
        }
    }

    const handleEdit = () => {
        navigate(ROUTES.EXPLORER, {
            state: {
                editmodeConfig: config,
                canvasID: canvasID,
                insightKey: insightKey,
                insightName: title,
            },
        })
    }

    // DELETE OPTION MUST REMAIN THE LAST ONE
    const menuItems = [
        {
            icon: ArrowSquareIn,
            alt: 'load',
            onClick: () => setOpenDialogOpen(true),
            label: 'Load',
        },
        !isChartASkeleton
            ? {
                  icon: PencilSimple,
                  alt: 'edit',
                  onClick: handleEdit,
                  label: 'Edit',
              }
            : null,
        {
            icon: TrashRed,
            alt: 'delete',
            onClick: () => setDeleteOpen(true),
            label: 'Delete',
        },
    ]

    useEffect(() => {
        if (workspaceMetadata) setTheme(getTheme())
    }, [workspaceMetadata, chartState?.config.themeID])

    return (
        <ElementWrapper
            type={config.view === 'table' ? 'table' : 'chart'}
            id={insightId}
            objectId={id}
            key={insightId}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementPosition={updateInsightPos}
            updateElementSize={updateInsightSz}
            backgroundColor={theme ? theme.colorScheme.backgroundColor : undefined}
            withTheme={!!theme}
            menuItems={menuItems}
            {...multiProps}
        >
            {isEditMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                    >
                        <InsightEdit
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            config={config}
                            chartState={chartState}
                            setConfig={setConfig}
                            isChartASkeleton={isChartASkeleton}
                            setIsChartASkeleton={setIsChartASkeleton}
                            server={server}
                            title={title}
                            id={id}
                            insightKey={insightKey}
                            handleTitleChange={handleTitleChange}
                            editInsightOpen={editInsightOpen}
                            setEditInsightOpen={setEditInsightOpen}
                            updateInsight={updateInsight}
                            workspaceMetadata={workspaceMetadata}
                            insightPrompts={getInsightPrompts()}
                        />
                    </div>
                    <ElementInfo id={id} type={'Summary'} title={title ?? 'Untitled'} />
                </>
            )}
            {promptSelecting && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        className={'prompt-link-button'}
                        onClick={() => dispatch(togglePromptObject(insightId))}
                        sx={{
                            backgroundColor: promptSelected
                                ? '#6191F2 !important'
                                : '#E8EFFC !important',
                        }}
                    >
                        {promptSelected ? (
                            <img src={Link} alt={'linked'} />
                        ) : (
                            <img src={LinkBreak} alt={'unlinked'} />
                        )}
                    </IconButton>
                </div>
            )}
            {updatedStyles.showTitle && (
                <div
                    style={{
                        height: '40px',
                        width: '50%',
                        paddingTop: 10,
                        paddingLeft: 10,
                    }}
                >
                    <Input
                        style={{
                            width: '100%',
                            color: theme && theme.colorScheme.darkTheme ? '#FFFFFF' : undefined,
                        }}
                        placeholder={title}
                        inputProps={ariaLabel}
                        margin="dense"
                        value={inputTitle}
                        onChange={handleTitleChange}
                        readOnly={activeMode !== 'edit'}
                        disableUnderline={activeMode !== 'edit'}
                    />
                </div>
            )}
            {isChartASkeleton && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={chartPlaceholder}
                        alt={'chart'}
                        style={{
                            height: '80%',
                            opacity: '0.6',
                            pointerEvents: 'none',
                        }}
                    />
                </Box>
            )}
            {!isChartASkeleton && (
                <Insight
                    chartProperties={chartState}
                    setChartProperties={setChartState}
                    config={config}
                    setConfig={setConfig}
                    embeddedSizeProps={{
                        left: 0,
                        width: '100%',
                        height: '100%',
                        containerSize: initialSize,
                    }}
                    server={server}
                    clickable={activeMode !== 'edit'}
                    activePrompts={getInsightPrompts()}
                    workspaceMetadata={workspaceMetadata}
                    theme={theme}
                />
                /*<NewInsightComponent config={config} chartProperties={chartState} />*/
            )}
            <DeleteDialog
                open={deleteOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                title={title}
                handleCancel={() => setDeleteOpen(false)}
                handleDelete={handleDelete}
                type={'Insight'}
            />
            <OpenDialog
                open={openDialogOpen}
                setOpen={setOpenDialogOpen}
                setInsight={handleLoadFromSavedInsight}
                workspaceMetadata={workspaceMetadata}
                server={server}
                setSelectedItemToLoad={setSelectedItemToLoad}
            />
        </ElementWrapper>
    )
}

export default InsightElement
