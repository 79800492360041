// ProcessDiagramElement.js
import React, { useEffect, useRef, useState } from 'react'
import ProcessDiagramPlaceholder from './process_diagram_skeleton.png' // Import the PNG file
import Input from '@mui/material/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateProcessDiagramPosition,
    updateProcessDiagramSize,
    updateProcessDiagramTitle,
} from '../../../store/actions'
import ProcessDiagramEdit from './ProcessDiagramEdit'
import { Process } from '../../screens/Process/Process'
import { Server } from '../../../api/Server'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'
import { OBJECT_TYPES } from '../types'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { togglePromptObject } from '../../../store/actions/promptActions'
import Link from '../../../assets/icons/Link.svg'
import LinkBreak from '../../../assets/icons/LinkBreak.svg'
import { ElementInfo } from '../../common/ElementInfo/ElementInfo'

const ProcessDiagramElement = ({
    id,
    title,
    initialPosition,
    initialSize,
    content,
    workspaceID,
    userID,
    token,
    multiProps,
    workspaceMetadata,
}) => {
    const processId = `ProcessDiagramElement-${id}`
    const [processDiagram, setProcessDiagram] = useState(null)
    const ariaLabel = { 'aria-label': 'description' }
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token))
    const dispatch = useDispatch()
    const [isProcessDiagramASkeleton, setIsProcessDiagramASkeleton] = React.useState(true) //when there is no ProcessDiagram data to display
    const [inputTitle, setInputTitle] = useState(title) // Local state for the input title
    const [isEditMode, setIsEditMode] = useState(false) // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const activeMode = useSelector((state) => state.ui.activeMode)
    const promptSelectingObjects = useSelector((state) => state.prompt.promptSelectingObjects)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const objects = useSelector((state) => state.objects)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const guestPrompts = useSelector((state) => state.auth.guestPrompts)
    const updatedStyles = useSelector(
        (state) => state.objects.find((obj) => obj.id === id)?.wrapperStyles || {}
    )
    const chartTypeMenu = useRef()
    const [showChartTypeMenu, setShowChartTypeMenu] = useState(false)

    const getProcessPrompts = () => {
        let prompts
        if (isGuestMode && guestPrompts.length > 0) prompts = guestPrompts
        else prompts = objects.filter((obj) => obj.type === OBJECT_TYPES.PROMPT)
        return prompts
            .filter((prompt) => prompt.promptProps?.objects.includes(processId))
            .map((prompt) => prompt.promptProps.prompt)
            .filter((prompt) => prompt !== null)
    }

    const [editProcessDiagramOpen, setEditProcessDiagramOpen] = useState(false)
    const [wrapperSize, setWrapperSize] = useState(initialSize)

    useEffect(() => {
        if (content?.processDiagram) {
            setProcessDiagram(content.processDiagram)
            setInputTitle(content.processDiagram)
            setIsProcessDiagramASkeleton(false)
        }
    }, [content?.processDiagram])

    useEffect(() => {
        setInputTitle(title)
    }, [title])

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value)
        dispatch(updateProcessDiagramTitle(id, e.target.value)) // Dispatch the UPDATE_SHEETLET_TITLE event
    }

    React.useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [userID, token, workspaceID])

    const updateProcessDiagramPos = (position) => {
        dispatch(updateProcessDiagramPosition(id, position))
    }

    const updateProcessDiagramSz = (size) => {
        dispatch(updateProcessDiagramSize(id, size))
    }

    const promptSelected = promptSelectingObjects.includes(processId)

    return (
        <ElementWrapper
            id={`ProcessDiagramElement-${id}`}
            key={`ProcessDiagramElement-${id}`}
            setWrapperSizeTrack={setWrapperSize}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementSize={updateProcessDiagramSz}
            updateElementPosition={updateProcessDiagramPos}
            type={'process'}
            {...multiProps}
            objectId={id}
        >
            {promptSelecting && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        className={'prompt-link-button'}
                        onClick={() => dispatch(togglePromptObject(processId))}
                        sx={{
                            backgroundColor: promptSelected
                                ? '#6191F2 !important'
                                : '#E8EFFC !important',
                        }}
                    >
                        {promptSelected ? (
                            <img src={Link} alt={'linked'} />
                        ) : (
                            <img src={LinkBreak} alt={'unlinked'} />
                        )}
                    </IconButton>
                </div>
            )}
            {isEditMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                    >
                        <ProcessDiagramEdit
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            isProcessDiagramASkeleton={isProcessDiagramASkeleton}
                            setIsProcessDiagramASkeleton={setIsProcessDiagramASkeleton}
                            server={server}
                            title={title}
                            id={id}
                            processDiagram={processDiagram}
                            handleTitleChange={handleTitleChange}
                            editProcessDiagramOpen={editProcessDiagramOpen}
                            setEditProcessDiagramOpen={setEditProcessDiagramOpen}
                            workspaceMetadata={workspaceMetadata}
                        />
                    </div>
                    <ElementInfo id={id} type={'Diagram'} title={title ?? 'Untitled'} />
                </>
            )}
            {updatedStyles.showTitle && (
                <div
                    style={{
                        height: '40px',
                        width: 200,
                        paddingTop: 10,
                        paddingLeft: 10,
                    }}
                >
                    <Input
                        style={{ width: '100%' }} // Add this line
                        placeholder={title}
                        inputProps={ariaLabel}
                        margin="dense"
                        value={inputTitle}
                        onChange={handleTitleChange}
                    />
                </div>
            )}
            {isProcessDiagramASkeleton && (
                <div
                    style={{
                        height: '80%',
                        width: '100%',
                        backgroundImage: `url(${ProcessDiagramPlaceholder})`, // Use the imported PNG as a background image
                        backgroundSize: 'contain', // Scale the image to fit within the container
                        backgroundRepeat: 'no-repeat', // Prevent image from repeating
                        backgroundPosition: 'center', // Center the image
                    }}
                />
            )}
            {!isProcessDiagramASkeleton && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'auto',
                        overflowY: 'auto',
                        backgroundColor: 'transparent',
                        pointerEvents: activeMode === 'edit' ? 'none' : 'auto',
                    }}
                >
                    <Process
                        activePrompts={getProcessPrompts()}
                        embeddedDiagram={{
                            embedded: true,
                            processDiagram: processDiagram,
                            left: 0,
                            width: wrapperSize.width,
                            top: 0,
                            height: wrapperSize.height,
                            clickable: activeMode !== 'edit',
                            workspaceMetadata: workspaceMetadata,
                        }}
                    />
                </div>
            )}
            <Menu
                open={showChartTypeMenu}
                anchorEl={chartTypeMenu.current}
                onClose={() => setShowChartTypeMenu(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={() => setShowChartTypeMenu(false)}>
                    <Typography className={'inter'} sx={{ fontSize: '12px' }}>
                        Sankey Chart
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => setShowChartTypeMenu(false)}>
                    <Typography className={'inter'} sx={{ fontSize: '12px' }}>
                        Process Diagram
                    </Typography>
                </MenuItem>
            </Menu>
        </ElementWrapper>
    )
}

export default ProcessDiagramElement
