import { OBJECT_TYPES } from '../types'

export class ArrowClass {
    constructor(id, startInitialPosition, endInitialPosition, arrowProps) {
        this.id = id
        this.startInitialPosition = startInitialPosition
        this.endInitialPosition = endInitialPosition
        this.type = OBJECT_TYPES.ARROW
        this.arrowProps = arrowProps
    }

    static newArrow(zoom, x, y) {
        const canvasScrollElement = document.getElementById('canvas-scrollable')
        return new ArrowClass(
            Math.random(),
            x && y
                ? { x: x, y: y }
                : {
                      x: (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
                      y: (300 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
                  },
            x && y
                ? { x: x + 200, y: y }
                : {
                      x: (400 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
                      y: (300 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
                  },
            {
                path: 'smooth',
                startAnchor: 'auto',
                endAnchor: 'auto',
                color: '#1273DE',
                showTail: false,
                showHead: true,
            }
        )
    }
}
