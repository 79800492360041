// LoadSheetletDialog.js
import * as React from 'react'
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Typography,
    FormControl,
    Select,
    InputLabel,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'

import { set } from 'lodash'
import Selector from '../common/Selector/Selector'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import moment from 'moment'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'

export function LoadSheetletDialog({
    open,
    setOpen,
    setSheetlet, //this returns the sheetlet's worksheetID, worksheetRange, worksheetNum, and worksheetName
    workspaceMetadata,
    workspaceID,
    server,
    setSelectedItemToLoad,
    setAnchor,
}) {
    let [workspaceSheetlets, setWorkspaceSheetlets] = React.useState(null)
    let [selectedWorksheetID, setSelectedWorksheetID] = React.useState(null)
    let [selectedWorksheetName, setSelectedWorksheetName] = React.useState(null)
    let [selectedWorksheetRange, setSelectedWorksheetRange] = React.useState(null)

    const [worksheetNames, setWorksheetNames] = React.useState([])
    const [worksheetNamedRanges, setWorksheetNamedRanges] = React.useState([])

    // select worksheet or named range
    const [dataSourceType, setDataSourceType] = React.useState('worksheet')

    const isWorksheetsEmpty = worksheetNames.length === 0
    const isNamedRangesEmpty = worksheetNamedRanges.length === 0

    const [loading, setLoading] = React.useState(false)

    function handleClose(event) {
        setOpen(false)
        setWorkspaceSheetlets(null)
        if (setAnchor) setAnchor(null)
    }

    React.useEffect(() => {
        if (selectedWorksheetID) {
            setLoading(true)
            setSelectedItemToLoad(selectedWorksheetID)
            server.postData(
                {
                    action: 'getSheetMetadata',
                    worksheetID: selectedWorksheetID,
                },
                (result) => {
                    setLoading(false)
                    if (result.worksheets) {
                        setWorksheetNames(result.worksheets)
                        setSelectedWorksheetName(result.worksheets[0]) // Pre-select the first item
                    }
                    if (result.namedRanges) {
                        setWorksheetNamedRanges(result.namedRanges)
                    }
                }
            )
        }
    }, [selectedWorksheetID, setSelectedItemToLoad])

    function handleOpen(event) {
        setOpen(false)
        if (setAnchor) setAnchor(null)

        try {
            const selectedIndex = worksheetNames.findIndex((name) => name === selectedWorksheetName)
            const worksheetURL = workspaceSheetlets.filter(
                (wi) => wi.worksheetID === selectedWorksheetID
            )[0].url

            if (dataSourceType === 'worksheet') {
                setSheetlet({
                    worksheetID: selectedWorksheetID,
                    worksheetRange: null,
                    worksheetName: selectedWorksheetName,
                    worksheetNum: selectedIndex,
                    worksheetURL: worksheetURL,
                })
            } else {
                setSheetlet({
                    worksheetID: selectedWorksheetID,
                    worksheetRange: selectedWorksheetRange,
                    worksheetName: null,
                    worksheetNum: null,
                    worksheetURL: worksheetURL,
                })
            }
        } catch (e) {
            console.log('error', e)
        }
    }

    if (!workspaceSheetlets && !selectedWorksheetID && open) {
        setWorkspaceSheetlets([])
        setSelectedWorksheetID(null)
        server.postData(
            {
                action: 'getObjects',
                class: 'scoop.worksheet.Worksheet',
                attributeName: 'workspaceID',
                attributeValue: workspaceID,
            },
            ({ objects: result }) => {
                let availableWorksheets = result.filter(
                    (wi) => wi.sheetType == 'DynamicSheet' || wi.sheetType == 'CalculatedSource'
                )

                availableWorksheets = availableWorksheets.sort((a, b) => {
                    if (a.label === null) return 1 // Push nulls to the end
                    if (b.label === null) return -1 // Keep non-nulls at the beginning
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                })

                setWorkspaceSheetlets(availableWorksheets)
            }
        )
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={handleClose} text={'Cancel'} />
            {!loading && (
                <Button
                    onClick={handleOpen}
                    text={'Open'}
                    className={'button-purple'}
                    disabled={
                        (dataSourceType === 'worksheet' && !selectedWorksheetName) ||
                        (dataSourceType === 'namedRange' && !selectedWorksheetRange)
                    } // Disable the button based on the selection
                />
            )}
        </Box>
    )

    if (workspaceMetadata) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                title={'Select a Workbook to load'}
                actions={actions}
            >
                {workspaceSheetlets && (
                    <Box
                        sx={{
                            minWidth: 500,
                            maxHeight: '30vh',
                            overflowY: 'auto',
                            bgcolor: 'background.paper',
                            mt: 2,
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            // Added the following for debugging purposes
                            borderColor: 'red', // Temporarily set to red to ensure visibility
                        }}
                    >
                        <List dense={true} sx={{ maxHeight: '30vh' }}>
                            {workspaceSheetlets?.map((wi) => (
                                <ListItemButton
                                    selected={
                                        selectedWorksheetID &&
                                        selectedWorksheetID === wi.worksheetID
                                    }
                                    key={wi.worksheetID}
                                    onClick={(event) => {
                                        setSelectedWorksheetID(wi.worksheetID)
                                    }}
                                >
                                    <ListItemText
                                        primary={wi.label}
                                        secondary={`(modified: ${moment(wi.lastModified).format('MM/DD/YY HH:mm:ss')})`}
                                        secondaryTypographyProps={{
                                            style: { fontSize: 'small' },
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                )}

                {(worksheetNames.length > 0 || worksheetNamedRanges.length > 0) && !loading && (
                    // <Box
                    //     sx={{
                    //         display: 'flex',
                    //         alignItems: 'center',
                    //         border: '1px solid #ccc',
                    //         borderRadius: '4px',
                    //         padding: '8px',
                    //         mt: 2,
                    //     }}
                    // >

                    <Box>
                        {/* Inline Radio Buttons with Label */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <FormLabel sx={{ marginRight: 2 }}>Bring data from</FormLabel>
                            <RadioGroup
                                row
                                aria-label="data source"
                                name="dataSource"
                                value={dataSourceType}
                                onChange={(event) => setDataSourceType(event.target.value)}
                            >
                                <FormControlLabel
                                    value="worksheet"
                                    control={<Radio />}
                                    label="Worksheet"
                                />
                                <FormControlLabel
                                    value="namedRange"
                                    control={<Radio />}
                                    label="Named Range"
                                />
                            </RadioGroup>
                        </Box>

                        {/* Worksheet Selector */}
                        {dataSourceType === 'worksheet' && (
                            <Box sx={{ mt: 2 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel
                                        sx={{
                                            fontSize: 'small',
                                            color: 'blue',
                                        }}
                                    >
                                        Worksheet Name
                                    </InputLabel>
                                    <Select
                                        value={selectedWorksheetName}
                                        onChange={(event) =>
                                            setSelectedWorksheetName(event.target.value)
                                        }
                                        label="Worksheet Name"
                                    >
                                        {worksheetNames.map((worksheetName) => (
                                            <MenuItem key={worksheetName} value={worksheetName}>
                                                {worksheetName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {/* Named Range Selector */}
                        {dataSourceType === 'namedRange' && (
                            <Box sx={{ mt: 2 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel
                                        sx={{
                                            fontSize: 'small',
                                            color: 'blue',
                                        }}
                                    >
                                        Named Range
                                    </InputLabel>
                                    <Select
                                        value={selectedWorksheetRange}
                                        onChange={(event) =>
                                            setSelectedWorksheetRange(event.target.value)
                                        }
                                        label="Named Range"
                                    >
                                        {worksheetNamedRanges.map((namedRange) => (
                                            <MenuItem key={namedRange} value={namedRange}>
                                                {namedRange}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </Box>

                    // </Box>
                )}
                {loading && (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={'100%'}
                    >
                        <ScoopLoader size={56} />
                    </Box>
                )}
            </Dialog>
        )
    } else return null
}
