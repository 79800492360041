import { Hubspot } from '../../Appconnect/Hubspot/Hubspot'
import { Salesforce } from '../../Appconnect/GMail/Salesforce'
import { GoogleDataStudio } from '../../Appconnect/GoogleDataStudio/GoogleDataStudio'
import { Database } from '../../Appconnect/Database/Database'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import Email from '../../../../assets/icons/Email.svg'
import Avatar from '../../../../assets/icons/Avatar.svg'
import Scoop from '../../../../assets/logo/NewLogo.svg'
import Button from '../../../common/Button/Button'
import Gmail from '../../../../assets/icons/Gmail.svg'
import Outlook from '../../../../assets/icons/Outlook.svg'
import EnvelopeSimple from '../../../../assets/icons/EnvelopeSimple.svg'

import React from 'react'

export const ConnectSource = ({ source, goBack, goForward }) => {
    const renderSource = () => {
        switch (source) {
            case 'Hubspot':
                return <Hubspot open={true} stepBack={goBack} />
            case 'Salesforce':
                return <Salesforce stepBack={goBack} />
            case 'GoogleDataStudio':
                return <GoogleDataStudio stepBack={goBack} />
            case 'Database':
                return <Database open={true} stepBack={goBack} />
            default:
                return <Hubspot open={true} stepBack={goBack} />
        }
    }

    return (
        <Box sx={{ maxWidth: '578px', margin: 'auto' }}>
            <Typography
                sx={{
                    color: '#635566',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    padding: '32px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                    letterSpacing: '-0.28px',
                }}
            >
                Great, your data is now ready to Scoop! It’s now time to auto-forward your{' '}
                {source.name} report emails to Scoop
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    justifyContent: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '48px 0',
                        background: '#FEF4F7',
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: '5px',
                            border: '1px solid  #F2F2F2',
                            boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            maxWidth: '42px',
                            height: '42px',
                        }}
                    >
                        <img src={source.icon} alt={source.name} />
                    </Box>
                    <Box sx={{ borderTop: '1px dashed #979099', width: '30px' }} />
                    <img src={Email} alt={'email'} />
                    <Box sx={{ borderTop: '1px dashed #979099', width: '30px' }} />
                    <Box
                        sx={{
                            borderRadius: '5px',
                            border: '1px solid  #F2F2F2',
                            boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#FFFFFF',
                        }}
                    >
                        <img src={Avatar} alt={'Avatar'} />
                    </Box>
                    <Box sx={{ borderTop: '1px dashed #979099', width: '30px' }} />
                    <img src={Email} alt={'email'} />
                    <Box sx={{ borderTop: '1px dashed #979099', width: '30px' }} />
                    <Box
                        sx={{
                            borderRadius: '5px',
                            border: '1px solid  #F2F2F2',
                            boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                            padding: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '42px',
                            minWidth: '42px',
                            background: '#FFFFFF',
                        }}
                    >
                        <img src={Scoop} alt={'Scoop'} style={{ height: '14px', width: 'auto' }} />
                    </Box>
                </Box>
                <Typography
                    sx={{
                        color: '#635566',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%',
                        letterSpacing: '-0.28px',
                    }}
                >
                    Where do you receive your {source.name} report emails?
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} gap={'8px'}>
                    <Button
                        sx={{ width: '100%', flex: 1 }}
                        className={'button-grey'}
                        onClick={() => {}}
                    >
                        <img
                            src={Gmail}
                            alt="Integrate Salesforce"
                            style={{
                                cursor: 'pointer',
                                maxWidth: '100%',
                                alignContent: 'center',
                            }}
                        />
                        {'Gmail'}
                    </Button>
                    <Button
                        sx={{ width: '100%', flex: 1 }}
                        className={'button-grey'}
                        onClick={() => {}}
                    >
                        <img
                            src={Outlook}
                            alt="Integrate Salesforce"
                            style={{
                                cursor: 'pointer',
                                maxWidth: '100%',
                                alignContent: 'center',
                            }}
                        />
                        {'Outlook'}
                    </Button>
                    <Button
                        sx={{ width: '100%', flex: 1 }}
                        className={'button-grey'}
                        onClick={goForward}
                    >
                        <img
                            src={EnvelopeSimple}
                            alt="Integrate Salesforce"
                            style={{
                                cursor: 'pointer',
                                maxWidth: '100%',
                                alignContent: 'center',
                            }}
                        />
                        {'Other'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
