import { getReportSeriesData, listProcesses } from './processesApi'
import { useQuery } from '@tanstack/react-query'
import { getWorkspaceID } from '../../../utils'

export const useProcesses = () => {
    const workspaceID = getWorkspaceID()

    const {
        data: processes,
        error,
        isLoading: listProcessLoading,
    } = useQuery({
        queryKey: ['processes', workspaceID],
        queryFn: listProcesses,
    })

    const { data: reportSeriesData, isLoading: isLoadingReportSeries } = useQuery({
        queryKey: ['reportSeriesData', workspaceID],
        queryFn: getReportSeriesData,
    })

    return {
        processes: processes?.processes || [],
        error,
        listProcessLoading,
        reportSeriesData: reportSeriesData?.tables || [],
        isLoadingReportSeries,
    }
}
