import { mobileAPIRequest } from '../../api'

export const getCategoryValues = async (columnName, likeValue, reportSeriesTableID) => {
    const action = {
        action: 'getCategoryValues',
        columnName: columnName,
        like: likeValue,
        reportSeriesTableID,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
