import Dialog from '../../../common/Dialog/Dialog'
import Button from '../../../common/Button/Button'
import React, { useState } from 'react'
import Input from '../../../common/Input/Input'
import { Box } from '@mui/material'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { useWorkspaces } from '../../../../api/hooks/workspaces/useWorkspaces'

export const CreateEditWorkspaceModal = ({
    open,
    close,
    onSuccess,
    initialName = '',
    workspaceID = null,
    title,
    buttonText,
    isCreate,
}) => {
    const [inputValue, setInputValue] = useState(initialName)

    const { create, rename, isCreating, isRenaming } = useWorkspaces()

    const handleAction = () => {
        if (isCreate) {
            create({
                workspaceName: inputValue,
                onSuccess: (response) => {
                    onSuccess({
                        workspaceID: response.workspaceID,
                        workspaceName: inputValue,
                    })
                },
            })
        } else {
            rename({
                workspaceName: inputValue,
                workspaceID: workspaceID,
                onSuccess: (response) => {
                    onSuccess(
                        {
                            workspaceID: workspaceID,
                            workspaceName: inputValue,
                        },
                        response.data
                    )
                },
            })
        }
    }

    const modalActions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={close} text={'Cancel'} />
            <Button
                onClick={handleAction}
                text={isCreating || isRenaming ? <ScoopLoader /> : buttonText}
                className={'button-purple'}
                disabled={
                    isCreating ||
                    isRenaming ||
                    inputValue.length === 0 ||
                    inputValue === initialName
                }
            />
        </Box>
    )

    return (
        <Dialog
            style={{ width: '600px', minHeight: '255px' }}
            open={open}
            title={title}
            actions={modalActions}
            onClose={close}
        >
            <Input
                label={'Workspace name'}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
        </Dialog>
    )
}
