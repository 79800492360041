import React, { useEffect, useState, useMemo } from 'react'

import { useApi } from '../../../api/api'
import { useDropzone } from 'react-dropzone'
import { Provider, useDispatch, useSelector } from 'react-redux'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Divider,
    Box,
    TextField,
    Select,
    MenuItem,
    LinearProgress,
    Menu,
} from '@mui/material'
import { set } from 'lodash'
import Dialog from '../../common/Dialog/Dialog'
import Button from '../../common/Button/Button'

import { styled, alpha } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { deleteImage, updateImageContent } from '../../../store/actions'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { UploadImageDialog } from '../../common/Dialog/UploadImage'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}))

export default function ImageEdit({ isMenuOpen, setIsMenuOpen, id }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isAlertOpen, setIsAlertOpen] = React.useState(false)
    const dispatch = useDispatch()

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setIsMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        isLoadDialogOpen ? setIsLoadDialogOpen(false) : setIsAlertOpen(false)
        setAnchorEl(null)
        setIsMenuOpen(false)
    }

    const handleDelete = () => {
        dispatch(deleteImage(id))
        setIsMenuOpen(false)
        setIsAlertOpen(false)
        setAnchorEl(null)
    }

    const [isLoadDialogOpen, setIsLoadDialogOpen] = useState(false)

    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize="large" color="info" />
                </IconButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => setIsLoadDialogOpen(true)} disableRipple>
                        <EditIcon />
                        Edit
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => setIsAlertOpen(true)} disableRipple>
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </StyledMenu>
            </div>

            <UploadImageDialog
                open={isLoadDialogOpen}
                handleClose={handleClose}
                id={id}
                showAsDialog={true}
            />

            <DeleteDialog
                open={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title="this image"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                type={'Image'}
                handleCancel={() => setIsAlertOpen(false)}
                handleDelete={handleDelete}
            />
        </>
    )
}
