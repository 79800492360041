import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Switch } from '../../../../../common/Switch/Switch'
import { EMPHASIS_OPTIONS, FONT_FAMILIES, LINE_STYLES, RADIAL_DEFAULT_VALUES } from '../styleConsts'
import { debounce, cloneDeep } from 'lodash'
import { Slider } from '../../../../../common/Slider/Slider'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'

export const RadialConfig = ({ config, setConfig, chartPreferences }) => {
    const [innerRadius, setInnerRadius] = useState(RADIAL_DEFAULT_VALUES.polar.radius[0])
    const [startAngle, setStartAngle] = useState(RADIAL_DEFAULT_VALUES.angleAxis.startAngle)
    const [topRadius, setTopRadius] = useState(RADIAL_DEFAULT_VALUES.itemStyle.borderRadius[1])
    const [bottomRadius, setBottomRadius] = useState(
        RADIAL_DEFAULT_VALUES.itemStyle.borderRadius[0]
    )
    const [labelFontSize, setLabelFontSize] = useState(
        RADIAL_DEFAULT_VALUES.radiusAxis.axisLabel.fontSize
    )
    const [barWidth, setBarWidth] = useState(RADIAL_DEFAULT_VALUES.barWidth)
    const [labelSize, setLabelSize] = useState(RADIAL_DEFAULT_VALUES.label.fontSize)
    const [labelRotation, setLabelRotation] = useState(RADIAL_DEFAULT_VALUES.label.rotation)

    useEffect(() => {
        setInnerRadius(getPolarRadius())
        setStartAngle(getAngleAxisPropValue('startAngle'))
        setTopRadius(getRadiusValue('top'))
        setBottomRadius(getRadiusValue('bottom'))
        setBarWidth(getBarWidth())
        setLabelFontSize(getRadialAxisPropValue('axisLabel', 'fontSize'))
        setLabelSize(getLabelConfig('fontSize'))
        setLabelRotation(getLabelConfig('rotate'))
    }, [config.styleOverrides.radialBar])

    const radiusDebouncedChange = useCallback(
        debounce((value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                if (!newConfig.styleOverrides.radialBar.polar.radius)
                    newConfig.styleOverrides.radialBar.polar.radius = ['0%', '75%']
                newConfig.styleOverrides.radialBar.polar.radius[0] = value
                return newConfig
            })
        }, 200),
        []
    )

    const angleDebouncedChange = useCallback(
        debounce((value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                newConfig.styleOverrides.radialBar.angleAxis.startAngle = value
                return newConfig
            })
        }, 200),
        []
    )

    const itemStyleDebouncedFunc = useCallback(
        debounce((prop, value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                newConfig.styleOverrides.radialBar.itemStyle[prop] = value
                return newConfig
            })
        }, 200),
        []
    )

    const axisLabelDebouncedFunc = useCallback(
        debounce((prop, value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                newConfig.styleOverrides.radialBar.radiusAxis.axisLabel[prop] = value
                return newConfig
            })
        }, 200),
        []
    )

    const barWidthDebouncedFunc = useCallback(
        debounce((value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                newConfig.styleOverrides.radialBar.barWidth = value
                return newConfig
            })
        }, 200),
        []
    )

    const labelDebouncedFunc = useCallback(
        debounce((prop, value) => {
            setConfig((prevConfig) => {
                const newConfig = cloneDeep(prevConfig)
                newConfig.styleOverrides.radialBar.label[prop] = value
                return newConfig
            })
        }, 200),
        []
    )

    const getRadialEmphasisPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.radialBar.emphasis, prop))
            return config.styleOverrides.radialBar.emphasis[prop]
        if (propExistsOnObject(chartPreferences?.radialBar?.emphasis, prop))
            return chartPreferences.radialBar.emphasis[prop]
        return RADIAL_DEFAULT_VALUES.emphasis[prop]
    }

    const handleRadialEmphasisPropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.radialBar.emphasis[prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedRadiusChange = (value, setter) => {
        setter(value)
        radiusDebouncedChange(value)
    }

    const handleDebouncedAngleChange = (value, setter) => {
        setter(value)
        angleDebouncedChange(value)
    }

    const handleDebouncedItemStyleChange = (target, value) => {
        itemStyleDebouncedFunc('borderRadius', value)
        target === 'top' ? setTopRadius(value[1]) : setBottomRadius(value[0])
    }

    const handleDebouncedBarWidthChange = (value) => {
        barWidthDebouncedFunc(value)
        setBarWidth(value)
    }

    const getRadialAxisPropValue = (target, prop) => {
        const overrides = config.styleOverrides.radialBar
        const preferences = chartPreferences.radialBar
        if (target === 'axisLine') {
            if (prop === 'show') {
                if (propExistsOnObject(overrides.radiusAxis.axisLine, prop))
                    return overrides.radiusAxis.axisLine[prop]
                if (propExistsOnObject(preferences?.radiusAxis?.axisLine, prop))
                    return preferences.radiusAxis.axisLine[prop]
                return RADIAL_DEFAULT_VALUES.radiusAxis.axisLine[prop]
            }
            if (propExistsOnObject(overrides.radiusAxis.axisLine.lineStyle, prop))
                return overrides.radiusAxis.axisLine.lineStyle[prop]
            if (propExistsOnObject(preferences?.radiusAxis.axisLine?.lineStyle, prop))
                return preferences.radiusAxis.axisLine.lineStyle[prop]
            return RADIAL_DEFAULT_VALUES.radiusAxis.axisLine.lineStyle[prop]
        }
        if (target === 'splitLine') {
            if (prop === 'show') {
                if (propExistsOnObject(overrides.radiusAxis.splitLine, prop))
                    return overrides.radiusAxis.splitLine[prop]
                if (propExistsOnObject(preferences?.radiusAxis?.splitLine, prop))
                    return preferences.radiusAxis.splitLine[prop]
                return RADIAL_DEFAULT_VALUES.radiusAxis.splitLine[prop]
            }
            if (propExistsOnObject(overrides.radiusAxis.splitLine.lineStyle, prop))
                return overrides.radiusAxis.splitLine.lineStyle[prop]
            if (propExistsOnObject(preferences?.radiusAxis.splitLine?.lineStyle, prop))
                return preferences.radiusAxis.splitLine.lineStyle[prop]
            return RADIAL_DEFAULT_VALUES.radiusAxis.splitLine.lineStyle[prop]
        }
        if (propExistsOnObject(overrides.radiusAxis[target], prop))
            return overrides.radiusAxis[target][prop]
        if (propExistsOnObject(preferences?.radiusAxis[target], prop))
            return preferences.radiusAxis[target][prop]
        return RADIAL_DEFAULT_VALUES.radiusAxis[target][prop]
    }

    const handleRadialAxisPropChange = (target, prop, value) => {
        const newConfig = cloneDeep(config)
        if (target === 'axisLine') {
            if (prop === 'show') {
                newConfig.styleOverrides.radialBar.radiusAxis.axisLine.show = value
            } else {
                newConfig.styleOverrides.radialBar.radiusAxis.axisLine.lineStyle[prop] = value
            }
        }
        if (target === 'splitLine') {
            if (prop === 'show') {
                newConfig.styleOverrides.radialBar.radiusAxis.splitLine.show = value
            } else {
                newConfig.styleOverrides.radialBar.radiusAxis.splitLine.lineStyle[prop] = value
            }
        }
        newConfig.styleOverrides.radialBar.radiusAxis[target][prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedAxisLabelChange = (prop, value, setter) => {
        axisLabelDebouncedFunc(prop, value)
        setter(value)
    }

    const getStackedValue = () => {
        if (propExistsOnObject(config.styleOverrides.radialBar, 'stack'))
            return config.styleOverrides.radialBar.stack
        if (propExistsOnObject(chartPreferences.radialBar, 'stack'))
            return chartPreferences.radialBar.stack
        return RADIAL_DEFAULT_VALUES.stack
    }

    const handleStackedChange = (value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.radialBar.stack = value
        setConfig(newConfig)
    }

    const getPolarRadius = () => {
        if (propExistsOnObject(config.styleOverrides.radialBar.polar, 'radius'))
            return config.styleOverrides.radialBar.polar.radius[0]
        if (propExistsOnObject(chartPreferences.radialBar?.polar, 'radius'))
            return chartPreferences.radialBar.polar.radius[0]
        return RADIAL_DEFAULT_VALUES.polar.radius[0]
    }

    const getAngleAxisPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.radialBar.angleAxis, prop))
            return config.styleOverrides.radialBar.angleAxis[prop]
        if (propExistsOnObject(chartPreferences.radialBar?.angleAxis, prop))
            return chartPreferences.radialBar.angleAxis[prop]
        return RADIAL_DEFAULT_VALUES.angleAxis[prop]
    }

    const getRadiusValue = (orientation) => {
        if (orientation === 'top') {
            if (propExistsOnObject(config.styleOverrides.radialBar.itemStyle, 'borderRadius'))
                return config.styleOverrides.radialBar.itemStyle.borderRadius[1]
            if (propExistsOnObject(chartPreferences.radialBar?.itemStyle, 'borderRadius'))
                return chartPreferences.radialBar.itemStyle.borderRadius[1]
            return RADIAL_DEFAULT_VALUES.itemStyle.borderRadius[1]
        } else {
            if (propExistsOnObject(config.styleOverrides.radialBar.itemStyle, 'borderRadius'))
                return config.styleOverrides.radialBar.itemStyle.borderRadius[0]
            if (propExistsOnObject(chartPreferences.radialBar?.itemStyle, 'borderRadius'))
                return chartPreferences.radialBar.itemStyle.borderRadius[0]
            return RADIAL_DEFAULT_VALUES.itemStyle.borderRadius[0]
        }
    }

    const getBarWidth = () => {
        if (propExistsOnObject(config.styleOverrides.radialBar, 'barWidth'))
            return config.styleOverrides.radialBar.barWidth
        if (propExistsOnObject(chartPreferences.radialBar, 'barWidth'))
            return chartPreferences.radialBar.barWidth
        return RADIAL_DEFAULT_VALUES.barWidth
    }

    const getLabelConfig = (prop) => {
        if (propExistsOnObject(config.styleOverrides.radialBar.label, prop))
            return config.styleOverrides.radialBar.label[prop]
        if (propExistsOnObject(chartPreferences.radialBar?.label, prop))
            return chartPreferences.radialBar.label[prop]
        return RADIAL_DEFAULT_VALUES.label[prop]
    }

    const setLabelConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.radialBar.label[prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedLabelChange = (prop, value, setter) => {
        labelDebouncedFunc(prop, value)
        setter(value)
    }

    const emphasisDisabled = getRadialEmphasisPropValue('disabled')
    const showAxisLabel = getRadialAxisPropValue('axisLabel', 'show')
    const showAxisLine = getRadialAxisPropValue('axisLine', 'show')
    const showSplitLine = getRadialAxisPropValue('splitLine', 'show')
    const showStacked = getStackedValue()
    const showLabel = getLabelConfig('show')

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch checked={showStacked} onClick={() => handleStackedChange(!showStacked)} />
                <Typography className={'inter style-config-label'}>Stack</Typography>
            </Box>
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch
                    checked={!emphasisDisabled}
                    onClick={() => handleRadialEmphasisPropChange('disabled', !emphasisDisabled)}
                />
                <Typography className={'inter style-config-label'}>Emphasis</Typography>
            </Box>
            {!emphasisDisabled &&
                renderSelector(
                    'Emphasis styles',
                    getRadialEmphasisPropValue('focus'),
                    (v) => handleRadialEmphasisPropChange('focus', v),
                    EMPHASIS_OPTIONS
                )}
            <Slider
                value={innerRadius?.split('%')[0]}
                onChange={(value) => handleDebouncedRadiusChange(value + '%', setInnerRadius)}
                min={0}
                max={100}
                step={1}
                label={`Inner Radius: ${innerRadius}`}
            />
            <Slider
                value={startAngle}
                onChange={(value) => handleDebouncedAngleChange(value, setStartAngle)}
                min={0}
                max={360}
                step={1}
                label={`Start Angle: ${startAngle}º`}
            />
            <Slider
                value={topRadius}
                onChange={(v) => handleDebouncedItemStyleChange('top', [bottomRadius, v])}
                min={0}
                max={100}
                step={1}
                label={`Top Radius: ${topRadius}px`}
            />
            <Slider
                value={bottomRadius}
                onChange={(v) => handleDebouncedItemStyleChange('bottom', [v, topRadius])}
                min={0}
                max={100}
                step={1}
                label={`Bottom Radius: ${bottomRadius}px`}
            />
            <Slider
                label={`Bar width: ${barWidth}`}
                value={barWidth.split('%')[0]}
                onChange={(v) => handleDebouncedBarWidthChange(v + '%')}
                min={0}
                max={100}
                step={1}
            />

            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch
                    checked={showAxisLabel}
                    onClick={() => handleRadialAxisPropChange('axisLabel', 'show', !showAxisLabel)}
                />
                <Typography className={'inter style-config-label'}>Show axis label</Typography>
            </Box>
            {showAxisLabel && (
                <Box
                    className={'column'}
                    sx={{
                        padding: '0 16px 16px 16px',
                        gap: '16px',
                        borderBottom: `${showAxisLabel ? '1px solid #E6E4E6' : ''}`,
                    }}
                >
                    {renderColorPicker('Color', getRadialAxisPropValue('axisLabel', 'color'), (v) =>
                        handleRadialAxisPropChange('axisLabel', 'color', v)
                    )}
                    <Slider
                        value={labelFontSize}
                        onChange={(v) =>
                            handleDebouncedAxisLabelChange('fontSize', v, setLabelFontSize)
                        }
                        min={10}
                        max={30}
                        step={1}
                        label={`Font size: ${labelFontSize}px`}
                    />
                    {renderSelector(
                        'Font family',
                        getRadialAxisPropValue('axisLabel', 'fontFamily'),
                        (v) => handleRadialAxisPropChange('axisLabel', 'fontFamily', v),
                        FONT_FAMILIES
                    )}
                </Box>
            )}
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch
                    checked={showAxisLine}
                    onClick={() => handleRadialAxisPropChange('axisLine', 'show', !showAxisLine)}
                />
                <Typography className={'inter style-config-label'}>Show axis line</Typography>
            </Box>
            {showAxisLine && (
                <Box
                    className={'column'}
                    sx={{
                        padding: '0 16px 16px 16px',
                        gap: '16px',
                        borderBottom: `${showSplitLine ? '1px solid #E6E4E6' : ''}`,
                    }}
                >
                    {renderColorPicker('Color', getRadialAxisPropValue('axisLine', 'color'), (v) =>
                        handleRadialAxisPropChange('axisLine', 'color', v)
                    )}
                    {renderSelector(
                        'Line style',
                        getRadialAxisPropValue('axisLine', 'type'),
                        (v) => handleRadialAxisPropChange('axisLine', 'type', v),
                        LINE_STYLES
                    )}
                </Box>
            )}
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch
                    checked={showSplitLine}
                    onClick={() => handleRadialAxisPropChange('splitLine', 'show', !showSplitLine)}
                />
                <Typography className={'inter style-config-label'}>Show split line</Typography>
            </Box>
            {showSplitLine && (
                <Box
                    className={'column'}
                    sx={{
                        padding: '0 16px 16px 16px',
                        gap: '16px',
                        borderBottom: `${showSplitLine ? '1px solid #E6E4E6' : ''}`,
                    }}
                >
                    {renderColorPicker('Color', getRadialAxisPropValue('splitLine', 'color'), (v) =>
                        handleRadialAxisPropChange('splitLine', 'color', v)
                    )}
                    {renderSelector(
                        'Line style',
                        getRadialAxisPropValue('splitLine', 'type'),
                        (v) => handleRadialAxisPropChange('splitLine', 'type', v),
                        LINE_STYLES
                    )}
                </Box>
            )}
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch checked={showLabel} onClick={() => setLabelConfig('show', !showLabel)} />
                <Typography className={'inter style-config-label'}>Show Label</Typography>
            </Box>
            {showLabel && (
                <Box
                    className={'column'}
                    sx={{
                        padding: '0 16px 16px 16px',
                        gap: '16px',
                        borderBottom: `${showLabel ? '1px solid #E6E4E6' : ''}`,
                    }}
                >
                    {renderColorPicker('Color', getLabelConfig('color'), (v) =>
                        setLabelConfig('color', v)
                    )}
                    <Slider
                        value={labelSize}
                        onChange={(v) => handleDebouncedLabelChange('fontSize', v, setLabelSize)}
                        min={4}
                        max={30}
                        step={1}
                        label={`Font size: ${labelSize}px`}
                    />
                    {renderSelector(
                        'Font family',
                        getLabelConfig('fontFamily'),
                        (v) => setLabelConfig('fontFamily', v),
                        FONT_FAMILIES
                    )}
                    <Slider
                        value={labelRotation}
                        onChange={(v) => handleDebouncedLabelChange('rotate', v, setLabelRotation)}
                        min={-90}
                        max={90}
                        step={1}
                        label={`Rotation: ${labelRotation}º`}
                    />
                </Box>
            )}
        </Box>
    )
}
