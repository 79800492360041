// actions/authActions.js
export const SET_GUEST_PROMPTS = 'SET_GUEST_PROMPTS'
export const SET_AUTO_SHOW_TOUR_WORKSPACE = 'SET_AUTO_SHOW_TOUR_WORKSPACE'
export const UPDATE_WORKSPACES = 'UPDATE_WORKSPACES'
export const UPDATE_WORKSPACE_NAME = 'UPDATE_WORKSPACE_NAME'
// Existing actions

export const login = (user) => ({
    type: 'LOGIN',
    user,
})

export const logout = () => ({
    type: 'LOGOUT',
})

// actions/authActions.js
export const setAuthenticated = (isAuthenticated, user) => ({
    type: 'SET_AUTHENTICATED',
    isAuthenticated,
    user,
})

export const updateUserInfo = (
    accountID,
    firstName,
    lastName,
    googleAccount,
    workspacesAccessible
) => ({
    type: 'UPDATE_USER_INFO',
    accountID,
    firstName,
    lastName,
    googleAccount,
    workspacesAccessible,
})

export const updateCanvas = (canvasID, canvasName) => ({
    type: 'UPDATE_CANVAS',
    canvasID,
    canvasName,
})

export const switchWorkspace = (workspaceID, workspaceName) => ({
    type: 'SWITCH_WORKSPACE',
    workspaceID,
    workspaceName,
})

export const setGuestMode = () => ({
    type: 'SET_GUEST_MODE',
})

export const setGuestPrompts = (guestPrompts) => ({
    type: SET_GUEST_PROMPTS,
    payload: { guestPrompts },
})

export const setAutoShowTourWorkspace = () => ({
    type: 'SET_AUTO_SHOW_TOUR_WORKSPACE',
    payload: false,
})

export const updateWorkspaces = (workspaces) => ({
    type: UPDATE_WORKSPACES,
    payload: workspaces,
})

export const updateWorkspaceName = (workspaceName, workspaceID, workspaces) => ({
    type: UPDATE_WORKSPACE_NAME,
    payload: { workspaceName, workspaceID, workspaces },
})
