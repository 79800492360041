import { eventLogClientRequest } from '../../api'
import { store } from '../../../store/store'

async function logEvent(eventType, eventData) {
    const params = {
        eventType,
        eventData, // Pass the actual object/array here
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }

    const userID = localStorage.getItem('userID')
    const workspaceID = localStorage.getItem('workspaceID')
    const userName = localStorage.getItem('userName')

    if (userID) params.userID = userID
    if (workspaceID) params.workspaceID = workspaceID
    if (userName) params.email = userName

    const data = JSON.stringify(params)

    // Construct payload for Lambda
    const payload = {
        action: 'logEvent',
        data, // Send the encrypted string
    }

    // Send the payload to Lambda
    return await eventLogClientRequest({
        method: 'post',
        data: payload, // Pass the object, not a string
    })
}

export { logEvent }
