import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import * as React from 'react'
import filterIcon from '../../../../../../assets/icons/Filter.svg'
import FilterModal from './FilterModal'
import { memo, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { packFilter } from './Filter'

const IconButtonWrapper = styled(Button)`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
`

const menuItems = [
    { label: 'Equals', value: 'Equals' },
    { label: 'Not Equals', value: 'NotEquals' },
    { label: 'Is Null', value: 'IsNull' },
    { label: 'Is Not Null', value: 'IsNotNull' },
    { label: 'Like', value: 'Like' },
]

const ColumnHeader = ({
    editingAnchorEl,
    isChecked,
    sheetID,
    headerName,
    inputQuery,
    setCurrentColumns,
    filterList,
    setFilterList,
    server,
    updatePreviewData,
    setColumns,
}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [operator, setOperator] = useState('Equals')
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const [checked, setChecked] = useState(isChecked)
    const [changes, setChanges] = useState(false)
    const [allValues, setAllValues] = useState([])
    const [filterListCopy, setFilterListCopy] = useState([...filterList])

    const [operatorValues, setOperatorValues] = useState(
        filterListCopy
            .filter((f) => f.attributeName === headerName)
            .map((f) => f.operator)
            .filter((value) => value !== undefined)
    )

    useEffect(() => {
        async function fetchData() {
            await handleClickFilter()
        }
        if (editingAnchorEl && editingAnchorEl.column === headerName) {
            setAnchorEl(editingAnchorEl.anchorEl)
            fetchData()
        }
    }, [editingAnchorEl])

    useEffect(() => {
        if (isChecked === checked) return
        setChecked(isChecked)
    }, [isChecked])

    useEffect(() => {
        setFilterListCopy([...filterList])
    }, [filterList])

    const saveFilter2 = async (newFilterList) => {
        let updatedFilterList = []

        if (newFilterList.length === 0) {
            // If newFilterList is empty, remove all filters for the current header
            updatedFilterList = filterListCopy.filter((f) => f.attributeName !== headerName)
        } else {
            // Otherwise, update filter list with new filters
            updatedFilterList = filterListCopy
                .filter(
                    (existingFilter) =>
                        !newFilterList.some(
                            (ff) =>
                                ff.attributeName === existingFilter.attributeName &&
                                ff.operator === existingFilter.operator
                        )
                )
                .concat(newFilterList)
                .filter((f) => f.operator !== undefined)
        }

        if (!changes) {
            handleClose()
            return
        }

        setFilterList(updatedFilterList)
        inputQuery.filter = packFilter(updatedFilterList)
        //await updatePreviewData(inputQuery);
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpen = async (event) => {
        setAnchorEl(event.currentTarget.parentElement.parentElement)
        await handleClickFilter()
    }

    const handleClickCheckbox = (event) => {
        const col = event.target.name
        setChecked((prev) => !prev)
        if (checked) {
            setColumns((prev) => {
                return prev.filter((c) => c !== col)
            })
        } else {
            setColumns((prev) => {
                return [...prev, col]
            })
        }
        setCurrentColumns(col)
    }

    const setCategoryValues = ({ values: categories }) => {
        const newItems = categories.filter((category) => category !== null)
        setAllValues(newItems)
    }

    const handleClickFilter = async () => {
        let action = {
            action: 'addOn',
            addOnAction: 'getCategoryValues',
            tableID: inputQuery.tableID,
            columnName: headerName,
            sheetID: sheetID,
        }
        await server.sheetPostData(action, setCategoryValues)
    }

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                padding: '6px 0px 6px 4px',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '2px',
                    alignItems: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '100%',
                }}
            >
                <Checkbox
                    sx={{
                        padding: 0,
                        color: '#E6E4E6',
                        '&.Mui-checked': {
                            color: '#E50B54',
                        },
                    }}
                    name={headerName}
                    checked={checked}
                    onChange={handleClickCheckbox}
                />
                <strong
                    style={{
                        textOverflow: 'ellipsis',
                        width: '90%',
                        overflow: 'hidden',
                    }}
                >
                    {headerName}
                </strong>
            </Box>
            <IconButtonWrapper sx={{ minWidth: 'auto' }} aria-label="filter">
                {filterListCopy.filter((f) => f.attributeName === headerName)?.length > 0 && (
                    <Box
                        sx={{
                            width: '6px',
                            height: '6px',
                            backgroundColor: '#E50B54',
                            position: 'absolute',
                            right: '0px',
                            top: '2px',
                            borderRadius: '50%',
                        }}
                    />
                )}
                <img src={filterIcon} alt={filterIcon} onClick={handleOpen} />
            </IconButtonWrapper>
            <FilterModal
                operator={operator}
                columnName={headerName}
                open={open}
                menuItems={menuItems}
                onClose={handleClose}
                id={id}
                setOperator={setOperator}
                allValues={allValues}
                filterList={filterListCopy}
                setChanges={setChanges}
                operatorValues={operatorValues}
                setOperatorValues={setOperatorValues}
                saveFilter2={saveFilter2}
                anchorEl={anchorEl}
            />
        </Box>
    )
}

export default ColumnHeader
