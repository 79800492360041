import React, { useState, useCallback, useEffect, useRef } from 'react'
import './CanvasDashboard.css'
import { Box, Typography, TextField, InputAdornment, Skeleton } from '@mui/material'
import ProjectorScreenPink from '../../../assets/icons/ProjectorScreenPink.svg'
import PlusWhite from '../../../assets/icons/PlusWhite.svg'
import EmptyCanvases from '../../../assets/icons/EmptyCanvases.svg'
import Button from '../../common/Button/Button'
import { debounce } from 'lodash'
import { NewCanvasDialog } from './NewCanvasDialog/NewCanvasDialog'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { useDispatch, useSelector } from 'react-redux'
import { CanvasBox } from './CanvasBox/CanvasBox'
import { useNavigate } from 'react-router-dom'
import { switchWorkspace } from '../../../store/actions/authActions'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import { setPresentationLoading, setShowRecipeModal } from '../../../store/actions/uiActions'
import { useCanvases } from '../../../api/hooks/canvases/useCanvases'
import { useWorkspaces } from '../../../api/hooks/workspaces/useWorkspaces'
import SearchBar from '../../common/SeacrhBar/SearchBar'
import { useWorkspaceMetadata } from '../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import { EmptyDataset } from '../../common/EmptyDataset/EmptyDataset'
import { useUserInfo } from '../../../api/hooks/user/useUserInfo'
import { getWorkspaceID } from '../../../utils'
import { ROUTES } from '../../../router/routes'
import { OnboardingModal } from './OnboardingModal'

const tabs = [
    { label: 'All', key: 'all' },
    { label: 'Starred', key: 'starred' },
    { label: 'Recent', key: 'recent' },
    { label: 'Draft', key: 'draft' },
]

export const CanvasDashboard = () => {
    const personalWorkspace = useSelector((state) => state.auth.personalWorkspace)
    const isPresentationLoading = useSelector((state) => state.ui.isPresentationLoading)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [filteredCanvases, setFilteredCanvases] = useState([])
    const [canvasID, setCanvasID] = useState(null)
    const [openDeleteCanvas, setOpenDeleteCanvas] = useState(false)
    const [snackbar, setSnackbar] = useState(null)
    const navigate = useNavigate()
    const { canvases, deleteCanvas, refetch, isLoading } = useCanvases()
    const cavasesAmountRef = useRef(null)
    const scoopUserEmail = useSelector((state) => state.auth.userEmail)
    const identityAlreadySet = useRef(false)
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
    const { workspaces: workspacesAccessible } = useWorkspaces()
    const { user } = useUserInfo()
    const { workspaceMetadata, isLoading: workspaceMetadataLoading } = useWorkspaceMetadata()
    const workspaceID = getWorkspaceID()

    useEffect(() => {
        if (canvases) setFilteredCanvases(canvases)
    }, [canvases])

    // this is strictly for heap
    useEffect(() => {
        if (canvases && scoopUserEmail && !identityAlreadySet.current) {
            // record Heap cookie
            try {
                // console.log('***Checking for heap')
                identityAlreadySet.current = true
                if (window.heap && typeof window.heap.identify === 'function') {
                    window.clarity('identify', `${scoopUserEmail}`)
                } else {
                    console.log('Heap is not set')
                }
            } catch (error) {}
            try {
                window.dataLayer.push({ Googlelogin_Product_Form_Entrance: 'Yes' })
            } catch (e) {}
        }
    }, [canvases, scoopUserEmail])

    const debounceFn = useCallback(
        debounce((value) => {
            setFilteredCanvases(
                canvases.filter((canvas) =>
                    canvas.canvasName.toLowerCase().includes(value.toLowerCase())
                )
            )
        }, 1000),
        [canvases]
    )
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        const redirectTour = localStorage.getItem('redirectTour')
        const recipeMapping = localStorage.getItem('recipeMapping')
        const personal = workspacesAccessible.find((w) => w.workspaceID === personalWorkspace)
        if (redirectTour && canvases.length > 0 && !recipeMapping) {
            setOpenOnboardingModal(true)
        } else if (workspacesAccessible.length > 0 && recipeMapping) {
            if (personal) {
                dispatch(switchWorkspace(personal.workspaceID, personal.name))
            } else {
                dispatch(
                    switchWorkspace(
                        workspacesAccessible[0].workspaceID,
                        workspacesAccessible[0].name
                    )
                )
            }
        }
    }, [canvases, workspacesAccessible, personalWorkspace])

    const handleSearchChange = (event) => {
        setSearch(event.target.value)
        debounceFn(event.target.value)
    }

    const handleDeleteDialog = (canvasID) => {
        setCanvasID(canvasID)
        setOpenDeleteCanvas(true)
    }

    const handleOnDeleteSuccess = () => {
        setOpenDeleteCanvas(false)
        setCanvasID(null)
    }

    const checkCanvasCreation = () => {
        const maxAttempts = 40
        let attempts = 0
        if (cavasesAmountRef.current === null) cavasesAmountRef.current = canvases.length
        const intervalId = setInterval(async () => {
            attempts += 1
            try {
                const result = await refetch()
                const updatedCanvases = result.data
                if (cavasesAmountRef.current < updatedCanvases.length) {
                    clearInterval(intervalId)
                    dispatch(setPresentationLoading(false))
                    const newCanvas = updatedCanvases.filter((newC) =>
                        canvases.some((oldC) => oldC.canvasID !== newC.canvasID)
                    )[0]
                    navigate(`${newCanvas.canvasID}`)
                }
                if (attempts >= maxAttempts) {
                    clearInterval(intervalId)
                    dispatch(setPresentationLoading(false))
                    setSnackbar({
                        severity: 'error',
                        msg: 'Failed to create presentation. Please try again later.',
                    })
                }
            } catch (error) {
                console.error('Error fetching canvases:', error)
                clearInterval(intervalId)
            }
        }, 5000)
    }

    const handleContinue = (selectedOption) => {
        switch (selectedOption) {
            case 0:
                setOpenOnboardingModal(false)
                navigate(`${canvases[0].canvasID}`)
                break
            case 1:
                const personalWorkspace1 = workspacesAccessible.find(
                    (w) => w.workspaceID === user?.personalWorkspace
                )
                dispatch(switchWorkspace(personalWorkspace1.workspaceID, personalWorkspace1.name))
                setOpenOnboardingModal(false)
                dispatch(setShowRecipeModal(true))
                localStorage.removeItem('redirectTour')
                break
            case 2:
                const personalWorkspace2 = workspacesAccessible.find(
                    (w) => w.workspaceID === user?.personalWorkspace
                )
                dispatch(switchWorkspace(personalWorkspace2.workspaceID, personalWorkspace2.name))
                navigate(ROUTES.SOURCES)
                localStorage.removeItem('redirectTour')
                break
        }
    }
    const isDefaultWorkspace = user?.defaultTour
        ? user.defaultTour === workspaceID
        : workspacesAccessible.find((w) => w.name === 'Tour Scoop')?.workspaceID === workspaceID

    const handleGoToDataset = () => {
        navigate(ROUTES.SOURCES, { state: { openNewDataset: true } })
    }

    return (
        <>
            {isDefaultWorkspace && (
                <Box
                    sx={{
                        width: '100vw',
                        top: '56px',
                        position: 'fixed',
                        backgroundColor: '#E8EFFC',
                        padding: '8px 0',
                        borderBottom: '1px solid #CCDAF7',
                    }}
                >
                    <Typography
                        className={'inter'}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            fontFamily: 'Inter',
                            color: '#2B65D9',
                            textAlign: 'center',
                            lineHeight: '20px',
                        }}
                    >
                        This is a Demo Workspace with dummy data and limited functionality
                    </Typography>
                </Box>
            )}
            <Box className={'canvases-screen-container'}>
                <Box className={'canvases-header'}>
                    <Box className={'canvases-pink'}>
                        <img src={ProjectorScreenPink} alt={'Canvases'} />
                    </Box>
                    <Box className={'header-text'}>
                        <Typography className={'inter'} sx={{ fontSize: '28px', fontWeight: 400 }}>
                            Canvases
                        </Typography>
                    </Box>
                    {(workspaceMetadataLoading || workspaceMetadata?.inboxes.length > 0) && (
                        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                            <SearchBar
                                value={search}
                                onChange={handleSearchChange}
                                sx={{ width: '500px' }}
                            />
                            <Button
                                className={'button-purple new-canvas'}
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}
                                disabled={isPresentationLoading}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flex: '1',
                                        alignItems: 'center',
                                    }}
                                >
                                    {isPresentationLoading ? (
                                        <ScoopLoader size={24} />
                                    ) : (
                                        <img src={PlusWhite} alt={'plus'} />
                                    )}
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', ml: '10px' }}
                                    >
                                        New Canvas
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    )}
                </Box>
                {/*<Tabs*/}
                {/*    value={selectedTab}*/}
                {/*    onChange={handleTabChange}*/}
                {/*    sx={{*/}
                {/*        marginTop: '48px',*/}
                {/*        marginBottom: '24px',*/}
                {/*        '& .MuiTabs-flexContainer': { height: '100%' },*/}
                {/*        '& .MuiTabs-indicator': { backgroundColor: '#E50B54', borderRadius: '15px' },*/}
                {/*        '& .Mui-selected': { opacity: 1, color: '#E50B54 !important' }*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {tabs.map((tab) => (*/}
                {/*        <Tab className={'drawer-tab'} key={tab.key} label={tab.label} value={tab.key} disabled={tab.key !== 'all'} />*/}
                {/*    ))}*/}
                {/*</Tabs>*/}
                <Box className={'canvases-container'}>
                    {isLoading || workspaceMetadataLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '24px',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            {Array.from({ length: 8 }, (_, i) => (
                                <Skeleton variant={'rounded'} key={i} width={290} height={160} />
                            ))}
                        </Box>
                    ) : filteredCanvases?.length > 0 ? (
                        filteredCanvases.map((canvas) => (
                            <CanvasBox
                                key={canvas.canvasID}
                                canvasID={canvas.canvasID}
                                canvasName={canvas.canvasName}
                                thumbnailURL={canvas.thumbnailURL}
                                handleDeleteDialog={handleDeleteDialog}
                            />
                        ))
                    ) : !workspaceMetadataLoading && workspaceMetadata?.inboxes.length === 0 ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <EmptyDataset
                                title={'Welcome to Canvases'}
                                description={
                                    'Here you can create and organize your Canvases in Scoop. To get started, create your first dataset.'
                                }
                                icon={EmptyCanvases}
                                onClick={handleGoToDataset}
                            />
                        </Box>
                    ) : (
                        <Typography>No canvases found.</Typography>
                    )}
                </Box>
                <NewCanvasDialog
                    startInterval={checkCanvasCreation}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                />
                <DeleteDialog
                    open={openDeleteCanvas}
                    title={'Canvas'}
                    handleDelete={() =>
                        deleteCanvas({ canvasID, onSuccess: handleOnDeleteSuccess })
                    }
                    handleCancel={() => setOpenDeleteCanvas(false)}
                    type={'Canvas'}
                    description={'Are you sure you want to permanently delete this canvas'}
                />
                {isPresentationLoading && (
                    <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <Alert severity={'info'} variant="filled">
                            Generating presentation, please wait a few minutes...
                        </Alert>
                    </Snackbar>
                )}
                {snackbar && (
                    <Snackbar
                        onClose={() => setSnackbar(null)}
                        autoHideDuration={5000}
                        open={true}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Alert
                            onClose={() => setSnackbar(null)}
                            severity={snackbar?.severity}
                            variant="filled"
                        >
                            {snackbar?.msg}
                        </Alert>
                    </Snackbar>
                )}
                {openOnboardingModal && <OnboardingModal handleContinue={handleContinue} />}
            </Box>
        </>
    )
}
