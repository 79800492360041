import { Box, Divider, Typography } from '@mui/material'
import { Slider } from '../../../../../common/Slider/Slider'
import React, { useCallback, useEffect, useState } from 'react'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'
import { Switch } from '../../../../../common/Switch/Switch'
import { FONT_FAMILIES, PIE_DEFAULT_VALUES, PIE_ORIENTATIONS } from '../styleConsts'
import { debounce, cloneDeep } from 'lodash'

export const PieConfig = ({ config, setConfig, pieDonut, chartPreferences }) => {
    const [innerRadius, setInnerRadius] = useState(PIE_DEFAULT_VALUES.radius[0])
    const [outerRadius, setOuterRadius] = useState(PIE_DEFAULT_VALUES.radius[1])
    const [categoryGap, setCategoryGap] = useState(PIE_DEFAULT_VALUES.padAngle)
    const [borderRadius, setBorderRadius] = useState(PIE_DEFAULT_VALUES.itemStyle.borderRadius)
    const [fontSize, setFontSize] = useState(PIE_DEFAULT_VALUES.label.fontSize)

    useEffect(() => {
        setInnerRadius(getPieRadiusValue('inner'))
        setOuterRadius(getPieRadiusValue('outer'))
        setCategoryGap(getPiePropValue('padAngle'))
        setBorderRadius(getPieBorderRadiusValue())
        setFontSize(getPieLabelValue('fontSize'))
    }, [config])

    const borderRadiusDebouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides[pieDonut].itemStyle.borderRadius = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const radiusDebouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            if (!newConfig.styleOverrides?.[pieDonut]?.radius) {
                newConfig.styleOverrides[pieDonut] = {
                    ...newConfig.styleOverrides[pieDonut],
                    radius: [PIE_DEFAULT_VALUES.radius[0], PIE_DEFAULT_VALUES.radius[1]],
                }
            }
            if (prop === 'outer') newConfig.styleOverrides[pieDonut].radius[1] = value
            else newConfig.styleOverrides[pieDonut].radius[0] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const pieDebouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides[pieDonut][prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const labelDebouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides[pieDonut].label[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const getPiePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides[pieDonut], prop))
            return config.styleOverrides[pieDonut][prop]
        if (propExistsOnObject(chartPreferences[pieDonut], prop))
            return chartPreferences[pieDonut][prop]
        return PIE_DEFAULT_VALUES[prop]
    }

    const setPiePropValue = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides[pieDonut][prop] = value
        setConfig(newConfig)
    }

    const setDebouncedPiePropValue = (prop, value, setter) => {
        pieDebouncedFunc(prop, value)
        setter(value)
    }

    const getPieBorderRadiusValue = () => {
        if (propExistsOnObject(config.styleOverrides[pieDonut].itemStyle, 'borderRadius'))
            return config.styleOverrides[pieDonut].itemStyle.borderRadius
        if (propExistsOnObject(chartPreferences[pieDonut]?.itemStyle, 'borderRadius'))
            return chartPreferences[pieDonut].itemStyle.borderRadius
        return PIE_DEFAULT_VALUES.itemStyle.borderRadius
    }

    const setPieBorderRadius = (prop, value, setter) => {
        borderRadiusDebouncedFunc(prop, value)
        setter(value)
    }

    const getPieRadiusValue = (prop) => {
        if (prop === 'outer') {
            if (propExistsOnObject(config.styleOverrides[pieDonut], 'radius'))
                return config.styleOverrides[pieDonut].radius[1]
            if (propExistsOnObject(chartPreferences[pieDonut], 'radius'))
                return chartPreferences[pieDonut].radius[1]
            return PIE_DEFAULT_VALUES.radius[1]
        } else {
            if (propExistsOnObject(config.styleOverrides[pieDonut], 'radius'))
                return config.styleOverrides[pieDonut].radius[0]
            if (propExistsOnObject(chartPreferences[pieDonut], 'radius'))
                return chartPreferences[pieDonut].radius[0]
            return PIE_DEFAULT_VALUES.radius[0]
        }
    }

    const setPieRadiusValue = (prop, value, setter) => {
        radiusDebouncedFunc(prop, value)
        setter(value)
    }

    const getPieEmphasisValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides[pieDonut].emphasis, prop))
            return config.styleOverrides[pieDonut].emphasis[prop]
        if (propExistsOnObject(chartPreferences[pieDonut]?.emphasis, prop))
            return chartPreferences[pieDonut].emphasis[prop]
        return PIE_DEFAULT_VALUES.emphasis[prop]
    }

    const setPieEmphasisValue = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides[pieDonut].emphasis[prop] = value
        setConfig(newConfig)
    }

    const getPieOrientationValue = () => {
        const startAngle = getPiePropValue('startAngle')
        const endAngle = getPiePropValue('endAngle')
        if (startAngle === 0 && endAngle === 360) {
            return 'full'
        } else if (startAngle === 90 && endAngle === 270) {
            return 'right'
        } else if (startAngle === 360 && endAngle === 180) {
            return 'bottom'
        } else if (startAngle === 180 && endAngle === 360) {
            return 'top'
        } else {
            return 'left'
        }
    }

    const setPieOrientationValue = (value) => {
        const newConfig = cloneDeep(config)
        switch (value) {
            case 'top':
                newConfig.styleOverrides[pieDonut].startAngle = 180
                newConfig.styleOverrides[pieDonut].endAngle = 360
                break
            case 'full':
                newConfig.styleOverrides[pieDonut].startAngle = 0
                newConfig.styleOverrides[pieDonut].endAngle = 360
                break
            case 'right':
                newConfig.styleOverrides[pieDonut].startAngle = 90
                newConfig.styleOverrides[pieDonut].endAngle = 270
                break
            case 'bottom':
                newConfig.styleOverrides[pieDonut].startAngle = 360
                newConfig.styleOverrides[pieDonut].endAngle = 180
                break
            case 'left':
                newConfig.styleOverrides[pieDonut].startAngle = 270
                newConfig.styleOverrides[pieDonut].endAngle = 90
                break
        }
        setConfig(newConfig)
    }

    const getPieLabelValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides[pieDonut].label, prop))
            return config.styleOverrides[pieDonut].label[prop]
        if (propExistsOnObject(chartPreferences[pieDonut]?.label, prop))
            return chartPreferences[pieDonut].label[prop]
        return PIE_DEFAULT_VALUES.label[prop]
    }

    const setPieLabelValue = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides[pieDonut].label[prop] = value
        setConfig(newConfig)
    }

    const setPieLabelDebouncedValue = (prop, value, setter) => {
        labelDebouncedFunc(prop, value)
        setter(value)
    }

    const nightingale = getPiePropValue('roseType')
    const scale = getPieEmphasisValue('scale')

    return (
        <Box className={'column'} sx={{ gap: '24px' }}>
            <Box className={'column'} sx={{ gap: '8px' }}>
                <Slider
                    label={`Inner radius: ${innerRadius}`}
                    value={innerRadius?.split('%')[0]}
                    onChange={(v) => setPieRadiusValue('inner', v + '%', setInnerRadius)}
                    min={0}
                    max={100}
                    step={1}
                />
                <Slider
                    label={`Outer radius: ${outerRadius}`}
                    value={outerRadius?.split('%')[0]}
                    onChange={(v) => setPieRadiusValue('outer', v + '%', setOuterRadius)}
                    min={0}
                    max={100}
                    step={1}
                />
                <Slider
                    label={`Category gap: ${categoryGap}`}
                    value={categoryGap}
                    onChange={(v) => setDebouncedPiePropValue('padAngle', v, setCategoryGap)}
                    min={0}
                    max={10}
                    step={1}
                />
            </Box>
            <Box className={'column'} sx={{ gap: '8px' }}>
                <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                    <Switch
                        checked={!!nightingale}
                        onClick={() =>
                            !!nightingale
                                ? setPiePropValue('roseType', '')
                                : setPiePropValue('roseType', 'area')
                        }
                    />
                    <Typography className={'inter style-config-label'}>Show Nightingale</Typography>
                </Box>
            </Box>
            <Box className={'column'} sx={{ gap: '8px' }}>
                <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                    <Switch checked={scale} onClick={() => setPieEmphasisValue('scale', !scale)} />
                    <Typography className={'inter style-config-label'}>
                        Scale on emphasis
                    </Typography>
                </Box>
            </Box>
            {renderSelector(
                'Pie orientation',
                getPieOrientationValue(),
                (v) => setPieOrientationValue(v),
                PIE_ORIENTATIONS
            )}
            <Slider
                label={`Border radius: ${borderRadius}px`}
                value={borderRadius}
                onChange={(v) => setPieBorderRadius('IS', v, setBorderRadius)}
                min={0}
                max={50}
            />
            <Divider />
            <Box className={'column'} sx={{ gap: '16px' }}>
                <Typography className={'inter style-config-label'}>Labels</Typography>
                <Box className={'column'} sx={{ gap: '16px', padding: '0 8px' }}>
                    <Slider
                        label={`Font size: ${fontSize}px`}
                        value={fontSize}
                        onChange={(v) => setPieLabelDebouncedValue('fontSize', v, setFontSize)}
                        min={8}
                        max={64}
                    />
                    {renderColorPicker('Color', getPieLabelValue('color'), (v) =>
                        setPieLabelValue('color', v)
                    )}
                    {renderSelector(
                        'Font family',
                        getPieLabelValue('fontFamily'),
                        (v) => setPieLabelValue('fontFamily', v),
                        FONT_FAMILIES
                    )}
                </Box>
            </Box>
        </Box>
    )
}
