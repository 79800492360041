// SheetSourceDialog.js
import * as React from 'react'
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Typography,
    FormControl,
    Select,
    InputLabel,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'

import { set } from 'lodash'
import Selector from '../common/Selector/Selector'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import moment from 'moment'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import { useEffect, useState } from 'react'

export function SheetSourceDialog({
    open,
    setOpen,
    chartState,
    config,
    setConfig,
    workspaceMetadata,
    workspaceID,
    setWorksheetMetrics,
    server,
}) {
    const [workspaceSheetlets, setWorkspaceSheetlets] = React.useState(null)
    const [selectedWorksheetID, setSelectedWorksheetID] = React.useState(null)
    const [parentWorksheet, setParentWorksheet] = React.useState(null)
    const [selectedWorksheetName, setSelectedWorksheetName] = React.useState(null)
    const [selectedWorksheetRange, setSelectedWorksheetRange] = React.useState(null)
    const [worksheetNames, setWorksheetNames] = React.useState([])
    const [worksheetNamedRanges, setWorksheetNamedRanges] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [loadingOpen, setLoadingOpen] = React.useState(false)
    const [dataSourceType, setDataSourceType] = useState('namedRange')

    useEffect(() => {
        if (!workspaceSheetlets && !selectedWorksheetID && open) {
            setWorkspaceSheetlets([])
            setSelectedWorksheetID(null)
            server.postData(
                {
                    action: 'getObjects',
                    class: 'scoop.worksheet.Worksheet',
                    attributeName: 'workspaceID',
                    attributeValue: workspaceID,
                },
                ({ objects: result }) => {
                    let availableWorksheets = result.filter(
                        (wi) =>
                            wi.sheetType === 'DynamicSheet' || wi.sheetType === 'CalculatedSource'
                    )
                    availableWorksheets = availableWorksheets.sort((a, b) => {
                        if (a.label === null) return 1 // Push nulls to the end
                        if (b.label === null) return -1 // Keep non-nulls at the beginning
                        return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                    })
                    setWorkspaceSheetlets(availableWorksheets)
                }
            )
        }
    }, [open, workspaceSheetlets, selectedWorksheetID])

    function handleClose() {
        setOpen(false)
    }

    const handleOpen = () => {
        setLoadingOpen(true)
        const newConfig = { ...config }
        newConfig.worksheetID = selectedWorksheetID
        const action = {
            action: 'getColumns',
            worksheetID: selectedWorksheetID,
        }
        if (dataSourceType === 'worksheet') {
            action.sheetName = selectedWorksheetName
            newConfig.sheetName = selectedWorksheetName
        }
        if (dataSourceType === 'namedRange') {
            action.namedRange = selectedWorksheetRange
            newConfig.rangeName = selectedWorksheetRange
        }
        server.sheetPostData(action, (r) => {
            setWorksheetMetrics(r.columns.filter((col) => col.isMeasure))
            chartState.setDrillAttributes(
                r.columns
                    .filter((col) => !col.isMeasure && !(col.columnType === 'DateTime'))
                    .map((c) => c.columnName)
            )
            newConfig.worksheetColumns = r.columns.map((c) => ({
                ...c,
                worksheetID: selectedWorksheetID,
                rangeName: selectedWorksheetRange,
            }))
            if (!r.columns.some((c) => c.columnType === 'DateTime')) {
                newConfig.categoryAxis = 'All'
                newConfig.tableTimeSeries = false
            }
            newConfig.selectedWorksheetName =
                parentWorksheet +
                ' - ' +
                (dataSourceType === 'namedRange' ? selectedWorksheetRange : selectedWorksheetName)
            setOpen(false)
            setLoadingOpen(false)
            setConfig(newConfig)
        })
    }

    const handleSelectWorksheet = (id, label) => {
        setParentWorksheet(label)
        setSelectedWorksheetID(id)
        setLoading(true)
        server.postData(
            {
                action: 'getSheetMetadata',
                worksheetID: id,
            },
            (result) => {
                setLoading(false)
                if (result.worksheets) {
                    setWorksheetNames(result.worksheets)
                    setSelectedWorksheetName(result.worksheets[0]) // Pre-select the first worksheet
                }
                if (result.namedRanges) {
                    setWorksheetNamedRanges(result.namedRanges)
                    if (result.namedRanges.length > 0) {
                        setSelectedWorksheetRange(result.namedRanges[0]) // Pre-select the first named range
                    }
                }
            }
        )
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={handleClose} text={'Cancel'} />
            {!loading && (
                <Button
                    onClick={handleOpen}
                    className={'button-purple'}
                    disabled={(!selectedWorksheetName && !selectedWorksheetRange) || loadingOpen} // Disable the button based on the selection
                >
                    {loadingOpen ? (
                        <ScoopLoader color="#7442D7" size={56} />
                    ) : (
                        <Typography className={'inter'}>Open</Typography>
                    )}
                </Button>
            )}
        </Box>
    )

    if (workspaceMetadata) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                title={'Specify a worksheet and range as an Explorer Source'}
                actions={actions}
            >
                {workspaceSheetlets && (
                    <Box
                        sx={{
                            minWidth: 500,
                            maxHeight: '30vh',
                            overflowY: 'auto',
                            bgcolor: 'background.paper',
                            mt: 2,
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            // Added the following for debugging purposes
                            borderColor: 'red', // Temporarily set to red to ensure visibility
                        }}
                    >
                        <List dense={true} sx={{ maxHeight: '30vh' }}>
                            {workspaceSheetlets?.map((wi) => (
                                <ListItemButton
                                    selected={
                                        selectedWorksheetID &&
                                        selectedWorksheetID === wi.worksheetID
                                    }
                                    key={wi.worksheetID}
                                    onClick={(event) =>
                                        handleSelectWorksheet(wi.worksheetID, wi.label)
                                    }
                                >
                                    <ListItemText
                                        primary={wi.label}
                                        secondary={`(modified: ${moment(wi.lastModified).format('MM/DD/YY HH:mm:ss')})`}
                                        secondaryTypographyProps={{
                                            style: { fontSize: 'small' },
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                )}
                {worksheetNames.length > 0 && worksheetNamedRanges.length > 0 && !loading && (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 2,
                                mb: 2,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    color: '#333333',
                                }}
                            >
                                Bring data from:{' '}
                            </Typography>
                            <RadioGroup
                                row
                                aria-label="data source"
                                name="dataSource"
                                value={dataSourceType}
                                onChange={(event) => setDataSourceType(event.target.value)}
                            >
                                <FormControlLabel
                                    disabled
                                    value="worksheet"
                                    control={
                                        <Radio
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#E50B54',
                                                },
                                            }}
                                        />
                                    }
                                    label="Worksheet"
                                />
                                <FormControlLabel
                                    value="namedRange"
                                    control={
                                        <Radio
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#E50B54',
                                                },
                                            }}
                                        />
                                    }
                                    label="Named Range"
                                />
                            </RadioGroup>
                        </Box>
                        {/* Worksheet Selector */}
                        {dataSourceType === 'worksheet' && (
                            <Selector
                                labelClassName={'selector-label-large'}
                                value={selectedWorksheetName}
                                onChange={(event) => setSelectedWorksheetName(event.target.value)}
                                label={'Worksheet Name'}
                            >
                                {worksheetNames.map((worksheetName) => (
                                    <MenuItem key={worksheetName} value={worksheetName}>
                                        {worksheetName}
                                    </MenuItem>
                                ))}
                            </Selector>
                        )}
                        {/* Named Range Selector */}
                        {dataSourceType === 'namedRange' && (
                            <Box sx={{ flexGrow: 1, mt: 2 }}>
                                <FormControl fullWidth size="small">
                                    <Selector
                                        labelClassName={'selector-label-large'}
                                        value={selectedWorksheetRange}
                                        onChange={(event) =>
                                            setSelectedWorksheetRange(event.target.value)
                                        }
                                        label="Named Range"
                                    >
                                        {worksheetNamedRanges.map((namedRange) => (
                                            <MenuItem key={namedRange} value={namedRange}>
                                                {namedRange}
                                            </MenuItem>
                                        ))}
                                    </Selector>
                                </FormControl>
                            </Box>
                        )}
                    </Box>
                )}
                {loading && (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={'100%'}
                    >
                        <ScoopLoader color="#7442D7" size={56} />
                    </Box>
                )}
            </Dialog>
        )
    } else return null
}
