import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Download from '../../../../assets/icons/Download.svg'
import { StatusBubble } from '../../../common/StatusBubble/StatusBubble'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Server } from '../../../../api/Server'

export const EmailDrawerInfo = ({ logDetails }) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)

    const successSteps = [
        { step: 'Email received', status: 'Success' },
        { step: 'Data found in email', status: 'Success' },
        { step: 'Data successfully processed', status: 'Success' },
    ]

    const failureSteps = [
        { step: 'Email received', status: 'Success' },
        { step: "Couldn't scoop any data", status: 'Failed' },
    ]

    const processSourceContent = (result) => {
        const cleanedUrl = result?.url?.replace(/\s+/g, '')
        window.open(cleanedUrl)
    }

    const handleSourceContent = (inboxID, s3Key) => {
        const server = new Server(workspaceID, userID, token)
        server.postData(
            {
                action: 'getIngestionContent',
                inboxID: inboxID,
                s3Key: s3Key,
            },
            processSourceContent
        )
    }
    return (
        <>
            {logDetails.metadata && (
                <Box>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#635566',
                            marginBottom: '8px',
                        }}
                    >
                        Source Info
                    </Typography>

                    <Box
                        sx={{
                            padding: '16px',
                            backgroundColor: '#F9F9F9',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        minWidth: '70px',
                                    }}
                                >
                                    Subject
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {logDetails.metadata?.subject}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        minWidth: '70px',
                                    }}
                                >
                                    From
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {logDetails.metadata?.from}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        minWidth: '70px',
                                    }}
                                >
                                    To
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {logDetails.metadata?.to}
                                </Typography>
                            </Box>
                            {logDetails.metadata?.attachment && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            minWidth: '70px',
                                        }}
                                    >
                                        Attachment
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {logDetails.metadata?.attachment}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#F2F2F2',
                                borderRadius: '5px',
                                padding: '6px',
                                height: 'fit-content',
                            }}
                            onClick={() => {
                                handleSourceContent(logDetails.inboxID, logDetails.s3key)
                            }}
                        >
                            <img src={Download} alt={'Download'} />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <Box>
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#635566',
                        marginBottom: '8px',
                    }}
                >
                    Scoop Activity
                </Typography>
                {logDetails?.rstiKey ? (
                    successSteps?.map((step, index) => (
                        <>
                            <Box>
                                <Box sx={{ display: 'flex', gap: '12px' }}>
                                    <StatusBubble status={step.status} />
                                    <Typography>{step.step}</Typography>
                                </Box>
                                {index !== successSteps?.length - 1 && (
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            borderLeft: '1px solid #D0CDD1',
                                            margin: '2px 0 2px 12px',
                                            height: '20px',
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    ))
                ) : (
                    <>
                        {failureSteps?.map((step, index) => (
                            <>
                                <Box>
                                    <Box sx={{ display: 'flex', gap: '12px' }}>
                                        <StatusBubble status={step.status} />
                                        <Typography>{step.step}</Typography>
                                    </Box>
                                    {index !== failureSteps?.length - 1 && (
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                borderLeft: '1px solid #D0CDD1',
                                                margin: '2px 0 2px 12px',
                                                height: '20px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </>
                        ))}
                        <Box
                            sx={{
                                marginLeft: '34px',
                                marginTop: '4px',
                                borderRadius: '5px',
                                padding: '16px',
                                backgroundColor: '#F9F9F9',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#635566',
                                }}
                            >
                                {
                                    'It looked like there was a bad condition, so we didn’t load the data to protect your snapshot data'
                                }
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}
