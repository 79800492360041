import {
    Box,
    Button,
    css,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material'
import SourceIcon from '@mui/icons-material/Source'
import TableChartIcon from '@mui/icons-material/TableChart'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import './Source.css'

export function ReportDialog({
    open,
    setOpen,
    ingestionMetadata,
    metadata,
    server,
    getIngestionMetadata,
    getWorkspaceMetadata,
}) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
    const [deleteRow, setDeleteRow] = React.useState(null)
    const [confirmMessage, setConfirmMessage] = React.useState(null)

    function handleClose(event) {
        setOpen(false)
    }

    function processSourceContent(result) {
        const cleanedUrl = result?.url?.replace(/\s+/g, '')
        window.open(cleanedUrl)
    }

    function handleSourceContent(inboxID, s3Key) {
        server.postData(
            {
                action: 'getIngestionContent',
                inboxID: inboxID,
                s3Key: s3Key,
            },
            processSourceContent
        )
    }

    function processTableContent(result) {
        window.open(result)
    }

    function handleTableContent(inboxID, contentKey) {
        server.postData(
            {
                action: 'getTableContent',
                inboxID: inboxID,
                contentKey: contentKey,
            },
            processTableContent
        )
    }

    function handleDelete() {
        setConfirmDeleteOpen(false)
        if (deleteRow) {
            if (deleteRow.rstiKey) {
                server.postData(
                    {
                        action: 'deleteIngestion',
                        inboxID: ingestionMetadata.inboxID,
                        rstiKey: deleteRow.rstiKey,
                    },
                    () => {
                        getIngestionMetadata(ingestionMetadata.inboxID)
                        getWorkspaceMetadata(server)
                    }
                )
            } else {
                server.postData(
                    {
                        action: 'deleteIngestion',
                        inboxID: ingestionMetadata.inboxID,
                        s3Key: deleteRow.s3Key,
                    },
                    () => {
                        getIngestionMetadata(ingestionMetadata.inboxID)
                        getWorkspaceMetadata(server)
                    }
                )
            }
            setDeleteRow(null)
        }
    }

    let rows = null
    if (ingestionMetadata) {
        rows = ingestionMetadata.ingestions
    }

    const columns = [
        {
            field: 'inferredDate',
            width: 100,
            renderHeader: (params) => <Box className="tableHeader">Date</Box>,
        },
        {
            field: 'rstiKey',
            align: 'center',
            headerAlign: 'center',
            width: 90,
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {params.value ? (
                        <CheckIcon style={{ color: 'green' }} fontSize="small" />
                    ) : (
                        <ErrorIcon fontSize="small" style={{ color: 'red' }} />
                    )}
                </Box>
            ),
            renderHeader: (params) => (
                <Box className="tableHeader">
                    Success/
                    <br />
                    Failure
                </Box>
            ),
        },
        {
            field: 's3key',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.s3key) {
                    return (
                        <IconButton
                            onClick={(event) => {
                                handleSourceContent(ingestionMetadata.inboxID, params.row.s3key)
                            }}
                        >
                            <SourceIcon />
                        </IconButton>
                    )
                } else {
                    return <Box></Box>
                }
            },
            renderHeader: (params) => (
                <Box className="tableHeader">
                    Raw
                    <br />
                    Source
                    <br />
                    Item
                </Box>
            ),
        },
        {
            field: 'reportSeriesTableID',
            width: 450,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                for (let i = 0; i < metadata.inboxes.length; i++) {
                    for (let r = 0; r < metadata.inboxes[i].tables.length; r++) {
                        if (metadata.inboxes[i].tables[r].reportSeriesTableID === params.value) {
                            if (params.row.originalInboxID) {
                                for (let oi = 0; oi < metadata.inboxes.length; oi++) {
                                    if (
                                        metadata.inboxes[oi].inboxID === params.row.originalInboxID
                                    ) {
                                        return (
                                            <Box>
                                                {metadata.inboxes[i].tables[r].tableName}
                                                &nbsp;(from:&nbsp;
                                                {metadata.inboxes[oi].inboxLabel} )
                                            </Box>
                                        )
                                    }
                                }
                            }
                            return <Box>{metadata.inboxes[i].tables[r].tableName}</Box>
                            break
                        }
                    }
                }
                return <Box></Box>
            },
            renderHeader: (params) => <Box className="tableHeader">Table Name</Box>,
        },
        {
            field: 'contentKey',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.rstiKey) {
                    return (
                        <IconButton
                            onClick={(event) => {
                                handleTableContent(ingestionMetadata.inboxID, params.row.contentKey)
                            }}
                        >
                            <TableChartIcon fontSize="small" />
                        </IconButton>
                    )
                } else {
                    return <Box></Box>
                }
            },
            renderHeader: (params) => (
                <Box className="tableHeader">
                    Content
                    <br />
                    Successfully
                    <br />
                    Processed
                </Box>
            ),
        },
        {
            field: 'deleteKey',
            width: 70,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.rstiKey) {
                    return (
                        <IconButton
                            onClick={(event) => {
                                setDeleteRow(params.row)
                                setConfirmMessage(
                                    'Are you sure that you want to delete this report content from Scoop?'
                                )
                                setConfirmDeleteOpen(true)
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )
                } else {
                    return <Box></Box>
                }
            },
            renderHeader: (params) => <Box className="tableHeader">Delete</Box>,
        },
    ]
    // maxWidth={false} to set to fullWidth, and fullWidth={true}
    return (
        <Box sx={{ width: '100%' }}>
            {ingestionMetadata && (
                <Dialog open={open} onClose={handleClose} maxWidth={800}>
                    <DialogTitle sx={{ color: 'text.secondary' }}>
                        {ingestionMetadata.inboxLabel}
                    </DialogTitle>
                    <DialogContent sx={{ width: '100%' }}>
                        <DialogContentText>Report load history</DialogContentText>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            rowHeight={24}
                            autoHeight
                            disableColumnMenu={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                maxWidth={'600px'}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDelete()
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
