import React, { useEffect, useRef, useState } from 'react'
import './NumericRange.css'
import { Box, Popover, TextField, Typography } from '@mui/material'
import Button from '../Button/Button'
import Slider from '@mui/material/Slider'

export const NumericRange = ({
    value,
    onChange,
    min,
    max,
    containerSx,
    label,
    popWidth,
    isGuestMode,
    theme,
}) => {
    const anchor = useRef()
    const [showSlider, setShowSlider] = useState(false)
    const [innerValue, setInnerValue] = useState([0, 0])

    const getMinValue = () => {
        if (min !== null) return min || 0
        else return 0
    }

    useEffect(() => {
        if (value) setInnerValue(value)
        else setInnerValue([getMinValue(), max || 1000000])
    }, [min, max, value])

    const handleInputChange = (value, from) => {
        if (from === 'min') {
            if (value >= min) setInnerValue([value, innerValue[1]])
        }
        if (from === 'max') {
            if (value <= max) setInnerValue([innerValue[0], value])
        }
    }

    const handleApply = () => {
        onChange(innerValue)
        setShowSlider(false)
    }

    return (
        <>
            <Box className={'numeric-range-container'} sx={containerSx}>
                {label && (
                    <Typography className={'inter'} sx={{ fontWeight: 600, fontSize: '12px' }}>
                        {label}
                    </Typography>
                )}
                <Box
                    className={'numeric-range-input'}
                    ref={anchor}
                    onClick={() => !isGuestMode && setShowSlider(true)}
                    sx={{
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                        color: theme?.colorScheme?.darkTheme ? 'white' : '',
                        border: theme?.colorScheme?.darkTheme
                            ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                            : '',
                    }}
                >
                    <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                        {value ? value[0] + ' to ' + value[1] : 'Select range'}
                    </Typography>
                </Box>
            </Box>
            <Popover
                open={showSlider}
                anchorEl={anchor.current}
                onClose={() => setShowSlider(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                container={document.getElementById('slide-container')}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                        backdropFilter:
                            theme?.colorScheme?.backgroundColor === '#00000000' ? 'blur(20px)' : '',
                    },
                }}
            >
                <Box
                    className={'numeric-range-popup'}
                    sx={{
                        width: popWidth,
                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '24px',
                            mb: '12px',
                        }}
                    >
                        <Box>
                            <label
                                className={'prompt-label-label'}
                                style={{
                                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                }}
                            >
                                Min
                            </label>
                            <Box sx={{ mt: '-20px' }}>
                                <TextField
                                    className={'numeric-range-value'}
                                    value={innerValue[0]}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        type: 'number',
                                        sx: {
                                            color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                        },
                                    }}
                                    onChange={(e) => handleInputChange(e.target.value, 'min')}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: theme?.colorScheme?.darkTheme
                                                ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                                                : '',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <label
                                className={'prompt-label-label'}
                                style={{
                                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                }}
                            >
                                Max
                            </label>
                            <Box sx={{ mt: '-20px' }}>
                                <TextField
                                    className={'numeric-range-value'}
                                    value={innerValue[1]}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        type: 'number',
                                        sx: {
                                            color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                        },
                                    }}
                                    onChange={(e) => handleInputChange(e.target.value, 'max')}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: theme?.colorScheme?.darkTheme
                                                ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                                                : '',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', padding: '0 12px' }}>
                        <Slider
                            value={innerValue}
                            onChange={(e, value) => setInnerValue(value)}
                            sx={{
                                '& .MuiSlider-track': {
                                    color: '#E50B54 !important',
                                },
                                '& .MuiSlider-rail': {
                                    color: '#FCE7EE !important',
                                },
                                '& .MuiSlider-thumb': {
                                    color: '#E50B54 !important',
                                    boxShadow: 'none !important',
                                },
                            }}
                            min={getMinValue()}
                            max={max || 1000000}
                        />
                    </Box>
                    <Box className={'numeric-range-footer'}>
                        <Button
                            onClick={() => setShowSlider(false)}
                            className={'numeric-range-button'}
                            sx={{
                                color: theme?.colorScheme?.darkTheme ? 'white !important' : '',
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleApply}
                            className={'numeric-range-button button-purple'}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    )
}
