import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { FONT_FAMILIES, FONT_WEIGHTS, TEXT_ALIGN, TITLE_DEFAULT_VALUES } from '../styleConsts'
import { Slider } from '../../../../../common/Slider/Slider'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'
import { debounce, cloneDeep } from 'lodash'

export const TitleConfig = ({ config, setConfig, chartPreferences }) => {
    const [fontSize, setFontSize] = useState(TITLE_DEFAULT_VALUES.textStyle.fontSize)
    const [top, setTop] = useState(TITLE_DEFAULT_VALUES.top)
    const [left, setLeft] = useState(TITLE_DEFAULT_VALUES.left)

    useEffect(() => {
        setTop(getTitlePropValue('top'))
        setLeft(getTitlePropValue('left'))
        setFontSize(getTitleTextStylePropValue('fontSize'))
    }, [config])

    const debouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.title[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const debouncedTextStyleFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.title.textStyle[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const getTitlePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.title, prop))
            return config.styleOverrides.title[prop]
        if (propExistsOnObject(chartPreferences.title, prop)) return chartPreferences.title[prop]
        return TITLE_DEFAULT_VALUES[prop]
    }

    const getTitleTextStylePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.title.textStyle, prop))
            return config.styleOverrides.title.textStyle[prop]
        if (propExistsOnObject(chartPreferences.title?.textStyle, prop))
            return chartPreferences.title.textStyle[prop]
        return TITLE_DEFAULT_VALUES.textStyle[prop]
    }

    const handleDebounceTitleTextStylePropChange = (prop, value, setter) => {
        debouncedTextStyleFunc(prop, value)
        setter(value)
    }

    const handleDebounceTitlePropChange = (prop, value, setter) => {
        debouncedFunc(prop, value)
        setter(value)
    }

    const handleTitleTextStylePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.title.textStyle[prop] = value
        setConfig(newConfig)
    }

    const handleTitlePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.title[prop] = value
        setConfig(newConfig)
    }

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            <Slider
                label={`Horizontal position: ${left}`}
                value={parseInt(left.replace('%', ''))}
                onChange={(v) => handleDebounceTitlePropChange('left', v + '%', setLeft)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Vertical position: ${top}`}
                value={parseInt(top.replace('%', ''))}
                onChange={(v) => handleDebounceTitlePropChange('top', v + '%', setTop)}
                min={0}
                max={100}
                step={1}
            />
            {renderSelector(
                'Text align',
                getTitlePropValue('textAlign'),
                (v) => handleTitlePropChange('textAlign', v),
                TEXT_ALIGN
            )}
            {renderSelector(
                'Font family',
                getTitleTextStylePropValue('fontFamily'),
                (v) => handleTitleTextStylePropChange('fontFamily', v),
                FONT_FAMILIES
            )}
            {renderSelector(
                'Font weight',
                getTitleTextStylePropValue('fontWeight'),
                (v) => handleTitleTextStylePropChange('fontWeight', v),
                FONT_WEIGHTS
            )}
            <Slider
                label={`Font size: ${fontSize}px`}
                value={fontSize}
                min={8}
                max={64}
                step={1}
                onChange={(v) => handleDebounceTitleTextStylePropChange('fontSize', v, setFontSize)}
            />
            {renderColorPicker('Font color', getTitleTextStylePropValue('color'), (v) =>
                handleTitleTextStylePropChange('color', v)
            )}
            {renderColorPicker('Background color', getTitlePropValue('backgroundColor'), (v) =>
                handleTitlePropChange('backgroundColor', v)
            )}
        </Box>
    )
}
