import RecipeThumbnail from '../../../../assets/images/RecipeThumbnail.svg'
import { useState } from 'react'
import { Box, Card, CardContent, CardMedia, Chip } from '@mui/material'
import ScoopLogo from '../../../../assets/logo/NewLogo.svg'
import Typography from '@mui/material/Typography'
import SearchBar from '../../../common/SeacrhBar/SearchBar'

export const ChooseRecipe = ({ selectRecipe }) => {
    const categories = [
        'All',
        'Sales',
        'Marketing',
        'Lead Generation',
        'Customer Success',
        'Finance',
        'Product Analysis',
        'Services',
    ]
    const recipes = [
        {
            img: RecipeThumbnail,
            department: 'Marketing',
            title: 'Deal velocity',
            description:
                'This recipe provides you with average time between stages and is filterable by any field available within the data source. These filters allow you look at deal velocity by fields like Deal Owner, Deal Source and Deal Type.',
            stats: ['1 dataset', '2 canvases'],
        },
        {
            img: RecipeThumbnail,
            department: 'Finance',
            title: 'Budget Analysis',
            description:
                'This recipe analyzes your budget data and provides insights into spending trends, allocation efficiency, and budget performance over time.',
            stats: ['3 datasets', '1 canvas'],
        },
        {
            img: RecipeThumbnail,
            department: 'Human Resources',
            title: 'Employee Satisfaction',
            description:
                'This recipe measures employee satisfaction through surveys and analyzes the results to identify areas for improvement and track changes over time.',
            stats: ['2 datasets', '3 canvases'],
        },
        {
            img: RecipeThumbnail,
            department: 'Operations',
            title: 'Inventory Optimization',
            description:
                'This recipe helps optimize inventory levels by analyzing historical data, demand forecasts, and supply chain disruptions to ensure efficient inventory management.',
            stats: ['2 datasets', '2 canvases'],
        },
        {
            img: RecipeThumbnail,
            department: 'Customer Service',
            title: 'Customer Feedback Analysis',
            description:
                'This recipe analyzes customer feedback from various channels such as surveys, reviews, and social media to identify trends, sentiment, and areas for improvement in customer service.',
            stats: ['3 datasets', '2 canvases'],
        },
        {
            img: RecipeThumbnail,
            department: 'Product Development',
            title: 'Feature Prioritization',
            description:
                'This recipe prioritizes product features based on customer feedback, market demand, and strategic goals to optimize product development efforts and maximize impact.',
            stats: ['2 datasets', '1 canvas'],
        },
        {
            img: RecipeThumbnail,
            department: 'Sales',
            title: 'Pipeline Analysis',
            description:
                'This recipe analyzes the sales pipeline to identify bottlenecks, opportunities, and potential risks, enabling better sales forecasting and decision-making.',
            stats: ['3 datasets', '2 canvases'],
        },
    ]

    const [categorySelected, setCategorySelected] = useState('All')

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt={'scoop-logo'} src={ScoopLogo} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#201024',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '110%',
                            letterSpacing: '-0.64px',
                        }}
                    >
                        Scoop Recipes
                    </Typography>
                    <Typography
                        sx={{
                            color: '#635566',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%', // 19.6px
                            letterSpacing: '-0.28px',
                        }}
                    >
                        These ready-made templates provide inspiration with just a few clicks.
                        <br />
                        Connect them to your own data sources and start customising.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <SearchBar placeholder={'Search recipes'} sx={{ minWidth: '400px' }} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '64px',
                    padding: '0 32px',
                    maxHeight: '400px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center',
                        minWidth: '200px',
                    }}
                >
                    <Typography
                        sx={{
                            width: '100%',
                            color: '#2B1630',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '110%',
                            letterSpacing: '-0.4px',
                        }}
                    >
                        Categories
                    </Typography>
                    <Box>
                        {categories.map((category, index) => (
                            <Box
                                key={index}
                                sx={{
                                    borderRadius: '5px',
                                    padding: '10px 12px',
                                    cursor: 'pointer',
                                    backgroundColor:
                                        categorySelected === category ? '#FCE7EE' : 'transparent',
                                }}
                                value={category}
                                onClick={() => setCategorySelected(category)}
                            >
                                <Typography
                                    sx={{
                                        color:
                                            categorySelected === category ? '#E50B54' : '#635566',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '115%',
                                        letterSpacing: '-0.28px',
                                    }}
                                >
                                    {category}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: ' 16px',
                        flexWrap: 'wrap',
                        maxHeight: '50%',
                        overflow: 'scroll',
                    }}
                >
                    {recipes.map((recipe, index) => (
                        <RecipeCard selectRecipe={selectRecipe} key={index} recipe={recipe} />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

const RecipeCard = ({ recipe, selectRecipe }) => {
    return (
        <Card
            onClick={() => selectRecipe(recipe)}
            sx={{ maxWidth: '360px', width: '360px', cursor: 'pointer' }}
        >
            <CardMedia title={recipe.title} sx={{ height: '178px' }}>
                <img alt={recipe.title} src={recipe.img} />
            </CardMedia>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px 24px',
                    gap: '12px',
                }}
            >
                <Box>
                    <Chip
                        label={recipe.department}
                        sx={{
                            backgroundColor: '#E8EFFC',
                            color: '#2B65D9',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '115%',
                            letterSpacing: '-0.24px',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: '#2B1630',
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '110%', // 22px
                            letterSpacing: '-0.4px',
                        }}
                    >
                        {recipe.title}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#635566',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%', // 16.8px
                            letterSpacing: '-0.24px',
                        }}
                    >
                        {recipe.description}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                    {recipe.stats.map((stat, index) => (
                        <Chip label={stat} key={index} />
                    ))}
                </Box>
            </CardContent>
        </Card>
    )
}
