import { useQuery } from '@tanstack/react-query'
import { getUserInfo, getUserWorkspaces } from './userApi'
import { getUserID } from '../../../utils'

export const useUserInfo = () => {
    const userID = getUserID()

    const { data: user, refetch: getUser } = useQuery({
        queryKey: ['user', userID],
        queryFn: () => getUserInfo(userID),
        enabled: !!userID,
    })

    return {
        user: user || {},
        getUser,
    }
}
