import React from 'react'
import Button from '../../../common/Button/Button' // Adjust the path as necessary
import QuickbooksIcon from '../../../../assets/icons/C2QB_green_btn_med_default.png' // Adjust the path as necessary
import { Server } from '../../../../api/Server'

export const displayPorperProductNameInDialog = (productName) => {
    if (productName === 'Monday') {
        return 'monday.com'
    } else if (productName == 'Quickbooks') {
        return 'QuickBooks'
    } else {
        return productName
    }
}

export const displayProperProductButton = (productName, APIFeatures) => {
    if (productName.toLowerCase() === 'quickbooks') {
        // Return a custom button for Quickbooks with an icon
        return (
            <Button
                className="button-no-color small"
                style={{ height: '36px' }}
                onClick={() => window.open(APIFeatures.oauthLink, '_blank', 'noreferrer')}
            >
                <img src={QuickbooksIcon} alt="Quickbooks" style={{ height: '36px' }} />
            </Button>
        )
    } else {
        // Default button text handling for other products
        return (
            <Button
                className={'button-purple small'}
                onClick={() => window.open(APIFeatures.oauthLink, '_blank', 'noreferrer')}
            >
                Next
            </Button>
        )
    }
}

// call before a user deletes the API connector just in case something else needs to be done first
export const preDelete = (productName, workspaceID, userID, token, connectionKey) => {
    return new Promise((resolve, reject) => {
        if (productName.toLowerCase() === 'quickbooks') {
            const server = new Server(workspaceID, userID, token)
            server.postData(
                { action: 'revokeAPIToken', connectionKey: connectionKey },
                (response) => resolve(response), // Resolve the promise on success
                (error) => resolve(error) // Resolve even on error, as you don't care about the result
            )
        } else {
            resolve() // Immediately resolve if no specific action is needed
        }
    })
}
