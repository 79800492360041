import { useSelector } from 'react-redux'
import { getSheet, getSheets } from './worksheetApi'
import { useQuery } from '@tanstack/react-query'
import { getWorkspaceID } from '../../../utils'

export const useWorksheet = (advanced, inboxID, sheetType, aggregation = false) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)

    const {
        data: worksheets,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['worksheet', workspaceID, userID, advanced, inboxID, sheetType, aggregation],
        queryFn: () => getSheet(workspaceID, userID, advanced, inboxID, sheetType, aggregation),
        enabled: !!workspaceID && !!userID,
    })

    return {
        worksheets,
        isLoading,
        refetch,
    }
}

export const useWorksheets = () => {
    const workspaceID = getWorkspaceID()

    const {
        data: worksheets,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['worksheets', workspaceID],
        queryFn: () => getSheets(),
        enabled: !!workspaceID,
    })

    return {
        worksheets: worksheets || [],
        isLoading,
        refetch,
    }
}
