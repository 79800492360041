import { OBJECT_TYPES } from '../types'

export class PromptClass {
    constructor(id, x, y, width, height, promptProps) {
        this.id = id
        this.x = x
        this.y = y
        this.width = width
        this.height = height
        this.type = OBJECT_TYPES.PROMPT
        this.promptProps = promptProps
    }

    static newPrompt(zoom, x, y) {
        const canvasScrollElement = document.getElementById('canvas-scrollable')
        return new PromptClass(
            Math.random(),
            x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
            y ? y : (200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
            300,
            100,
            {
                objects: [],
                prompt: null,
                label: '',
                type: '',
            }
        )
    }
}
