import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Download from '../../../../assets/icons/Download.svg'
import { StatusBubble } from '../../../common/StatusBubble/StatusBubble'
import * as React from 'react'
import { Server } from '../../../../api/Server'
import { useSelector } from 'react-redux'

export const BotDrawerInfo = ({ logDetails, dataset }) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)

    // messaging depends on whether this is a real bot or file upload which isn't a real bot but classified as one
    const successSteps = dataset.botService
        ? [
              { step: 'Bot accessed report', status: 'Success' },
              { step: 'Data found in report', status: 'Success' },
              { step: 'Data successfully processed', status: 'Success' },
          ]
        : [
              { step: 'Found report', status: 'Success' },
              { step: 'Data found in report', status: 'Success' },
              { step: 'Data successfully processed', status: 'Success' },
          ]

    const failureSteps = dataset.botService
        ? [{ step: "Bot Couldn't access report", status: 'Failed' }]
        : [
              {
                  step: "We couldn't access report in the file you uploaded",
                  status: 'Failed',
              },
          ]

    const actionableAdvice = dataset.botService
        ? 'Configure Dataset for the Scoop Data Connector to access a proper report.'
        : 'Try uploading a different CSV or Zip file with a table containing data.'

    const processSourceContent = (result) => {
        const resultUrl = result?.url ? result.url : result
        const cleanedUrl = resultUrl.replace(/\s+/g, '')
        window.open(cleanedUrl)
    }

    const failMessage = dataset.botService
        ? 'It looks like the bot couldn’t access this report. Try reconfiguring this dataset and rerunning this scoop.'
        : 'The file you uploaded did not contain a valid table that Scoop could work with.'

    const handleSourceContent = (inboxID, s3Key) => {
        const server = new Server(workspaceID, userID, token)
        server.postData(
            {
                action: 'getIngestionContent',
                inboxID: inboxID,
                s3Key: s3Key,
            },
            processSourceContent
        )
    }
    return (
        <>
            {
                <Box>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#635566',
                            marginBottom: '8px',
                        }}
                    >
                        Source Info
                    </Typography>

                    <Box
                        sx={{
                            padding: '16px',
                            backgroundColor: '#F9F9F9',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        minWidth: '50px',
                                    }}
                                >
                                    Report
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        marginRight: '20px',
                                    }}
                                >
                                    {logDetails.contentKey}
                                </Typography>
                            </Box>
                        </Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#F2F2F2',
                                borderRadius: '5px',
                                padding: '6px',
                                height: 'fit-content',
                            }}
                            onClick={() => {
                                handleSourceContent(logDetails.inboxID, logDetails.s3key)
                            }}
                        >
                            <img src={Download} alt={'Download'} />
                        </IconButton>
                    </Box>
                </Box>
            }
            <Box>
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#635566',
                        marginBottom: '8px',
                    }}
                >
                    Scoop Activity
                </Typography>
                {logDetails?.rstiKey ? (
                    successSteps?.map((step, index) => (
                        <>
                            <Box>
                                <Box sx={{ display: 'flex', gap: '12px' }}>
                                    <StatusBubble status={step.status} />
                                    <Typography>{step.step}</Typography>
                                </Box>
                                {index !== successSteps?.length - 1 && (
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            borderLeft: '1px solid #D0CDD1',
                                            margin: '2px 0 2px 12px',
                                            height: '20px',
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    ))
                ) : (
                    <>
                        {failureSteps?.map((step, index) => (
                            <>
                                <Box>
                                    <Box sx={{ display: 'flex', gap: '12px' }}>
                                        <StatusBubble status={step.status} />
                                        <Typography>{step.step}</Typography>
                                    </Box>
                                    {index !== failureSteps?.length - 1 && (
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                borderLeft: '1px solid #D0CDD1',
                                                margin: '2px 0 2px 12px',
                                                height: '20px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </>
                        ))}
                        <Box
                            sx={{
                                marginLeft: '34px',
                                marginTop: '4px',
                                borderRadius: '5px',
                                padding: '16px',
                                backgroundColor: '#F9F9F9',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#635566',
                                    marginBottom: '12px',
                                }}
                            >
                                {failMessage}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    fontWeight: 600,
                                }}
                            >
                                {actionableAdvice}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}
