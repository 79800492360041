import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ScoopLogo from '../../assets/logo/ScoopLogo.svg'
import { DEFAULT_WORKSPACE_NAME } from '../SwitchWorkspaceDialog'
import UserProfileModal from './Profile'
import { navbarItems } from './navbarItems'
import NavbarItem from './NavBarItem'
import UserCircle from '../../assets/icons/UserCircle.svg'
import './NavBar.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CaretUpDown from '../../assets/icons/CaretUpDown.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout, switchWorkspace } from '../../store/actions/authActions'
import { ROUTES } from '../../router/routes'
import { useEffect, useRef, useState } from 'react'
import { useApi } from '../../api/api'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Typography from '@mui/material/Typography'
import { Tooltip } from '@mui/material'
import {
    toggleSwitchWorkspaceModal,
    canShowTourAlert,
    setResetTrigger,
} from '../../store/actions/uiActions'
import Welcome from '../../assets/images/Welcome.svg'
import { signOut } from '../../api/auth'
import { useWorkspaces } from '../../api/hooks/workspaces/useWorkspaces'
import { SwitchWorkspacePopover } from '../SwitchWorkspacePopover'
import { getWorkspaceID } from '../../utils'
import Button from '../common/Button/Button'

const NavBar = ({ workspaceName }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [switchAnchorEl, setSwitchAnchorEl] = useState(null)
    const [alertOpen, setAlertOpen] = useState(false)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const defaultTourWorkspace = useSelector((state) => state.auth.defaultTourWorkspace)
    const switchWorkspaceModalOpen = useSelector((state) => state.ui.switchWorkspaceModalOpen)
    const userID = useSelector((state) => state.auth.userID)
    const userName = useSelector((state) => state.auth.userName)
    const readyToShowAlert = useSelector((state) => state.ui.canShowTourAlert)
    const { canvasID } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMenuOpen = Boolean(anchorEl)
    const [showUserProfile, setShowUserProfile] = useState(false)
    const { postData: postDataDefault } = useApi()
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)

    const [openWorkspaceActionsModal, setOpenWorkspaceActionsModal] = useState(false)
    const workspacePopoverRef = useRef()

    const { workspaces: workspacesAccessible } = useWorkspaces()

    useEffect(() => {
        if (!workspaceID && !isGuestMode && !localStorage.getItem('recipeMapping')) {
            dispatch(toggleSwitchWorkspaceModal(true))
        } else {
            dispatch(toggleSwitchWorkspaceModal(false))
        }
    }, [workspaceID, isGuestMode])

    useEffect(() => {
        let currentWorkspace = workspacesAccessible.find(
            (workspace) => workspace.workspaceID === workspaceID
        )

        if (!readyToShowAlert) return
        if (currentWorkspace) {
            if (defaultTourWorkspace) {
                if (
                    defaultTourWorkspace === currentWorkspace.workspaceID ||
                    currentWorkspace.name === DEFAULT_WORKSPACE_NAME
                ) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(false)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            } else {
                if (currentWorkspace.name === DEFAULT_WORKSPACE_NAME) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(false)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            }
        } else setAlertOpen(false)
    }, [workspacesAccessible, workspaceID, defaultTourWorkspace, readyToShowAlert])

    useEffect(() => {
        const redirect = localStorage.getItem('redirectUrl')
        if (redirect) {
            navigate(redirect)
            localStorage.removeItem('redirectUrl')
        }
    }, [])

    // If not detault workspace has been selected (i.e. new user)
    useEffect(() => {
        if (workspacesAccessible.length > 0 && !getWorkspaceID()) {
            const tour = workspacesAccessible.find((w) => w.workspaceID === defaultTourWorkspace)
            const tourWorkspace = workspacesAccessible.find(
                (workspace) => workspace.name === DEFAULT_WORKSPACE_NAME
            )
            if (tour) dispatch(switchWorkspace(tour.workspaceID, tour.name))
            else dispatch(switchWorkspace(tourWorkspace?.workspaceID, DEFAULT_WORKSPACE_NAME))
            dispatch(setResetTrigger())
            navigate(ROUTES.CANVAS_DASHBOARD)
        }
    }, [workspacesAccessible])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        dispatch(toggleSwitchWorkspaceModal(false))
    }

    const handleProfileItemClick = () => {
        setAnchorEl(null)
        setShowUserProfile(true)
    }

    const handleLogout = () => {
        signOut().then(() => {
            dispatch(logout())
        })
    }

    const menuId = 'primary-search-account-menu'

    const RenderTooltip = ({ children }) => {
        return <Tooltip title={'Switch Workspace'}>{children}</Tooltip>
    }

    const WorkspaceExplainer = () => {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    display: 'inline-block',
                    top: '64px',
                    left: '20px',
                    width: '377px',
                    zIndex: 1000,
                    boxShadow: '0px 0px 20px 0px #14092A40',
                    borderRadius: '5px',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ backgroundColor: '#422e47', display: 'flex', justifyContent: 'center' }}>
                    <img
                        style={{
                            height: '165px',
                            width: '190px',
                            position: 'relative',
                            objectFit: 'contain',
                            bottom: '-21px',
                        }}
                        alt={'image'}
                        src={Welcome}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                        padding: '24px',
                        backgroundColor: 'white',
                        borderRadius: '0 0 5px 5px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Typography
                            sx={{
                                color: '#201024',
                                fontWeight: 600,
                                fontSize: '24px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Your Demo Workspace
                        </Typography>
                        <Typography
                            sx={{
                                color: '#635566',
                                fontWeight: 400,
                                fontSize: '14px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Here you can explore Scoop with sample data and see example canvases.
                            Click the Workspace switcher to get back to your Workspace and use your
                            own data.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Button
                            fullWidth
                            className={'button button-purple small'}
                            onClick={() => setAlertOpen(false)}
                        >
                            Got it
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <UserProfileModal
                open={showUserProfile}
                handleClose={() => setShowUserProfile(false)}
            />
            {!isGuestMode && (
                <AppBar>
                    <Toolbar className={'navbar'}>
                        <Box className={'scoop-logo-container'}>
                            <img
                                src={ScoopLogo}
                                alt="Scoop Logo"
                                onClick={() => navigate(ROUTES.CANVAS_DASHBOARD)}
                            />
                        </Box>
                        <Box className={'navbar-items-container'}>
                            <Box
                                onClick={(e) => setSwitchAnchorEl(e.currentTarget)}
                                className={'navbar-item'}
                                sx={{ padding: '0 16px' }}
                            >
                                <RenderTooltip>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Typography className={'inter'}>{workspaceName}</Typography>
                                        <img src={CaretUpDown} alt="Switch Workspace" />
                                    </Box>
                                </RenderTooltip>
                            </Box>
                            {navbarItems.map((item, index) => (
                                <NavbarItem
                                    key={index}
                                    icon={item.icon}
                                    label={item.label}
                                    route={item.route}
                                    selected={location.pathname.includes(item.route)}
                                    onClick={() => navigate(item.route)}
                                />
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                    gap: '14px',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            <a
                                href="https://www.scoopanalytics.com/help"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Box aria-label="show new notifications" className={'navbar-item'}>
                                    <HelpOutlineIcon sx={{ color: 'white', fontWeight: 400 }} />
                                </Box>
                            </a>
                            <Box
                                className={'navbar-item'}
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                sx={{ color: 'white' }}
                            >
                                <img src={UserCircle} alt={'User Circle'} />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{ top: 30 }}
            >
                <MenuItem onClick={handleProfileItemClick}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
            <SwitchWorkspacePopover
                open={Boolean(switchAnchorEl)}
                anchorEl={switchAnchorEl}
                onClose={() => setSwitchAnchorEl(null)}
                openCreateWorkspaceModal={() => dispatch(toggleSwitchWorkspaceModal(true))}
                openRenameWorkspaceModal={() => setOpenWorkspaceActionsModal(true)}
                openDeleteWorkspaceModal={() => setOpenWorkspaceActionsModal(true)}
            />
            {alertOpen && <WorkspaceExplainer />}
        </>
    )
}
export default NavBar
