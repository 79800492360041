import { mobileAPIRequest } from '../../api'

export const getReportSeriesMetadata = async (
    reportSeriesTableID,
    addCount = false,
    expandDateKeys = false
) => {
    const action = {
        action: 'getReportSeriesMetadata',
        addCount,
        reportSeriesTableID,
        expandDateKeys,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const getTablePreviewData = async (reportSeriesTableID, latestLoad) => {
    const action = {
        action: 'reportSeriesTablePreviewData',
        inputQuery: {
            tableID: reportSeriesTableID,
            useLatestLoad: latestLoad,
        },
    }
    const previewData = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    return previewData
}

export const getIngestionMetadata = async (inboxID) => {
    const action = {
        action: 'getIngestionMetadata',
        inboxID,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const getEventLogItems = async (inboxID) => {
    const action = {
        action: 'getEventLogItems',
        inboxID,
    }
    const result = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    return result?.eventLogItems?.filter((log) => log.inboxID === inboxID) || []
}

export const deleteEventLog = async (inboxID, deleteLog) => {
    const action = {
        action: 'deleteIngestion',
        inboxID,
        ...(deleteLog.rstiKey ? { rstiKey: deleteLog.rstiKey } : { s3Key: deleteLog.s3key }),
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const getProcessStatus = async () => {
    const action = {
        action: 'getProcessStatus',
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const deleteTable = async (reportSeriesTableID) => {
    if (!reportSeriesTableID) return
    const action = {
        action: 'delete',
        reportSeriesTableID,
        deleteAll: true,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const getTableData = async (reportSeriesTableID) => {
    const action = {
        action: 'getObject',
        key: reportSeriesTableID,
        class: 'scoop.reportseriestable.ReportSeriesTable',
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const renameTable = async (reportSeriesTableID, newName, ignoreTable) => {
    const tableMetadata = await getTableData(reportSeriesTableID)
    if (!tableMetadata) return
    tableMetadata.metadata.tableName = newName
    tableMetadata.metadata.ignoreTable = ignoreTable
    const action = {
        action: 'putObject',
        class: 'scoop.reportseriestable.ReportSeriesTable',
        key: reportSeriesTableID,
        value: tableMetadata,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
