// Gmail.jsx

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../../../../api/api'
import { ROUTES } from '../../../../router/routes'

import Dialog from '../../../common/Dialog/Dialog'
import Button from '../../../common/Button/Button'
import { DialogTitle, Step, StepContent, StepLabel, Stepper } from '@mui/material'
import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Stack,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    Box,
    Typography,
    Switch,
} from '@mui/material'

import DialogContent from '@mui/material/DialogContent'
import Copy from '../../../../assets/icons/Copy.svg'

import EmailPreviewModal from './PreviewEmail'

import Email from '../../../../assets/icons/Email.svg'
import SalesforceIcon from '../../../../assets/icons/Salesforce.svg'
import Scoop from '../../../../assets/images/scoop-logo.png'
import Avatar from '../../../../assets/icons/Avatar.svg'
import Gmail from '../../../../assets/icons/Gmail.svg'
import Outlook from '../../../../assets/icons/Outlook.svg'
import EnvelopeSimple from '../../../../assets/icons/EnvelopeSimple.svg'
import { replace } from 'lodash'
import datasourceDefs from '../../../../api/datasourceConfig'

import Input from '../../../common/Input/Input'
import Selector from '../../../common/Selector/Selector'
import LinearProgress from '@mui/material/LinearProgress'
import { useSelector } from 'react-redux'

const bot = datasourceDefs.find((b) => b.name === 'Gmail')

// google api, add redirect to this in code based on the current url
const GMAIL_API_URL = bot.GMAIL_API_URL
const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/gmail`

export const Salesforce = ({ onClose, stepBack, type, setAlert, basicConfiguration }) => {
    const navigate = useNavigate()
    const { postData } = useApi(bot.BOT_API_URL)

    const [selectedReport, setSelectedReport] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const [oauthCode, setOauthCode] = useState('')

    const [userName, setUserName] = useState('') //this is the username returned by the first email scan call, since we don't know what gmail user the user selected

    const [fromSender, setFromSender] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [subjectOnly, setSubjecOnly] = useState(true)
    const [emailCount, setEmailCount] = useState(0)
    const [selectedEmail, setSelectedEmail] = useState('')
    const [emailsList, setEmailsList] = useState([])
    const [emailPreviewModalOpen, setEmailPreviewModalOpen] = useState(false)
    const [rawEmail, setRawEmail] = useState('')
    const [step, setStep] = useState(0)
    const [emailGenerated, setEmailGenerated] = useState('')
    const [reportName, setReportName] = useState('')
    const [reportDescription, setReportDescription] = useState('')
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const code = queryParams.get('code')

        if (code) {
            const decodedCode = decodeURIComponent(code)
            setOauthCode(decodedCode)
        } else if (queryParams.toString() !== '') {
            console.log('Got back from Google with other parameters:', queryParams.toString())
        }
    }, []) // Empty dependency array ensures this runs once on mount

    useEffect(() => {
        if (step === 1) {
            const emailDomain =
                process.env.REACT_APP_SCOOP_ENV === 'dev'
                    ? '@dev.scoopup.report'
                    : '@scoopup.report'
            setEmailGenerated(`${generateUUID()}${emailDomain}`)
        }
    }, [step])

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0
            const v = c === 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    const generateInboxName = (emailGenerated) => {
        // Remove the exact substring "@scoopup.report" and "@dev.scoopup.report"
        let withoutDomain = emailGenerated.replace(/@(dev\.)?scoopup\.report/g, '')

        // Replace non-alphanumeric characters, excluding dashes, with an underscore
        let result = withoutDomain.replace(/[^a-zA-Z0-9\-]/g, '_')

        return result
    }

    const scanEmails = async () => {
        const action = {
            action: 'scanEmails',
            emailSubject: emailSubject,
            emailFrom: fromSender,
            max_days_ago: '',
            userName: userName,
            subjectOnly: subjectOnly,
            redirect_uri: REDIRECT_URI,
            oauthCode: oauthCode,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        if (result?.success)
            setAlert(
                'We’re ready to receive your emailed report! We’ve copied the forward email address to your clipboard'
            )
        return result
    }

    const handleScanEmails = async () => {
        setIsLoading(true)
        try {
            const data = await scanEmails()
            if (data.status === 'success') {
                setEmailsList(data?.emails)
                setUserName(data?.userName)
            } else {
                console.log('error reading emails!')
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const previewEmail = async (emailID) => {
        const action = {
            action: 'previewEmail',
            userName: userName,
            emailID: emailID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        return result
    }

    const handleEmailClick = async (emailID) => {
        setIsLoading(true)
        try {
            const data = await previewEmail(emailID)
            setRawEmail(data?.email)
            setEmailPreviewModalOpen(true)
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const commenceGMailFlow = () => {
        window.location.href = replace(GMAIL_API_URL, 'REDIRECT_URI', REDIRECT_URI)
    }

    const handleSubjectToggle = (event) => {
        setSubjecOnly(event.target.checked)
    }

    const handleSetUpInbox = async () => {
        const action = {
            action: 'setupInboxForBot',
            inboxName: generateInboxName(emailGenerated),
            keepOnlyCurrent: basicConfiguration.isMostRecent,
            incremental: basicConfiguration.isIncremental,
            enableMultipleSameDayLoads: basicConfiguration.isMultipleLoads,
            workspaceID: workspaceID,
            service: bot.service,
            label: reportName,
            description: reportDescription,
            userName: userID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        setAlert({
            message: 'Thank you. Keep an eye on your email for completion steps.',
            severity: 'success',
        })
        onClose()
    }

    const previewHtml = '<div><strong>Preview:</strong> This is formatted HTML content.</div>'

    const actions =
        step === 0 ? (
            <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
                <Button onClick={stepBack} text={'Back'} className={'button-grey small'}></Button>
                {oauthCode && (
                    <Button
                        onClick={handleScanEmails}
                        className={'button-purple'}
                        text={'Apply'}
                    ></Button>
                )}
            </Box>
        ) : (
            <>
                <Button className={'button-grey small'} onClick={() => setStep(0)}>
                    Back
                </Button>
                <Button
                    disabled={!reportName}
                    className={'button-purple small'}
                    onClick={() => {
                        handleSetUpInbox()
                    }}
                >
                    {' '}
                    I've done this{' '}
                </Button>
            </>
        )

    return (
        <>
            <EmailPreviewModal
                rawEmail={rawEmail}
                open={emailPreviewModalOpen}
                onClose={() => setEmailPreviewModalOpen(false)}
            />
            <Dialog
                open={true}
                title={'Dataset from Salesforce'}
                actions={actions}
                maxWidth={'800px'}
                onClose={onClose}
            >
                {step === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            m: 0,
                            mt: 2,
                            alignItems: 'center',
                            width: '500px',
                        }}
                    >
                        <DialogContent style={{ width: '800px', textAlign: 'center' }}>
                            {!oauthCode && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '14px',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: '#635566',
                                            textAlign: 'start',
                                        }}
                                    >
                                        Great, now it’s time to integrate with your email to allow
                                        Scoop to automatically retrieve future Salesforce reports.
                                    </div>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '48px 0',
                                            background: '#FEF4F7',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                borderRadius: '5px',
                                                border: '1px solid  #F2F2F2',
                                                boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                                                padding: '8px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: '#FFFFFF',
                                            }}
                                        >
                                            <img src={SalesforceIcon} alt={'salesforce'} />
                                        </Box>
                                        <Box
                                            sx={{
                                                borderTop: '1px dashed #979099',
                                                width: '30px',
                                            }}
                                        />
                                        <img src={Email} alt={'email'} />
                                        <Box
                                            sx={{
                                                borderTop: '1px dashed #979099',
                                                width: '30px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                borderRadius: '5px',
                                                border: '1px solid  #F2F2F2',
                                                boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                                                padding: '8px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                background: '#FFFFFF',
                                            }}
                                        >
                                            <img src={Avatar} alt={'salesforce'} />
                                        </Box>
                                        <Box
                                            sx={{
                                                borderTop: '1px dashed #979099',
                                                width: '30px',
                                            }}
                                        />
                                        <img src={Email} alt={'email'} />
                                        <Box
                                            sx={{
                                                borderTop: '1px dashed #979099',
                                                width: '30px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                borderRadius: '5px',
                                                border: '1px solid  #F2F2F2',
                                                boxShadow: '0px 0px 6px 0px rgba(20, 9, 42, 0.05)',
                                                padding: '8px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                minHeight: '42px',
                                                minWidth: '42px',
                                                background: '#FFFFFF',
                                            }}
                                        >
                                            <img
                                                src={Scoop}
                                                alt={'salesforce'}
                                                style={{
                                                    height: '14px',
                                                    width: 'auto',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        gap={'8px'}
                                    >
                                        <Button
                                            sx={{ width: '100%', flex: 1 }}
                                            className={'button-grey'}
                                            onClick={() => setStep(1)}
                                        >
                                            <img
                                                src={Gmail}
                                                alt="Integrate Salesforce"
                                                style={{
                                                    cursor: 'pointer',
                                                    maxWidth: '100%',
                                                    alignContent: 'center',
                                                }}
                                            />
                                            {'Gmail'}
                                        </Button>
                                        <Button
                                            sx={{ width: '100%', flex: 1 }}
                                            className={'button-grey'}
                                            onClick={() => setStep(1)}
                                            disabled
                                        >
                                            <img
                                                src={Outlook}
                                                alt="Integrate Salesforce"
                                                style={{
                                                    cursor: 'pointer',
                                                    maxWidth: '100%',
                                                    alignContent: 'center',
                                                }}
                                            />
                                            {'Outlook'}
                                        </Button>
                                        <Button
                                            sx={{ width: '100%', flex: 1 }}
                                            className={'button-grey'}
                                            onClick={() => setStep(1)}
                                            disabled
                                        >
                                            <img
                                                src={EnvelopeSimple}
                                                alt="Integrate Salesforce"
                                                style={{
                                                    cursor: 'pointer',
                                                    maxWidth: '100%',
                                                    alignContent: 'center',
                                                }}
                                            />
                                            {'Other'}
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </DialogContent>
                    </Box>
                )}
                {step === 1 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px',
                        }}
                    >
                        <Typography sx={{ fontSize: '14px', color: '#635566' }}>
                            Forward your Salesforce data reports to this unique Scoop inbox
                            <br />
                            Follow this step by step guide or{' '}
                            <a href="javascript:void(0);" style={{ color: '#BF0A46' }}>
                                watch the video
                            </a>
                        </Typography>

                        <Stepper orientation="vertical">
                            <Step active={true}>
                                <StepLabel
                                    StepIconProps={{
                                        style: {
                                            color: '#F2F2F2',
                                        },
                                    }}
                                >
                                    Login into the email account you receive your Salesforce account
                                </StepLabel>
                            </Step>
                            <Step active={true}>
                                <StepLabel
                                    StepIconProps={{
                                        style: {
                                            color: '#F2F2F2',
                                        },
                                    }}
                                >
                                    Forward the email to the following inbox (copy this email
                                    address)
                                </StepLabel>
                                <StepContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            padding: ' 12px 16px',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderRadius: '5px',
                                            background: '#F9F9F9',
                                            fontSize: '14px',
                                        }}
                                        id="email-subject-textfield"
                                    >
                                        {emailGenerated}
                                        <InputAdornment
                                            position="end"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                navigator.clipboard.writeText(emailGenerated)
                                            }
                                        >
                                            <img
                                                src={Copy}
                                                alt={'copy'}
                                                style={{ width: '16px' }}
                                            />
                                        </InputAdornment>
                                    </Box>
                                </StepContent>
                            </Step>
                            <Step active={true}>
                                <StepLabel
                                    StepIconProps={{
                                        style: {
                                            color: '#F2F2F2',
                                        },
                                    }}
                                >
                                    <Input
                                        sx={{ width: '100%' }}
                                        label={'Report description'}
                                        onChange={(event) => {
                                            setReportName(event.target.value)
                                            setReportDescription(event.target.value) //name and description are same since label will be used for report name and description will be used for what the user entered
                                        }}
                                        value={reportName}
                                    />
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                )}
            </Dialog>
        </>
    )
}
