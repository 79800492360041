import { useMutation, useQuery } from '@tanstack/react-query'
import {
    createCanvasRequest,
    deleteCanvasRequest,
    getCanvases,
    renameCanvasRequest,
} from './canvasApi'
import { getWorkspaceID } from '../../../utils'
import { logEvent } from '../logs/api'

export const useCanvases = () => {
    const workspaceID = getWorkspaceID()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['canvases', workspaceID],
        queryFn: () => getCanvases(),
        enabled: !!workspaceID,
    })

    const { mutate: createCanvas, isPending: isCreating } = useMutation({
        mutationFn: ({ canvasName, onSuccess }) => createCanvasRequest({ canvasName }),
        onSuccess: (response, variables) => {
            refetch().then(() => {
                if (variables?.onSuccess) variables?.onSuccess(response.canvasID)
            })
            logEvent('createCanvas', {
                canvasName: variables.canvasName,
                canvasID: response.canvasID,
            })
        },
    })

    const { mutate: deleteCanvas, isPending: isDeleting } = useMutation({
        mutationFn: ({ canvasID }) => deleteCanvasRequest({ canvasID }),
        onSuccess: (response, variables) => {
            refetch().then(() => {
                if (variables?.onSuccess) variables?.onSuccess()
            })
            logEvent('deleteCanvas', { canvasID: variables.canvasID })
        },
    })

    const { mutate: renameCanvas, isPending: isRenaming } = useMutation({
        mutationFn: ({ canvasID, canvasName }) => renameCanvasRequest({ canvasID, canvasName }),
        onSuccess: (response, variables) => {
            refetch().then(() => {
                if (variables?.onSuccess) variables?.onSuccess()
            })
            logEvent('renameCanvas', {
                canvasID: variables.canvasID,
                canvasName: variables.canvasName,
            })
        },
    })

    return {
        canvases: data || [],
        isLoading,
        refetch,
        createCanvas,
        isCreating,
        deleteCanvas,
        isDeleting,
        renameCanvas,
        isRenaming,
    }
}
