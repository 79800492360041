// App.jsx is the main entry point of the application. It is responsible for
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { router } from './router/router'
import { validateToken } from './api/tokenValidation'
import { Auth } from 'aws-amplify'

const App = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const inviteCode = queryParams.get('invite')
        const workspaceID = queryParams.get('workspaceID')
        if (inviteCode && workspaceID) {
            dispatch({
                type: 'SET_GUEST_MODE',
                isGuestMode: true,
                passcode: inviteCode,
            })
            dispatch({
                type: 'SWITCH_WORKSPACE',
                workspaceID: workspaceID,
                workspaceName: 'Guest',
            })
            dispatch({
                type: 'SET_AUTHENTICATED',
                isAuthenticated: true,
                user: {
                    signInUserSession: {
                        idToken: { jwtToken: inviteCode },
                        refreshToken: { token: inviteCode }, // Empty refreshToken token
                    },
                    username: 'Guest',
                    userFirstName: 'Guest',
                    attributes: {
                        email: null,
                    },
                },
            })
            return // This stops the execution of the rest of the useEffect block
        }
        Auth.currentAuthenticatedUser()
            .then((user) => {
                // Dispatch the authenticated user's information to the Redux store
                dispatch({
                    type: 'SET_AUTHENTICATED',
                    isAuthenticated: true,
                    user: {
                        signInUserSession: {
                            idToken: {
                                jwtToken: user.signInUserSession.idToken.jwtToken,
                            },
                            refreshToken: {
                                token: user.signInUserSession.refreshToken.token,
                            },
                        },
                        username: user.username,
                        userFirstName: user.attributes.given_name, // Assuming the given name attribute is set
                        attributes: {
                            email: user.attributes.email,
                        },
                    },
                })

                // record user login in db
                const body = {
                    action: 'login',
                    userID: user.username,
                    isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                }
                fetch(
                    'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/ui_information',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
                        },
                        body: JSON.stringify(body),
                    }
                )
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json() // Or handle the response in the way you need if you care about it
                    })
                    .catch((error) => {
                        console.error('Error recording login', error)
                    })
            })
            .catch((err) => {
                const storedToken = localStorage.getItem('userToken')
                if (storedToken) {
                    const isTokenValid = validateToken(storedToken)
                    if (isTokenValid) {
                        dispatch({
                            type: 'SET_AUTHENTICATED',
                            isAuthenticated: true,
                            user: {
                                signInUserSession: {
                                    idToken: { jwtToken: storedToken },
                                    refreshToken: {
                                        token: localStorage.getItem('refreshToken'),
                                    },
                                },
                                username: localStorage.getItem('userID'),
                                userFirstName: localStorage.getItem('userFirstName'),
                                attributes: {
                                    email: localStorage.getItem('userName'),
                                },
                            },
                        })

                        // record user login in db
                        const body = {
                            action: 'login',
                            userID: localStorage.getItem('userID'),
                            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                        }
                        fetch(
                            'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/ui_information',
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${storedToken}`,
                                },
                                body: JSON.stringify(body),
                            }
                        )
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok')
                                }
                                return response.json() // Or handle the response in the way you need if you care about it
                            })
                            .catch((error) => {
                                console.error('Error recording login', error)
                            })
                    } else {
                        dispatch({
                            type: 'LOGOUT',
                        })
                    }
                }
            })
    }, [dispatch])

    return <RouterProvider router={router} />
}

export default App
