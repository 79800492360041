import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../../api/api'
import { Server } from '../../../../api/Server'
import Dialog from '../../../common/Dialog/Dialog'
import Selector from '../../../common/Selector/Selector'
import Button from '../../../common/Button/Button'
import {
    Box,
    Modal,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Input,
} from '@mui/material'

const DeleteDiagram = ({ isOpen, closeModal, deleteDiagramName, doDeleteDiagram }) => {
    const handleDeleteDiagram = () => {
        doDeleteDiagram(deleteDiagramName)
        closeModal()
    }

    // Actions for the modal
    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={closeModal} className="primary-button" text="Cancel" />
            <Button
                onClick={handleDeleteDiagram}
                className="primary-button button-purple"
                text="Delete Diagram"
            />
        </Box>
    )

    return (
        <Dialog
            onClose={closeModal}
            open={isOpen}
            title="Delete this Diagram"
            actions={actions}
            fullWidth
        >
            <Typography variant="h6" gutterBottom>
                Are you sure you want to delete this diagram?
            </Typography>

            <Typography variant="caption" gutterBottom>
                You are not deleting the data behind the diagram, so you will be able to recreate
                this diagram manually at any time.
            </Typography>
        </Dialog>
    )
}

export default DeleteDiagram
