import { useState } from 'react'
import { Box, Chip } from '@mui/material'
import Button from '../../common/Button/Button'
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg'
import ScoopLogo from '../../../assets/logo/NewLogo.svg'
import Typography from '@mui/material/Typography'
import { ChooseRecipe } from './RecipeFlow/ChooseRecipe'
import { SelectSource } from './RecipeFlow/SelectSource'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import Stepper from '../../common/Stepper/Stepper'
import { ConnectSource } from './RecipeFlow/ConnectSource'
import { MatchFields } from './RecipeFlow/MatchFields'

export const Recipe = () => {
    const [step, setStep] = useState(0)
    const [selectedRecipe, setSelectedRecipe] = useState(null)
    const [selectedSource, setSelectedSource] = useState(null)
    const navigate = useNavigate()

    const handleGoBack = () => {
        if (step === 0) navigate(ROUTES.LOGIN)
        setStep(step - 1)
    }

    const handleSelectRecipe = (recipe) => {
        setSelectedRecipe(recipe)
        setStep(1)
    }

    const renderSteps = () => {
        switch (step) {
            case 0:
                return <ChooseRecipe selectRecipe={handleSelectRecipe} />
            case 1:
                return (
                    <>
                        {renderStepper()}
                        <SelectSource
                            source={selectedSource}
                            goNext={() => setStep(2)}
                            selectSource={(source) => setSelectedSource(source)}
                        />
                    </>
                )
            case 2:
                return (
                    <>
                        {renderStepper()}
                        <ConnectSource goForward={() => setStep(3)} source={selectedSource} />
                    </>
                )
            case 3:
                return (
                    <>
                        {renderStepper()}
                        <MatchFields source={selectedSource} goBack={handleGoBack} />
                    </>
                )
            default:
                return <></>
        }
    }

    const renderStepper = () => {
        const steps = ['Prepare Data', 'Scoop Data', 'Match Fields', 'Complete']

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '48px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Chip sx={{ padding: '2px 8px !important' }} label={'New Workspace'} />
                        <Typography
                            sx={{
                                color: '#201024',
                                fontFamily: 'Inter',
                                fontSize: '28px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '110%',
                                letterSpacing: '-0.56px',
                            }}
                        >
                            {selectedRecipe.title + ' Recipe'}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: '40%' }}>
                    <Stepper showChecked steps={steps} activeStep={step - 1} />
                </Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                height: '100vh',
                maxHeight: '100vh',
                width: '100vw',
                maxWidth: '100vw',
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 64px 64px 64px',
            }}
        >
            <RecipeNavbar goBack={handleGoBack} showIcon={step >= 1} />
            <Box sx={{ flex: 1 }}>{renderSteps()}</Box>
        </Box>
    )
}

export const RecipeNavbar = ({ showIcon, goBack }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '32px 0',
                minHeight: '45.5px',
                boxSizing: 'content-box',
            }}
        >
            <Button
                onClick={goBack}
                sx={{
                    padding: '6px 8px !important',
                    display: 'flex',
                    gap: '8px',
                    width: 'auto',
                }}
                className={'button-grey small'}
            >
                <img alt={'go back'} src={ArrowLeft} />
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '115%',
                        letterSpacing: '-0.28px',
                        color: '#2B1630',
                    }}
                >
                    Back
                </Typography>
            </Button>
            {showIcon && (
                <Box>
                    <img alt={'scoop logo'} src={ScoopLogo} />
                </Box>
            )}
        </Box>
    )
}
