import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'
import { AXIS_DEFAULT_VALUES, CHART_PREFERENCES_DEFAULT_VALUES, LINE_STYLES } from '../styleConsts'
import { Slider } from '../../../../../common/Slider/Slider'
import { Switch } from '../../../../../common/Switch/Switch'
import { debounce, cloneDeep } from 'lodash'

export const SplitLineConfig = ({ config, setConfig, chartPreferences }) => {
    const [xLineWidth, setXLineWidth] = useState(AXIS_DEFAULT_VALUES.splitLine.lineStyle.width)
    const [yLineWidth, setYLineWidth] = useState(AXIS_DEFAULT_VALUES.splitLine.lineStyle.width)

    useEffect(() => {
        setXLineWidth(getAxisPropValue('width', 'lineStyle', 'xAxis'))
        setYLineWidth(getAxisPropValue('width', 'lineStyle', 'yAxis'))
    }, [config])

    const debouncedFunc = useCallback(
        debounce((prop, value, axisName) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides[axisName].forEach(
                (axis) => (axis.splitLine.lineStyle[prop] = value)
            )
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const getAxisPropValue = (prop, subObject, axisName) => {
        const overrides = config.styleOverrides[axisName][0]
        const preferences = chartPreferences[axisName]
        switch (subObject) {
            case 'splitLine':
                if (propExistsOnObject(overrides.splitLine, prop)) return overrides.splitLine[prop]
                if (propExistsOnObject(preferences?.splitLine, prop))
                    return preferences.splitLine[prop]
                if (prop === 'show')
                    return CHART_PREFERENCES_DEFAULT_VALUES[axisName].splitLine[prop]
                return AXIS_DEFAULT_VALUES.splitLine[prop]
            case 'lineStyle':
                if (propExistsOnObject(overrides.splitLine?.lineStyle, prop))
                    return overrides.splitLine.lineStyle[prop]
                if (propExistsOnObject(preferences?.splitLine?.lineStyle, prop))
                    return preferences.splitLine.lineStyle[prop]
                return AXIS_DEFAULT_VALUES.splitLine.lineStyle[prop]
        }
    }

    const handleAxisPropValueChange = (prop, value, subObject, axisName) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides[axisName].forEach((axis) => {
            switch (subObject) {
                case 'splitLine':
                    axis.splitLine[prop] = value
                    break
                case 'lineStyle':
                    axis.splitLine.lineStyle[prop] = value
                    break
            }
        })
        setConfig(newConfig)
    }

    const handleDebounceAxisPropValueChange = (prop, value, setter, axisName) => {
        debouncedFunc(prop, value, axisName)
        setter(value)
    }

    // this check for xAxis show is to ensure the correct default value (false)
    /* const showX = (typeof config.styleOverrides.xAxis?.splitLine?.show !== 'undefined' ||
        typeof chartPreferences.xAxis?.splitLine?.show !== 'undefined') ?
        getAxisPropValue('show', 'splitLine', 'xAxis') :
        false
*/
    const showX = getAxisPropValue('show', 'splitLine', 'xAxis')
    const showY = getAxisPropValue('show', 'splitLine', 'yAxis')

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            <Box className={'column'} sx={{ gap: '8px' }}>
                <Typography className={'inter style-config-label'}>X Axis</Typography>
                <Box className={'row'} sx={{ gap: '5px', alignItems: 'center' }}>
                    <Switch
                        checked={showX}
                        onClick={() =>
                            handleAxisPropValueChange('show', !showX, 'splitLine', 'xAxis')
                        }
                    />
                    <Typography className={'inter style-config-label'}>Show</Typography>
                </Box>
                {renderSelector(
                    'Type',
                    getAxisPropValue('type', 'lineStyle', 'xAxis'),
                    (v) => handleAxisPropValueChange('type', v, 'lineStyle', 'xAxis'),
                    LINE_STYLES
                )}
                <Slider
                    label={`Line width: ${xLineWidth}px`}
                    value={xLineWidth}
                    onChange={(v) =>
                        handleDebounceAxisPropValueChange('width', v, setXLineWidth, 'xAxis')
                    }
                    min={0}
                    max={12}
                    step={1}
                />
                <Box sx={{ padding: '8px 4px' }}>
                    {renderColorPicker(
                        'Line color',
                        getAxisPropValue('color', 'lineStyle', 'xAxis'),
                        (v) => handleAxisPropValueChange('color', v, 'lineStyle', 'xAxis')
                    )}
                </Box>
            </Box>
            <Box
                className={'column'}
                sx={{
                    borderTop: '1px solid #E6E4E6',
                    gap: '8px',
                    padding: '16px 0 0 0',
                }}
            >
                <Typography className={'inter style-config-label'}>Y Axis</Typography>
                <Box className={'row'} sx={{ gap: '5px', alignItems: 'center' }}>
                    <Switch
                        checked={showY}
                        onClick={() =>
                            handleAxisPropValueChange('show', !showY, 'splitLine', 'yAxis')
                        }
                    />
                    <Typography className={'inter style-config-label'}>Show</Typography>
                </Box>
                {renderSelector(
                    'Type',
                    getAxisPropValue('type', 'lineStyle', 'yAxis'),
                    (v) => handleAxisPropValueChange('type', v, 'lineStyle', 'yAxis'),
                    LINE_STYLES
                )}
                <Slider
                    label={`Line width: ${yLineWidth}px`}
                    value={yLineWidth}
                    onChange={(v) =>
                        handleDebounceAxisPropValueChange('width', v, setYLineWidth, 'yAxis')
                    }
                    min={0}
                    max={12}
                    step={1}
                />
                <Box sx={{ padding: '8px 4px' }}>
                    {renderColorPicker(
                        'Line color',
                        getAxisPropValue('color', 'lineStyle', 'yAxis'),
                        (v) => handleAxisPropValueChange('color', v, 'lineStyle', 'yAxis')
                    )}
                </Box>
            </Box>
        </Box>
    )
}
