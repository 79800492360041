import React, { useEffect, useState } from 'react'
import {
    Box,
    Chip,
    Drawer,
    FormControl,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material'
import {
    setLastEditedPrompt,
    setPromptSelecting,
    setPromptSelectingObjects,
} from '../../../store/actions/promptActions'
import CloseIcon from '../../../assets/icons/CloseIcon.svg'
import Selector from '../../common/Selector/Selector'
import Check from '../../../assets/icons/Check.svg'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../common/Button/Button'
import { useApi } from '../../../api/api'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import { updatePromptProps } from '../../../store/actions'
import { ScoopDatePicker } from '../../common/DatePicker/ScoopDatePicker'
import { NumericRange } from '../../common/NumericRange/NumericRange'
import { Toast } from '../../common/Toast/Toast'
import { Checkbox } from '@mui/joy'

export const PromptDrawer = ({ workspaceMetadata, currentPromptObject, workspaceID }) => {
    const dispatch = useDispatch()
    const { postData } = useApi()
    const userID = useSelector((state) => state.auth.userID)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const promptSelectingObjects = useSelector((state) => state.prompt.promptSelectingObjects)
    const [dataSources, setDataSources] = useState([])
    const [dataSource, setDataSource] = useState(null)
    const [field, setField] = useState(null)
    const [promptType, setPromptType] = useState('')
    const [label, setLabel] = useState('')
    const [categoryValues, setCategoryValues] = useState([])
    const [categoryValuesLoading, setCategoryValuesLoading] = useState(false)
    const [sheetLoading, setSheetLoading] = useState(false)
    const [singleSelectValue, setSingleSelectValue] = useState('')
    const [multipleSelectValue, setMultipleSelectValue] = useState([])
    const [dateValue, setDateValue] = useState(null)
    const [numericRange, setNumericRange] = useState(null)
    const [singleValue, setSingleValue] = useState(0)
    const [alert, setAlert] = useState(false)
    const [theme, setTheme] = useState('')
    const [dataSourceType, setDataSourceType] = useState('dataset')
    const [availableSheets, setAvailableSheets] = React.useState([])
    const [worksheet, setWorksheet] = React.useState(null)
    const [worksheetNamedRanges, setWorksheetNamedRanges] = React.useState([])
    const [selectedWorksheetRange, setSelectedWorksheetRange] = React.useState(null)
    const [worksheetColumns, setWorksheetColumns] = React.useState([])
    const [refreshInputQueries, setRefreshInputQueries] = React.useState(false)

    const getCategoryValues = (tableID, worksheetID, rangeName, columnName, likeValue) => {
        setCategoryValuesLoading(true)
        const action = {
            action: 'getCategoryValues',
            columnName: columnName,
            like: likeValue,
        }
        if (tableID) {
            action.reportSeriesTableID = tableID
        } else if (worksheetID) {
            action.worksheetID = worksheetID
            action.rangeName = rangeName
        }
        postData(action)
            .then((r) => {
                setCategoryValuesLoading(false)
                setCategoryValues(r.values)
            })
            .catch(() => {
                setCategoryValuesLoading(false)
                setCategoryValues([])
                setAlert(true)
            })
    }

    const getColumns = (worksheetID, namedRange) => {
        setCategoryValuesLoading(true)
        const action = {
            action: 'getColumns',
            worksheetID: worksheetID,
            namedRange: namedRange,
        }
        postData(action)
            .then((r) => {
                setWorksheetColumns(r.columns)
                if (currentPromptObject.promptProps.worksheetId) {
                    r?.columns.forEach((col) => {
                        if (col.columnName === currentPromptObject.promptProps.fieldName) {
                            setField(col)
                        }
                    })
                }
                setCategoryValuesLoading(false)
            })
            .catch(() => {
                setWorksheetColumns([])
                setAlert(true)
            })
    }

    useEffect(() => {
        if (workspaceID && userID) {
            postData({
                action: 'getObjects',
                class: 'scoop.worksheet.Worksheet',
                attributeName: 'workspaceID',
                attributeValue: workspaceID,
            }).then(({ objects: result }) => {
                let availableWorksheets = result.filter(
                    (wi) => wi.sheetType === 'DynamicSheet' || wi.sheetType === 'CalculatedSource'
                )
                availableWorksheets = availableWorksheets.sort((a, b) => {
                    if (a.label === null) return 1 // Push nulls to the end
                    if (b.label === null) return -1 // Keep non-nulls at the beginning
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                })
                setAvailableSheets(availableWorksheets)
            })
        }
    }, [workspaceID, currentPromptObject, userID])

    useEffect(() => {
        if (currentPromptObject?.promptProps?.worksheetId && availableSheets.length > 0) {
            availableSheets.forEach((sheet) => {
                if (sheet.worksheetID === currentPromptObject.promptProps.worksheetId) {
                    setWorksheet(sheet)
                }
            })
        }
    }, [currentPromptObject, availableSheets])

    useEffect(() => {
        if (currentPromptObject && workspaceMetadata && availableSheets) {
            const props = currentPromptObject.promptProps
            setLabel(props.label)
            setPromptType(props.type)
            setTheme(props.theme)
            if (props.worksheetId) {
                setDataSourceType('namedRange')
                setSheetRanges(props.worksheetId)
            } else {
                workspaceMetadata?.inboxes?.forEach((inbox) => {
                    inbox.tables.forEach((table) => {
                        if (table.reportSeriesTableID === props.dataSourceId) {
                            setDataSource(table)
                            table.columns.forEach((col) => {
                                if (col.columnName === props.fieldName) {
                                    setField(col)
                                }
                            })
                        }
                    })
                })
            }
            switch (props.type) {
                case 'single-select':
                    setSingleSelectValue(props.value || '')
                    break
                case 'multi-select':
                    setMultipleSelectValue(props.value || [])
                    break
                case 'single-date':
                    setDateValue(props.value ? new Date(props.value) : null)
                    break
                case 'date-range':
                    setDateValue(
                        props.value ? [new Date(props.value[0]), new Date(props.value[1])] : null
                    )
                    break
                case 'numeric-range':
                    setNumericRange(props.value || null)
                    break
                case 'single-value':
                    setSingleValue(props.value || 0)
                    break
            }
            setRefreshInputQueries(props.refreshInputQueries)
        }
    }, [currentPromptObject, availableSheets])

    useEffect(() => {
        if (workspaceMetadata) {
            let tables = []
            workspaceMetadata?.inboxes?.forEach((inbox) => {
                inbox.tables.forEach((table) => {
                    tables.push(table)
                })
            })
            tables = tables.map((table) => ({
                ...table,
                tableName: table.tableName || 'Unnamed',
            }))
            tables.sort((a, b) => a.tableName.localeCompare(b.tableName))
            setDataSources(tables)
        }
    }, [workspaceMetadata])

    useEffect(() => {
        if (field) {
            if (dataSource) {
                getCategoryValues(dataSource.reportSeriesTableID, null, null, field.columnName)
            } else if (worksheet) {
                getCategoryValues(
                    null,
                    worksheet.worksheetID,
                    selectedWorksheetRange,
                    field.columnName
                )
            }
        }
    }, [field])

    const handleClose = () => {
        setField(null)
        setDataSource(null)
        setLabel('')
        setPromptType('')
        setCategoryValues([])
        setDataSourceType('dataset')
        setWorksheet(null)
        setSingleSelectValue('')
        setMultipleSelectValue([])
        setDateValue(null)
        setNumericRange(null)
        setSingleValue(0)
        setTheme('')
        setWorksheetNamedRanges([])
        setSelectedWorksheetRange(null)
        setSheetLoading(false)
        setCategoryValuesLoading(false)
        setWorksheetColumns([])
        dispatch(setPromptSelecting(false, null))
        dispatch(setPromptSelectingObjects([]))
    }

    const handleSave = () => {
        let value
        let prompt = {
            attributeName: field?.columnName,
            operator: 'Equals',
            filterValue: { values: null },
        }
        switch (promptType) {
            case 'single-select':
                value = singleSelectValue
                prompt.filterValue.values = [singleSelectValue]
                break
            case 'multi-select':
                value = multipleSelectValue
                prompt.filterValue.values =
                    multipleSelectValue.length === categoryValues.length
                        ? ['All']
                        : multipleSelectValue
                break
            case 'single-date':
                value = dateValue.toString()
                prompt.filterValue.values = [dateValue.toISOString().split('T')[0]]
                break
            case 'date-range':
                prompt = [
                    {
                        attributeName: field?.columnName,
                        operator: 'GreaterThanOrEquals',
                        filterValue: {
                            values: [dateValue[0].toISOString().split('T')[0]],
                        },
                    },
                    {
                        attributeName: field?.columnName,
                        operator: 'LessThanOrEquals',
                        filterValue: {
                            values: [dateValue[1].toISOString().split('T')[0]],
                        },
                    },
                ]
                value = [dateValue[0].toString(), dateValue[1].toString()]
                break
            case 'numeric-range':
                prompt = [
                    {
                        attributeName: field?.columnName,
                        operator: 'GreaterThanOrEquals',
                        filterValue: { values: [numericRange[0]] },
                    },
                    {
                        attributeName: field?.columnName,
                        operator: 'LessThanOrEquals',
                        filterValue: { values: [numericRange[1]] },
                    },
                ]
                value = numericRange
                break
            case 'single-value':
                value = singleValue
                prompt.filterValue.values = [singleValue]
                break
        }
        const promptProps = {
            label: label,
            theme: theme || null,
            dataSourceId: dataSource?.reportSeriesTableID,
            worksheetId: worksheet?.worksheetID,
            rangeName: selectedWorksheetRange,
            fieldName: field?.columnName,
            type: promptType,
            value: value,
            prompt: prompt,
            objects: promptSelectingObjects,
        }
        if (refreshInputQueries) promptProps.refreshInputQueries = true
        dispatch(updatePromptProps(currentPromptObject.id, promptProps))
        dispatch(setLastEditedPrompt(promptProps))
        handleClose()
    }

    const renderMultipleValues = (values) => {
        return values.map((value) => (
            <Chip
                key={value}
                label={value}
                onMouseDown={(e) => {
                    if (['svg', 'path'].includes(e.target.tagName)) e.stopPropagation()
                }}
                onDelete={() => {
                    let newValues = [...multipleSelectValue]
                    newValues.splice(newValues[newValues.indexOf(value)], 1)
                    setMultipleSelectValue(newValues)
                }}
                sx={{ maxWidth: 150 }}
            />
        ))
    }

    const handleMultipleSelect = (e) => {
        if (e.target.value.includes('Select all')) {
            setMultipleSelectValue([...categoryValues])
        } else if (e.target.value.includes('Clear all')) {
            setMultipleSelectValue([])
        } else {
            setMultipleSelectValue(e.target.value)
        }
    }

    const handleChangeTheme = (themeID) => {
        if (themeID === 'None') setTheme(undefined)
        else setTheme(themeID)
    }

    const setSheetRanges = (worksheetID) => {
        setSheetLoading(true)
        postData({
            action: 'getSheetMetadata',
            worksheetID: worksheetID,
        })
            .then((result) => {
                if (result.namedRanges) {
                    setWorksheetNamedRanges(result.namedRanges)
                    if (currentPromptObject.promptProps.rangeName) {
                        getColumns(
                            currentPromptObject.promptProps.worksheetId,
                            currentPromptObject.promptProps.rangeName
                        )
                        setSelectedWorksheetRange(currentPromptObject.promptProps.rangeName)
                    }
                    setSheetLoading(false)
                }
            })
            .catch((e) => {
                setSheetLoading(false)
            })
    }

    const handleSelectWorksheet = (worksheetID) => {
        for (let i = 0; i < availableSheets.length; i++) {
            if (availableSheets[i].worksheetID === worksheetID) {
                setWorksheet(availableSheets[i])
                setSheetRanges(worksheetID)
                break
            }
        }
    }

    return (
        <Drawer
            anchor={'right'}
            open={promptSelecting}
            variant={'persistent'}
            PaperProps={{ sx: { marginTop: '115px' } }}
        >
            <Box className={'prompt-drawer-content'}>
                <Box className={'prompt-drawer-header'}>
                    <Typography className={'inter'} sx={{ fontSize: '20px', fontWeight: 600 }}>
                        Prompt
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <img src={CloseIcon} alt={'close'} />
                    </IconButton>
                </Box>
                <Box className={'prompts-controls'}>
                    <FormControl
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <label className={'prompt-label-label'}>Label</label>
                        <TextField
                            className={'prompt-label-input'}
                            value={label}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </FormControl>
                    {workspaceMetadata?.themes && (
                        <Selector
                            value={theme}
                            label={'Color Theme'}
                            labelClassName={'selector-label-bold'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                        >
                            <MenuItem value={undefined} onClick={() => handleChangeTheme('None')}>
                                None
                            </MenuItem>
                            {workspaceMetadata.themes?.map((theme) => {
                                return (
                                    <MenuItem
                                        value={theme.themeID}
                                        key={theme.themeID}
                                        onClick={() => handleChangeTheme(theme.themeID)}
                                    >
                                        {theme.themeName
                                            ? theme.themeName + ' (' + theme.canvasName + ')'
                                            : theme.canvasName + ' (Default)'}
                                    </MenuItem>
                                )
                            })}
                        </Selector>
                    )}
                    <Selector
                        value={dataSourceType || ''}
                        label={'Source type'}
                        labelClassName={'selector-label-bold'}
                        sx={{
                            height: 38,
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                        }}
                        MenuProps={{ sx: { maxHeight: 350 } }}
                    >
                        <MenuItem
                            value={'dataset'}
                            key={'dataset'}
                            onClick={() => {
                                setDataSourceType('dataset')
                                setWorksheet(null)
                                setSelectedWorksheetRange(null)
                            }}
                        >
                            <Typography
                                className={'inter'}
                                sx={{
                                    fontSize: '14px',
                                    width: 300,
                                    overflow: 'hidden',
                                }}
                            >
                                Dataset
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            value={'namedRange'}
                            key={'namedRange'}
                            onClick={() => {
                                setDataSourceType('namedRange')
                                setDataSource(null)
                            }}
                        >
                            <Typography
                                className={'inter'}
                                sx={{
                                    fontSize: '14px',
                                    width: 300,
                                    overflow: 'hidden',
                                }}
                            >
                                Live Sheet Range
                            </Typography>
                        </MenuItem>
                    </Selector>
                    {dataSourceType === 'dataset' && (
                        <Selector
                            value={dataSource || ''}
                            renderValue={(d) => d.tableName}
                            label={'Source'}
                            labelClassName={'selector-label-bold'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                            MenuProps={{ sx: { maxHeight: 350 } }}
                        >
                            {dataSources.map((table) => (
                                <MenuItem
                                    key={table.tableName}
                                    onClick={() => {
                                        setDataSource(table)
                                        setField(null)
                                        setPromptType('')
                                    }}
                                >
                                    <Typography
                                        className={'inter'}
                                        sx={{
                                            fontSize: '14px',
                                            width: 300,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {table.tableName}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Selector>
                    )}
                    {dataSourceType === 'namedRange' && availableSheets && (
                        <>
                            <FormControl fullWidth size="small">
                                <Selector
                                    value={worksheet?.worksheetID}
                                    labelClassName={'selector-label-bold'}
                                    onChange={(event) => handleSelectWorksheet(event.target.value)}
                                    label={'Live Sheet Name'}
                                    sx={{
                                        height: 38,
                                        fontFamily: 'Inter, sans-serif',
                                        fontSize: '14px',
                                    }}
                                >
                                    {availableSheets.map((worksheet) => (
                                        <MenuItem
                                            key={worksheet.worksheetID}
                                            value={worksheet.worksheetID}
                                        >
                                            <Typography
                                                className={'inter'}
                                                sx={{
                                                    fontSize: '14px',
                                                    width: 300,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {worksheet.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Selector>
                            </FormControl>
                            {sheetLoading ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px',
                                        display: 'grid',
                                        placeContent: 'center',
                                    }}
                                >
                                    <ScoopLoader />
                                </Box>
                            ) : (
                                worksheet && (
                                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                                        <FormControl fullWidth size="small">
                                            <Selector
                                                value={selectedWorksheetRange}
                                                labelClassName={'selector-label-bold'}
                                                onChange={(event) => {
                                                    setSelectedWorksheetRange(event.target.value)
                                                    setField(null)
                                                    getColumns(
                                                        worksheet.worksheetID,
                                                        event.target.value
                                                    )
                                                }}
                                                label="Named Range"
                                                sx={{
                                                    height: 38,
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {worksheetNamedRanges.map((namedRange) => (
                                                    <MenuItem key={namedRange} value={namedRange}>
                                                        <Typography
                                                            className={'inter'}
                                                            sx={{
                                                                fontSize: '14px',
                                                                width: 300,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {namedRange}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Selector>
                                        </FormControl>
                                    </Box>
                                )
                            )}
                        </>
                    )}
                    {dataSource && (
                        <Selector
                            value={field || ''}
                            renderValue={(f) => f.columnName}
                            label={'Source column'}
                            labelClassName={'selector-label-bold'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                            MenuProps={{ sx: { maxHeight: 350 } }}
                        >
                            {dataSource?.columns
                                ?.sort((a, b) => a.columnName.localeCompare(b.columnName))
                                ?.map((col) => (
                                    <MenuItem
                                        key={col.columnName}
                                        onClick={() => {
                                            setField(col)
                                            setPromptType('')
                                        }}
                                    >
                                        <Typography
                                            className={'inter'}
                                            sx={{
                                                fontSize: '14px',
                                                width: 300,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {col.columnName}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Selector>
                    )}
                    {worksheet &&
                        worksheetColumns &&
                        selectedWorksheetRange &&
                        !sheetLoading &&
                        (categoryValuesLoading ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    marginTop: '20px',
                                    display: 'grid',
                                    placeContent: 'center',
                                }}
                            >
                                <ScoopLoader />
                            </Box>
                        ) : (
                            <Selector
                                value={field || ''}
                                renderValue={(f) => f.columnName}
                                label={'Source column'}
                                labelClassName={'selector-label-bold'}
                                sx={{
                                    height: 38,
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                }}
                                MenuProps={{ sx: { maxHeight: 350 } }}
                            >
                                {worksheetColumns
                                    ?.sort((a, b) => a.columnName.localeCompare(b.columnName))
                                    ?.map((col) => (
                                        <MenuItem
                                            key={col.columnName}
                                            onClick={() => {
                                                setField(col)
                                                setPromptType('')
                                            }}
                                        >
                                            <Typography
                                                className={'inter'}
                                                sx={{
                                                    fontSize: '14px',
                                                    width: 300,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {col.columnName}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Selector>
                        ))}
                    {field &&
                        (!categoryValuesLoading ? (
                            <Selector
                                value={promptType || ''}
                                onChange={(e) => {
                                    setPromptType(e.target.value)
                                    setSingleSelectValue('')
                                    setMultipleSelectValue([])
                                    setDateValue(null)
                                    setNumericRange(null)
                                }}
                                label={'Prompt type'}
                                labelClassName={'selector-label-bold'}
                                sx={{ height: 38 }}
                            >
                                {field.columnType === 'String' && [
                                    <MenuItem value={'single-select'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Single select
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem value={'multi-select'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Multi select
                                        </Typography>
                                    </MenuItem>,
                                ]}
                                {field.columnType === 'DateTime' && [
                                    <MenuItem value={'date-range'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Date range
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem value={'single-date'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Single date
                                        </Typography>
                                    </MenuItem>,
                                ]}
                                {(field.columnType === 'Integer' ||
                                    field.columnType === 'Currency' ||
                                    field.columnType === 'Decimal') && [
                                    <MenuItem value={'numeric-range'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Numeric range
                                        </Typography>
                                    </MenuItem>,
                                    <MenuItem value={'single-value'}>
                                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                            Single value
                                        </Typography>
                                    </MenuItem>,
                                ]}
                            </Selector>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    marginTop: '20px',
                                    display: 'grid',
                                    placeContent: 'center',
                                }}
                            >
                                <ScoopLoader />
                            </Box>
                        ))}
                    {!categoryValuesLoading && !sheetLoading && promptType === 'single-select' && (
                        <Selector
                            value={singleSelectValue || ''}
                            label={'Default value'}
                            renderValue={(v) => v}
                            labelClassName={'selector-label-bold'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                            MenuProps={{ sx: { maxHeight: 350 } }}
                        >
                            {categoryValues.map((value) => (
                                <MenuItem key={value} onClick={() => setSingleSelectValue(value)}>
                                    <Typography
                                        className={'inter'}
                                        sx={{
                                            fontSize: '14px',
                                            width: 300,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {value}
                                    </Typography>
                                    {singleSelectValue === value && (
                                        <img src={Check} alt={'check'} />
                                    )}
                                </MenuItem>
                            ))}
                        </Selector>
                    )}
                    {!categoryValuesLoading && !sheetLoading && promptType === 'multi-select' && (
                        <Selector
                            value={multipleSelectValue || ''}
                            onChange={handleMultipleSelect}
                            label={'Default value'}
                            renderValue={renderMultipleValues}
                            labelClassName={'selector-label-bold'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                                '& .MuiSelect-select': {
                                    padding: '2px !important',
                                    gap: '2px',
                                },
                            }}
                            MenuProps={{ sx: { maxHeight: 350 } }}
                            multiple
                        >
                            {[
                                multipleSelectValue.length === categoryValues.length
                                    ? 'Clear all'
                                    : 'Select all',
                                ...categoryValues,
                            ].map((value) => {
                                const selected = multipleSelectValue.includes(value)
                                return (
                                    <MenuItem key={value} value={value}>
                                        <Typography
                                            className={'inter'}
                                            sx={{
                                                fontSize: '14px',
                                                width: 300,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {value}
                                        </Typography>
                                        {selected && <img src={Check} alt={'check'} />}
                                    </MenuItem>
                                )
                            })}
                        </Selector>
                    )}
                    {!categoryValuesLoading &&
                        !sheetLoading &&
                        (promptType === 'date-range' || promptType === 'single-date') && (
                            <ScoopDatePicker
                                value={dateValue}
                                onChange={setDateValue}
                                range={promptType === 'date-range'}
                                containerSx={{ marginTop: '20px' }}
                                label={'Default value'}
                            />
                        )}
                    {!categoryValuesLoading && !sheetLoading && promptType === 'numeric-range' && (
                        <NumericRange
                            label={'Default value'}
                            value={numericRange}
                            onChange={setNumericRange}
                            containerSx={{ marginTop: '20px' }}
                            max={parseInt(categoryValues[categoryValues.length - 1])}
                            min={categoryValues[0] !== null ? parseInt(categoryValues[0]) : 0}
                            popWidth={350}
                        />
                    )}
                    {!categoryValuesLoading && !sheetLoading && promptType === 'single-value' && (
                        <FormControl
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <label className={'prompt-label-label'}>Single value</label>
                            <TextField
                                className={'prompt-label-input'}
                                value={singleValue}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setSingleValue(e.target.value.replace(/\D/g, ''))}
                            />
                        </FormControl>
                    )}
                    {!categoryValuesLoading && !sheetLoading && worksheet && (
                        <FormControl>
                            <Checkbox
                                sx={{ fontSize: '12px' }}
                                label={'Refresh worksheet queries'}
                                checked={refreshInputQueries}
                                onClick={() => setRefreshInputQueries(!refreshInputQueries)}
                            />
                        </FormControl>
                    )}
                </Box>
                <Box className={'prompt-buttons-container'}>
                    <Button className={'prompt-button'} text={'Cancel'} onClick={handleClose} />
                    <Button
                        text={'Save Changes'}
                        className={'button-purple prompt-button'}
                        onClick={handleSave}
                    />
                </Box>
            </Box>
            {alert && (
                <Toast
                    alert={{
                        message: 'Error fetching values, try again later.',
                        severity: 'error',
                    }}
                    onClose={() => setAlert(false)}
                />
            )}
        </Drawer>
    )
}
