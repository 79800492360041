import { Box, DialogContentText, MenuItem, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import * as React from 'react'
import { packFilter, unpackFilters } from './Filter'
import Grid from '@mui/material/Unstable_Grid2'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import Selector from '../common/Selector/Selector'
import Input from '../common/Input/Input'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import { ScoopDatePicker } from '../common/DatePicker/ScoopDatePicker'
import dayjs from 'dayjs'

const DEBOUNCE_DELAY = 600

export function FilterDialog({
    open,
    setOpen,
    workspaceMetadata,
    server,
    selectedFilterColumn,
    setSelectedFilterColumn,
    editState,
    setEditState,
    chartProperties,
    config,
    setConfig,
    analyzeChanges,
    resetFilterState,
    fromMetricDialog,
    columnSource,
}) {
    const [selectedTable, setSelectedTable] = React.useState(null)
    const [operator, setOperator] = React.useState('Equals')
    const [items, setItems] = React.useState([])
    const [selectedItems, setSelectedItems] = React.useState([])
    const [search, setSearch] = React.useState('')
    const [likeValue, setLikeValue] = React.useState('')
    const [categoryValuesLoading, setCategoryValuesLoading] = React.useState(false)
    const [noColumnsAvailable, setNoColumnsAvailable] = useState(null)
    const timerIdRef = useRef(null)
    const [selectedDate, setSelectedDate] = React.useState(undefined)
    const isDate = useRef(
        selectedFilterColumn != null && selectedFilterColumn.columnType === 'DateTime'
    )

    useEffect(() => {
        if (
            selectedFilterColumn &&
            selectedFilterColumn.filterValue &&
            selectedFilterColumn.filterValue.length > 0
        ) {
            const parsedDates = selectedFilterColumn.filterValue.map((date) => {
                const [year, month, day] = date.split('-').map(Number)
                const parsedDate = new Date()
                parsedDate.setFullYear(year, month - 1, day)
                parsedDate.setHours(0, 0, 0, 0)
                return parsedDate
            })
            parsedDates.length > 1 ? setSelectedDate(parsedDates) : setSelectedDate(parsedDates[0])
        }
    }, [open, selectedFilterColumn])

    useEffect(() => {
        if (editState && open) {
            let tableId = ''
            workspaceMetadata?.inboxes?.forEach((inbox) => {
                inbox.tables.forEach((table) => {
                    table.columns.forEach((col) => {
                        if (col.columnName === editState.attributeName)
                            tableId = table.reportSeriesTableID
                    })
                })
            })
            setSelectedItems(editState.filterValue.values)
            setOperator(editState.operator)
            getCategoryValues(tableId, editState.attributeName)
        }
    }, [open])

    function setCategoryValues(categories) {
        if (!categories || categories.length === 0) {
            setItems([])
            setCategoryValuesLoading(false)
            noColumnsAvailable === null && setNoColumnsAvailable(true)
        } else {
            var newItems = []
            for (var i = 0; i < categories.length; i++) {
                if (categories[i] === null) continue
                var row = {
                    id: categories[i],
                    value: categories[i],
                }
                newItems.push(row)
            }
            setItems(newItems)
            setCategoryValuesLoading(false)
            noColumnsAvailable && setNoColumnsAvailable(false)
        }
    }

    function getReportSeriesTable(reportSeriesTableID) {
        for (var i = 0; i < workspaceMetadata.inboxes.length; i++) {
            for (var j = 0; j < workspaceMetadata.inboxes[i].tables.length; j++) {
                if (
                    workspaceMetadata.inboxes[i].tables[j].reportSeriesTableID ===
                    reportSeriesTableID
                ) {
                    return workspaceMetadata.inboxes[i].tables[j]
                }
            }
        }
        return null
    }

    function getCategoryValues(id, columnName, likeValue) {
        setCategoryValuesLoading(true)
        var action = {
            action: 'getCategoryValues',
            columnName: columnName,
            like: likeValue,
        }
        if (config.worksheetID && config.rangeName) {
            action.worksheetID = config.worksheetID
            action.rangeName = config.rangeName
        } else {
            action.reportSeriesTableID = id
        }
        server.postData(action, (r) => setCategoryValues(r.values))
    }

    function handleChangeAttribute(event) {
        let column
        let table
        if (event.currentTarget.textContent) {
            if (config.worksheetID && config.rangeName) {
                column = { columnName: event.currentTarget.textContent }
            } else if (columnSource?.column && columnSource?.table) {
                column = { columnName: event.currentTarget.textContent }
                table = columnSource.table
            } else {
                if (config.selectedItems && config.selectedItems.length > 0) {
                    {
                        for (let i = 0; i < config.selectedItems.length; i++) {
                            var rst = getReportSeriesTable(
                                config.selectedItems[i].reportSeriesTableID
                            )
                            if (rst) {
                                for (var k = 0; k < rst.columns.length; k++) {
                                    if (
                                        rst.columns[k].columnName ===
                                        event.currentTarget.textContent
                                    ) {
                                        table = rst
                                        column = rst.columns[k]
                                        break
                                    }
                                }
                                if (!table && rst.changeColumns) {
                                    for (var k = 0; k < rst.changeColumns.length; k++) {
                                        if (
                                            rst.changeColumns[k].columnName ===
                                            event.currentTarget.textContent
                                        ) {
                                            table = rst
                                            column = rst.changeColumns[k]
                                            break
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var i = 0; i < workspaceMetadata.inboxes.length; i++) {
                        for (var j = 0; j < workspaceMetadata.inboxes[i].tables.length; j++) {
                            for (
                                var k = 0;
                                k < workspaceMetadata.inboxes[i].tables[j].columns.length;
                                k++
                            ) {
                                if (
                                    workspaceMetadata.inboxes[i].tables[j].columns[k].columnName ===
                                    event.currentTarget.textContent
                                ) {
                                    table = workspaceMetadata.inboxes[i].tables[j]
                                    column = workspaceMetadata.inboxes[i].tables[j].columns[k]
                                }
                            }
                        }
                    }
                }
            }
            getCategoryValues(table?.reportSeriesTableID, column?.columnName, null)
        }
        setSelectedFilterColumn(column)
        isDate.current = column?.columnType === 'DateTime'
        setSelectedTable(table)
    }

    useEffect(() => {
        if (!selectedFilterColumn) {
            setOperator('Equals')
            setSelectedItems([])
            setItems([])
            setSearch('')
            setLikeValue('')
        } else {
            setSearch('')
            //handleChangeAttribute({currentTarget: {textContent: selectedFilterColumn.columnName}})
        }
    }, [selectedFilterColumn])

    function handleClose() {
        setOperator('Equals')
        setSelectedItems([])
        setItems([])
        setSearch('')
        setLikeValue('')
        resetFilterState()
        setEditState(null)
        setOpen(false)
        setSelectedDate(null)
    }

    function handleSave(e) {
        e.stopPropagation()
        if (!selectedFilterColumn) {
            setOpen(false)
            return
        }
        let attributeFilter = null
        let index = 0
        let filterList = unpackFilters(
            fromMetricDialog ? config.filter : chartProperties.config.filter
        )
        for (let f of filterList) {
            if (f.attributeName === selectedFilterColumn.columnName && f.operator === operator) {
                attributeFilter = f
                break
            }
            index++
        }
        if (selectedItems.length === 0) {
            if (attributeFilter !== null) {
                filterList.splice(index, 1)
            }
        } else {
            if (attributeFilter == null) {
                if (operator === 'Between') {
                    let newFilter = {
                        attributeName: selectedFilterColumn.columnName,
                        operator: 'GreaterThanOrEquals',
                        filterValue: {
                            values: [selectedItems[0]],
                        },
                    }
                    filterList.push(newFilter)
                    newFilter = {
                        attributeName: selectedFilterColumn.columnName,
                        operator: 'LessThanOrEquals',
                        filterValue: {
                            values: [selectedItems[1]],
                        },
                    }
                    filterList.push(newFilter)
                } else {
                    attributeFilter = {
                        attributeName: selectedFilterColumn.columnName,
                        operator: operator,
                        filterValue: {
                            values: selectedItems,
                        },
                    }
                    filterList.push(attributeFilter)
                }
            } else {
                attributeFilter.operator = operator
                attributeFilter.filterValue = {
                    values: selectedItems,
                }
            }
        }
        let newFilter = packFilter(filterList)
        if (!fromMetricDialog) {
            config.filter = newFilter
            chartProperties.config.filter = newFilter
            setConfig({ ...config })
            chartProperties.getResults(config)
        }
        config.filter = newFilter
        setConfig({ ...config })
        handleClose()
    }

    function handleSearchChange(event) {
        setSearch(event.target.value)

        clearTimeout(timerIdRef.current)

        timerIdRef.current = setTimeout(() => {
            getCategoryValues(
                selectedTable.reportSeriesTableID,
                selectedFilterColumn.columnName,
                event.target.value === '' ? null : event.target.value
            )
        }, DEBOUNCE_DELAY)
    }

    function handleLikeChange(event) {
        setLikeValue(event.target.value)
    }

    function addLikeValue(event) {
        setSelectedItems([likeValue, ...selectedItems])
    }

    function removeLikeValue(event) {
        let result = []
        let found = false
        for (let i = 0; i < selectedItems.length; i++) {
            let val = selectedItems[i]
            if (event.target.name === val) {
                found = true
            } else {
                result.push(val)
            }
        }
        setSelectedItems(result)
    }

    const isNumeric =
        selectedFilterColumn != null &&
        (selectedFilterColumn.columnType === 'Decimal' ||
            selectedFilterColumn.columnType === 'Currency' ||
            selectedFilterColumn.columnType === 'Integer')

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'} />
            <Button onClick={handleSave} text={'Apply'} className={'button-purple'} />
        </Box>
    )
    const getAvailableDateFilters = () => {
        const tableIds = config.selectedItems.map((i) => i.reportSeriesTableID)
        const dates = []
        if (!workspaceMetadata) return []
        workspaceMetadata?.inboxes?.forEach((inbox) => {
            inbox.tables.forEach((table) => {
                if (tableIds.includes(table.reportSeriesTableID)) dates.push(table.dates || [])
            })
        })
        return _.intersection(...dates)
    }

    const getChartFilterBy = () => {
        const dateCols =
            getAvailableDateFilters()?.map((date) => (
                <MenuItem value={date} key={date} onClick={handleChangeAttribute}>
                    {date}
                </MenuItem>
            )) || []
        const drillCols =
            chartProperties.drillAttributes?.map((item) => (
                <MenuItem value={item} key={item} onClick={handleChangeAttribute}>
                    {item}
                </MenuItem>
            )) || []
        const changeDrillCols = analyzeChanges
            ? chartProperties.changeDrillAttributes?.map((item) => (
                  <MenuItem value={item} key={item} onClick={handleChangeAttribute}>
                      {item}
                  </MenuItem>
              ))
            : []
        return [...dateCols, ...drillCols, ...changeDrillCols]
    }

    const getTableFilterBy = () => {
        if (workspaceMetadata) {
            if (config.worksheetID && config.rangeName) {
                const drillCols =
                    chartProperties.drillAttributes?.map((item) => (
                        <MenuItem value={item} key={item} onClick={handleChangeAttribute}>
                            {item}
                        </MenuItem>
                    )) || []
                return [...drillCols]
            } else {
                const tables = []
                workspaceMetadata?.inboxes?.forEach((inbox) => {
                    inbox.tables.forEach((table) => {
                        if (config.selectedTables.includes(table.reportSeriesTableID))
                            tables.push(table)
                    })
                })
                const columns = tables.map((table) =>
                    table.columns.map((col) => ({
                        ...col,
                        reportSeriesTableID: table.reportSeriesTableID,
                    }))
                )
                return _.intersection(...columns).map((col) => (
                    <MenuItem
                        value={col.columnName}
                        key={col.columnName}
                        onClick={handleChangeAttribute}
                    >
                        {col.columnName}
                    </MenuItem>
                ))
            }
        }
        return []
    }

    const getFilterOptionsFromTableSelected = () => {
        return columnSource?.table?.columns?.map((col) => (
            <MenuItem value={col.columnName} key={col.columnName} onClick={handleChangeAttribute}>
                {col.columnName}
            </MenuItem>
        ))
    }

    const handleSelectDate = (newDate) => {
        let newSelectedItems = []
        if (operator === 'Between') {
            const from = dayjs(newDate[0]).format('YYYY-MM-DD')
            const to = dayjs(newDate[1]).format('YYYY-MM-DD')
            newSelectedItems.push(from, to)
        } else {
            newSelectedItems.push(dayjs(newDate).format('YYYY-MM-DD'))
        }
        setSelectedItems(newSelectedItems)
        setSelectedDate(newDate)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={'Add a Filter'}
            actions={actions}
            style={{ width: 600 }}
        >
            <DialogContentText>Select an attribute and values to filter by</DialogContentText>
            <Box
                sx={{
                    display: 'flex',
                    m: 0,
                    alignItems: 'center',
                    gap: '16px',
                }}
            >
                <Selector
                    value={selectedFilterColumn && selectedFilterColumn.columnName}
                    label="Filter by"
                >
                    {fromMetricDialog === true
                        ? getFilterOptionsFromTableSelected()
                        : config.view === 'table'
                          ? getTableFilterBy()
                          : getChartFilterBy()}
                </Selector>
                {selectedFilterColumn && (
                    <Selector
                        label={'Filter type'}
                        value={operator}
                        onChange={(event) => {
                            setOperator(event.target.value)
                        }}
                    >
                        <MenuItem value={'Equals'}>Equals</MenuItem>
                        {(isNumeric || isDate.current) && (
                            <MenuItem value={'GreaterThan'}>Greater Than</MenuItem>
                        )}
                        {(isNumeric || isDate.current) && (
                            <MenuItem value={'GreaterThanOrEquals'}>
                                Greater Than or Equals
                            </MenuItem>
                        )}
                        {(isNumeric || isDate.current) && (
                            <MenuItem value={'LessThan'}>Less Than</MenuItem>
                        )}
                        {(isNumeric || isDate.current) && (
                            <MenuItem value={'LessThanOrEquals'}>Less Than or Equals</MenuItem>
                        )}
                        <MenuItem value={'NotEquals'}>Not Equals</MenuItem>
                        {!isNumeric && !isDate.current && <MenuItem value={'Like'}>Like</MenuItem>}
                        {isDate.current && <MenuItem value={'Between'}>Between</MenuItem>}
                    </Selector>
                )}
            </Box>
            {categoryValuesLoading && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'grid',
                        placeContent: 'center',
                    }}
                >
                    <ScoopLoader />
                </Box>
            )}
            {isDate.current && (
                <ScoopDatePicker
                    value={selectedDate}
                    onChange={(newValue) => handleSelectDate(newValue)}
                    label={`Select ${operator === 'Between' ? 'range' : 'date'}`}
                    range={operator === 'Between'}
                />
            )}
            {(operator === 'Equals' || operator === 'NotEquals') &&
                selectedFilterColumn &&
                !selectedFilterColumn.isMeasure && (
                    <Box>
                        {(items.length > 0 || search.length > 0) && !categoryValuesLoading && (
                            <Box>
                                <Box sx={{ mb: 3 }}>
                                    {!isDate.current && (
                                        <Input
                                            label="Search:"
                                            value={search}
                                            onChange={handleSearchChange}
                                        />
                                    )}
                                </Box>
                                {!isDate.current && (
                                    <DataGrid
                                        autoHeight
                                        columns={[
                                            {
                                                field: 'value',
                                                headerName: 'Value',
                                                type: 'string',
                                                sortable: false,
                                                editable: false,
                                                width: '100%',
                                                headerAlign: 'center',
                                            },
                                        ]}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    page: 0,
                                                    pageSize: 20,
                                                },
                                            },
                                        }}
                                        rows={items}
                                        rowHeight={25}
                                        checkboxSelection
                                        rowSelectionModel={selectedItems}
                                        onRowSelectionModelChange={(ids) => {
                                            setSelectedItems(ids)
                                        }}
                                        sx={{
                                            '& .Mui-checked': {
                                                color: '#E50B54',
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                        {!categoryValuesLoading && noColumnsAvailable && search.length === 0 && (
                            <Typography variant="body1">
                                Column appears empty - no items available to select
                            </Typography>
                        )}
                    </Box>
                )}
            {(operator === 'Equals' || operator === 'NotEquals') &&
                selectedFilterColumn &&
                selectedFilterColumn.isMeasure && (
                    <Box>
                        <Input
                            label={'Value'}
                            sx={{ width: 200, mr: 10 }}
                            value={selectedItems[0]}
                            onChange={(event) => setSelectedItems([event.target.value])}
                        />
                    </Box>
                )}
            {(operator === 'GreaterThan' ||
                operator === 'LessThan' ||
                operator === 'GreaterThanOrEquals' ||
                operator === 'GreaterThanOrEquals') &&
                !isDate.current && (
                    <Box sx={{ mb: 3 }}>
                        <Input
                            label={'Value'}
                            type={isNumeric ? 'number' : 'text'}
                            sx={{
                                width: 200,
                                mr: 10,
                            }}
                            value={selectedItems[0]}
                            onChange={(event) => setSelectedItems([event.target.value])}
                        />
                    </Box>
                )}
            {operator === 'Like' && (
                <Grid
                    container
                    spacing={1}
                    sx={{
                        m: 0,
                        width: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Input
                        label={'Value'}
                        style={{ width: 200, mr: 10 }}
                        value={likeValue}
                        onChange={handleLikeChange}
                    />
                    <Grid item xs={4}>
                        <Button
                            variant="text"
                            sx={{ ml: 0, mr: 0 }}
                            onClick={addLikeValue}
                            text={'Add Value'}
                        />
                    </Grid>
                </Grid>
            )}
            {operator === 'Like' &&
                selectedItems?.map((item) => (
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            m: 0,
                            width: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid item xs={6}>
                            <Typography sx={{ m: 1 }}>{item}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="text"
                                name={item}
                                onClick={removeLikeValue}
                                text={'Remove'}
                            />
                        </Grid>
                    </Grid>
                ))}
        </Dialog>
    )
}
