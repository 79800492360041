import React, { useEffect, useRef, useState } from 'react'
import Xarrow from 'react-xarrows'
import { useDispatch, useSelector } from 'react-redux'
import { updateArrow } from '../../../store/actions'
import { useResizeDnD } from '../../../hooks/useResizeDnD'
import useKeyboardShortcuts from '../../../hooks/useKeyboardShortcuts'
import { setSelectedObject } from '../../../store/actions/uiActions'

export const ArrowElement = ({
    id,
    setShowMenu,
    arrowProps,
    startInitialPosition,
    endInitialPosition,
    multiProps,
}) => {
    const dispatch = useDispatch()
    const [editMode, setEditMode] = useState(false)
    const startId = `ArrowElement-START-${id}`
    const endId = `ArrowElement-END-${id}`
    const arrowId = `ArrowElement-${id}`
    const { multiSelect, multiMouseDown, multiMouseUp, multiMouseMove, multiPosition } = multiProps
    const snap = useSelector((state) => state.ui.snap)
    const activeMode = useSelector((state) => state.ui.activeMode)
    const editCanvas = activeMode === 'edit'
    const zoom = useSelector((state) => state.ui.zoom)
    const objects = useSelector((state) => state.objects)
    const workspaceID = useSelector((state) => state.ui.workspaceID)
    const [zoomFlag, setZoomFlag] = useState(false)
    const [draggingByBody, setDraggingByBody] = useState(false)
    const [draggingByStart, setDraggingByStart] = useState(false)
    const [draggingByEnd, setDraggingByEnd] = useState(false)
    const [arrowKey, setArrowKey] = useState(Math.random())
    const startRef = useRef()
    const endRef = useRef()

    useEffect(() => {
        setZoomFlag(true)
        // this is a sync problem between zoom animation and arrow rendering
        setTimeout(() => {
            setZoomFlag(false)
            setArrowKey(Math.random())
        }, 150)
    }, [zoom])

    const updateAnchorPosition = (id, key, position) => {
        dispatch(updateArrow(id, { key, value: position }))
    }

    const {
        position: startPos,
        handleMouseDown: startMouseDown,
        handleMove: startMove,
        handleClick: startClick,
        handleMouseUp: startMouseUp,
        resetDragAndResize: resetDragAndResizeStart,
    } = useResizeDnD(
        0,
        startInitialPosition,
        null,
        (position) => updateAnchorPosition(id, 'startInitialPosition', position),
        null,
        (mode) => {
            if (setShowMenu) setShowMenu(true, arrowProps)
            setEditMode(mode)
        },
        null,
        undefined,
        snap,
        null
    )

    const {
        position: endPos,
        handleMouseDown: endMouseDown,
        handleMove: endMove,
        handleClick: endClick,
        handleMouseUp: endMouseUp,
        resetDragAndResize: resetDragAndResizeEnd,
    } = useResizeDnD(
        0,
        endInitialPosition,
        null,
        (position) => updateAnchorPosition(id, 'endInitialPosition', position),
        null,
        (mode) => {
            if (setShowMenu) setShowMenu(true, arrowProps)
            setEditMode(mode)
        },
        null,
        undefined,
        snap,
        null
    )

    useEffect(() => {
        function handleClickOutside(event) {
            const ArrowElement = document.getElementById(arrowId)
            const ArrowStart = document.getElementById(startId)
            const ArrowEnd = document.getElementById(endId)
            const ArrowMenu = document.getElementById('arrow-menu')
            const DeleteDialog = document.getElementById('delete-dialog')
            const target = event.target
            const className = target.getAttribute('class')
            if (
                !ArrowElement?.contains(target) &&
                !ArrowStart?.contains(target) &&
                !ArrowEnd?.contains(target) &&
                !ArrowMenu?.contains(target) &&
                !DeleteDialog?.contains(target) &&
                !className?.includes('MuiButtonBase-root') &&
                !className?.includes('MuiTypography-root') &&
                event.target.tagName !== 'IMG' &&
                !className?.includes('MuiBackdrop-root')
            ) {
                if (setShowMenu) setShowMenu(false, null)
                setEditMode(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [editMode])

    const startSize = draggingByStart ? 400 : 20
    const startSub = draggingByStart ? 190 : 0
    const endSize = draggingByEnd ? 400 : 20
    const endSub = draggingByEnd ? 190 : 0

    const handleCopy = () => {
        if (editMode) {
            const canvasScrollElement = document.getElementById('canvas-scrollable')
            const copyObjects = objects.filter((obj) => obj.id == id)
            localStorage.setItem(
                'copiedObjects',
                JSON.stringify({
                    copyObjects: copyObjects,
                    scrollLeft: canvasScrollElement ? canvasScrollElement.scrollLeft : 0,
                    scrollTop: canvasScrollElement ? canvasScrollElement.scrollTop : 0,
                    workspaceID: workspaceID,
                })
            )
        }
    }

    useKeyboardShortcuts('c', handleCopy)

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    display: 'grid',
                    placeContent: 'center',
                    width: startSize,
                    height: startSize,
                    left: (multiPosition?.startPos?.x || startPos.x) - startSub,
                    top: (multiPosition?.startPos?.y || startPos.y) - startSub,
                    borderRadius: '50%',
                }}
                onMouseMove={editCanvas ? startMove : null}
                onMouseUp={() => {
                    if (editCanvas) {
                        setDraggingByStart(false)
                        startMouseUp()
                    }
                }}
            >
                <div
                    id={startId}
                    ref={startRef}
                    style={{
                        width: 20,
                        height: 20,
                        background: editMode ? '#6191F2' : 'none',
                        borderRadius: '10px',
                        cursor: 'move',
                    }}
                    onMouseDown={(e) => {
                        if (editCanvas) {
                            setDraggingByStart(true)
                            startMouseDown(e)
                        }
                    }}
                    onClick={editCanvas ? startClick : null}
                />
            </div>
            <div
                style={{
                    position: 'absolute',
                    display: 'grid',
                    placeContent: 'center',
                    width: endSize,
                    height: endSize,
                    left: (multiPosition?.endPos?.x || endPos.x) - endSub,
                    top: (multiPosition?.endPos?.y || endPos.y) - endSub,
                    borderRadius: '50%',
                }}
                onMouseMove={editCanvas ? endMove : null}
                onMouseUp={() => {
                    if (editCanvas) {
                        setDraggingByEnd(false)
                        endMouseUp()
                    }
                }}
            >
                <div
                    id={endId}
                    ref={endRef}
                    style={{
                        width: 20,
                        height: 20,
                        background: editMode ? '#6191F2' : 'none',
                        borderRadius: '10px',
                        cursor: 'move',
                    }}
                    onMouseDown={(e) => {
                        if (editCanvas) {
                            setDraggingByEnd(true)
                            endMouseDown(e)
                        }
                    }}
                    onClick={editCanvas ? endClick : null}
                />
            </div>
            <Xarrow
                path={arrowProps.path}
                color={multiSelect ? '#6191F2' : arrowProps.color}
                endAnchor={arrowProps.endAnchor}
                startAnchor={arrowProps.startAnchor}
                showHead={arrowProps.showHead}
                showTail={arrowProps.showTail}
                curveness={0.9}
                start={startRef}
                end={endRef}
                strokeWidth={4 * zoom}
                divContainerProps={{
                    onClick: () => {
                        if (!multiSelect && editCanvas) {
                            if (setShowMenu) setShowMenu(true, arrowProps)
                            setEditMode(true)
                            dispatch(setSelectedObject(id))
                        }
                    },
                    onMouseDown: (e) => {
                        if (editCanvas) {
                            setDraggingByBody(true)
                            if (multiSelect) {
                                multiMouseDown(e)
                            } else {
                                if (editMode) {
                                    startMouseDown(e)
                                    endMouseDown(e)
                                }
                            }
                        }
                    },
                    id: arrowId,
                    key: arrowKey,
                }}
                SVGcanvasProps={{
                    onMouseUp: () => {
                        if (editCanvas) {
                            setDraggingByBody(false)
                            if (multiSelect) {
                                multiMouseUp()
                            } else {
                                dispatch(
                                    updateArrow(id, {
                                        key: 'startInitialPosition',
                                        value: startPos,
                                    })
                                )
                                dispatch(
                                    updateArrow(id, {
                                        key: 'endInitialPosition',
                                        value: endPos,
                                    })
                                )
                                resetDragAndResizeStart()
                                resetDragAndResizeEnd()
                            }
                        }
                    },
                    onMouseMove: (e) => {
                        if (editCanvas) {
                            if (multiSelect) {
                                multiMouseMove(e)
                            } else {
                                startMove(e)
                                endMove(e)
                            }
                        }
                    },
                }}
                SVGcanvasStyle={{
                    pointerEvents: draggingByBody ? 'all' : 'none',
                }}
                _extendSVGcanvas={draggingByBody ? 400 : 0}
                divContainerStyle={{
                    cursor: editCanvas && editMode ? 'move' : 'default',
                    transform: activeMode !== 'presentation' ? `scale(${1 / zoom})` : ``,
                }}
            />
        </>
    )
}
