import { SelectSourceStep } from './SelectSourceStep'
import { useState } from 'react'
import FileUpload from '../../../Objects/FileUpload'
import { ConnectDatasource } from './ConnectDatasource'
import { EmailForward } from './EmailForward'
import BlendingDatasets from './Blending/BlendingDatasets'

export const NewDatasetModal = ({ open, onClose, setAlert, getMetadata }) => {
    const [step, setStep] = useState(0)
    const [option, setOption] = useState('')
    const renderStepContent = () => {
        if (open)
            switch (step) {
                case 0:
                    return (
                        <SelectSourceStep
                            index={0}
                            setOption={setOption}
                            setStep={setStep}
                            onClose={onClose}
                        />
                    )
                case 1:
                    return renderStep2()
                default:
                    return renderStepContent()
            }
        else return <></>
    }
    const renderStep2 = () => {
        if (open) {
            switch (option) {
                case 'Data File':
                    return (
                        <FileUpload
                            isOpen={open}
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                        />
                    )
                case 'Application':
                    return (
                        <ConnectDatasource
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => setStep(1)}
                            setAlert={setAlert}
                        />
                    )
                case 'Data Email':
                    return (
                        <EmailForward
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => setStep(0)}
                            setAlert={setAlert}
                            getMetadata={getMetadata}
                        />
                    )
                case 'Existing Scoop Data':
                    return (
                        <BlendingDatasets
                            onClose={() => {
                                onClose()
                                setStep(0)
                            }}
                            stepBack={() => setStep(0)}
                        />
                    )
                default:
                    return <> </>
            }
        }
    }
    return <>{renderStepContent()}</>
}
