// Canvas.js
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { debounce, isEqual } from 'lodash'
import { Alert, Box, Button } from '@mui/material'
import FileUpload from '../Objects/FileUpload'
import html2canvas from 'html2canvas'
import { useApi } from '../../api/api'
import { ZoomControls } from './ZoomControls/ZoomControls'
import KPI from '../Objects/KPI/KPI'
import Worksheet from '../Objects/Worksheet/Worksheet'
import InsightElement from '../Objects/Insight/InsightElement'
import {
    createThumbnail,
    resetResetTrigger,
    setZoom,
    canShowTourAlert,
} from '../../store/actions/uiActions'
import SheetletElement from '../Objects/Sheetlet/SheetletElement'
import { OBJECT_TYPES } from '../Objects/types'
import { ArrowElement } from '../Objects/Arrow/ArrowElement'
import ImageElement from '../Objects/Image/ImageElement'
import VideoElement from '../Objects/Video/VideoElement'
import TextEditorElement from '../Objects/TextEditor/TextEditorElement'
import { EditArrowMenu } from '../Objects/Arrow/EditArrowMenu'
import ProcessDiagramElement from '../Objects/ProcessDiagram/ProcessDiagramElement'
import { useMultiDnD } from '../../hooks/useMultiDnD'
import {
    updateArrow,
    updateInsightPosition,
    updateKPIPosition,
    updateProcessDiagramPosition,
    updateSheetletPosition,
    updateWorksheetPosition,
    updateImagePosition,
    updateVideoPosition,
    addObjects,
    removeObjects,
    updatePromptPosition,
    updateFramePosition,
    updateGenericShapePosition,
} from '../../store/actions'
import SaveIcon from '@mui/icons-material/Save'
import { useDragSelection } from '../../hooks/useDragSelection'
import { ScrollBars } from './ScrollBars/ScrollBars'
import useKeyboardShortcuts, { transformCopiedObjects } from '../../hooks/useKeyboardShortcuts'
import DeleteDialog from '../common/Dialog/DeleteDialog'
import { PromptElement } from '../Objects/Prompt/PromptElement'
import sha256 from 'crypto-js/sha256'
import Snackbar from '@mui/material/Snackbar'
import { FrameElement } from '../Objects/Frame/FrameElement'
import { EditFrameMenu } from '../Objects/Frame/EditFrameMenu'
import { EditTextMenu } from '../Objects/TextEditor/EditTextMenu'
import { GenericShapeElement } from '../Objects/GenericShape/GenericShapeElement'
import { drawShape, isCanvasBlank } from './DrawShapes'
import { EditGenericShapeMenu } from '../Objects/GenericShape/EditGenericShapeMenu'
import { useNavigate } from 'react-router-dom'

const DEFAULT_CANVAS_NAME = 'Scoop Tour'
const DEFAULT_WORKSPACE_NAME = 'Tour Scoop'
const FRAME_TO_CENTER = 'Tour Overview'

export const CanvasGrid = (props) => {
    const autoShowTourWorkspace = useSelector((state) => state.auth.autoShowTourWorkspace)
    const canvases = useSelector((state) => state.auth.canvases)
    const workspaceName = useSelector((state) => state.auth.workspaceName)
    const objects = useSelector((state) => state.objects)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const showFileUpload = useSelector((state) => state.ui.showFileUpload)
    const resetTrigger = useSelector((state) => state.ui.resetTrigger)
    const snapThumbnail = useSelector((state) => state.ui.snapThumbnail)
    const snap = useSelector((state) => state.ui.snap)
    const zoom = useSelector((state) => state.ui.zoom)
    const lastSaved = useSelector((state) => state.ui.lastSaved)
    const backgroundColor = useSelector((state) => state.ui.backgroundColor || '#ffffff')
    const presentationID = useSelector((state) => state.ui.presentationID)
    const activeMode = useSelector((state) => state.ui.activeMode)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const dispatch = useDispatch()
    const [insightsMaterialized, setInsightsMaterialized] = useState([])
    const [sheetletMaterialized, setSheetletMaterialized] = useState([])
    const [processDiagramMaterialized, setProcessDiagramMaterialized] = useState([])
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2'
    const { postData } = useApi(
        `https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`
    )
    const [showSavedMessage, setShowSavedMessage] = useState(false)
    const captureRef = useRef(null)
    const [arrowProps, setArrowProps] = useState(null)
    const [showArrowMenu, setShowArrowMenu] = useState(false)
    const [showFrameMenu, setShowFrameMenu] = useState(false)
    const [showShapeMenu, setShowShapeMenu] = useState(false)
    const [showTextEditorMenu, setShowTextEditorMenu] = useState(false)
    const [frame, setFrame] = useState({
        id: 5,
        x: 580,
        y: 1000,
        width: 1200,
        height: 675,
        backgroundColor: '#130417',
        type: 'Frame',
        title: 'Slide 5',
        presentationIndex: 3,
    })
    const [arrowId, setArrowId] = useState(null)
    const [shape, setShape] = useState(null)
    const [textEditor, setTextEditor] = useState(null)
    const [imageData, setImageData] = useState(null)
    const [multiSelect, setMultiSelect] = useState([])
    const [multiSelectInitialPositions, setMultiSelectInitialPositions] = useState([])
    const [deleteObjects, setDeleteObjects] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')
    const drawnShapes = useRef([])
    const navigate = useNavigate()
    // state of the visible area to the user, which updates as the user zooms and scrolls
    const [visibleRect, setVisibleRect] = useState({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    })
    const [renderedObjects, setRenderedObjects] = useState(new Set())

    // virtual padding to increate the logical size of the visible rect to load more objects while the user isn't doing anything
    const [lastInteractionTime, setLastInteractionTime] = useState(Date.now())
    const [virtualPadding, setVirtualPadding] = useState(0)
    const timeoutDuration = 5000 // increate virtual padding every 5 seconds of inactivity
    const increaseVirtualPaddingBy = 500 // increase the virtual padding by 500px when idle

    const isInside = (container, child) => {
        return !(
            child.x < container.x ||
            child.y < container.y ||
            child.x + child.width > container.x + container.width ||
            child.y + child.height > container.y + container.height
        )
    }

    const positionedTourWorkspace = () => {
        const canvas = document.getElementById('canvas-scrollable')
        const frame = objects?.find((obj) => obj.type === 'Frame' && obj.title === FRAME_TO_CENTER)
        if (!frame) return

        const canvasRect = canvas.getBoundingClientRect()
        const frameWidth = frame.width
        const frameHeight = frame.height

        const zoomX = canvasRect.width / frameWidth
        const zoomY = canvasRect.height / frameHeight
        const zoom = Math.min(zoomX, zoomY) * 0.9

        const left = (frame.x + frame.width / 2) * zoom - canvasRect.width / 2
        const top = (frame.y + frame.height / 2) * zoom - canvasRect.height / 2

        canvas.scrollTo({
            left: left,
            top: top,
            behavior: 'instant',
        })

        dispatch(setZoom(zoom))
        dispatch(canShowTourAlert(true))
        localStorage.removeItem('redirectTour')
    }

    useEffect(() => {
        if (!props.readyToRedirect || !localStorage.getItem('redirectTour')) return
        positionedTourWorkspace()
    }, [props.readyToRedirect])

    useEffect(() => {
        const handleClick = (event) => {
            const target = event.target
            if (target.tagName === 'A') {
                let href = target.getAttribute('href')
                if (href && href.startsWith('/canvas')) {
                    event.preventDefault()
                    navigate(href)
                }
            }
        }
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [navigate])

    const {
        position: dragSelectPos,
        size: dragSelectSize,
        showSelectionBox,
        handleMouseDown: dragSelectDown,
        handleMouseUp: dragSelectUp,
        intersects,
    } = useDragSelection(
        5,
        (box) => {
            const tempIds = []
            const tempPos = []
            objects.forEach((obj) => {
                // check if it's an arrow
                if (obj.startInitialPosition) {
                    const startPos = obj.startInitialPosition
                    const endPos = obj.endInitialPosition
                    const arrowBox = {
                        top: Math.min(startPos.y, endPos.y),
                        left: Math.min(startPos.x, endPos.x),
                        height: Math.abs(startPos.y - endPos.y) + 20,
                        width: Math.abs(startPos.x - endPos.x) + 20,
                    }
                    const int = intersects(box, arrowBox)
                    if (int) {
                        tempIds.push(obj.id)
                        tempPos.push({
                            startPos: {
                                x: obj.startInitialPosition.x,
                                y: obj.startInitialPosition.y,
                            },
                            endPos: {
                                x: obj.endInitialPosition.x,
                                y: obj.endInitialPosition.y,
                            },
                        })
                    }
                } else {
                    // check for intersection
                    const int = intersects(box, {
                        top: obj.y,
                        left: obj.x,
                        height: obj.height,
                        width: obj.width,
                    })
                    // check if drag selection is contained inside a component
                    const inside = isInside(
                        {
                            y: obj.y,
                            x: obj.x,
                            height: obj.height,
                            width: obj.width,
                        },
                        {
                            y: box.top,
                            x: box.left,
                            height: box.height,
                            box: obj.width,
                        }
                    )
                    if (int && !inside) {
                        tempIds.push(obj.id)
                        tempPos.push({ x: obj.x, y: obj.y })
                    }
                }
            })
            if (!isEqual(tempIds, multiSelect)) {
                setMultiSelect(tempIds)
                setMultiSelectInitialPositions(tempPos)
            }
        },
        (target) => target.id === 'scoop-canvas',
        multiSelect
    )

    const handleCopy = (toCopyObjects) => {
        const inputFocused = !!document.activeElement
            .getAttribute('class')
            ?.includes('MuiInputBase')
        if (!inputFocused) {
            const canvasScrollElement = document.getElementById('canvas-scrollable')
            let copyObjects
            if (toCopyObjects) copyObjects = toCopyObjects
            else copyObjects = objects.filter((obj) => multiSelect.includes(obj.id))
            if (copyObjects.length > 0) {
                localStorage.setItem(
                    'copiedObjects',
                    JSON.stringify({
                        copyObjects: copyObjects,
                        scrollLeft: canvasScrollElement ? canvasScrollElement.scrollLeft : 0,
                        scrollTop: canvasScrollElement ? canvasScrollElement.scrollTop : 0,
                        workspaceID: workspaceID,
                    })
                )
            }
        }
        setLastInteractionTime(Date.now())
    }

    const handlePaste = async () => {
        const inputFocused = !!document.activeElement
            .getAttribute('class')
            ?.includes('MuiInputBase')
        const isEditingFroala = document.getElementsByClassName('fr-box').length > 0
        const clipboardText = await navigator.clipboard.readText()
        if (clipboardText && isEditingFroala) return
        if (!inputFocused) {
            let copiedObjects = JSON.parse(localStorage.getItem('copiedObjects'))
            const currentFrames = objects.filter((obj) => obj.type === OBJECT_TYPES.FRAME)
            if (copiedObjects) {
                if (workspaceID !== copiedObjects.workspaceID) return
                delete copiedObjects.workspaceID
                const newObjects = transformCopiedObjects(copiedObjects, zoom, currentFrames)
                dispatch(addObjects(newObjects))
                const ids = newObjects.map((obj) => obj.id)
                const positions = newObjects.map((obj) => {
                    if (obj.type === OBJECT_TYPES.ARROW)
                        return {
                            endPos: {
                                x: obj.endInitialPosition.x,
                                y: obj.endInitialPosition.y,
                            },
                            startPos: {
                                x: obj.startInitialPosition.x,
                                y: obj.startInitialPosition.y,
                            },
                        }
                    else return { x: obj.x, y: obj.y }
                })
                setMultiSelect(ids)
                setMultiSelectInitialPositions(positions)
                handleCopy(newObjects)
            }
        }
    }

    const handleDelete = () => {
        setDeleteObjects(multiSelect)
    }

    useKeyboardShortcuts('c', handleCopy)
    useKeyboardShortcuts('v', handlePaste)
    useKeyboardShortcuts('Backspace', handleDelete)

    const containerStyleBase = {
        backgroundSize: '20px 20px',
        backgroundPosition: '10px 10px',
        height: props.height,
        width: props.width,
        fontFamily: 'Arial, sans-serif',
        transform: `scale(${zoom})`,
        transformOrigin: 'top left',
        //transition: 'transform 150ms ease',
        zIndex: 0,
        userSelect: 'none',
        cursor: props.itemToAdd ? 'crosshair' : 'auto',
    }

    //   display radial grid only when not in cursor mode
    const containerStyle =
        activeMode !== 'cursor'
            ? {
                  ...containerStyleBase,
                  backgroundImage: `radial-gradient(lightgrey 1px, transparent 0)`,
              }
            : containerStyleBase

    useEffect(() => {
        if (resetTrigger) {
            setInsightsMaterialized([])
            setSheetletMaterialized([])
            setProcessDiagramMaterialized([])
            dispatch(resetResetTrigger())
        }
    }, [resetTrigger, dispatch])

    useEffect(() => {
        if (snapThumbnail) {
            flashSavedMessage()
            saveObjects(true)
            dispatch(createThumbnail())
        }
    }, [snapThumbnail, dispatch])

    // TO-DO: NESTI, this technically works when the canvas is not zoom and not panned. Needs to be enhanced to account for zoom level and offset if user moves down/right in the canvas
    const capture = () => {
        return new Promise((resolve, reject) => {
            if (captureRef.current) {
                html2canvas(captureRef.current, { useCORS: true }) // Ensuring CORS is respected for external images
                    .then((canvas) => {
                        // Create an offscreen canvas
                        const offscreenCanvas = document.createElement('canvas')
                        const ctx = offscreenCanvas.getContext('2d')
                        // Set the dimensions you want
                        offscreenCanvas.width = 400
                        offscreenCanvas.height = 400

                        // Calculate the scaling factor to maintain aspect ratio
                        const scale = Math.min(
                            offscreenCanvas.width / window.innerWidth,
                            offscreenCanvas.height / window.innerHeight
                        )

                        // Draw the resized image, make perfect square
                        ctx.drawImage(
                            canvas,
                            0,
                            0,
                            window.innerWidth,
                            window.innerWidth,
                            0,
                            0,
                            window.innerWidth * scale,
                            window.innerWidth * scale
                        )

                        resolve(offscreenCanvas.toDataURL('image/png'))
                    })
                    .catch((error) => {
                        console.error('Error capturing the element:', error)
                        reject(error)
                    })
            } else {
                reject('No ref to capture')
            }
        })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setOpenSnackbar(true)
    }

    const saveObjects = useCallback(
        async (shouldTakeSnapshot) => {
            if (objects.length > 0) {
                try {
                    let currentImageData = imageData
                    // For now, if true is passed in, take a snapshot for the thumbnail and save
                    if (shouldTakeSnapshot) {
                        currentImageData = await capture()
                        setImageData(currentImageData) // Update imageData for future use
                        showSnackbar(
                            'Thumbnail updated. Please note it may take a few moments to refresh.',
                            'success'
                        )
                    }

                    const checksum = sha256(JSON.stringify(objects)).toString()

                    // Add the image data to the action object
                    const action = {
                        action: 'saveCanvas',
                        userID: props.userID,
                        workspaceID: props.workspaceID,
                        canvasID: props.canvasID,
                        canvasName: props.canvasName || '',
                        canvasObjects: objects,
                        canvasImage: currentImageData, // Use the freshly captured image data
                        zoom: zoom,
                        background: backgroundColor,
                        presentationID: presentationID,
                        checksum: checksum,
                        lastSaved: lastSaved,
                        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                    }
                    // Make the API call
                    const result = await postData(action)
                    // if save is unsuccessful, show a snackbar with the error message
                    if (result?.status === 'error' && activeMode === 'edit') {
                        showSnackbar('Oops, your canvas is outdated. ' + result.message)
                    }
                } catch (error) {
                    console.error('Error in saveObjects:', error)
                }
            }
        },
        [imageData, objects, props, postData, zoom]
    )

    const flashSavedMessage = () => {
        setShowSavedMessage(true)
        setTimeout(() => {
            setShowSavedMessage(false)
        }, 1000)
    }

    const debouncedEffect = useCallback(
        debounce(() => {
            flashSavedMessage()
            saveObjects(false)
        }, 2000),
        [objects, zoom, backgroundColor, presentationID]
    )

    useEffect(() => {
        if (!isGuestMode) {
            debouncedEffect()
        }
        // Clean up function to cancel the debounced effect if the component is unmounted
        return () => {
            debouncedEffect.cancel()
        }
    }, [objects, debouncedEffect])

    const closeFileUpload = () => {
        dispatch({ type: 'HIDE_FILE_UPLOAD' }) // This action should update state.ui.showFileUpload to false
    }

    const multiUpdatePositions = (positions) => {
        multiSelect.forEach((id, i) => {
            const object = objects[objects.findIndex((obj) => obj.id === id)]
            switch (object.type) {
                case OBJECT_TYPES.KPI:
                    dispatch(updateKPIPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.WORKSHEET:
                    dispatch(updateWorksheetPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.INSIGHT:
                    dispatch(updateInsightPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.SHEETLET:
                    dispatch(updateSheetletPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.TEXT:
                    dispatch(updateInsightPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.IMAGE:
                    dispatch(updateImagePosition(id, positions[i]))
                    break
                case OBJECT_TYPES.VIDEO:
                    dispatch(updateVideoPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.ARROW:
                    dispatch(
                        updateArrow(id, {
                            key: 'startInitialPosition',
                            value: positions[i].startPos,
                        })
                    )
                    dispatch(
                        updateArrow(id, {
                            key: 'endInitialPosition',
                            value: positions[i].endPos,
                        })
                    )
                    break
                case OBJECT_TYPES.PROCESS:
                    dispatch(updateProcessDiagramPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.PROMPT:
                    dispatch(updatePromptPosition(id, positions[i]))
                    break
                case OBJECT_TYPES.FRAME:
                    dispatch(updateFramePosition(id, positions[i]))
                    break
                case OBJECT_TYPES.GENERIC_SHAPE:
                    dispatch(updateGenericShapePosition(id, positions[i]))
                    break
            }
        })
    }

    const {
        isDragging: multiDragging,
        positions: multiPositions,
        handleMouseDown: multiMouseDown,
        handleMouseUp: multiMouseUp,
        handleMove: multiMouseMove,
        clearPositions: clearMultiPositions,
    } = useMultiDnD(10, multiSelect, multiSelectInitialPositions, multiUpdatePositions, snap)

    const getElementMultiPosition = (id) => {
        const index = multiSelect.indexOf(id)
        if (index !== -1) return multiPositions[index]
    }

    const getMultiProps = (id) => {
        return {
            multiSelect: multiSelect.includes(id),
            setMultiSelect,
            multiDragging,
            multiMouseDown,
            multiMouseUp,
            multiMouseMove,
            multiPosition: getElementMultiPosition(id),
        }
    }

    useEffect(() => {
        function handleMultiSelectClear(event) {
            if (event.target.id === 'scoop-canvas' && multiSelect.length > 0) {
                setMultiSelect([])
                setMultiSelectInitialPositions([])
                clearMultiPositions()
            }
        }

        document.addEventListener('mousedown', handleMultiSelectClear)
        return () => document.removeEventListener('mousedown', handleMultiSelectClear)
    }, [multiSelect])

    // Update the visibleRect when the user scrolls and zooms so we can render components conditionally if visible
    useEffect(() => {
        const container = document.getElementById('canvas-scrollable')
        if (!container) return

        const handleScroll = () => {
            const zoomLevel = zoom
            const newVisibleRect = {
                left: Math.max(0, container.scrollLeft / zoomLevel - virtualPadding),
                top: Math.max(0, container.scrollTop / zoomLevel - virtualPadding),
                right: Math.min(
                    props.width,
                    (container.scrollLeft + container.clientWidth) / zoomLevel + virtualPadding
                ),
                bottom: Math.min(
                    props.height,
                    (container.scrollTop + container.clientHeight) / zoomLevel + virtualPadding
                ),
            }
            // TO-DO (GJ) remove this console.log once we're happy w the way the canvas preloads objects outside of visible area while idling
            //console.log("newVisibleRect", newVisibleRect)
            setVisibleRect(newVisibleRect)
        }

        container.addEventListener('scroll', handleScroll)
        handleScroll() // Call it to set the initial visibleRect

        return () => container.removeEventListener('scroll', handleScroll)
    }, [zoom, virtualPadding, props.width, props.height]) // React to changes in these dependencies

    // monitor user interaction to reset the virtual padding
    useEffect(() => {
        const resetInteractionTimer = () => {
            setLastInteractionTime(Date.now())
        }
        window.addEventListener('mousemove', resetInteractionTimer)

        return () => {
            window.removeEventListener('mousemove', resetInteractionTimer)
        }
    }, [])

    // Check for inactivity to set virtual padding

    useEffect(() => {
        // This function will be called once the timeout expires
        const checkIdleTime = () => {
            const idleTime = Date.now() - lastInteractionTime
            // Calculate new potential padding value
            const potentialNewPadding = virtualPadding + increaseVirtualPaddingBy
            // Check if new padding would exceed the canvas dimensions
            if (
                idleTime >= timeoutDuration &&
                potentialNewPadding <= Math.max(props.width, props.height)
            ) {
                setVirtualPadding(potentialNewPadding)
            }
        }

        // Check if adding more padding would exceed the canvas dimensions before setting a timeout
        if (virtualPadding + increaseVirtualPaddingBy <= Math.max(props.width, props.height)) {
            const timerId = setTimeout(checkIdleTime, timeoutDuration)
            return () => clearTimeout(timerId)
        }
    }, [lastInteractionTime, virtualPadding, props.width, props.height, timeoutDuration])

    // Check if an object is visible in the canvas - just exclude the ones that are completely out of the visible rect
    const isVisible = (x, y, objectWidth, objectHeight) => {
        return !(
            x + objectWidth < visibleRect.left ||
            x > visibleRect.right ||
            y + objectHeight < visibleRect.top ||
            y > visibleRect.bottom
        )
    }

    useEffect(() => {
        const drawObjects = document.getElementsByClassName('drawObject')
        if (drawObjects.length > 0) {
            const drawArray = [...drawObjects]
            drawArray.forEach((canvas) => {
                if (!drawnShapes.current.includes(canvas.id) && isCanvasBlank(canvas)) {
                    let shape = JSON.parse(canvas.dataset.shape)
                    if (shape) {
                        drawShape(canvas, shape)
                        drawnShapes.current = [...drawnShapes.current, canvas.id]
                    }
                }
            })
        }
    }, [renderedObjects, activeMode])

    const renderObjects = useMemo(() => {
        // Clone the current renderedObjects set for mutation within this useMemo call.
        const newRendered = new Set(renderedObjects)

        const objectsToRender = objects
            .map((obj) => {
                let shouldBeRendered
                if (
                    obj.type === OBJECT_TYPES.ARROW ||
                    obj.type === OBJECT_TYPES.TEXT ||
                    obj.type === OBJECT_TYPES.FRAME
                ) {
                    shouldBeRendered = true
                } else {
                    shouldBeRendered =
                        isVisible(obj.x, obj.y, obj.width, obj.height) || newRendered.has(obj.id)
                }

                if (shouldBeRendered) {
                    newRendered.add(obj.id) // Mark as rendered if it's visible now
                }

                // Only proceed to render if the object is marked to be rendered
                if (!shouldBeRendered) return null

                // Render the object. This is your existing switch-case logic.
                switch (obj.type) {
                    // Your existing cases remain unchanged.
                    case OBJECT_TYPES.KPI:
                    case OBJECT_TYPES.WORKSHEET:
                    case OBJECT_TYPES.IMAGE:
                    case OBJECT_TYPES.VIDEO:
                    case OBJECT_TYPES.INSIGHT:
                    case OBJECT_TYPES.SHEETLET:
                    case OBJECT_TYPES.TEXT:
                    case OBJECT_TYPES.ARROW:
                    case OBJECT_TYPES.PROCESS:
                    case OBJECT_TYPES.PROMPT:
                    case OBJECT_TYPES.FRAME:
                    case OBJECT_TYPES.GENERIC_SHAPE:
                        return constructComponent(obj) // Use your original switch logic encapsulated in a function for clarity.
                    default:
                        return null
                }
            })
            .filter(Boolean) // Filter out null values representing objects not to be rendered.

        // Update the state of rendered objects if there are any new ones added during this render cycle.
        if (newRendered.size !== renderedObjects.size) {
            setRenderedObjects(newRendered)
        }

        return <>{objectsToRender}</>
    }, [
        objects,
        visibleRect,
        zoom,
        multiSelect,
        multiPositions,
        workspaceID,
        userID,
        token,
        sheetletMaterialized,
        insightsMaterialized,
        processDiagramMaterialized,
    ])

    function constructComponent(obj) {
        switch (obj.type) {
            case OBJECT_TYPES.KPI:
                return (
                    <KPI
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        multiProps={getMultiProps(obj.id)}
                    />
                )
            case OBJECT_TYPES.WORKSHEET:
                return (
                    <Worksheet
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        url={obj.url}
                        multiProps={getMultiProps(obj.id)}
                    />
                )
            case OBJECT_TYPES.IMAGE:
                return (
                    <ImageElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        url={obj.url}
                        multiProps={getMultiProps(obj.id)}
                        content={obj.content}
                    />
                )
            case OBJECT_TYPES.VIDEO:
                return (
                    <VideoElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        url={obj.url}
                        multiProps={getMultiProps(obj.id)}
                        content={obj.content}
                    />
                )
            case OBJECT_TYPES.INSIGHT:
                return (
                    <InsightElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        content={obj.content}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        workspaceMetadata={props.workspaceMetadata}
                        insightsMaterialized={insightsMaterialized}
                        setInsightsMaterialized={setInsightsMaterialized}
                        multiProps={getMultiProps(obj.id)}
                    />
                )
            case OBJECT_TYPES.SHEETLET:
                return (
                    <SheetletElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        content={obj.content}
                        shouldHideGrid={obj.shouldHideGrid}
                        shouldHideHeaders={obj.shouldHideHeaders}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        sheetletMaterialized={sheetletMaterialized}
                        setSheetletMaterialized={setSheetletMaterialized}
                        multiProps={getMultiProps(obj.id)}
                        inboxes={props.workspaceMetadata?.inboxes}
                    />
                )
            case OBJECT_TYPES.TEXT:
                return (
                    <TextEditorElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        value={obj.content}
                        replacement={obj.replacement || obj.worksheetID}
                        worksheetID={obj.worksheetID}
                        rangeName={obj.namedRange}
                        fitData={obj.fitData}
                        initialBorder={obj.border}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        object={obj}
                        multiProps={getMultiProps(obj.id)}
                        setShowMenu={(value) => {
                            if (value) setTextEditor(obj)
                            else setTextEditor(null)
                            setShowTextEditorMenu(value)
                        }}
                        canvasID={props.canvasID}
                        slides={props.slides}
                    />
                )
            case OBJECT_TYPES.ARROW:
                return (
                    <ArrowElement
                        id={obj.id}
                        key={obj.id}
                        arrowProps={obj.arrowProps}
                        setShowMenu={(value, props) => {
                            setArrowId(obj.id)
                            setShowArrowMenu(value)
                            setArrowProps(props)
                        }}
                        startInitialPosition={obj.startInitialPosition}
                        endInitialPosition={obj.endInitialPosition}
                        multiProps={getMultiProps(obj.id)}
                    />
                )
            case OBJECT_TYPES.PROCESS:
                return (
                    <ProcessDiagramElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        content={obj.content}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        processDiagramMaterialized={processDiagramMaterialized}
                        setProcessDiagramMaterialized={setProcessDiagramMaterialized}
                        multiProps={getMultiProps(obj.id)}
                        workspaceMetadata={props.workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.PROMPT:
                return (
                    <PromptElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        promptProps={obj.promptProps}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        multiProps={getMultiProps(obj.id)}
                        workspaceMetadata={props.workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.FRAME:
                return (
                    <FrameElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        title={obj.title}
                        backgroundColor={obj.backgroundColor}
                        multiProps={getMultiProps(obj.id)}
                        setShowMenu={(value) => {
                            if (value) setFrame(obj)
                            else setFrame(null)
                            setShowFrameMenu(value)
                        }}
                        backgroundImage={obj.backgroundImage}
                    />
                )
            case OBJECT_TYPES.GENERIC_SHAPE:
                return (
                    <GenericShapeElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{ x: obj.x, y: obj.y }}
                        initialSize={{ width: obj.width, height: obj.height }}
                        content={obj.content}
                        shapeType={obj.shapeType}
                        multiProps={getMultiProps(obj.id)}
                        setShowMenu={(value) => {
                            if (value) setShape(obj)
                            else setShape(null)
                            setShowShapeMenu(value)
                        }}
                    />
                )
            default:
                return null
        }
    }

    return (
        <>
            {activeMode === 'edit' && showSavedMessage && (
                <Box style={{ position: 'fixed', top: 140, right: 40 }}>
                    <SaveIcon />
                </Box>
            )}
            {activeMode === 'edit' && showFileUpload && (
                <FileUpload isOpen={showFileUpload} onClose={closeFileUpload} />
            )}
            <div
                style={containerStyle}
                ref={captureRef}
                id={'scoop-canvas'}
                onMouseDown={activeMode === 'edit' && !promptSelecting ? dragSelectDown : null}
                onMouseUp={activeMode === 'edit' && !promptSelecting ? dragSelectUp : null}
            >
                {renderObjects}
                {activeMode === 'edit' && showSelectionBox && (
                    <div
                        style={{
                            position: 'absolute',
                            top: dragSelectPos.y,
                            left: dragSelectPos.x,
                            height: dragSelectSize.height,
                            width: dragSelectSize.width,
                            border: '1px solid #E50B54',
                            backgroundColor: 'rgba(235, 67, 123, 0.10)',
                        }}
                        id={'drag-selection-box'}
                    />
                )}
            </div>
            {activeMode === 'edit' && showArrowMenu && (
                <EditArrowMenu
                    arrowProps={arrowProps}
                    setArrowProps={setArrowProps}
                    id={arrowId}
                    handleDelete={() => {
                        setShowArrowMenu(false)
                        setArrowId(null)
                        setArrowProps(null)
                    }}
                />
            )}
            {activeMode === 'edit' && showTextEditorMenu && (
                <EditTextMenu
                    textEditor={textEditor}
                    handleDelete={() => {
                        setShowTextEditorMenu(false)
                        setTextEditor(null)
                    }}
                    workspaceID={workspaceID}
                />
            )}
            {activeMode === 'edit' && showFrameMenu && (
                <EditFrameMenu
                    frame={frame}
                    handleDelete={() => {
                        setShowFrameMenu(false)
                        setFrame(null)
                    }}
                />
            )}
            {activeMode === 'edit' && showShapeMenu && (
                <EditGenericShapeMenu
                    shape={shape}
                    handleDelete={() => {
                        setShowShapeMenu(false)
                        setShape(null)
                    }}
                />
            )}
            <ZoomControls
                width={props.width}
                clearSelection={() => {
                    setMultiSelect([])
                    setMultiSelectInitialPositions([])
                    clearMultiPositions()
                }}
            />
            <ScrollBars width={props.width} height={props.height} />
            <DeleteDialog
                handleCancel={() => setDeleteObjects([])}
                title={'this selected objects'}
                type={'selected objects'}
                open={deleteObjects.length > 0}
                handleDelete={() => {
                    dispatch(removeObjects(deleteObjects))
                    setDeleteObjects([])
                }}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}
