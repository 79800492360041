import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'

export const DataGrid = ({ rows, columns, style, ...props }) => {
    return (
        <MuiDataGrid
            {...props}
            rows={rows}
            columns={columns}
            rowHeight={32}
            getRowClassName={(params) =>
                `MuiDataGrid-row ${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}`
            }
            sx={{
                '& .MuiDataGrid-row.even': {
                    backgroundColor: '#ffffff', // white for even rows
                },
                '& .MuiDataGrid-row.odd': {
                    backgroundColor: '#f7f7f7', // light grey for odd rows
                },
                '& .MuiDataGrid-cell': {
                    overflow: 'hidden',
                    color: '#412747',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontFamily: '"Source Sans Pro"',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#f7f7f7', // Add this if you want a white background for the header
                },
                '& .MuiDataGrid-columnHeader': {
                    color: '#412747', // Neutral-300 color
                    fontFamily: '"Source Sans Pro"',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '140%', // This will compute to roughly 16.8px if the font size is 12px
                    letterSpacing: '-0.24px',
                },
                '& .MuiDataGrid-sortIcon': {
                    display: 'block', // Always show the sort icon
                },
                '& .MuiDataGrid-columnHeader:hover': {
                    backgroundColor: 'transparent', // Remove the hover effect
                    '.MuiDataGrid-sortIcon': {
                        // If needed, add styles for your icon on hover here
                    },
                },
                '& .MuiDataGrid-columnSeparator': {
                    display: 'none', // Hide the vertical dividers
                },
                ...style,
            }}
            slots={{
                columnSortedAscendingIcon: ArrowDropUpIcon,
                columnSortedDescendingIcon: ArrowDropDownIcon,
            }}
        />
    )
}
