import * as React from 'react'
import ColumnHeader from './ColumnHeader'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { DataGrid, useGridApiEventHandler, useGridApiContext } from '@mui/x-data-grid'
import { packFilter } from './Filter'
import { useEffect, useRef, useState } from 'react'
import { ScoopLoader } from '../../../../../common/Spinner/ScoopLoader'

export default function DefineInputQuery({
    editingAnchorEl,
    setEditingAnchorEl,
    selectedColumns,
    selectedFilters,
    handleFilterChange,
    setSelectedColumns,
    index,
    table,
    inputQuery,
    server,
    worksheetID,
    aggregate,
    timeLevel,
    useLatest,
    groupByDate,
    pickedDate,
    save,
    setSave,
    dataRetrieval,
    continueToNext,
    setIsCreating,
    resetState,
}) {
    const [columns, setColumns] = useState(() => {
        if (selectedColumns?.index === index) {
            return selectedColumns?.columns?.map((col) => col.columnName) || []
        } else {
            return []
        }
    })

    const [filterList, setFilterList] = useState(() => {
        if (selectedFilters?.index === index) {
            if (
                selectedFilters?.filter?.length > 0 &&
                selectedFilters?.filter[0]?.attributeName !== null
            ) {
                return selectedFilters?.filter
            } else {
                return []
            }
        } else {
            return []
        }
    })

    const [previewColumns, setPreviewColumns] = React.useState([])
    const [previewRows, setPreviewRows] = React.useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [anchorEl, setAnchorEl] = useState(editingAnchorEl)
    //is used to keep track of the left position of the column headers
    const left = useRef(0)

    useEffect(() => {
        setAnchorEl(editingAnchorEl)
    }, [editingAnchorEl?.anchorEl])

    useEffect(() => {
        if (!filterList) return
        handleFilterChange(filterList)
    }, [filterList])

    useEffect(() => {
        if (selectedFilters?.index === index) {
            setFilterList(selectedFilters?.filter)
        }
    }, [selectedFilters.filter])

    useEffect(() => {
        setColumns(selectedColumns?.columns.map((col) => col.columnName))
    }, [selectedColumns.columns])

    useEffect(() => {
        async function updateBlending() {
            await updateBlendingPreview()
        }

        updateBlending()
    }, [
        table,
        timeLevel,
        useLatest,
        groupByDate,
        aggregate,
        pickedDate,
        dataRetrieval,
        selectedFilters.filter,
    ])

    useEffect(() => {
        async function saveData() {
            if (save) {
                /*isBlending ? await handleSaveBlending() : await handleSaveAggregate();*/
                await handleSaveBlending()
            }
        }

        saveData()
    }, [save])

    function getGridColType(scoopType) {
        switch (scoopType) {
            case 'String':
                return 'string'
            case 'Decimal':
                return 'number'
            case 'DateTime':
                return 'dateTime'
            case 'Currency':
                return 'number'
            case 'Boolean':
                return 'boolean'
            case 'Integer':
                return 'number'
            default:
                return 'string'
        }
    }

    function transform(cType, val) {
        if (cType) {
            if (cType === 'DateTime') {
                return new Date(val)
            }
            return val
        }
        return val
    }

    const processPreviewBlending = (previewData) => {
        setIsLoading(true)
        if (!previewData || !previewData.rows || !previewData.rows.length > 0) {
            setError(true)
            return
        }
        var newPreviewColumns = []
        var newPreviewRows = []
        for (var cnum = 0; cnum < previewData.rows[0].length - 1; cnum++) {
            var minWidth = 8
            var cName = previewData.rows[0][cnum]
            var cType = 'string'
            if (cnum < table.columns.length) {
                if (table.columns[cnum].columnType !== 'String') {
                    minWidth = 15
                } else {
                    for (var rnum = 1; rnum < previewData.rows.length; rnum++) {
                        if (previewData.rows[rnum][cnum].length > minWidth) {
                            minWidth = previewData.rows[rnum][cnum].length
                        }
                    }
                }
                if (
                    table.columns[cnum].columnType === 'String' &&
                    table.columns[cnum].columnName.length > minWidth
                ) {
                    minWidth = table.columns[cnum].columnName.length
                }
                cName = table.columns[cnum].columnName
                cType = getGridColType(table.columns[cnum].columnType)
            } else {
                minWidth = 20
            }
            if (minWidth > 35) {
                minWidth = 35
            }
            newPreviewColumns.push({
                field: cName,
                headerName: cName,
                width: minWidth * 11,
                type: cType,
                sortable: false,
                editable: false,
                headerAlign: 'center',
                headerClassName: 'column--header',
            })
        }
        for (rnum = 1; rnum < previewData.rows.length; rnum++) {
            var newRow = { id: rnum }
            for (cnum = 0; cnum < previewData.rows[0].length; cnum++) {
                var x = previewData.rows[rnum][cnum]
                if (cnum < table.columns.length) {
                    newRow[table.columns[cnum].columnName] = transform(
                        table.columns[cnum].columnType,
                        x
                    )
                } else {
                    newRow[previewData.rows[0][cnum]] = previewData.rows[rnum][cnum]
                }
            }
            newPreviewRows.push(newRow)
        }
        setIsLoading(false)
        setError(false)
        setPreviewColumns(newPreviewColumns)
        setPreviewRows(newPreviewRows)
    }

    const updateBlendingPreview = async () => {
        //if ((inputQuery.columns.length === 0 && !isNew) && !(columns.length === 0)) return
        setEditingAnchorEl(null)
        setIsLoading(true)
        try {
            await server.sheetPostData(
                {
                    action: 'addOn',
                    addOnAction: 'preview',
                    inputQuery: inputQuery,
                    numRows: 15,
                    sheetID: worksheetID,
                },
                processPreviewBlending
            )
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const finishSaveBlending = () => {
        setIsCreating(false)
        continueToNext()
        resetState()
        setSave(false)
    }

    const handleSaveBlending = async () => {
        setIsCreating(true)
        inputQuery.filter = packFilter(filterList)
        try {
            await server.sheetPostData(
                {
                    action: 'addOn',
                    addOnAction: 'saveInputQuery',
                    inputQuery: inputQuery,
                    index: index,
                    refresh: true,
                    sheetID: worksheetID,
                },
                finishSaveBlending
            )
        } catch (e) {
            setIsCreating(false)
            continueToNext()
        } finally {
            resetState()
        }
    }

    const ColumnHeaders = (props) => {
        //        const [left, setLeft] = React.useState(0);
        const apiRef = useGridApiContext()
        const hRef = React.useRef()

        const handleEvent = (params) => {
            if (hRef.current) {
                left.current = -params.left
                hRef.current.style.left = -params.left + 'px'
            }
        }
        const totalWidth = props.visibleColumns.reduce((acc, col) => acc + col.width, 0)

        useGridApiEventHandler(apiRef, 'scrollPositionChange', handleEvent)

        return (
            <Box
                ref={hRef}
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    minWidth: '1118px',
                    width: totalWidth + 'px',
                    position: 'relative',
                    minHeight: '38px',
                    left: left.current + 'px',
                    backgroundColor: '#F9F9F9',
                }}
            >
                {props.visibleColumns.map((col, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: col.width + 'px',
                            minWidth: col.minWidth + 'px',
                        }}
                    >
                        <ColumnHeader
                            editingAnchorEl={anchorEl}
                            sheetID={worksheetID}
                            headerName={col.headerName}
                            inputQuery={inputQuery}
                            isChecked={columns.includes(col.headerName)}
                            setColumns={setColumns}
                            setCurrentColumns={setSelectedColumns}
                            filterList={filterList ? filterList : []}
                            setFilterList={setFilterList}
                            server={server}
                            updatePreviewData={updateBlendingPreview}
                        ></ColumnHeader>
                    </Box>
                ))}
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>Select columns</Typography>
                <div
                    style={{
                        height: '310px',
                        width: '100%',
                        minHeight: '310px',
                    }}
                >
                    {!isLoading && !error && previewColumns?.length > 0 && (
                        <DataGrid
                            filterMode="server"
                            rows={previewRows}
                            columns={previewColumns}
                            getRowHeight={() => 'auto'}
                            pageSizeOptions={[25, 100]}
                            disableColumnMenu
                            hideFooter={true}
                            columnHeaderHeight={90}
                            slots={{ columnHeaders: ColumnHeaders }}
                            sx={{
                                '& .column--header': {
                                    lineHeight: 1,
                                },
                            }}
                        />
                    )}
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                height: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ScoopLoader size={72} />
                        </Box>
                    )}
                    {!isLoading && error && (
                        <Box
                            sx={{
                                display: 'flex',
                                height: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: '16px' }}>No data available</Typography>
                        </Box>
                    )}
                </div>
            </Box>
        </Box>
    )
}
