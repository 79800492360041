import React, { useEffect, useState } from 'react'
import './ColorPicker.css'
import Github from '@uiw/react-color-github'

export const ColorPicker = ({ color, setColor, width, height, menuTop, menuLeft }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            const PickerElement = document.getElementById('color-picker')
            if (!PickerElement?.contains(event.target)) {
                setShow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div
            className={'picker-container'}
            style={{ backgroundColor: color, width: width, height: height }}
            onClick={(e) => e.target.className === 'picker-container' && setShow(true)}
        >
            {show && (
                <Github
                    color={color}
                    style={{
                        borderRadius: 10,
                        position: 'absolute',
                        top: menuTop,
                        left: menuLeft,
                        '--github-background-color': 'white',
                    }}
                    onChange={(color) => {
                        setColor(color.hex)
                        setShow(false)
                    }}
                    placement={'T'}
                    id={'color-picker'}
                />
            )}
        </div>
    )
}
