import { mobileAPIRequest } from '../../api'

export const getInsights = async (workspaceID) => {
    const action = {
        action: 'getObjects',
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        attributeName: 'workspaceID',
        attributeValue: workspaceID,
        class: 'scoop.insight.Insight',
        fields: ['insightKey', 'workspaceID', 'insightName'],
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const deleteInsightRequest = async ({ insightKey }) => {
    const action = {
        action: 'deleteObject',
        class: 'scoop.insight.Insight',
        key: insightKey,
    }

    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const createInsightRequest = async ({ insightName, savedInsight, workspaceID }) => {
    const action = {
        action: 'putObject',
        class: 'scoop.insight.Insight',
        value: {
            insightKey: null,
            insightName,
            savedObject: savedInsight,
            workspaceID,
        },
    }

    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
