import { Box } from '@mui/material'
import { propExistsOnObject, renderColorPicker } from '../utils'
import { WATERFALL_DEFAULT_VALUES } from '../styleConsts'
import { cloneDeep } from 'lodash'

export const WaterfallConfig = ({ config, setConfig, chartPreferences }) => {
    const getWaterfallPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.waterfall, prop))
            return config.styleOverrides.waterfall[prop]
        if (propExistsOnObject(chartPreferences.waterfall, prop))
            return chartPreferences.waterfall[prop]
        return WATERFALL_DEFAULT_VALUES[prop]
    }

    const handleLinePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.waterfall[prop] = value
        setConfig(newConfig)
    }

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            {renderColorPicker('Start Color', getWaterfallPropValue('startColor'), (v) =>
                handleLinePropChange('startColor', v)
            )}
            {renderColorPicker('End Color', getWaterfallPropValue('endColor'), (v) =>
                handleLinePropChange('endColor', v)
            )}
            {renderColorPicker('Up Color', getWaterfallPropValue('upColor'), (v) =>
                handleLinePropChange('upColor', v)
            )}
            {renderColorPicker('Down Color', getWaterfallPropValue('downColor'), (v) =>
                handleLinePropChange('downColor', v)
            )}
        </Box>
    )
}
