import { Box, Typography } from '@mui/material'
import Button from '../Button/Button'
import PlusWhite from '../../../assets/icons/PlusWhite.svg'
import React from 'react'

export const EmptyDataset = ({ title, description, icon, onClick }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '373px',
            }}
        >
            <Box sx={{ width: '98px' }}>
                <img src={icon} alt={'Empty dataset'} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: '28px',
                        lineHeight: '30.8px',
                        color: '#201024',
                        fontFamily: 'Inter',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: '14px',
                        lineHeight: '19.6px',
                        color: '#979099',
                        fontFamily: 'Inter',
                    }}
                >
                    {description}
                </Typography>
            </Box>
            <Button
                className={'small button-purple'}
                style={{
                    width: 'fit-content',
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content',
                    minHeight: '40px',
                    fontSize: '14px !important',
                    padding: '10px 16px',
                }}
                onClick={onClick}
            >
                {' '}
                <img src={PlusWhite} alt={'plus'} style={{ color: 'white' }} />
                Create your first dataset
            </Button>
        </Box>
    )
}
