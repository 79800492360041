import React, { useEffect, useState } from 'react'
import { useResizeDnD } from '../../../hooks/useResizeDnD'
import { RESIZE_POSITION } from './types'
import { useDispatch, useSelector } from 'react-redux'
import useKeyboardShortcuts from '../../../hooks/useKeyboardShortcuts'
import { OBJECT_TYPES } from '../../Objects/types'
import { setSelectedObject } from '../../../store/actions/uiActions'
import { Divider, MenuItem, Popover, Typography } from '@mui/material'
import { ReorderPicker } from '../ReorderPicker/ReorderPicker'

export const ElementWrapper = ({
    id,
    type,
    editMode,
    toggleEditMode,
    initialSize,
    initialPosition,
    updateElementSize,
    updateElementPosition,
    setWrapperSizeTrack,
    multiSelect,
    setMultiSelect,
    multiDragging,
    multiMouseDown,
    multiMouseUp,
    multiMouseMove,
    multiPosition,
    sizeRef,
    minSize,
    drag,
    children,
    backgroundColor,
    withTheme,
    menuItems,
    objectId,
}) => {
    const dispatch = useDispatch()
    const snap = useSelector((state) => state.ui.snap)
    const activeMode = useSelector((state) => state.ui.activeMode)
    const objects = useSelector((state) => state.objects)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const promptSelectingObjects = useSelector((state) => state.prompt.promptSelectingObjects)
    const [draggingBy, setDraggingBy] = useState('')
    const [isMultiDragging, setIsMultiDragging] = useState(false)
    const editCanvas = activeMode === 'edit'
    const editSheetlet = activeMode === 'cursor' && type === 'sheetlet'

    useEffect(() => {
        if (multiDragging) setIsMultiDragging(true)
    }, [multiDragging])

    const {
        position,
        size,
        handleMouseDown,
        handleResizeStart,
        handleMove,
        handleMouseUp,
        handleClick,
    } = useResizeDnD(
        10,
        initialPosition,
        initialSize,
        updateElementPosition,
        updateElementSize,
        toggleEditMode,
        setWrapperSizeTrack,
        minSize,
        snap,
        drag,
        editCanvas,
        multiSelect
    )
    const [anchorEl, setAnchorEl] = useState(null)

    const resizeDots = [
        {
            resizePosition: RESIZE_POSITION.BOTTOM_RIGHT,
            cursor: 'nwse-resize',
            position: {
                left: position.x + size.width - 5,
                top: position.y + size.height - 5,
            },
        },
        {
            resizePosition: RESIZE_POSITION.BOTTOM_LEFT,
            cursor: 'nesw-resize',
            position: {
                left: position.x - 6,
                top: position.y + size.height - 5,
            },
        },
        {
            resizePosition: RESIZE_POSITION.TOP_RIGHT,
            cursor: 'nesw-resize',
            position: {
                left: position.x + size.width - 5,
                top: position.y - 6,
            },
        },
        {
            resizePosition: RESIZE_POSITION.TOP_LEFT,
            cursor: 'nwse-resize',
            position: { left: position.x - 6, top: position.y - 6 },
        },
    ]

    useEffect(() => {
        function handleClickOutside(event) {
            const element = document.getElementById(id)
            const resizeDotsElements = resizeDots.map((dot) =>
                document.getElementById(`resize-${dot.resizePosition}-${id}`)
            )
            const target = event.target
            const className = target.getAttribute('class')
            const dialogElement = document.getElementById('dialog-content-container')
            const colorPicker = document.getElementById('color-picker-popover')
            const frameMenu = document.getElementById('frame-menu')
            const textMenu = document.getElementById('textEditor-menu')
            const shapeMenu = document.getElementById('shape-menu')
            if (
                !target.id.includes('resize') &&
                !element?.contains(target) &&
                !dialogElement?.contains(target) &&
                !colorPicker?.contains(target) &&
                !frameMenu?.contains(target) &&
                !shapeMenu?.contains(target) &&
                !textMenu?.contains(target) &&
                !resizeDotsElements.some((el) => el?.contains(target)) &&
                !className?.includes('MuiButtonBase-root') &&
                !className?.includes('MuiTypography-root') &&
                !className?.includes('MuiBackdrop-root') &&
                target.tagName !== 'path'
            ) {
                toggleEditMode(false)
                dispatch(setSelectedObject(''))
                handleMouseUp()
            }
        }

        if (editMode && (editCanvas || editSheetlet)) {
            document.addEventListener('mousedown', handleClickOutside)
        }
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [editCanvas, editMode])

    const handleCopy = () => {
        if (editMode) {
            const canvasScrollElement = document.getElementById('canvas-scrollable')
            const objectId = id.split('-')[1].includes(':')
                ? id.split('-')[1]
                : parseFloat(id.split('-')[1])
            const copyObject = objects.filter((obj) => obj.id === objectId)
            if (copyObject.type === OBJECT_TYPES.ARROW) return
            if (copyObject[0].type === OBJECT_TYPES.TEXT && !drag) {
                localStorage.setItem('copiedObjects', null)
                return
            }
            localStorage.setItem(
                'copiedObjects',
                JSON.stringify({
                    copyObjects: copyObject,
                    scrollLeft: canvasScrollElement ? canvasScrollElement.scrollLeft : 0,
                    scrollTop: canvasScrollElement ? canvasScrollElement.scrollTop : 0,
                    workspaceID,
                })
            )
        }
    }

    useKeyboardShortcuts('c', handleCopy)

    const handleElementClick = (e) => {
        const cursor = window.getComputedStyle(e.target).cursor
        if (
            (editCanvas || editSheetlet) &&
            !promptSelecting &&
            !isMultiDragging &&
            cursor !== 'crosshair'
        ) {
            handleClick()
            dispatch(setSelectedObject(id))
            setMultiSelect([])
        }
        setIsMultiDragging(false)
    }

    const objectType = id.split('-')[0]
    const idNumber = id.split('-')[1].includes(':')
        ? id.split('-')[1]
        : parseFloat(id.split('-')[1])

    const updatedStyles = useSelector(
        (state) => state.objects.find((obj) => obj.id === idNumber)?.wrapperStyles || {}
    )

    const baseStyles = {
        position: 'absolute',
        left: multiPosition?.x || position.x,
        top: multiPosition?.y || position.y,
        width: size.width,
        height: size.height,
        outline:
            (editMode && !editSheetlet) || multiSelect || promptSelectingObjects.includes(id)
                ? '2px solid #6191F2'
                : 'none',
        border:
            withTheme || type === 'TextEditor' || type === 'Image' || type === 'GenericShape'
                ? ''
                : '1px solid #D0CDD1',
        backgroundColor:
            type === 'TextEditor' || type === 'Image' || type === 'Frame' || type === 'GenericShape'
                ? ''
                : backgroundColor
                  ? backgroundColor
                  : 'white',
        boxShadow:
            type === 'TextEditor' || type === 'Image' || type === 'Frame' || type === 'GenericShape'
                ? ''
                : '0 2px 10px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: type === 'TextEditor' || type === 'Image' || type === 'GenericShape' ? 0 : 5,
    }

    const getUpdatedStyles = () => {
        if (updatedStyles.backgroundColor) {
            return {
                border: `${updatedStyles.borderWidth} ${updatedStyles.borderStyle} ${updatedStyles.borderColor}`,
                backgroundColor: updatedStyles.backgroundColor,
                borderRadius: updatedStyles.borderRadius,
                opacity: updatedStyles.opacity,
                boxShadow: updatedStyles.boxShadow,
            }
        }
        return {}
    }

    const shouldOverrideStyles =
        objectType === OBJECT_TYPES.SHEETLET ||
        objectType === OBJECT_TYPES.PROCESS ||
        objectType === OBJECT_TYPES.INSIGHT
    const combinedStyles = shouldOverrideStyles
        ? { ...baseStyles, ...getUpdatedStyles() }
        : baseStyles

    const handleContextMenu = (event) => {
        event.preventDefault()
        setAnchorEl({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const renderMenuItems = (items) => {
        return items.map((item) => {
            if (item) {
                return (
                    <MenuItem
                        onClick={() => {
                            item.onClick()
                            handleClose()
                        }}
                        sx={{ padding: '8px' }}
                    >
                        <img
                            src={item.icon}
                            alt={item.alt}
                            style={{ marginRight: '10px', width: 20 }}
                        />
                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                            {item.label}
                        </Typography>
                    </MenuItem>
                )
            }
            return null
        })
    }

    const open = Boolean(anchorEl)
    const popoverId = open ? 'simple-popover' : undefined

    return (
        <>
            <div
                onContextMenu={editCanvas ? handleContextMenu : null}
                id={id}
                key={id}
                style={combinedStyles}
                onMouseDown={
                    editCanvas
                        ? multiSelect
                            ? multiMouseDown
                            : editMode
                              ? handleMouseDown
                              : null
                        : null
                }
                onMouseMove={editCanvas ? (multiSelect ? multiMouseMove : handleMove) : null}
                onMouseUp={
                    editCanvas
                        ? multiSelect
                            ? multiMouseUp
                            : editMode
                              ? handleMouseUp
                              : null
                        : null
                }
                onClick={handleElementClick}
                ref={sizeRef}
            >
                {children}
            </div>
            {editMode &&
                !editSheetlet &&
                resizeDots.map((dot) => (
                    <div
                        key={`resize-wrapper-${dot.resizePosition}-${id}`}
                        style={{
                            position: 'absolute',
                            display: 'grid',
                            placeContent: 'center',
                            height: draggingBy === dot.resizePosition ? 200 : 10,
                            width: draggingBy === dot.resizePosition ? 200 : 10,
                            top:
                                draggingBy === dot.resizePosition
                                    ? dot.position.top - 95
                                    : dot.position.top,
                            left:
                                draggingBy === dot.resizePosition
                                    ? dot.position.left - 95
                                    : dot.position.left,
                        }}
                        onMouseUp={() => {
                            setDraggingBy('')
                            handleMouseUp()
                        }}
                    >
                        <div
                            id={`resize-${dot.resizePosition}-${id}`}
                            key={`resize-${dot.resizePosition}-${id}`}
                            style={{
                                width: 15,
                                height: 15,
                                background: 'white',
                                border: '2px solid #6191F2',
                                borderRadius: '2px',
                                cursor: dot.cursor,
                            }}
                            onMouseDown={(e) => {
                                setDraggingBy(dot.resizePosition)
                                handleResizeStart(e, dot.resizePosition)
                            }}
                        />
                    </div>
                ))}
            {objectId && (
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: anchorEl?.mouseY || 0,
                        horizontal: anchorEl?.mouseX || 0,
                    }}
                    onContextMenu={handleClose}
                    sx={{ minWidth: '200px' }}
                >
                    {menuItems && renderMenuItems(menuItems.slice(0, -1))}
                    <ReorderPicker
                        closeMenu={handleClose}
                        title={'Order'}
                        origin={'right'}
                        objectId={objectId}
                    />
                    <Divider sx={{ margin: '0 !important' }} />
                    {menuItems && renderMenuItems([menuItems[menuItems.length - 1]])}
                </Popover>
            )}
        </>
    )
}
