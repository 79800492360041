import { Box, Typography } from '@mui/material'
import { Slider } from '../../../../../common/Slider/Slider'
import React, { useCallback, useEffect, useState } from 'react'
import { debounce, cloneDeep } from 'lodash'
import { BAR_DEFAULT_VALUES } from '../styleConsts'
import { Switch } from '../../../../../common/Switch/Switch'
import { propExistsOnObject } from '../utils'

export const BarConfig = ({ config, setConfig, chartPreferences }) => {
    const [topRadius, setTopRadius] = useState(BAR_DEFAULT_VALUES.itemStyle.borderRadius[0])
    const [bottomRadius, setBottomRadius] = useState(BAR_DEFAULT_VALUES.itemStyle.borderRadius[2])
    const [barWidth, setBarWidth] = useState(BAR_DEFAULT_VALUES.barWidth)
    const [barGap, setBarGap] = useState(BAR_DEFAULT_VALUES.barGap)
    const [barCategoryGap, setBarCategoryGap] = useState(BAR_DEFAULT_VALUES.barCategoryGap)

    useEffect(() => {
        setBarWidth(getBarPropValue('barWidth'))
        setBarGap(getBarPropValue('barGap'))
        setBarCategoryGap(getBarPropValue('barCategoryGap'))
        setTopRadius(getBorderRadiusPropValue('top'))
        setBottomRadius(getBorderRadiusPropValue('bottom'))
    }, [config.styleOverrides.bar])

    const barDebouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.bar[prop] = value
            setConfig(newConfig)
        }, 200),
        [config]
    )

    const barItemStyleDebouncedFunc = useCallback(
        debounce((prop, value, destination) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.bar.itemStyle[prop] = newConfig.styleOverrides.bar.itemStyle[
                prop
            ] || [5, 5, 0, 0]

            if (destination === 'top') {
                newConfig.styleOverrides.bar.itemStyle[prop][0] = value
                newConfig.styleOverrides.bar.itemStyle[prop][1] = value
            }
            if (destination === 'bottom') {
                newConfig.styleOverrides.bar.itemStyle[prop][2] = value
                newConfig.styleOverrides.bar.itemStyle[prop][3] = value
            }

            setConfig(newConfig)
        }, 200),
        [config]
    )

    const getBarPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.bar, prop))
            return config.styleOverrides.bar[prop]
        if (propExistsOnObject(chartPreferences.bar, prop)) return chartPreferences.bar[prop]
        return BAR_DEFAULT_VALUES[prop]
    }

    const getBorderRadiusPropValue = (orientation) => {
        if (orientation === 'top') {
            if (propExistsOnObject(config.styleOverrides.bar.itemStyle, 'borderRadius'))
                return config.styleOverrides.bar.itemStyle.borderRadius[0]
            if (propExistsOnObject(chartPreferences.bar?.itemStyle, 'borderRadius'))
                return chartPreferences.bar.itemStyle.borderRadius[0]
            return BAR_DEFAULT_VALUES.itemStyle.borderRadius[0]
        } else {
            if (propExistsOnObject(config.styleOverrides.bar.itemStyle, 'borderRadius'))
                return config.styleOverrides.bar.itemStyle.borderRadius[2]
            if (propExistsOnObject(chartPreferences.bar?.itemStyle, 'borderRadius'))
                return chartPreferences.bar.itemStyle.borderRadius[2]
            return BAR_DEFAULT_VALUES.itemStyle.borderRadius[2]
        }
    }

    const getBarEmphasisPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.bar.emphasis, prop))
            return config.styleOverrides.bar.emphasis[prop]
        if (propExistsOnObject(chartPreferences.bar?.emphasis, prop))
            return chartPreferences.bar.emphasis[prop]
        return BAR_DEFAULT_VALUES.emphasis[prop]
    }

    const handleDebouncedBarChange = (prop, value, setter) => {
        barDebouncedFunc(prop, value)
        setter(value)
    }

    const handleDebouncedItemStyleBarChange = (prop, value, setter, destination) => {
        barItemStyleDebouncedFunc(prop, value, destination)
        setter(value)
    }

    const handleBarEmphasisPropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.bar.emphasis[prop] = value
        setConfig(newConfig)
    }

    const emphasisDisabled = getBarEmphasisPropValue('disabled')
    const bar = config.seriesType === 'bar'

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            <Box className={'row'} sx={{ gap: '12px', alignItems: 'center' }}>
                <Switch
                    checked={!emphasisDisabled}
                    onClick={() => handleBarEmphasisPropChange('disabled', !emphasisDisabled)}
                />
                <Typography className={'inter style-config-label'}>Emphasis</Typography>
            </Box>
            <Slider
                label={`${!bar ? 'Top' : 'Right'} radius: ${topRadius}px`}
                value={topRadius}
                onChange={(v) =>
                    handleDebouncedItemStyleBarChange('borderRadius', v, setTopRadius, 'top')
                }
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`${!bar ? 'Bottom' : 'Left'} radius: ${bottomRadius}px`}
                value={bottomRadius}
                onChange={(v) =>
                    handleDebouncedItemStyleBarChange('borderRadius', v, setBottomRadius, 'bottom')
                }
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Bar width: ${barWidth}`}
                value={barWidth.split('%')[0]}
                onChange={(value) => handleDebouncedBarChange('barWidth', value + '%', setBarWidth)}
                min={1}
                max={100}
                step={1}
            />
            <Slider
                label={`Bar gap: ${barGap}`}
                value={barGap.split('%')[0]}
                onChange={(value) => handleDebouncedBarChange('barGap', value + '%', setBarGap)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Bar category gap: ${barCategoryGap}`}
                value={barCategoryGap.split('%')[0]}
                onChange={(value) =>
                    handleDebouncedBarChange('barCategoryGap', value + '%', setBarCategoryGap)
                }
                min={0}
                max={100}
                step={1}
            />
        </Box>
    )
}
