import { sheetServerRequest, worksheetsClientRequest } from '../../api'

export const getSheet = async (advanced, inboxID, sheetType, aggregation) => {
    const action = {
        action: 'getSheet',
        aggregation,
        sheetRange: {
            advanced,
            inboxID,
            sheetType,
        },
    }
    return await sheetServerRequest({
        method: 'post',
        data: action,
    })
}

export const getSheets = async () => {
    const action = {
        action: 'listDynamicSheets',
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }
    const result = await worksheetsClientRequest({
        method: 'post',
        data: action,
    })
    return result?.spreadsheets?.map((sheet) => ({
        name: sheet.label + '_' + sheet.description,
        platform: sheet.url ? 'Google Sheets' : 'Other',
        createdBy: sheet.firstName + ' ' + sheet.lastName,
        lastEdited: sheet.lastModified,
        worksheetData: sheet,
    }))
}
