import React, { useEffect, useState } from 'react'
import './ScoopFormat.css'
import Input from '../Input/Input'
import { Box } from '@mui/material'
import Selector from '../Selector/Selector'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { Switch } from '../Switch/Switch'
import { getFormatter } from '../../Insights/KPI/utils'

export const ScoopFormat = ({ format, onChange }) => {
    const [formatObject, setFormatObject] = useState({
        type: 'number',
        decimals: 0,
        symbol: '$',
        short: false,
    })
    const [testValue, setTestValue] = useState(0)
    const [currentFormat, setCurrentFormat] = useState('')

    useEffect(() => {
        if (format) {
            const tempFormat = {
                type: 'number',
                decimals: 0,
                symbol: '$',
                short: false,
            }
            if (format.includes('%')) tempFormat.type = 'percentage'
            if (format.includes('$')) tempFormat.type = 'currency'
            if (format.includes('.')) {
                const decimals = format.split('.')[1]
                tempFormat.decimals = decimals.replace(/\D/g, '').length
            } else {
                tempFormat.decimals = 0
            }
            if (format.includes('a')) tempFormat.short = true
            setFormatObject(tempFormat)
            setCurrentFormat(format)
        }
    }, [format])

    const getDecimals = (d) => {
        if (d > 0) return '.' + '0'.repeat(d)
        return ''
    }

    const handleFormatChange = (prop, value) => {
        const newFormat = { ...formatObject }
        newFormat[prop] = value
        let formatString = ''
        switch (newFormat.type) {
            case 'number':
                if (newFormat.short) formatString = '#,###' + getDecimals(newFormat.decimals) + 'K'
                else formatString = '#,###' + getDecimals(newFormat.decimals)
                break
            case 'currency':
                if (newFormat.short)
                    formatString =
                        newFormat.symbol + '#,###' + getDecimals(newFormat.decimals) + 'K'
                else formatString = newFormat.symbol + '#,###' + getDecimals(newFormat.decimals)
                break
            case 'percentage':
                formatString = '#,###' + getDecimals(newFormat.decimals) + '%'
                break
        }
        setCurrentFormat(formatString)
        setFormatObject(newFormat)
        onChange(formatString)
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>
                    Type
                </Typography>
                <Selector
                    value={formatObject.type}
                    onChange={(e) => handleFormatChange('type', e.target.value)}
                    sx={{ height: '32px !important' }}
                >
                    <MenuItem value={'number'}>
                        <Typography className={'inter'}>Number</Typography>
                    </MenuItem>
                    <MenuItem value={'currency'}>
                        <Typography className={'inter'}>Currency</Typography>
                    </MenuItem>
                    <MenuItem value={'percentage'}>
                        <Typography className={'inter'}>Percentage</Typography>
                    </MenuItem>
                </Selector>
            </Box>
            <Input
                label={'Decimals'}
                value={formatObject.decimals}
                onChange={(event) => {
                    const value = event.target.value
                    handleFormatChange('decimals', value >= 0 ? value : 0)
                }}
                type={'number'}
            />
            {/*{*/}
            {/*    formatObject.type === 'currency' &&*/}
            {/*    <Input*/}
            {/*        label={'Symbol'}*/}
            {/*        value={formatObject.symbol}*/}
            {/*        onChange={(event) => handleFormatChange('symbol', event.target.value)}*/}
            {/*    />*/}
            {/*}*/}
            {/*<Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
            {/*    <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>*/}
            {/*        Short format*/}
            {/*    </Typography>*/}
            {/*    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 32, width: 200}}>*/}
            {/*        <Switch checked={formatObject.short} onClick={() => handleFormatChange('short', !formatObject.short)} />*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <Input
                label={'Format string'}
                value={currentFormat}
                onChange={(event) => setCurrentFormat(event.target.value)}
            />
            <Input
                label={'Test'}
                value={testValue}
                onChange={(event) => setTestValue(event.target.value)}
            />
            <Input
                label={'Result'}
                value={getFormatter(currentFormat).format(testValue)}
                disabled={true}
            />
        </Box>
    )
}
