import axios from 'axios'
import { store } from '../store/store'
import crypto from 'crypto-js'

export const mobileAPIClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

export const sheetServerClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_SHEET_SERVER_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

export const canvasClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_CANVAS_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

export const worksheetsClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_WORKSHEETS_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

export const eventClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_EVENTS_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

export const workspaceClient = (() =>
    axios.create({
        baseURL: process.env.REACT_APP_API_WORKSPACE_URL,
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }))()

mobileAPIClient.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token || localStorage.getItem('userToken')
        const userID = localStorage.getItem('userID')
        const workspaceID = localStorage.getItem('workspaceID')
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (userID) config.data.userID = userID
        if (workspaceID) config.data.workspaceID = workspaceID
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

mobileAPIClient.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
            // ... do something
        }
        if (status === 403 && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)

sheetServerClient.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token || localStorage.getItem('userToken')
        const userID = localStorage.getItem('userID')
        const workspaceID = localStorage.getItem('workspaceID')
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (userID) config.data.userID = userID
        if (workspaceID) config.data.workspaceID = workspaceID
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

sheetServerClient.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
            // ... do something
        }
        if (status === 403 && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)

canvasClient.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token || localStorage.getItem('userToken')
        const userID = localStorage.getItem('userID')
        const workspaceID = localStorage.getItem('workspaceID')
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (userID) config.data.userID = userID
        if (workspaceID) config.data.workspaceID = workspaceID
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

canvasClient.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
            // ... do something
        }
        if (status === 403 && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)

worksheetsClient.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token || localStorage.getItem('userToken')
        const userID = localStorage.getItem('userID')
        const workspaceID = localStorage.getItem('workspaceID')
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (userID) config.data.userID = userID
        if (workspaceID) config.data.workspaceID = workspaceID
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

worksheetsClient.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
            // ... do something
        }
        if (status === 403 && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)

eventClient.interceptors.request.use(
    (config) => {
        config.data.isDev = process.env.REACT_APP_SCOOP_ENV === 'dev'
        const passkey = 'omega145022'
        config.data.data = crypto.AES.encrypt(JSON.stringify(config.data.data), passkey).toString()
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

eventClient.interceptors.response.use(
    (res) => {
        // Simply return the response for successful requests
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null

        // console.log("API call failed. Suppressing error. Status:", status);

        // Suppress all errors and return a resolved promise with a fallback value
        return Promise.resolve({
            data: null, // You can define a fallback structure here
            status,
            error: null, // Indicate an error occurred (optional for tracking)
        })
    }
)

workspaceClient.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token || localStorage.getItem('userToken')
        const userID = localStorage.getItem('userID')
        const workspaceID = localStorage.getItem('workspaceID')
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (userID) config.data.userID = userID
        if (workspaceID) config.data.workspaceID = workspaceID
        config.data.isDev = process.env.REACT_APP_SCOOP_ENV === 'dev'
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

workspaceClient.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const status = error.response ? error.response.status : null
        if (status === 401) {
            // ... do something
        }
        if (status === 403 && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)
