import React, { useEffect, useState } from 'react'
import './KPI.css'
import chroma from 'chroma-js'
import { Box } from '@mui/material'
import {
    getCompareDiff,
    getCompareDirection,
    getFormattedKPIValue,
    getKPIData,
    getKPIStyles,
    getKPITitle,
} from './utils'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import Typography from '@mui/material/Typography'
import { CompareArrow } from './CompareArrow'

export const KPI = ({ config, setConfig, server, prompts, embeddedSizeProps, theme }) => {
    const [loading, setLoading] = useState(false)
    const [kpiValue, setKpiValue] = useState(0)
    const [compareDirection, setCompareDirection] = useState('')
    const [compareValue, setCompareValue] = useState(0)
    const [compareDiff, setCompareDiff] = useState(0)
    const [metricFormat, setMetricFormat] = useState('')
    const styles = getKPIStyles(theme, config, embeddedSizeProps, kpiValue)
    const compareColor =
        compareDirection === 'up'
            ? styles.compareStyles.positiveColor
            : styles.compareStyles.negativeColor

    useEffect(() => {
        if (config.selectedItems.length > 0) {
            setLoading(true)
            getKPIData(config, server, prompts, (r) => {
                if (config.tableTimeSeries) {
                    const data = r.series[0]?.data
                    if (data) {
                        const lastValue = data[data.length - 1]
                        const secondToLastValue = data[data.length - 2]
                        setMetricFormat(r.metrics[0].format || '')
                        setKpiValue(lastValue)
                        setCompareValue(secondToLastValue)
                        setCompareDiff(
                            getCompareDiff(lastValue, secondToLastValue, config.kpiCompareType)
                        )
                        setCompareDirection(getCompareDirection(lastValue, secondToLastValue))
                    }
                } else {
                    const data = r.dataset?.series[0]?.data
                    if (data) {
                        const lastValue = data[0]
                        setMetricFormat(r.dataset.metrics[0].format || '')
                        setKpiValue(lastValue)
                        const newConfig = { ...config }
                        newConfig.kpiCompareTarget = lastValue
                        setConfig(newConfig)
                    }
                }
                setLoading(false)
            })
        }
    }, [config.period, config.calendarType, config.timeRange, config.tableTimeSeries])

    useEffect(() => {
        if (config.kpiCompareTarget && !config.tableTimeSeries) {
            setCompareValue(config.kpiCompareTarget)
            setCompareDiff(getCompareDiff(kpiValue, config.kpiCompareTarget, config.kpiCompareType))
            setCompareDirection(getCompareDirection(kpiValue, config.kpiCompareTarget))
        } else {
            setCompareDiff(getCompareDiff(kpiValue, compareValue, config.kpiCompareType))
        }
    }, [config.kpiCompareTarget, config.tableTimeSeries, kpiValue, config.kpiCompareType])

    return (
        <Box
            className={'KPI-container'}
            style={{
                ...styles.containerStyles,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: config.kpiCompareType !== 'none' ? 'space-between' : 'center',
                width: embeddedSizeProps?.width || 200,
                height: embeddedSizeProps?.height || 200,
                margin: embeddedSizeProps ? '' : 'auto',
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        display: 'grid',
                        placeContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <ScoopLoader size={60} />
                </Box>
            ) : (
                <>
                    <Box className={'KPI-title'} style={styles.titleStyles}>
                        {getKPITitle(config)}
                    </Box>
                    <Box className={'KPI-value'} style={styles.valueStyles}>
                        {kpiValue && getFormattedKPIValue(kpiValue, metricFormat)}
                    </Box>
                    {config.kpiCompareType !== 'none' && (
                        <Box className={'KPI-compare'}>
                            {compareDirection !== 'equal' && (
                                <Box
                                    className={'KPI-compare-icon'}
                                    sx={{
                                        backgroundColor: chroma(compareColor).alpha(0.2).hex(),
                                    }}
                                >
                                    <CompareArrow
                                        width={styles.compareStyles.fontSize}
                                        height={styles.compareStyles.fontSize}
                                        direction={compareDirection}
                                        fill={compareColor}
                                    />
                                    <Typography
                                        sx={{
                                            ...styles.compareStyles,
                                            color: compareColor,
                                        }}
                                    >
                                        {compareDiff === 0 ? '-' : compareDiff}
                                    </Typography>
                                </Box>
                            )}
                            <Typography sx={{ ...styles.compareStyles }}>
                                {'(' + getFormattedKPIValue(compareValue, metricFormat) + ')'}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}
