import React, { useState } from 'react'
import './TextEditor.css'
import { useDispatch } from 'react-redux'
import { Box, IconButton, Tooltip } from '@mui/material'
import TrashRed from '../../../assets/icons/TrashRed.svg'
import WorksheetBlack from '../../../assets/icons/WorksheetBlack.svg'
import { ReorderPicker } from '../../common/ReorderPicker/ReorderPicker'
import { deleteTextEditor, setTextEditorTableRange } from '../../../store/actions'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import SelectRange from '../../common/Dialog/SelectRange'

export const EditTextMenu = ({ textEditor, handleDelete, workspaceID }) => {
    const dispatch = useDispatch()

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [fillOpen, setFillOpen] = useState(false)

    const handleDeleteTextEditor = () => {
        handleDelete()
        dispatch(deleteTextEditor(textEditor.id))
        setDeleteOpen(false)
    }

    const handleSelectRange = (worksheet, selectedWorksheetRange, fitData) => {
        setFillOpen(false)
        dispatch(
            setTextEditorTableRange(
                textEditor.id,
                worksheet.worksheetID,
                selectedWorksheetRange,
                fitData
            )
        )
    }

    return (
        <Box className={'textEditor-menu'} id={'textEditor-menu'}>
            <Box
                sx={{
                    height: 48,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {textEditor.tableShape && (
                    <Tooltip title={'Fill table with data from LiveSheet range'}>
                        <IconButton
                            size={'small'}
                            sx={{ ml: '10px' }}
                            onClick={() => setFillOpen(true)}
                        >
                            <img src={WorksheetBlack} alt={'worksheet'} />
                        </IconButton>
                    </Tooltip>
                )}
                <ReorderPicker origin={'top'} objectId={textEditor.id} tooltip />
                <IconButton size={'small'} sx={{ ml: '10px' }} onClick={() => setDeleteOpen(true)}>
                    <img src={TrashRed} alt={'delete'} />
                </IconButton>
            </Box>
            <DeleteDialog
                handleCancel={() => setDeleteOpen(false)}
                title={'TextEditor'}
                type={'text object'}
                open={deleteOpen}
                handleDelete={handleDeleteTextEditor}
            />
            <SelectRange
                handleCancel={() => setFillOpen(false)}
                open={fillOpen}
                worksheetID={textEditor?.worksheetID}
                namedRange={textEditor?.namedRange}
                handleSelect={handleSelectRange}
                workspaceID={workspaceID}
                fitGridToData={textEditor.fitData}
            />
        </Box>
    )
}
