import React from 'react'

export const CalendarIcon = ({ fill }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.625 2.25H12.9375V1.6875C12.9375 1.53832 12.8782 1.39524 12.7727 1.28975C12.6673 1.18426 12.5242 1.125 12.375 1.125C12.2258 1.125 12.0827 1.18426 11.9773 1.28975C11.8718 1.39524 11.8125 1.53832 11.8125 1.6875V2.25H6.1875V1.6875C6.1875 1.53832 6.12824 1.39524 6.02275 1.28975C5.91726 1.18426 5.77418 1.125 5.625 1.125C5.47582 1.125 5.33274 1.18426 5.22725 1.28975C5.12176 1.39524 5.0625 1.53832 5.0625 1.6875V2.25H3.375C3.07663 2.25 2.79048 2.36853 2.5795 2.5795C2.36853 2.79048 2.25 3.07663 2.25 3.375V14.625C2.25 14.9234 2.36853 15.2095 2.5795 15.4205C2.79048 15.6315 3.07663 15.75 3.375 15.75H14.625C14.9234 15.75 15.2095 15.6315 15.4205 15.4205C15.6315 15.2095 15.75 14.9234 15.75 14.625V3.375C15.75 3.07663 15.6315 2.79048 15.4205 2.5795C15.2095 2.36853 14.9234 2.25 14.625 2.25ZM5.0625 3.375V3.9375C5.0625 4.08668 5.12176 4.22976 5.22725 4.33525C5.33274 4.44074 5.47582 4.5 5.625 4.5C5.77418 4.5 5.91726 4.44074 6.02275 4.33525C6.12824 4.22976 6.1875 4.08668 6.1875 3.9375V3.375H11.8125V3.9375C11.8125 4.08668 11.8718 4.22976 11.9773 4.33525C12.0827 4.44074 12.2258 4.5 12.375 4.5C12.5242 4.5 12.6673 4.44074 12.7727 4.33525C12.8782 4.22976 12.9375 4.08668 12.9375 3.9375V3.375H14.625V5.625H3.375V3.375H5.0625ZM14.625 14.625H3.375V6.75H14.625V14.625Z"
                fill={fill}
            />
        </svg>
    )
}
