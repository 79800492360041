import React, { useEffect, useRef, useState } from 'react'
import '../Explorer.css'
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Typography,
} from '@mui/material'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import PlusBlack from '../../../../assets/icons/Plus.svg'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { ColumnTreeItem } from '../../../Insights/ColumnTreeItem'
import Checkbox from '../../../common/Checkbox/Checkbox'
import Button from '../../../common/Button/Button'
import { SheetSourceDialog } from '../../../Insights/SheetSourceDialog'
import { WorksheetMetric } from '../../../Insights/WorksheetMetric'
import { NewMetricDialog } from '../../../Insights/MetricDialog/NewMetricDialog'
import { useWorkspaceMetadata } from '../../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'

export const DataTab = ({
    server,
    config,
    setConfig,
    chartState,
    setWorkspaceMetadata,
    getWorkspaceMetadata,
    workspaceID,
    worksheetMetrics,
    setWorksheetMetrics,
}) => {
    const [newItems, setNewItems] = useState(config.selectedItems)
    const [selectedMetric, setSelectedMetric] = useState(null)
    const [itemOptionsMenuOpen, setItemOptionsMenuOpen] = useState(false)
    const [createKpiMenuOpen, setCreateKpiMenuOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)
    const [expanded, setExpanded] = useState([])
    const [defaultExpanded, setDefaultExpanded] = useState([])
    const [deleteKpi, setDeleteKpi] = useState(false)
    const [sheetSourceOpen, setSheetSourceOpen] = useState(false)
    const [metricDialogOpen, setMetricDialogOpen] = useState(false)
    const [derived, setDerived] = useState(false)
    const [kpis, setKpis] = useState([])
    const currentItemRef = useRef()
    const kpiMenuRef = useRef()

    const { workspaceMetadata, isLoading } = useWorkspaceMetadata()

    useEffect(() => {
        if (config.selectedItems !== newItems) {
            config.selectedItems = newItems
            if (config.selectedItems.length > 0) {
                chartState.getResults(config)
                setConfig({ ...config })
            } else {
                chartState.clear()
            }
        }
    }, [newItems])

    useEffect(() => {
        if (config.allowedKPIs) {
            setKpis(
                workspaceMetadata?.kpis?.filter((kpi) =>
                    config.allowedKPIs.includes(kpi.metricName)
                ) || []
            )
        } else {
            setKpis(workspaceMetadata?.kpis || [])
        }
    }, [config.allowedKPIs, workspaceMetadata])

    useEffect(() => {
        if (config.selectedItems.length === 0 && newItems.length !== 0) setNewItems([])
    }, [config.selectedItems])

    const setSelectedColumn = (event) => {
        if (!event.target.value) return
        let { reportSeriesTableID, columnName, kpi } = JSON.parse(event.target.value)
        let found = false
        if (config.view === 'kpi') {
            if (kpi) {
                if (
                    newItems.some(
                        (i) => i.reportSeriesTableID === reportSeriesTableID && i.kpi === kpi
                    )
                ) {
                    setNewItems([])
                } else {
                    setNewItems((state) => [{ reportSeriesTableID: reportSeriesTableID, kpi: kpi }])
                }
            } else {
                if (
                    newItems.some(
                        (i) =>
                            i.reportSeriesTableID === reportSeriesTableID &&
                            i.columnName === columnName
                    )
                ) {
                    setNewItems([])
                } else {
                    setNewItems((state) => [
                        {
                            reportSeriesTableID: reportSeriesTableID,
                            columnName: columnName,
                        },
                    ])
                }
            }
        }
        if (config.view === 'table') {
            const newConfig = { ...config }
            if (newConfig.selectedTableKpis.some((k) => k.metricName === kpi)) {
                // Deleting
                const deleteIndex = newConfig.selectedTableKpis.findIndex(
                    (k) => k.metricName === kpi
                )
                newConfig.selectedTableKpis.splice(deleteIndex, 1)
                if (
                    newConfig.selectedTables.includes(reportSeriesTableID) &&
                    !newConfig.selectedTableKpis.some(
                        (k) => k.reportSeriesTableID === reportSeriesTableID
                    )
                ) {
                    newConfig.selectedTables.splice(
                        newConfig.selectedTables.indexOf(reportSeriesTableID),
                        1
                    )
                }
                setConfig(newConfig)
            } else {
                // Adding
                workspaceMetadata.kpis.forEach((k) => {
                    if (k.metricName === kpi) newConfig.selectedTableKpis.push(k)
                })
                if (!newConfig.selectedTables.includes(reportSeriesTableID)) {
                    newConfig.selectedTables.push(reportSeriesTableID)
                }
                setConfig(newConfig)
            }
        }
        if (config.view === 'chart') {
            if (kpi) {
                for (var i = 0; i < newItems.length; i++) {
                    if (
                        newItems[i].reportSeriesTableID === reportSeriesTableID &&
                        newItems[i].kpi === kpi
                    ) {
                        found = true
                        const temp = [...newItems]
                        temp.splice(i, 1)
                        setNewItems([...temp])
                    }
                }
                if (!found) {
                    if (
                        !(
                            (config.seriesType === 'pie' || config.seriesType === 'donut') &&
                            config.selectedItems.length === 1
                        )
                    ) {
                        setNewItems((state) => [
                            ...state,
                            {
                                reportSeriesTableID: reportSeriesTableID,
                                kpi: kpi,
                            },
                        ])
                        if (newItems?.length === 1 && workspaceMetadata) {
                            for (i = 0; i < workspaceMetadata.kpis?.length; i++) {
                                if (workspaceMetadata.kpis[i].metricName === kpi) {
                                    config.period = workspaceMetadata.kpis[i].period
                                    setConfig({ ...config })
                                    break
                                }
                            }
                        }
                    }
                }
            } else {
                for (i = 0; i < newItems.length; i++) {
                    if (
                        newItems[i].reportSeriesTableID === reportSeriesTableID &&
                        newItems[i].columnName === columnName
                    ) {
                        found = true
                        const temp = [...newItems]
                        temp.splice(i, 1)
                        setNewItems([...temp])
                    }
                }
                if (!found) {
                    if (
                        !(
                            (config.seriesType === 'pie' || config.seriesType === 'donut') &&
                            config.selectedItems.length === 1
                        )
                    ) {
                        setNewItems((state) => [
                            ...state,
                            {
                                reportSeriesTableID: reportSeriesTableID,
                                columnName: columnName,
                            },
                        ])
                    }
                }
            }
        }
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds)
    }

    const setSelectedColumnDate = (value) => {
        let { selectedDate, reportSeriesTableID, columnName } = JSON.parse(value)
        if (selectedDate === 'Snapshot Date') {
            config.selectedDates.delete(reportSeriesTableID + ':' + columnName)
        } else {
            config.selectedDates.set(reportSeriesTableID + ':' + columnName, selectedDate)
        }
        chartState.getResults(config)
        setConfig({ ...config })
    }

    const hasMeasures = (table) => {
        for (var i = 0; i < table.columns?.length; i++) {
            if (
                config.drillAttribute &&
                chartState.allowedReportSeriesTables &&
                chartState.allowedReportSeriesTables.indexOf(table.reportSeriesTableID) < 0
            )
                return false
            if (table.columns[i].isMeasure) return true
        }
        return false
    }

    const hasTablesWithMeasures = (inbox) => {
        for (var i = 0; i < inbox.tables?.length; i++) {
            if (hasMeasures(inbox.tables[i])) return true
        }
        return false
    }

    const deleteItem = () => {
        setItemOptionsMenuOpen(false)
        const newConfig = { ...config }
        let index
        if (currentItem.columnName) {
            index = newConfig.selectedItems.findIndex(
                (i) =>
                    i.reportSeriesTableID === currentItem.reportSeriesTableID &&
                    i.columnName === currentItem.columnName
            )
        } else {
            index = newConfig.selectedItems.findIndex(
                (i) =>
                    i.reportSeriesTableID === currentItem.reportSeriesTableID &&
                    i.kpi === currentItem.kpi
            )
        }
        newConfig.selectedItems.splice(index, 1)
        chartState.getResults(newConfig)
        setConfig(newConfig)
        setCurrentItem(null)
    }

    const editItem = () => {
        if (currentItem.kpi) {
            workspaceMetadata.kpis?.forEach((kpi) => {
                if (
                    kpi.metricName === currentItem.kpi &&
                    kpi.reportSeriesTableID === currentItem.reportSeriesTableID
                )
                    setSelectedMetric(kpi)
            })
        } else {
            // fin better solution for this, setting selected metric without searching all tables
            workspaceMetadata.inboxes?.forEach((inbox) => {
                inbox.tables?.forEach((table) => {
                    table.columns?.forEach((col) => {
                        if (
                            col.columnName === currentItem.columnName &&
                            table.reportSeriesTableID === currentItem.reportSeriesTableID
                        )
                            setSelectedMetric(col)
                    })
                })
            })
        }
    }

    const handleCreateKpi = (derived) => {
        setDerived(derived)
        setMetricDialogOpen(true)
        setCreateKpiMenuOpen(false)
    }

    const handleTableSelection = (table) => {
        const newConfig = { ...config }
        const tableId = table.reportSeriesTableID
        if (!newConfig.selectedTables.includes(tableId)) newConfig.selectedTables.push(tableId)
        else newConfig.selectedTables.splice(newConfig.selectedTables.indexOf(tableId), 1)
        setConfig(newConfig)
    }

    const getAvailableTables = (inbox) => {
        if (config.allowedReportSeriesTables) {
            return (
                inbox?.tables?.filter((table) =>
                    config.allowedReportSeriesTables.includes(table.reportSeriesTableID)
                ) || []
            )
        } else {
            return inbox?.tables || []
        }
    }

    const handleWorksheetMetricSelect = (col) => {
        const newConfig = { ...config }
        if (newConfig.selectedItems.some((it) => it.measureName === col.columnName)) {
            const deleteIndex = newConfig.selectedItems.findIndex(
                (it) => it.measureName === col.columnName
            )
            newConfig.selectedItems.splice(deleteIndex, 1)
            setConfig(newConfig)
            if (newConfig.selectedItems.length > 0) chartState.getResults(newConfig)
        } else {
            newConfig.selectedItems.push({
                measureName: col.columnName,
                worksheetID: config.worksheetID,
                rangeName: config.rangeName,
            })
            if (config.worksheetColumns.some((c) => c.columnType === 'DateTime')) {
                const dateCol = config.worksheetColumns.find((c) => c.columnType === 'DateTime')
                newConfig.selectedDates.set(
                    config.worksheetID + ':' + col.columnName,
                    dateCol.columnName
                )
            }
            setConfig(newConfig)
            chartState.getResults(newConfig)
        }
    }

    const handleUnselectDeletedMetric = (metric) => {
        const newConfig = { ...config }
        if (newConfig.view === 'chart') {
            if (newConfig.selectedItems.some((it) => it.kpi === metric.metricName)) {
                const deleteIndex = newConfig.selectedItems.findIndex(
                    (it) => it.kpi === metric.metricName
                )
                newConfig.selectedItems.splice(deleteIndex, 1)
                setConfig(newConfig)
                if (newConfig.selectedItems.length > 0) chartState.getResults(newConfig)
            }
        } else {
            if (newConfig.selectedTableKpis.some((kpi) => kpi.metricName === metric.metricName)) {
                const deleteIndex = newConfig.selectedTableKpis.findIndex(
                    (it) => it.metricName === metric.metricName
                )
                newConfig.selectedTableKpis.splice(deleteIndex, 1)
                setConfig(newConfig)
            }
        }
    }

    const handleOpenMetric = (metric) => {
        setSelectedMetric(metric)
        setDerived(metric.derived)
        setMetricDialogOpen(true)
    }

    return (
        <>
            <Box
                sx={{
                    height: `calc(${config.worksheetID && config.view !== 'table' ? '30%' : '50%'} - 50px)`,
                }}
            >
                <Box className={'drawer-section-header'}>
                    <span style={{ fontWeight: 600 }}>KPIs</span>
                    <IconButton
                        size={'small'}
                        sx={{
                            borderRadius: '5px',
                            marginRight: '5px',
                            border: '1px solid #E6E4E6',
                        }}
                        ref={kpiMenuRef}
                        onClick={() => setCreateKpiMenuOpen(true)}
                    >
                        <img src={PlusBlack} alt={'menu'} />
                    </IconButton>
                </Box>
                <List
                    sx={{
                        flex: 1,
                        maxWidth: 400,
                        textAlign: 'left',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100% - 50px)',
                    }}
                >
                    {isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {Array.from({ length: 6 }, (_, i) => (
                                <Skeleton variant={'rounded'} key={i} width={'100%'} height={25} />
                            ))}
                        </Box>
                    ) : workspaceMetadata && kpis && kpis.length > 0 ? (
                        kpis.map((kpi) => {
                            if (
                                !config.drillAttribute ||
                                !chartState.allowedKPIs ||
                                chartState.allowedKPIs.indexOf(kpi.metricName) >= 0
                            ) {
                                return (
                                    <ColumnTreeItem
                                        server={server}
                                        chartState={chartState}
                                        kpi={kpi}
                                        key={kpi.metricName}
                                        config={config}
                                        setConfig={setConfig}
                                        setSelectedColumn={setSelectedColumn}
                                        setSelectedColumnDate={setSelectedColumnDate}
                                        setSelectedMetric={handleOpenMetric}
                                        workspaceMetadata={workspaceMetadata}
                                        getWorkspaceMetadata={getWorkspaceMetadata}
                                        newItems={newItems}
                                        setDeleteKpi={setDeleteKpi}
                                    />
                                )
                            }
                            return null
                        })
                    ) : null}
                </List>
            </Box>
            <Divider />
            <Box
                sx={{
                    height: `calc(${config.worksheetID && config.view !== 'table' ? '30%' : '50%'} - 50px)`,
                }}
            >
                <Box className={'drawer-section-header'}>
                    <span style={{ fontWeight: 600 }}>
                        {config.view !== 'table' ? 'Source Metrics' : 'Data Sources'}
                    </span>
                </Box>
                <TreeView
                    expanded={expanded}
                    defaultExpanded={defaultExpanded}
                    onNodeToggle={handleToggle}
                    defaultCollapseIcon={
                        <ArrowDropDownRoundedIcon
                            fontSize={'large'}
                            sx={{ fontSize: '24px !important' }}
                        />
                    }
                    defaultExpandIcon={
                        <ArrowRightRoundedIcon
                            fontSize={'large'}
                            sx={{ fontSize: '24px !important' }}
                        />
                    }
                    sx={{
                        flex: 1,
                        maxWidth: 400,
                        textAlign: 'left',
                        overflow: 'auto',
                        height: 'calc(100% - 50px)',
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTreeItem-content.Mui-selected': {
                            backgroundColor: 'transparent !important',
                        },
                        '& .MuiTreeItem-content': {
                            ':hover': {
                                backgroundColor: 'transparent !important',
                            },
                        },
                        '& .MuiTreeItem-label': {
                            ':hover': {
                                backgroundColor: 'transparent !important',
                            },
                        },
                    }}
                >
                    {isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {Array.from({ length: 6 }, (_, i) => (
                                <Skeleton variant={'rounded'} key={i} width={'100%'} height={25} />
                            ))}
                        </Box>
                    ) : (
                        workspaceMetadata != null &&
                        workspaceMetadata.inboxes?.map((inbox) => {
                            if (hasTablesWithMeasures(inbox)) {
                                return (
                                    <TreeItem
                                        nodeId={inbox.inboxName}
                                        key={inbox.inboxName}
                                        label={inbox.label}
                                        sx={{
                                            '& .MuiTreeItem-label': {
                                                fontSize: '14px',
                                            },
                                            padding: '8px',
                                            borderRadius: '6px',
                                            backgroundColor: expanded.includes(inbox.inboxName)
                                                ? '#F9F9F9'
                                                : 'initial',
                                            ':hover': {
                                                backgroundColor: '#F9F9F9',
                                            },
                                        }}
                                    >
                                        {config.view === 'table' &&
                                            (getAvailableTables(inbox).length > 0 ? (
                                                getAvailableTables(inbox).map((table, i) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                            }}
                                                            key={table.tableName + i}
                                                        >
                                                            <Checkbox
                                                                checked={config.selectedTables.includes(
                                                                    table.reportSeriesTableID
                                                                )}
                                                                onClick={() =>
                                                                    handleTableSelection(table)
                                                                }
                                                                size={'medium'}
                                                            />
                                                            <ListItemText
                                                                primary={table.tableName}
                                                                sx={{
                                                                    ml: 1,
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                })
                                            ) : (
                                                <Typography
                                                    className={'inter'}
                                                    sx={{ fontSize: '12px' }}
                                                >
                                                    No tables available
                                                </Typography>
                                            ))}
                                        {(config.view === 'chart' || config.view === 'kpi') &&
                                            (getAvailableTables(inbox).length > 0 ? (
                                                getAvailableTables(inbox).map((table) => {
                                                    if (hasMeasures(table)) {
                                                        return (
                                                            <TreeItem
                                                                nodeId={table.reportSeriesTableID}
                                                                key={table.reportSeriesTableID}
                                                                label={table.tableName}
                                                                sx={{
                                                                    padding: '8px',
                                                                    borderRadius: '6px',
                                                                    backgroundColor:
                                                                        expanded.includes(
                                                                            table.reportSeriesTableID
                                                                        )
                                                                            ? '#F9F9F9'
                                                                            : 'initial',
                                                                    ':hover': {
                                                                        backgroundColor: '#F9F9F9',
                                                                    },
                                                                }}
                                                            >
                                                                {table.columns?.map((column) => (
                                                                    <ColumnTreeItem
                                                                        server={server}
                                                                        chartState={chartState}
                                                                        table={table}
                                                                        column={column}
                                                                        key={
                                                                            table.reportSeriesTableID +
                                                                            ':' +
                                                                            column.columnName
                                                                        }
                                                                        config={config}
                                                                        setConfig={setConfig}
                                                                        setSelectedColumn={
                                                                            setSelectedColumn
                                                                        }
                                                                        setSelectedColumnDate={
                                                                            setSelectedColumnDate
                                                                        }
                                                                        setSelectedMetric={
                                                                            setSelectedMetric
                                                                        }
                                                                        workspaceMetadata={
                                                                            workspaceMetadata
                                                                        }
                                                                        getWorkspaceMetadata={
                                                                            getWorkspaceMetadata
                                                                        }
                                                                        newItems={newItems}
                                                                        noEdit
                                                                    />
                                                                ))}
                                                            </TreeItem>
                                                        )
                                                    }
                                                })
                                            ) : (
                                                <Typography
                                                    className={'inter'}
                                                    sx={{ fontSize: '12px' }}
                                                >
                                                    No tables available
                                                </Typography>
                                            ))}
                                    </TreeItem>
                                )
                            }
                        })
                    )}
                </TreeView>
            </Box>
            <Divider />
            {config.worksheetID && config.view !== 'table' && (
                <>
                    <Box sx={{ height: '40%' }}>
                        <Box className={'drawer-section-header'}>
                            <span style={{ fontWeight: 600 }}>Worksheet metrics</span>
                        </Box>
                        <Box
                            sx={{
                                overflow: 'auto',
                                height: 'calc(100% - 34px)',
                            }}
                        >
                            {worksheetMetrics.map((col, i) => {
                                const selected = config.selectedItems.some(
                                    (it) => it.measureName === col.columnName
                                )
                                return (
                                    <WorksheetMetric
                                        chartState={chartState}
                                        setConfig={setConfig}
                                        config={config}
                                        key={col.columnName + '-' + i}
                                        metricCol={col}
                                        handleWorksheetMetricSelect={handleWorksheetMetricSelect}
                                        selected={selected}
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                    <Divider />
                </>
            )}
            <Box sx={{ height: 100 }}>
                <Box className={'drawer-section-header'}>
                    <span style={{ fontWeight: 600 }}>Worksheets</span>
                </Box>
                {config.worksheetID ? (
                    <Typography className={'inter'} sx={{ mt: '5px', fontSize: '16px' }}>
                        {config.selectedWorksheetName}
                    </Typography>
                ) : (
                    <Button
                        className={'button-grey filter-button'}
                        onClick={() => setSheetSourceOpen(true)}
                    >
                        Create from worksheet
                    </Button>
                )}
            </Box>
            <Menu
                open={itemOptionsMenuOpen}
                anchorEl={currentItemRef.current}
                onClose={() => setItemOptionsMenuOpen(false)}
            >
                <MenuItem onClick={editItem}>Edit</MenuItem>
                <MenuItem onClick={deleteItem}>Delete</MenuItem>
            </Menu>
            <Menu
                open={createKpiMenuOpen}
                anchorEl={kpiMenuRef.current}
                onClose={() => setCreateKpiMenuOpen(false)}
            >
                <MenuItem onClick={() => handleCreateKpi(false)}>
                    <Typography className={'inter'}>
                        Add a KPI aggregating Scoop table data
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateKpi(true)}>
                    <Typography className={'inter'}>
                        Add a KPI calculated using other KPIs
                    </Typography>
                </MenuItem>
            </Menu>
            <NewMetricDialog
                open={metricDialogOpen}
                setOpen={setMetricDialogOpen}
                derived={derived}
                editMetric={selectedMetric}
                setEditMetric={setSelectedMetric}
                deleteMetric={deleteKpi}
                setDeleteMetric={setDeleteKpi}
            />
            <SheetSourceDialog
                open={sheetSourceOpen}
                setOpen={setSheetSourceOpen}
                config={config}
                setConfig={setConfig}
                chartState={chartState}
                workspaceMetadata={workspaceMetadata}
                workspaceID={workspaceID}
                setWorksheetMetrics={setWorksheetMetrics}
                server={server}
            />
        </>
    )
}
