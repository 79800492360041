import React, { useEffect, useState } from 'react'
import Input from '@mui/material/Input'
import { useDispatch } from 'react-redux'
import {
    updateWorksheetPosition,
    updateWorksheetSize,
    updateWorksheetTitle,
} from '../../../store/actions'
import WorksheetEdit from './WorksheetEdit'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'

const Worksheet = ({ id, title, initialPosition, initialSize, url, multiProps }) => {
    const ariaLabel = { 'aria-label': 'description' }
    const dispatch = useDispatch()
    const [inputTitle, setInputTitle] = useState(title) // Local state for the input title
    const [isEditMode, setIsEditMode] = useState(false) // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value)
        dispatch(updateWorksheetTitle(id, e.target.value)) // Dispatch the UPDATE_Worksheet_TITLE event
    }

    const updateWorksheetPos = (position) => {
        dispatch(updateWorksheetPosition(id, position))
    }

    const updateWorksheetSz = (size) => {
        dispatch(updateWorksheetSize(id, size))
    }

    return (
        <ElementWrapper
            id={`Worksheet-${id}`}
            key={`Worksheet-${id}`}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialSize={initialSize}
            initialPosition={initialPosition}
            updateElementSize={updateWorksheetSz}
            updateElementPosition={updateWorksheetPos}
            {...multiProps}
        >
            {isEditMode && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <WorksheetEdit
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        title={title}
                        id={id}
                    />
                </div>
            )}
            <div
                style={{
                    height: '20px',
                    width: '100%',
                }}
            >
                <Input
                    placeholder={title}
                    inputProps={ariaLabel}
                    margin="dense"
                    value={inputTitle}
                    onChange={handleTitleChange}
                />
            </div>
            <div style={{ height: `calc(100% - 20px)`, width: '100%' }}>
                <iframe src={url} width="100%" height="100%" frameBorder="0" />
            </div>
        </ElementWrapper>
    )
}

export default Worksheet
