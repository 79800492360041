import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

function EmailPreviewModal({ rawEmail, open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
            </IconButton>
            <DialogContent maxWidth="lg" fullWidth dividers style={{ overflow: 'auto' }}>
                <div dangerouslySetInnerHTML={{ __html: rawEmail }} />
            </DialogContent>
        </Dialog>
    )
}

export default EmailPreviewModal
