import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Server } from '../../../api/Server'
import { useApi } from '../../../api/api'
import { Toast } from './../../common/Toast/Toast'

import {
    Box,
    Button,
    TextField,
    IconButton,
    CircularProgress,
    MenuItem,
    Typography,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

export const ScoopAdmin = () => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const workspaceName = useSelector((state) => state.auth.workspaceName)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const [accountID, setAccountID] = useState(null)
    const [forceExit, setForceExit] = useState(false)
    const { postData: postDataWorkspaceActions } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions'
    )

    const [targetUserID, setTargetUserID] = useState(null)
    const [targetUserName, setTargetUserName] = useState(null)

    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [userOptions, setUserOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allWorkspaces, setAllWorkspaces] = useState([])
    const [selectedWorkspaceID, setSelectedWorkspaceID] = useState('')
    const [userRole, setUserRole] = useState('Member')
    const [workspacesForThisUser, setWorkspacesForThisUser] = useState([])
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [newWorkspaceName, setNewWorkspaceName] = useState('')

    const [server, setServer] = useState(new Server(workspaceID, userID, token))

    const [alert, setAlert] = useState(false)

    const loggedUserInfo = (data) => {
        try {
            setAccountID(data.objects[0].accountID)
            if (data.objects[0].accountID !== 'A1') {
                console.log('data.objects[0].accountID: ', data.objects[0].accountID)
                setForceExit(true)
            }
        } catch (e) {
            setForceExit(true)
        }
    }

    useEffect(() => {
        if (forceExit) {
            setAlert({
                message: `You are not authorized to access this page!`,
                severity: 'error',
            })
            window.location.href = window.location.origin
        }
    }, [forceExit])

    function getLoggedUserInfo(server) {
        if (server.token && !accountID) {
            server.postData(
                {
                    action: 'getObjects',
                    attributeName: 'userID',
                    attributeValue: userID,
                    class: 'scoop.user.User',
                },
                loggedUserInfo
            )
        }
    }

    useEffect(() => {
        // When userID or token changes, update the server instance
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer)
        getLoggedUserInfo(newServer)
    }, [userID, token, workspaceID])

    const dispatch = useDispatch()

    const handleLogInAsUser = () => {
        localStorage.setItem('userID', targetUserID)
        localStorage.setItem('userName', targetUserName)
        localStorage.setItem('userEmail', targetUserName)

        localStorage.setItem(
            'CognitoIdentityServiceProvider.76atr62rn4iuk8uhj8r8gt39ms.LastAuthUser',
            targetUserID
        )
        localStorage.setItem('workspaceID', '')
        localStorage.setItem('workspaceName', '')

        setAlert({
            message: `Logged in as ${targetUserName}`,
            severity: 'info',
        })

        window.location.href = window.location.origin
    }

    const loadAllUsers = async (server) => {
        return new Promise((resolve, reject) => {
            server.postData(
                {
                    action: 'getObjects',
                    class: 'scoop.user.User',
                },
                (result) => {
                    if (result && result.objects) {
                        resolve(result.objects)
                    } else {
                        reject(new Error('Failed to load users'))
                    }
                }
            )
        })
    }

    const loadAllWorkspaces = async (server) => {
        return new Promise((resolve, reject) => {
            server.postData(
                {
                    action: 'getObjects',
                    class: 'scoop.workspace.Workspace',
                },
                (result) => {
                    if (result && result.objects) {
                        resolve(result.objects)
                    } else {
                        reject(new Error('Failed to load workspaces'))
                    }
                }
            )
        })
    }

    const loadWorkspacesForUser = async (server) => {
        return new Promise((resolve, reject) => {
            server.postData(
                {
                    action: 'getObjects',
                    attributeName: 'userID',
                    class: 'scoop.workspace.Workspace',
                    attributeValue: targetUserID,
                    relatedClass: 'scoop.workspace.WorkspaceUser',
                    userID: targetUserID,
                },
                (result) => {
                    if (result && result.objects) {
                        resolve(result.objects)
                    } else {
                        reject(new Error('Failed to load workspaces for user'))
                    }
                }
            )
        })
    }

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
        if (newInputValue.length >= 3) {
            const filteredOptions = allUsers.filter((user) => user.userName.includes(newInputValue))
            setUserOptions(filteredOptions)
        } else {
            setUserOptions(allUsers)
        }
    }

    const handleRefresh = async () => {
        setLoading(true)
        try {
            const users = await loadAllUsers(server)
            setAllUsers(users)
            setUserOptions(users)
            setAutocompleteOpen(true) // Auto-open the user selection
            const workspaces = await loadAllWorkspaces(server)
            const sortedWorkspaces = workspaces.sort((a, b) => a.name.localeCompare(b.name))
            setAllWorkspaces(sortedWorkspaces)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        const fetchWorkspacesForUser = async () => {
            const workspacesForUser = await loadWorkspacesForUser(server)
            console.log('workspacesForUser: ', workspacesForUser)
            setWorkspacesForThisUser(workspacesForUser)
        }

        if (targetUserID) {
            fetchWorkspacesForUser()
        }
    }, [targetUserID])

    const addUserToSpaceAPI = async (server) => {
        return new Promise((resolve, reject) => {
            server.postData(
                {
                    action: 'putObject',
                    class: 'scoop.workspace.WorkspaceUser',
                    value: {
                        workspaceID: selectedWorkspaceID,
                        userID: targetUserID,
                        role: userRole,
                    },
                },
                (result) => {
                    console.log('result: ', result)
                    resolve(result)
                }
            )
        })
    }

    const removeUserFromSpaceAPI = async (server, workspaceID) => {
        return new Promise((resolve, reject) => {
            server.postData(
                {
                    action: 'deleteObject',
                    class: 'scoop.workspace.WorkspaceUser',
                    keyParts: [workspaceID, targetUserID],
                },
                (result) => {
                    console.log('result: ', result)
                    resolve(result)
                }
            )
        })
    }

    const addUserToWorkspace = async (workspaceID) => {
        console.log(`Adding user to workspace: ${workspaceID}`)
        try {
            await addUserToSpaceAPI(server)
            // Find the workspace in allWorkspaces and add it to workspacesForThisUser
            const workspace = allWorkspaces.find((ws) => ws.workspaceID === workspaceID)
            if (workspace) {
                setWorkspacesForThisUser((prevWorkspaces) => [...prevWorkspaces, workspace])
                console.log('Updated workspacesForThisUser: ', workspacesForThisUser)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const createNewWorkspaceAPI = async (newWorkspaceName, userName, userID) => {
        const payload = {
            action: 'createWorkspace',
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
            workspaceName: newWorkspaceName,
            userName: targetUserName,
            userID: targetUserID,
        }

        console.log('payload: ', payload)
        return postDataWorkspaceActions(payload)
    }

    const createNewWorkspace = async (newWorkspaceName, userName, userID) => {
        console.log(`Creating new workspace: ${newWorkspaceName}`)
        setAlert({
            message: `Stay put, creating new workspace ${newWorkspaceName}`,
            severity: 'info',
        })
        try {
            // Make sure to pass all necessary parameters.
            const response = await createNewWorkspaceAPI(newWorkspaceName, userName, userID)
            console.log('Workspace created successfully:', response)
            setAlert({
                message: `Workspace ${newWorkspaceName} created successfully as: ${response.workspaceID}`,
                severity: 'success',
            })
            handleRefresh()
        } catch (error) {
            console.error('Failed to create workspace:', error)
        }
    }

    const removeUserWorkspace = async (workspaceID) => {
        console.log(`Removing user from workspace: ${workspaceID}`)
        try {
            await removeUserFromSpaceAPI(server, workspaceID)
            // Remove the workspace from workspacesForThisUser
            setWorkspacesForThisUser((prevWorkspaces) => {
                const updatedWorkspaces = prevWorkspaces.filter(
                    (ws) => ws.workspaceID !== workspaceID
                )
                console.log('Updated workspacesForThisUser: ', updatedWorkspaces)
                return updatedWorkspaces
            })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box alignItems="center" style={{ marginTop: 100, marginLeft: 100, marginRight: 100 }}>
            <Typography variant="h4" marginBottom={2}>
                Scoop Admin
            </Typography>
            {targetUserID && (
                <Box marginTop={2} marginBottom={3}>
                    <Button variant="contained" color="secondary" onClick={handleLogInAsUser}>
                        Login as {targetUserName}
                    </Button>
                </Box>
            )}
            <Autocomplete
                freeSolo
                open={autocompleteOpen}
                onOpen={() => setAutocompleteOpen(true)}
                onClose={() => setAutocompleteOpen(false)}
                options={userOptions}
                getOptionLabel={(option) => option.userName}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setTargetUserID(newValue.userID)
                        setTargetUserName(newValue.userName)
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Email" variant="outlined" marginBottom={2} />
                )}
                renderOption={(props, option) => (
                    <MenuItem {...props} key={option.userID} value={option.userName}>
                        {option.userName}
                    </MenuItem>
                )}
            />
            <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography>Load all users</Typography>
                <IconButton onClick={handleRefresh} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
                </IconButton>
            </Box>
            {targetUserID && (
                <Box marginTop={2}>
                    <Typography variant="h8" marginTop={2}>
                        {targetUserName} last logged in: 1/1/1970 (Gabe to fix this)
                    </Typography>
                </Box>
            )}

            {targetUserID && (
                <>
                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Workspaces <u>{targetUserName}</u> has access to
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Workspace ID</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workspacesForThisUser.map((workspace) => (
                                    <TableRow key={workspace.workspaceID}>
                                        <TableCell>{workspace.name}</TableCell>
                                        <TableCell>{workspace.workspaceID}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    removeUserWorkspace(workspace.workspaceID)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box marginTop={4}>
                        <Typography variant="h6">Give access to workspace</Typography>
                        <Box display="flex" alignItems="center" marginBottom={2}>
                            <FormControl
                                variant="outlined"
                                margin="normal"
                                style={{ marginRight: 10, minWidth: 120 }}
                            >
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    value={userRole}
                                    onChange={(e) => setUserRole(e.target.value)}
                                    label="Role"
                                >
                                    <MenuItem value="Owner">Owner</MenuItem>
                                    <MenuItem value="Admin">Admin</MenuItem>
                                    <MenuItem value="Member">Member</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel id="workspace-select-label">Workspace</InputLabel>
                                <Select
                                    labelId="workspace-select-label"
                                    value={selectedWorkspaceID}
                                    onChange={(e) => setSelectedWorkspaceID(e.target.value)}
                                    label="Workspace"
                                >
                                    {allWorkspaces
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((workspace) => (
                                            <MenuItem
                                                key={workspace.workspaceID}
                                                value={workspace.workspaceID}
                                            >
                                                {workspace.name} ({workspace.workspaceID})
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => addUserToWorkspace(selectedWorkspaceID)}
                        >
                            Give {targetUserName} access to {selectedWorkspaceID}
                        </Button>
                    </Box>

                    <Box marginTop={4}>
                        <Typography variant="h6">Create New Workspace</Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginBottom={2}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="New Workspace Name"
                                value={newWorkspaceName}
                                onChange={(e) => setNewWorkspaceName(e.target.value)}
                                placeholder="Enter workspace name"
                            />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => createNewWorkspace(newWorkspaceName)}
                            disabled={!newWorkspaceName}
                        >
                            Create New Workspace
                        </Button>
                    </Box>
                </>
            )}
            {alert && <Toast alert={alert} onClose={() => setAlert(false)} />}
        </Box>
    )
}
