// ProcessDiagramEdit.js
import React, { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Divider from '@mui/material/Divider'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useDispatch } from 'react-redux'
import {
    deleteProcessDiagram,
    editProcessDiagram,
    updateProcessDiagramContent,
} from '../../../store/actions'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import LoadDiagram from '../../screens/Process/LoadDiagram/LoadDiagram'
import { ConstructionOutlined, KeyboardReturnOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { loadFromSavedProcessDiagram, saveProcessDiagram } from './ProcessDiagramAPI'
import { useApi } from '../../../api/api'
import { map } from 'lodash'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import SaveChangesDialog from '../../common/Dialog/SaveChangesDialog'
import { Process } from '../../screens/Process/Process'
import { ROUTES } from '../../../router/routes'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setShowEditElementWrapper } from '../../../store/actions/uiActions'
import PaletteIcon from '@mui/icons-material/Palette'

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}))

const modalBoxStyle = {
    position: 'absolute',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '95vh',
    backgroundColor: '#27142c',
    boxShadow: 24,
}

export default function ProcessDiagramEdit({
    setIsMenuOpen,
    isProcessDiagramASkeleton,
    setIsProcessDiagramASkeleton,
    server,
    title,
    id,
    processDiagram,
    handleTitleChange,
    editProcessDiagramOpen,
    setEditProcessDiagramOpen,
    workspaceMetadata,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const menuRef = React.useRef()
    const buttonRef = React.useRef()
    const [isAlertOpen, setIsAlertOpen] = React.useState(false)

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [loadDiagramModal, setLoadDiagramModal] = useState(false)
    // Get auth details from the state
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const workspaceID = useSelector((state) => state.auth.workspaceID)

    const { postData } = useApi() // Use the useApi hook here

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setIsMenuOpen(true)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        const actionType = event.actionType
        if (actionType === 'delete') {
            setIsAlertOpen(true)
        } else if (actionType === 'edit') {
            navigate(ROUTES.INSIGHTS, {
                state: {
                    editDiagram: { processDiagram: processDiagram },
                    workspaceMetadata,
                },
            })
        } else if (actionType === 'load') {
            setLoadDiagramModal(true)
        }
        setIsMenuOpen(false)
        setAnchorEl(null)
    }

    const handleDelete = () => {
        dispatch(deleteProcessDiagram(id))
        setIsMenuOpen(false)
        setIsAlertOpen(false)
        setAnchorEl(null)
    }

    const handleEdit = () => {}

    const onCloseLoadDiagramModal = () => {
        setLoadDiagramModal(false)
    }

    const handleLoadFromSavedProcessDiagram = (processDiagram) => {
        if (processDiagram) {
            handleTitleChange({
                target: { value: processDiagram.worksheetName },
            })
            loadFromSavedProcessDiagram(
                processDiagram,
                setIsProcessDiagramASkeleton,
                dispatch,
                id,
                workspaceID
            )
        }
        setIsMenuOpen(false)
        setLoadDiagramModal(false)
    }

    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize="medium" color="info" />
                </IconButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {!isProcessDiagramASkeleton && (
                        <MenuItem
                            onClick={(event) => {
                                event.actionType = 'edit'
                                handleClose(event)
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Edit
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => dispatch(setShowEditElementWrapper([true, id]))}
                        disableRipple
                    >
                        <PaletteIcon />
                        Style
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'load'
                            handleClose(event)
                        }}
                        disableRipple
                    >
                        <FileOpenIcon />
                        Load
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'delete'
                            handleClose(event)
                        }}
                        disableRipple
                    >
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </StyledMenu>
            </div>
            {isAlertOpen && (
                <DeleteDialog
                    open={isAlertOpen}
                    title={title}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    type={'ProcessDiagram'}
                    handleDelete={handleDelete}
                    handleCancel={() => setIsAlertOpen(false)}
                />
            )}
            {/* <SaveChangesDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                handleSave={() => {
                    saveChanges(); // Save changes
                    setConfirmDialogOpen(false);
                    setEditProcessDiagramOpen(false); // Close the Modal after saving changes
                    setAnchorEl(null)
                }}
                handleDontSave={() => {
                    setConfirmDialogOpen(false);
                    setEditProcessDiagramOpen(false); // Close the Modal without saving changes
                    setAnchorEl(null)
                }}
            />*/}

            <Modal
                open={editProcessDiagramOpen}
                onClose={() => setConfirmDialogOpen(true)} // Set the confirmation dialog to open when the Modal is closed
            >
                <Box sx={modalBoxStyle}>
                    <Process workspaceID={workspaceID} userID={userID} token={token} />
                </Box>
            </Modal>
            <LoadDiagram
                setAnchor={setAnchorEl}
                isOpen={loadDiagramModal}
                closeModal={onCloseLoadDiagramModal}
                loadDiagram={handleLoadFromSavedProcessDiagram}
            />
        </>
    )
}
