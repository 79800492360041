import { MenuItem } from '@mui/material'
import * as React from 'react'
import Selector from '../common/Selector/Selector'

export function TimeRangeControl({
    config,
    setConfig,
    chartProperties,
    setTimeRangeDialogOpen,
    controlHeight,
}) {
    return (
        <Selector
            value={config.timeRange}
            onChange={(event) => {
                if (event.target.value === 'Custom') {
                    setTimeRangeDialogOpen(true)
                } else {
                    if (
                        config.selectedItems.length === 0 &&
                        config.selectedTableColumns.length === 0
                    ) {
                        chartProperties.series = null
                    } else {
                        config.timeRange = event.target.value
                        if (config.view === 'chart') chartProperties.getResults(config)
                        setConfig({ ...config })
                    }
                }
            }}
            label="Time Range"
            labelClassName={'selector-label-bold'}
            sx={{ height: controlHeight }}
        >
            <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
            <MenuItem value={'Last Day'}>Last Day</MenuItem>
            <MenuItem value={'Last Week'}>Last Week</MenuItem>
            <MenuItem value={'Last Month'}>Last Month</MenuItem>
            <MenuItem value={'Last Quarter'}>Last Quarter</MenuItem>
            <MenuItem value={'Last Year'}>Last Year</MenuItem>
            <MenuItem value={'Next Day'}>Next Day</MenuItem>
            <MenuItem value={'Next Week'}>Next Week</MenuItem>
            <MenuItem value={'Next Month'}>Next Month</MenuItem>
            <MenuItem value={'Next Quarter'}>Next Quarter</MenuItem>
            <MenuItem value={'Next Year'}>Next Year</MenuItem>
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Custom'}>Custom</MenuItem>
        </Selector>
    )
}
