import React, { useState } from 'react'
import './GenericShape.css'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'
import { updateGenericShapePosition, updateGenericShapeSize } from '../../../store/actions'
import { useDispatch } from 'react-redux'

export const GenericShapeElement = ({
    id,
    initialPosition,
    initialSize,
    content,
    shapeType,
    setShowMenu,
    multiProps,
}) => {
    const shapeId = `GenericShapeElement-${id}`
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState(false)

    const toggleEditMode = (value) => {
        if (value) setShowMenu(true)
        else setShowMenu(false)
        setIsEditMode(value)
    }

    const getContent = () => {
        if (content && content.includes('<img')) {
            let img = (
                content.split('/>')[0].slice(0, -1) + '?withHeaders=yes" crossOrigin="anonymous" />'
            ).replace(
                'https://scoop-image-uploads.s3.us-west-2.amazonaws.com',
                'https://d3lkv74tdz6isd.cloudfront.net'
            )
            img += content
                .substring(content.indexOf('/>') + 2)
                .replace(
                    'https://scoop-image-uploads.s3.us-west-2.amazonaws.com',
                    'https://d3lkv74tdz6isd.cloudfront.net'
                )
            return img
        }
        return content
    }

    return (
        <ElementWrapper
            id={shapeId}
            editMode={isEditMode}
            toggleEditMode={toggleEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementPosition={(position) => dispatch(updateGenericShapePosition(id, position))}
            updateElementSize={(size) => dispatch(updateGenericShapeSize(id, size))}
            type={'GenericShape'}
            {...multiProps}
        >
            <div
                className={'generic-content-container'}
                dangerouslySetInnerHTML={{ __html: getContent() }}
                style={{
                    display:
                        shapeType === 'line' || shapeType === 'straightConnector1' ? 'grid' : '',
                    placeContent:
                        shapeType === 'line' || shapeType === 'straightConnector1' ? 'start' : '',
                }}
            />
        </ElementWrapper>
    )
}
