import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../Explorer.css'
import {
    Box,
    Divider,
    FormControl,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { TimeRangeControl } from '../../../Insights/TimeRangeControl'
import ChartBarPink from '../../../../assets/icons/ChartBarPink.svg'
import ChartLineUpPink from '../../../../assets/icons/ChartLineUpPink.svg'
import TablePink from '../../../../assets/icons/TablePink.svg'
import Selector from '../../../common/Selector/Selector'
import { InfoTooltip } from '../../../common/InfoTooltop/InfoTooltip'
import { Switch } from '../../../common/Switch/Switch'
import IconButton from '@mui/material/IconButton'
import ResetArrow from '../../../../assets/icons/ArrowCounterClockwise.svg'
import _, { debounce, cloneDeep } from 'lodash'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import Checkbox from '../../../common/Checkbox/Checkbox'
import { SORTING, SORTING_OPTIONS } from '../../../Insights/consts'
import Button from '../../../common/Button/Button'
import { useInsight } from '../../../../api/hooks/insight/useInsight'

export const ConfigTab = ({
    config,
    setConfig,
    chartState,
    advanced,
    setAdvanced,
    analyzeChanges,
    setAnalyzeChanges,
    columnLoading,
    setColumnLoading,
    dateFlag,
    setDateFlag,
    setTimeRangeDialogOpen,
    workspaceMetadata,
}) => {
    const controlHeight = 40
    const [availableColumns, setAvailableColumns] = useState([])
    const [chartTitle, setChartTitle] = useState(config.chartTitle)
    const [kpiCompareTarget, setKpiCompareTarget] = useState(config.kpiCompareTarget)
    const dateMenuRef = useRef()
    const [currentColumn, setCurrentColumn] = useState(null)
    const [currentTable, setCurrentTable] = useState(null)
    const [colMenuOpen, setColMenuOpen] = useState(false)

    const debouncedFunc = useCallback(
        debounce((value) => {
            const newConfig = { ...config }
            newConfig.chartTitle = value
            setConfig(newConfig)
            chartState.setChartStateConfig(newConfig)
        }, 500),
        [config, chartState]
    )
    const targetDebouncedFunc = useCallback(
        debounce((value) => {
            const newConfig = { ...config }
            newConfig.kpiCompareTarget = value
            setConfig(newConfig)
        }, 500),
        [config, chartState]
    )
    const buildSortingColumnOptions = useMemo(() => {
        if (!Array.isArray(chartState.series)) return []
        return chartState.series.reduce((acc, s) => {
            if (!['radial', 'gauge', 'donut', 'pie'].includes(s.type)) {
                acc.push(s.name)
            }
            return acc
        }, [])
    }, [chartState.series])

    useEffect(() => {
        if (workspaceMetadata && workspaceMetadata.inboxes) {
            const tables = []
            workspaceMetadata?.inboxes?.forEach((inbox) => {
                inbox.tables.forEach((table) => {
                    if (config.selectedTables.includes(table.reportSeriesTableID))
                        tables.push(table)
                })
            })
            const columns = tables.map((table) =>
                table.columns.map((col) => ({
                    ...col,
                    reportSeriesTableID: table.reportSeriesTableID,
                    tableName: table.tableName,
                }))
            )
            columns.forEach((tableCols) =>
                tableCols.push({
                    columnName: 'Count',
                    reportSeriesTableID: tableCols[0].reportSeriesTableID,
                    isMeasure: true,
                })
            )
            const datasetMeasures = []
            const datasetAttributes = []
            if (columns.length > 0) {
                columns.forEach((tableColumns) => {
                    tableColumns.forEach((col) => {
                        if (col.isMeasure) {
                            if (
                                col.columnName === 'Count' &&
                                !datasetMeasures.some((c) => c.columnName === 'Count')
                            )
                                datasetMeasures.push(col)
                            else datasetMeasures.push(col)
                        }
                        if (!col.isMeasure) datasetAttributes.push(col)
                    })
                })
            }
            const worksheetMeasures = config.worksheetColumns
                .filter((c) => c.isMeasure)
                .map((c) => ({
                    ...c,
                    worksheetID: config.worksheetID,
                    rangeName: config.rangeName,
                }))
            const worksheetAttributes = config.worksheetColumns.filter((c) => !c.isMeasure)
            const mixCols = [datasetAttributes, worksheetAttributes]
            setAvailableColumns([
                ...worksheetMeasures,
                ...datasetMeasures,
                ..._.intersectionBy(...mixCols.filter((a) => a.length !== 0), 'columnName'),
            ])
        }
    }, [workspaceMetadata, config.selectedTables.length, config.worksheetColumns.length])

    useEffect(() => {
        if (!kpiCompareTarget) setKpiCompareTarget(config.kpiCompareTarget)
    }, [config.kpiCompareTarget])

    const handleViewChange = (e) => {
        const value = e.target.value
        const newConfig = { ...config }
        if (value === 'table') {
            const selectedTables = []
            const selectedTableColumns = []
            const selectedTableKpis = []
            newConfig.selectedItems.forEach((item) => {
                if (
                    item.worksheetID &&
                    !selectedTableColumns.some((col) => col.measureName === item.measureName)
                ) {
                    const worksheetMetric = config.worksheetColumns.find(
                        (c) =>
                            c.columnName === item.measureName && c.worksheetID === item.worksheetID
                    )
                    const byDate = config.worksheetColumns.find((c) => c.columnType === 'DateTime')
                    if (byDate) worksheetMetric.byDate = byDate
                    if (worksheetMetric) selectedTableColumns.push(worksheetMetric)
                }
                if (item.reportSeriesTableID && !selectedTables.includes(item.reportSeriesTableID))
                    selectedTables.push(item.reportSeriesTableID)
                if (
                    item.columnName &&
                    !selectedTableColumns.some((col) => col.columnName === item.columnName)
                ) {
                    workspaceMetadata?.inboxes?.forEach((inbox) => {
                        inbox.tables.forEach((table) => {
                            table.columns.forEach((col) => {
                                if (
                                    col.columnName === item.columnName &&
                                    item.reportSeriesTableID === table.reportSeriesTableID
                                ) {
                                    selectedTableColumns.push({
                                        ...col,
                                        reportSeriesTableID: table.reportSeriesTableID,
                                    })
                                }
                            })
                        })
                    })
                }
                if (item.kpi && !selectedTableKpis.some((kpi) => kpi.metricName === item.kpi)) {
                    workspaceMetadata.kpis.forEach((kpi) => {
                        if (kpi.metricName === item.kpi) {
                            selectedTableKpis.push(kpi)
                        }
                    })
                }
            })
            newConfig.selectedTableColumns = selectedTableColumns
            newConfig.selectedTableKpis = selectedTableKpis
            newConfig.selectedTables = selectedTables
        } else if (value === 'chart') {
            if (newConfig.view === 'table') {
                let selectedItems = []
                let kpis = []
                let measures = []
                kpis = newConfig.selectedTableKpis
                    .map((k) => ({
                        kpi: k.metricName,
                        reportSeriesTableID: k.reportSeriesTableID,
                    }))
                    .slice(0, 2)
                measures = newConfig.selectedTableColumns
                    .filter((col) => col.isMeasure)
                    .map((item) => {
                        if (item.worksheetID) {
                            return {
                                measureName: item.columnName,
                                worksheetID: item.worksheetID,
                                rangeName: item.rangeName,
                            }
                        } else
                            return {
                                columnName: item.columnName,
                                reportSeriesTableID: item.reportSeriesTableID,
                            }
                    })
                    .slice(0, 2)
                selectedItems = [...kpis, ...measures]
                newConfig.selectedItems = selectedItems.slice(0, 2)
            }
        } else {
            if (newConfig.view === 'table') {
                let kpi = newConfig.selectedTableKpis.map((k) => ({
                    kpi: k.metricName,
                    reportSeriesTableID: k.reportSeriesTableID,
                }))[0]
                let measure = newConfig.selectedTableColumns
                    .filter((col) => col.isMeasure)
                    .map((item) => {
                        if (item.worksheetID) {
                            return {
                                measureName: item.columnName,
                                worksheetID: item.worksheetID,
                                rangeName: item.rangeName,
                            }
                        } else
                            return {
                                columnName: item.columnName,
                                reportSeriesTableID: item.reportSeriesTableID,
                            }
                    })[0]
                newConfig.selectedItems = kpi ? [kpi] : measure ? [measure] : []
            }
        }
        newConfig.view = value
        setConfig(newConfig)
        if (newConfig.selectedItems.length > 0 && value === 'chart') {
            chartState.getResults(newConfig)
        }
    }

    const handleColumnToggle = (col) => {
        const newConfig = { ...config }
        if (
            newConfig.selectedTableColumns.some(
                (c) =>
                    c.columnName === col.columnName &&
                    (col.reportSeriesTableID === c.reportSeriesTableID ||
                        col.worksheetID === c.worksheetID)
            )
        ) {
            if (newConfig.selectedTableColumns.length !== 1) setColumnLoading(true)
            newConfig.selectedTableColumns.splice(
                newConfig.selectedTableColumns.findIndex(
                    (c) =>
                        c.columnName === col.columnName &&
                        (col.reportSeriesTableID === c.reportSeriesTableID ||
                            col.worksheetID === c.worksheetID)
                ),
                1
            )
        } else {
            setColumnLoading(true)
            if (col.worksheetID) {
                const byDates = config.worksheetColumns.filter((c) => c.columnType === 'DateTime')
                if (byDates.length > 0)
                    newConfig.selectedTableColumns.push({
                        ...col,
                        byDate: byDates[0].columnName,
                    })
                else newConfig.selectedTableColumns.push(col)
            } else {
                newConfig.selectedTableColumns.push(col)
            }
        }
        setConfig(newConfig)
    }

    const handleSeriesTypeChange = (metricName, newSeriesType) => {
        const newConfig = { ...config }
        const oldCategoryAxis = config.categoryAxis
        if (['bar', 'pie', 'donut', 'pictorialBar', 'radialBar', 'gauge'].includes(newSeriesType)) {
            if (newConfig.categoryAxis === 'Time') {
                if (
                    Array.isArray(chartState.drillAttributes) &&
                    chartState.drillAttributes.length > 0
                )
                    newConfig.categoryAxis = chartState.drillAttributes[0]
                newConfig.timeRange = 'Most Recent'
            }
        }

        if (
            !metricName ||
            newSeriesType === 'scatter' ||
            newSeriesType === 'bar' ||
            newConfig.seriesType === 'scatter' ||
            newConfig.seriesType === 'bar' ||
            newSeriesType === 'waterfall'
        ) {
            newConfig.seriesTypeMap.clear()
        } else {
            if (newConfig.seriesTypeMap.size < chartState?.result?.metrics?.length) {
                chartState?.result?.metrics.forEach((metric) => {
                    if (!newConfig.seriesTypeMap.has(metric.name))
                        newConfig.seriesTypeMap.set(metric.name, 'column')
                })
            }
            newConfig.seriesTypeMap.set(metricName, newSeriesType)
        }
        if ((newSeriesType === 'pie' || newSeriesType === 'donut') && newConfig.drillAttribute) {
            newConfig.categoryAxis = newConfig.drillAttribute
            newConfig.drillAttribute = null
        }
        setConfig(newConfig)
        chartState.configureSeries(newSeriesType, newConfig)
        const newCategoryAxis = newConfig.categoryAxis
        if (oldCategoryAxis !== newCategoryAxis) chartState.getResults(newConfig)
    }

    const handleChangeDrill = (event) => {
        let newDrillAttribute = event.currentTarget.textContent
        if (newDrillAttribute === 'None') newDrillAttribute = null
        config.drillAttribute = newDrillAttribute
        chartState.getResults(config)
        setConfig({ ...config })
    }

    const handleCategoryAxisChange = (e) => {
        const newConfig = { ...config }
        if (e.target.value === 'Time') newConfig.timeRange = 'Last Year'
        newConfig.categoryAxis = e.target.value
        setConfig(newConfig)
        chartState.getResults(newConfig)
    }

    const handleSortingChange = (target, value) => {
        const newConfig = cloneDeep(config)
        if (!newConfig.sortColumns || newConfig.sortColumns.length === 0)
            newConfig.sortColumns = [
                {
                    column: '',
                    order: SORTING.NAT,
                },
            ]
        newConfig.sortColumns[0][target] = value
        if (!newConfig.sortColumns?.[0].column)
            newConfig.sortColumns[0].column = buildSortingColumnOptions[0]
        newConfig.sortColumns = newConfig.sortColumns.filter(
            (column) => column.order !== SORTING.NAT
        )
        chartState.getResults(newConfig)
        setConfig(newConfig)
    }

    const handleTableTimeSeriesToggle = () => {
        const newConfig = { ...config }
        const timeSeries = !newConfig.tableTimeSeries
        newConfig.tableTimeSeries = timeSeries
        if (timeSeries) newConfig.timeRange = 'Last Year'
        else newConfig.timeRange = 'Most Recent'
        setConfig(newConfig)
    }

    const handleClearColumns = () => {
        const newConfig = { ...config }
        newConfig.selectedTableColumns = []
        setConfig(newConfig)
    }

    const handleOpenColumnMenu = (e, col) => {
        dateMenuRef.current = e.target
        setCurrentColumn(col)
        setColMenuOpen(true)
        if (workspaceMetadata) {
            workspaceMetadata?.inboxes?.forEach((inbox) => {
                inbox.tables.forEach((table) => {
                    if (table.reportSeriesTableID === col.reportSeriesTableID)
                        setCurrentTable(table)
                })
            })
        }
    }

    const handleCloseColumnMenu = () => {
        setColMenuOpen(false)
        setCurrentTable(null)
        setCurrentColumn(null)
    }

    const findCurrentColumn = () => {
        let changeColumn = null
        let changeColumnIndex = -1
        config.selectedTableColumns.forEach((col, index) => {
            if (
                col.columnName === currentColumn?.columnName &&
                col.reportSeriesTableID === currentTable?.reportSeriesTableID
            ) {
                changeColumn = col
                changeColumnIndex = index
            }
        })
        return { index: changeColumnIndex, column: changeColumn }
    }

    const handleByDateSelect = (value) => {
        const newConfig = { ...config }
        if (currentColumn.worksheetID) {
            const colIndex = newConfig.selectedTableColumns.findIndex(
                (c) =>
                    c.columnName === currentColumn.columnName &&
                    c.worksheetID === currentColumn.worksheetID
            )
            const worksheetCol = newConfig.selectedTableColumns[colIndex]
            newConfig.selectedTableColumns[colIndex] = {
                ...worksheetCol,
                byDate: value,
            }
        } else {
            const { index: changeColumnIndex, column: changeColumn } = findCurrentColumn()
            if (changeColumn && changeColumnIndex !== -1) {
                newConfig.selectedTableColumns[changeColumnIndex] = {
                    ...changeColumn,
                    byDate: value,
                }
            }
        }
        setConfig(newConfig)
        setDateFlag(!dateFlag)
        handleCloseColumnMenu()
    }

    const getByDateItems = () => {
        const { column } = findCurrentColumn()
        const snapshot = {
            selectedDate: 'Snapshot Date',
            reportSeriesTableID: currentColumn?.reportSeriesTableID,
            columnName: currentColumn?.columnName,
        }
        const items = []
        if (currentColumn?.worksheetID) {
            config.worksheetColumns
                .filter((c) => c.columnType === 'DateTime')
                .forEach((c) => {
                    items.push(
                        <MenuItem
                            value={c.columnName}
                            sx={{
                                backgroundColor:
                                    column?.byDate?.selectedDate === c.columnName ? '#dad9d9' : '',
                            }}
                            onClick={() => handleByDateSelect(c.columnName)}
                        >
                            by {c.columnName}
                        </MenuItem>
                    )
                })
        }
        if (currentTable && currentTable.dates) {
            items.push(
                <MenuItem
                    value={snapshot}
                    sx={{
                        backgroundColor:
                            !column?.byDate || column?.byDate?.selectedDate === 'Snapshot Date'
                                ? '#dad9d9'
                                : '',
                    }}
                    onClick={() => handleByDateSelect(snapshot)}
                >
                    by Snapshot Date
                </MenuItem>
            )
            const byDates = currentTable.dates.map((d) => {
                const value = {
                    selectedDate: d,
                    reportSeriesTableID: currentTable.reportSeriesTableID,
                    columnName: currentColumn?.columnName,
                }
                return (
                    <MenuItem
                        key={d}
                        value={value}
                        sx={{
                            backgroundColor: column?.byDate?.selectedDate === d ? '#dad9d9' : '',
                        }}
                        onClick={() => handleByDateSelect(value)}
                    >
                        by {d}
                    </MenuItem>
                )
            })
            items.push(...byDates)
        }
        return items
    }

    const handleChartTitleChange = (value) => {
        setChartTitle(value)
        debouncedFunc(value)
    }

    const handleKPITargetChange = (value) => {
        setKpiCompareTarget(value)
        targetDebouncedFunc(value)
    }

    const isTableColSelected = (col) => {
        if (col.isMeasure) {
            return config.selectedTableColumns.some(
                (c) =>
                    c.columnName === col.columnName &&
                    (col.reportSeriesTableID === c.reportSeriesTableID ||
                        col.worksheetID === c.worksheetID)
            )
        }
        return config.selectedTableColumns.some((c) => c.columnName === col.columnName)
    }

    const getTableColDisplayName = (col) => {
        if (col.isMeasure) {
            if (availableColumns.filter((c) => c.columnName === col.columnName).length > 1) {
                if (col.reportSeriesTableID) return `${col.columnName} (${col.tableName})`
                if (col.worksheetID) return `${col.columnName} (${col.rangeName})`
            }
        }
        return col.columnName
    }

    return (
        <>
            <FormControl
                variant="outlined"
                sx={{
                    '& .MuiFormControl-root': { marginTop: '0px !important' },
                    '& .MuiInputBase-root': { height: '54px !important' },
                    '& .MuiSelect-select': { padding: '8px !important' },
                }}
            >
                <Selector
                    value={config.view}
                    onChange={handleViewChange}
                    sx={{
                        padding: '2px',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <MenuItem value={'table'} className={'view-select-item'}>
                        <img src={TablePink} alt={'Table'} className={'select-icons'} />
                        Table
                    </MenuItem>
                    <MenuItem value={'chart'} className={'view-select-item'}>
                        <img src={ChartBarPink} alt={'ChartBar'} className={'select-icons'} />
                        Chart
                    </MenuItem>
                    <MenuItem value={'kpi'} className={'view-select-item'}>
                        <img src={ChartLineUpPink} alt={'ChartLineUp'} className={'select-icons'} />
                        KPI
                    </MenuItem>
                </Selector>
            </FormControl>
            {(config.view === 'chart' || config.view === 'kpi') && (
                <FormControl
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <label
                        className={'prompt-label-label'}
                    >{`${config.view === 'kpi' ? 'KPI' : 'Chart'} title`}</label>
                    <TextField
                        className={'prompt-label-input'}
                        value={chartTitle}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChartTitleChange(e.target.value)}
                    />
                </FormControl>
            )}
            {config.view === 'kpi' && (
                <Selector
                    value={config.kpiCompareType}
                    onChange={(event) => {
                        const newConfig = { ...config }
                        newConfig.kpiCompareType = event.target.value
                        setConfig(newConfig)
                    }}
                    label={`${!config.tableTimeSeries ? 'Target' : 'Prior period'} comparison`}
                    labelClassName={'selector-label-bold'}
                    sx={{ height: controlHeight }}
                >
                    <MenuItem value={'percentage'}>Percentage</MenuItem>
                    <MenuItem value={'number'}>Number</MenuItem>
                    <MenuItem value={'none'}>None</MenuItem>
                </Selector>
            )}
            {config.view === 'table' && !config.worksheetID && (
                <Selector
                    value={config.displayRows}
                    onChange={(event) => {
                        const newConfig = { ...config }
                        newConfig.displayRows = event.target.value
                        setConfig(newConfig)
                    }}
                    label="Display"
                    labelClassName={'selector-label-bold'}
                    sx={{ height: controlHeight }}
                >
                    <MenuItem value={10}>Top 10</MenuItem>
                    <MenuItem value={50}>Top 50</MenuItem>
                    <MenuItem value={100}>Top 100</MenuItem>
                    <MenuItem value={500}>Top 500</MenuItem>
                    <MenuItem value={'All'}>All</MenuItem>
                </Selector>
            )}
            {(config.view === 'table' || config.view === 'kpi') && (
                <>
                    <Divider sx={{ marginTop: '10px' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                className={'inter'}
                                sx={{ fontSize: '12px', fontWeight: 600 }}
                            >
                                Time series
                            </Typography>
                        </Box>
                        <Switch
                            disabled={config.disableTimeSeries}
                            checked={config.tableTimeSeries}
                            onChange={handleTableTimeSeriesToggle}
                        />
                    </Box>
                </>
            )}
            {advanced && (
                <>
                    {config.view === 'chart' &&
                        chartState.result?.metrics?.map((metric) => {
                            return (
                                <Selector
                                    value={
                                        config.seriesTypeMap.has(metric.name)
                                            ? config.seriesTypeMap.get(metric.name)
                                            : config.seriesType
                                    }
                                    onChange={(event) =>
                                        handleSeriesTypeChange(metric.name, event.target.value)
                                    }
                                    label={metric.name + ' Chart Type'}
                                    labelClassName={'selector-label-bold'}
                                    sx={{ height: controlHeight }}
                                >
                                    <MenuItem value={'column'}>Column</MenuItem>
                                    <MenuItem value={'line'}>Line</MenuItem>
                                    <MenuItem value={'area'}>Area</MenuItem>
                                    <MenuItem value={'bar'}>Bar</MenuItem>
                                    {config.selectedItems?.length <= 1 && (
                                        <MenuItem value={'pie'}>Pie</MenuItem>
                                    )}
                                    {config.selectedItems?.length <= 1 && (
                                        <MenuItem value={'donut'}>Donut</MenuItem>
                                    )}
                                    <MenuItem value={'pictorialBar'}>Pictorial</MenuItem>
                                    <MenuItem value={'gauge'}>Gauge</MenuItem>
                                    <MenuItem value={'radialBar'}>Radial Bar</MenuItem>
                                    {config.selectedItems?.length === 2 && (
                                        <MenuItem value={'scatter'}>Scatter</MenuItem>
                                    )}
                                    {config.worksheetID && (
                                        <MenuItem value={'waterfall'}>Waterfall</MenuItem>
                                    )}
                                </Selector>
                            )
                        })}
                    {config.view === 'chart' && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                                    Stacking
                                </Typography>
                                <InfoTooltip
                                    title={'Stacking is only available for bar and column charts.'}
                                />
                            </Box>
                            <Switch
                                checked={config.stacked}
                                onChange={(event) => {
                                    config.stacked = !config.stacked
                                    chartState.configureSeries(config.seriesType, config)
                                }}
                                disabled={config.selectedItems?.length === 0}
                            ></Switch>
                        </Box>
                    )}
                </>
            )}
            {!advanced && config.view === 'chart' && (
                <>
                    <Selector
                        value={config.seriesType}
                        onChange={(event) => handleSeriesTypeChange(null, event.target.value)}
                        label="Chart Type"
                        labelClassName={'selector-label-bold'}
                        sx={{ height: controlHeight }}
                    >
                        <MenuItem value={'column'}>Column</MenuItem>
                        <MenuItem value={'line'}>Line</MenuItem>
                        <MenuItem value={'area'}>Area</MenuItem>
                        <MenuItem value={'bar'}>Bar</MenuItem>
                        {config.selectedItems?.length <= 1 && (
                            <MenuItem value={'pie'}>Pie</MenuItem>
                        )}
                        {config.selectedItems?.length <= 1 && (
                            <MenuItem value={'donut'}>Donut</MenuItem>
                        )}
                        <MenuItem value={'pictorialBar'}>Pictorial</MenuItem>
                        <MenuItem value={'gauge'}>Gauge</MenuItem>
                        <MenuItem value={'radialBar'}>Radial Bar</MenuItem>
                        {config.selectedItems?.length === 2 && (
                            <MenuItem value={'scatter'}>Scatter</MenuItem>
                        )}
                        {config.worksheetID && <MenuItem value={'waterfall'}>Waterfall</MenuItem>}
                    </Selector>
                    {(config.seriesType === 'bar' || config.seriesType === 'column') && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                                    Stacking
                                </Typography>
                                <InfoTooltip
                                    title={'Stacking is only available for bar and column charts.'}
                                />
                            </Box>
                            <Switch
                                checked={config.stacked}
                                onChange={() => {
                                    config.stacked = !config.stacked
                                    chartState.configureSeries(config.seriesType, config)
                                }}
                                disabled={config.selectedItems?.length === 0}
                            />
                        </Box>
                    )}
                </>
            )}
            {advanced && config.view === 'chart' && (
                <Selector
                    value={config.categoryAxis}
                    onChange={handleCategoryAxisChange}
                    sx={{ height: controlHeight }}
                    label={'Category Axis'}
                    labelClassName={'selector-label-bold'}
                >
                    {!['bar', 'pictorialBar', 'radialBar'].includes(config.seriesType) && (
                        <MenuItem value={'Time'}>Time</MenuItem>
                    )}
                    {chartState.drillAttributes?.map((drillAttribute) => {
                        if (drillAttribute === config.drillAttribute) {
                            return null
                        }
                        return <MenuItem value={drillAttribute}>{drillAttribute}</MenuItem>
                    })}
                    {!config.worksheetID && <MenuItem value={'Other'}>Other</MenuItem>}
                </Selector>
            )}
            {advanced &&
                config.view === 'chart' &&
                config.categoryAxis !== 'Time' &&
                !['radial', 'gauge', 'donut', 'pie'].includes(config.seriesType) && (
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Selector
                            value={config.sortColumns?.[0]?.column || buildSortingColumnOptions[0]}
                            onChange={(e) => handleSortingChange('column', e.target.value)}
                            sx={{ height: controlHeight }}
                            label={'Sort By'}
                            labelClassName={'selector-label-bold'}
                        >
                            {buildSortingColumnOptions.map((col) => (
                                <MenuItem value={col}>{col}</MenuItem>
                            ))}
                        </Selector>
                        <Selector
                            value={config.sortColumns?.[0]?.order || SORTING.NAT}
                            onChange={(e) => handleSortingChange('order', e.target.value)}
                            sx={{ height: controlHeight }}
                            label={'Sort Order'}
                            labelClassName={'selector-label-bold'}
                        >
                            {SORTING_OPTIONS.map((o) => (
                                <MenuItem value={o.value}>{o.label}</MenuItem>
                            ))}
                        </Selector>
                    </Box>
                )}
            {config.view === 'kpi' && !config.tableTimeSeries && (
                <>
                    <Divider sx={{ marginTop: '10px' }} />
                    <FormControl
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <label className={'prompt-label-label'}>Target value</label>
                        <TextField
                            className={'prompt-label-input'}
                            value={kpiCompareTarget}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                handleKPITargetChange(e.target.value.replace(/\D/g, ''))
                            }
                        />
                    </FormControl>
                </>
            )}
            {config.selectedItems.length > 1 && config.view === 'chart' && (
                <Button
                    onClick={() => chartState.invertAxis()}
                    className={'button-grey invert-axis-button'}
                >
                    <Typography sx={{ fontSize: '14px' }}>Switch axis order</Typography>
                </Button>
            )}
            {(config.view === 'kpi' ? config.tableTimeSeries : true) && (
                <>
                    <Divider sx={{ marginTop: '10px' }} />
                    <TimeRangeControl
                        config={config}
                        setConfig={setConfig}
                        chartProperties={chartState}
                        setTimeRangeDialogOpen={setTimeRangeDialogOpen}
                        controlHeight={controlHeight}
                    />
                </>
            )}
            {config.categoryAxis === 'Time' && config.tableTimeSeries && (
                <Selector
                    value={config.period}
                    onChange={(event) => {
                        const newConfig = { ...config }
                        newConfig.period = event.target.value
                        if (config.view === 'chart') chartState.getResults(newConfig)
                        setConfig(newConfig)
                    }}
                    label="Period Frequency"
                    labelClassName={'selector-label-bold'}
                    sx={{ height: controlHeight }}
                >
                    <MenuItem value={'Daily'}>Daily</MenuItem>
                    <MenuItem value={'Weekly'}>Weekly</MenuItem>
                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                    <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                    <MenuItem value={'Annually'}>Annually</MenuItem>
                </Selector>
            )}
            {config.categoryAxis === 'Time' && config.tableTimeSeries && (
                <Selector
                    value={config.calendarType}
                    onChange={(event) => {
                        config.calendarType = event.target.value
                        if (config.selectedItems?.length === 0) {
                            chartState.series = null
                        } else {
                            config.calendarType = event.target.value
                            chartState.getResults(config)
                        }
                        setConfig({ ...config })
                    }}
                    label="Period End"
                    labelClassName={'selector-label-bold'}
                    sx={{ height: controlHeight }}
                >
                    <MenuItem value={'Rolling'}>Rolling</MenuItem>
                    <MenuItem value={'Calendar'}>Calendar</MenuItem>
                </Selector>
            )}
            {!['scatter', 'pie', 'donut', 'pictorialBar', 'radialBar'].includes(
                config.seriesType
            ) &&
                config.view === 'chart' && (
                    <>
                        <Divider sx={{ marginTop: '10px' }} />
                        <Selector
                            value={config.drillAttribute ? config.drillAttribute : 'None'}
                            label="Group By"
                            labelClassName={'selector-label-bold'}
                            sx={{ height: controlHeight }}
                        >
                            <MenuItem value={'None'} onClick={handleChangeDrill}>
                                None
                            </MenuItem>
                            {chartState.drillAttributes?.map((item) => {
                                if (item === config.categoryAxis) return null
                                return (
                                    <MenuItem value={item} key={item} onClick={handleChangeDrill}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Selector>
                    </>
                )}
            {advanced && config.view !== 'kpi' && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                            Analyze Changes
                        </Typography>
                        <InfoTooltip title={'Allow drilling and grouping by snapshot changes.'} />
                    </Box>
                    <Switch
                        checked={analyzeChanges}
                        onChange={(event) => {
                            setAnalyzeChanges(!analyzeChanges)
                        }}
                    ></Switch>
                </Box>
            )}
            {config.view === 'table' && (
                <>
                    <Box
                        sx={{
                            mt: '24px',
                            height: '24px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Columns</Typography>
                        {columnLoading ? (
                            <ScoopLoader size={24} />
                        ) : (
                            <IconButton
                                size={'small'}
                                onClick={handleClearColumns}
                                disabled={config.selectedTableColumns.length === 0}
                            >
                                <img src={ResetArrow} alt={'reset'} />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: availableColumns.length > 0 ? 1 : 'unset',
                            overflow: 'auto',
                        }}
                    >
                        {(
                            config.worksheetID
                                ? config.worksheetColumns.length === 0
                                : availableColumns.length === 0
                        ) ? (
                            <Typography
                                sx={{
                                    color: '#979099',
                                    fontSize: '14px',
                                    mt: 1,
                                }}
                            >
                                Select a KPI or Source to view available columns
                            </Typography>
                        ) : (
                            availableColumns
                                .filter((col) => col.columnName)
                                .sort((a, b) => a.columnName.localeCompare(b.columnName))
                                .map((col) => {
                                    const checked = isTableColSelected(col)
                                    return (
                                        <Box
                                            key={col.columnName}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                padding: '0 2px 0 8px',
                                                borderRadius: '5px',
                                                height: 35,
                                                ':hover': {
                                                    backgroundColor: '#F9F9F9',
                                                },
                                            }}
                                        >
                                            <Checkbox
                                                checked={checked}
                                                onClick={() => handleColumnToggle(col)}
                                                size={'medium'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    width: 190,
                                                    ml: 1,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    flex: 1,
                                                }}
                                            >
                                                {getTableColDisplayName(col)}
                                            </Typography>
                                            {col.isMeasure && checked && (
                                                <IconButton
                                                    onClick={(e) => handleOpenColumnMenu(e, col)}
                                                    sx={{
                                                        padding: '4px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #E6E4E6',
                                                        color: 'inherit',
                                                    }}
                                                >
                                                    <MoreHorizRoundedIcon fontSize={'small'} />
                                                </IconButton>
                                            )}
                                        </Box>
                                    )
                                })
                        )}
                    </Box>
                    <Menu
                        open={colMenuOpen}
                        anchorEl={dateMenuRef?.current}
                        onClose={handleCloseColumnMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        {getByDateItems()}
                    </Menu>
                </>
            )}
        </>
    )
}
