const SHAPE_OFFSET = 20

function getWidth(canvas, shape) {
    if (shape.shapeWidth) return shape.shapeWidth
    if (shape.width) return shape.width
    return canvas.width - shape.offset
}

function getHeight(canvas, shape) {
    if (shape.shapeHeight) return shape.shapeHeight
    if (shape.height) return shape.height
    return canvas.height - shape.offset
}

// Function to calculate the rotated gradient's start and end points
function calculateGradientPoints(x, y, width, height, angle) {
    const radians = (-angle * Math.PI) / 180
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)

    const cx = x + width / 2
    const cy = y + height / 2

    return {
        x0: cx - cos * width,
        y0: cy - sin * height,
        x1: cx + cos * width,
        y1: cy + sin * height,
    }
}

function getPoint(x, y) {
    return { x: x ? x : 0, y: y ? y : 0 }
}

function setFillStyle(ctx, shape, drawObject) {
    // Create a gradient (rotated as the canvas is rotated)
    let drawAfterLoad = false
    if (shape.gradientFill) {
        const { x0, y0, x1, y1 } = calculateGradientPoints(
            -shape.width / 2,
            -shape.height / 2,
            shape.width,
            shape.height,
            shape.gradientFill.deg ?? 0
        ) // Rotate the canvas
        var gradient = ctx.createLinearGradient(x0, y0, x1, y1)
        for (let i = 0; i < shape.gradientFill.colors.length; i++) {
            gradient.addColorStop(shape.gradientFill.stops[i], shape.gradientFill.colors[i])
        }
        // Fill the rounded rectangle with the gradient
        ctx.fillStyle = gradient
    } else if (shape.texturePaint) {
        const img = new Image()
        img.src =
            'https://d3lkv74tdz6isd.cloudfront.net/' + shape.texturePaint.s3Key + '?withHeaders=yes'
        img.crossOrigin = 'anonymous'
        ctx.fillStyle = ctx.createPattern(img, 'no-repeat')
        if (drawObject) {
            drawAfterLoad = true
            img.onload = function () {
                if (shape.fillColor) {
                    ctx.fillStyle = shape.fillColor
                } else {
                    ctx.fillStyle = 'transparent'
                }
                ctx.save()
                drawObject(ctx, shape)
                ctx.clip()
                let width = shape.shapeWidth ? shape.shapeWidth : shape.width
                let height = shape.shapeHeight ? shape.shapeHeight : shape.height
                let x = -width / 2
                let y = -height / 2
                if (shape.texturePaint.stretch) {
                    if (shape.texturePaint.stretch.left) {
                        var xAdj = (shape.texturePaint.stretch.left / 100000) * shape.width
                        x += xAdj
                        width -= xAdj
                    }
                    if (shape.texturePaint.stretch.top) {
                        var yAdj = (shape.texturePaint.stretch.top / 100000) * shape.height
                        y += yAdj
                        height -= yAdj
                    }
                    if (shape.texturePaint.stretch.right) {
                        width -= (shape.texturePaint.stretch.right / 100000) * shape.width
                    }
                    if (shape.texturePaint.stretch.bottom) {
                        height -= (shape.texturePaint.stretch.bottom / 100000) * shape.height
                    }
                }
                ctx.drawImage(img, 0, 0, img.width, img.height, x, y, width, height)
                if (shape.lineWidth) {
                    ctx.restore()
                    drawObject(ctx, shape)
                    ctx.stroke()
                }
            }
        }
    } else if (shape.fillColor) {
        ctx.fillStyle = shape.fillColor
    } else {
        ctx.fillStyle = 'transparent'
    }
    if (!drawAfterLoad) {
        drawObject(ctx, shape)
        if (shape.lineWidth) {
            ctx.stroke()
        }
    }
}

function drawRectBorder(ctx, xPos, yPos, width, height, thickness = 1) {
    ctx.rect(xPos + thickness, yPos + thickness, width - thickness * 2, height - thickness * 2)
}

function drawRect(ctx, shape) {
    if (shape.fillColor || shape.gradientFill) {
        ctx.fillRect(-shape.width / 2, -shape.height / 2, shape.width, shape.height)
    } else {
        ctx.rect(-shape.width / 2, -shape.height / 2, shape.width, shape.height)
    }
    if (shape.lineWidth > 0) {
        drawRectBorder(ctx, -shape.width / 2, -shape.height / 2, shape.width, shape.height)
        ctx.stroke()
    }
}

function getLengthSizes(length) {
    if (length === 'SMALL') return 3
    if (length === 'MEDIUM') return 8
    if (length === 'LARGE') return 15
    return 8
}

function drawLine(ctx, canvas, line) {
    let start = getPoint(line.startX, line.startY)
    let end = getPoint(line.endX, line.endY)
    if (line.lineDash) {
        ctx.setLineDash(line.lineDash)
    }
    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
    ctx.stroke()
    if (line.tail) {
        var angle = Math.atan2(end.y - start.y, end.x - start.x)
        let arrowLength = getLengthSizes(line.tail.length)
        // Move to the end of the line to draw the arrowhead
        // Calculate and draw the first side of the arrowhead
        ctx.moveTo(
            end.x - arrowLength * Math.cos(angle - Math.PI / 6),
            end.y - arrowLength * Math.sin(angle - Math.PI / 6)
        )
        ctx.lineTo(end.x, end.y)
        ctx.lineTo(
            end.x - arrowLength * Math.cos(angle + Math.PI / 6),
            end.y - arrowLength * Math.sin(angle + Math.PI / 6)
        )
        ctx.lineTo(
            end.x - arrowLength * Math.cos(angle - Math.PI / 6),
            end.y - arrowLength * Math.sin(angle - Math.PI / 6)
        )
        ctx.lineTo(end.x, end.y)

        // Stroke or fill the arrowhead
        ctx.fill()
        ctx.stroke() // For just the outline
    }
    if (line.head) {
        var angle = Math.atan2(start.y - end.y, start.x - end.x)
        let arrowLength = getLengthSizes(line.tail.length)
        // Move to the end of the line to draw the arrowhead
        // Calculate and draw the first side of the arrowhead
        ctx.moveTo(
            start.x - arrowLength * Math.cos(angle - Math.PI / 6),
            start.y - arrowLength * Math.sin(angle - Math.PI / 6)
        )
        ctx.lineTo(start.x, start.y)
        ctx.lineTo(
            start.x - arrowLength * Math.cos(angle + Math.PI / 6),
            start.y - arrowLength * Math.sin(angle + Math.PI / 6)
        )
        ctx.lineTo(
            start.x - arrowLength * Math.cos(angle - Math.PI / 6),
            start.y - arrowLength * Math.sin(angle - Math.PI / 6)
        )
        ctx.lineTo(start.x, start.y)

        // Stroke or fill the arrowhead
        ctx.fill()
        ctx.stroke() // For just the outline
    }
}

function drawRoundRect(ctx, shape, fill) {
    ctx.globalAlpha = shape.fillAlpha
    ctx.strokeStyle = shape.lineColor
    ctx.lineWidth = shape.lineWidth

    // Define the rounded rectangle path
    function roundedRect(ctx, x, y, width, height, radius) {
        ctx.beginPath()
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
        ctx.lineTo(x + radius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
        ctx.lineTo(x, y + radius)
        ctx.quadraticCurveTo(x, y, x + radius, y)
        ctx.closePath()
    }

    roundedRect(ctx, -shape.width / 2, -shape.height / 2, shape.width, shape.height, shape.radius)
    ctx.fill()
}

function drawTrapezoid(ctx, shape, rotation) {
    ctx.beginPath()
    ctx.moveTo(-shape.offset, -shape.height / 2)
    ctx.lineTo(shape.offset, -shape.height / 2)
    ctx.lineTo(shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.offset, -shape.height / 2)
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
}

function drawArrow(ctx, shape) {
    if (!shape.headLength) {
        if (shape.shapeType === 'rightArrow' || shape.shapeType === 'leftArrow') {
            shape.headLength = shape.width * 0.4
            shape.stemWidth = shape.height / 4
        } else {
            shape.headLength = shape.height * 0.4
            shape.stemWidth = shape.width / 4
        }
    } else {
        if (shape.shapeType === 'rightArrow') {
            shape.headLength = shape.headLength * shape.width
            shape.stemWidth = (shape.stemWidth * shape.height) / 2
        } else if (shape.shapeType === 'leftArrow') {
            shape.headLength = (1 - shape.headLength) * shape.width
            shape.stemWidth = ((1 - shape.stemWidth) * shape.height) / 2
        } else if (shape.shapeType === 'upArrow') {
            shape.headLength = (1 - shape.headLength) * shape.height
            shape.stemWidth = ((1 - shape.stemWidth) * shape.width) / 2
        } else {
            shape.headLength = shape.headLength * shape.height
            shape.stemWidth = (shape.stemWidth * shape.width) / 2
        }
    }
    ctx.beginPath()
    if (shape.shapeType === 'rightArrow') {
        ctx.moveTo(-shape.width / 2, -shape.stemWidth)
        ctx.lineTo(shape.width / 2 - shape.headLength, -shape.stemWidth)
        ctx.lineTo(shape.width / 2 - shape.headLength, -shape.height / 2)
        ctx.lineTo(shape.width / 2, 0)
        ctx.lineTo(shape.width / 2 - shape.headLength, shape.height / 2)
        ctx.lineTo(shape.width / 2 - shape.headLength, shape.stemWidth)
        ctx.lineTo(-shape.width / 2, shape.stemWidth)
        ctx.lineTo(-shape.width / 2, -shape.stemWidth)
    } else if (shape.shapeType === 'leftArrow') {
        ctx.moveTo(shape.width / 2, -shape.stemWidth)
        ctx.lineTo(-shape.width / 2 + shape.headLength, -shape.stemWidth)
        ctx.lineTo(-shape.width / 2 + shape.headLength, -shape.height / 2)
        ctx.lineTo(-shape.width / 2, 0)
        ctx.lineTo(-shape.width / 2 + shape.headLength, shape.height / 2)
        ctx.lineTo(-shape.width / 2 + shape.headLength, shape.stemWidth)
        ctx.lineTo(shape.width / 2, shape.stemWidth)
        ctx.lineTo(shape.width / 2, -shape.stemWidth)
    } else if (shape.shapeType === 'upArrow') {
        ctx.moveTo(-shape.stemWidth, shape.height / 2)
        ctx.lineTo(-shape.stemWidth, -shape.height / 2 + shape.headLength)
        ctx.lineTo(-shape.width / 2, -shape.height / 2 + shape.headLength)
        ctx.lineTo(0, -shape.height / 2)
        ctx.lineTo(shape.width / 2, -shape.height / 2 + shape.headLength)
        ctx.lineTo(shape.stemWidth, -shape.height / 2 + shape.headLength)
        ctx.lineTo(shape.stemWidth, shape.height / 2)
        ctx.lineTo(-shape.stemWidth, shape.height / 2)
    } else if (shape.shapeType === 'downArrow') {
        ctx.moveTo(-shape.stemWidth, -shape.height / 2)
        ctx.lineTo(-shape.stemWidth, shape.height / 2 - shape.headLength)
        ctx.lineTo(-shape.width / 2, shape.height / 2 - shape.headLength)
        ctx.lineTo(0, shape.height / 2)
        ctx.lineTo(shape.width / 2, shape.height / 2 - shape.headLength)
        ctx.lineTo(shape.stemWidth, shape.height / 2 - shape.headLength)
        ctx.lineTo(shape.stemWidth, -shape.height / 2)
        ctx.lineTo(-shape.stemWidth, -shape.height / 2)
    }
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
}

function drawEllipse(ctx, shape) {
    // ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle)
    // x, y are the coordinates of the ellipse's center
    // radiusX, radiusY are the horizontal and vertical radii of the ellipse
    // rotation is the rotation of the ellipse, in radians
    // startAngle and endAngle define the start and end points of the ellipse in radians
    ctx.ellipse(0, 0, shape.width / 2, shape.height / 2, 0, 0, 2 * Math.PI)
    ctx.fill() // Fill the ellipse with the fill color
    ctx.stroke() // Draw the border of the ellipse
}

function drawTriangle(ctx, shape) {
    ctx.moveTo(0, -shape.height / 2)
    ctx.lineTo(shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.width / 2, shape.height / 2)
    ctx.lineTo(0, -shape.height / 2)
    ctx.fill()
    ctx.stroke()
}

function drawRightTriangle(ctx, shape) {
    ctx.moveTo(-shape.width / 2, -shape.height / 2)
    ctx.lineTo(shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.width / 2, -shape.height / 2)
    ctx.fill()
    ctx.stroke()
}

function drawParallelogram(ctx, shape) {
    if (!shape.offset) {
        shape.offset = 0
    }
    var offset = shape.offset * shape.width
    ctx.beginPath()
    ctx.moveTo(-shape.width / 2 + offset, -shape.height / 2)
    ctx.lineTo(shape.width / 2, -shape.height / 2)
    ctx.lineTo(shape.width / 2 - offset, shape.height / 2)
    ctx.lineTo(-shape.width / 2, shape.height / 2)
    ctx.lineTo(-shape.width / 2 + offset, -shape.height / 2)
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
}

function drawChevron(ctx, shape) {
    if (!shape.offset) {
        shape.offset = 0
    }
    var margin = shape.lineWidth ? shape.lineWidth : 0
    var offset = shape.offset * Math.min(shape.height, shape.width)
    //console.log(margin);
    ctx.beginPath()
    ctx.moveTo(-shape.width / 2 + margin, -shape.height / 2 + margin)
    ctx.lineTo(shape.width / 2 - offset + margin, -shape.height / 2 + margin)
    ctx.lineTo(shape.width / 2 + margin, 0)
    ctx.lineTo(shape.width / 2 - offset + margin, shape.height / 2 - margin)
    ctx.lineTo(-shape.width / 2 + margin, +shape.height / 2 - margin)
    ctx.lineTo(-shape.width / 2 + offset + margin, 0)
    ctx.lineTo(-shape.width / 2 + margin, -shape.height / 2 + margin)
    ctx.fill()
    ctx.stroke()
}

function drawHomePlate(ctx, shape) {
    if (!shape.offset) {
        shape.offset = 0
    }
    var margin = shape.lineWidth ? shape.lineWidth : 0
    var offset = shape.offset * Math.min(shape.height, shape.width)
    //console.log(margin);
    ctx.beginPath()
    ctx.moveTo(-shape.width / 2 + margin, -shape.height / 2 + margin)
    ctx.lineTo(shape.width / 2 - offset + margin, -shape.height / 2 + margin)
    ctx.lineTo(shape.width / 2 + margin, 0)
    ctx.lineTo(shape.width / 2 - offset + margin, shape.height / 2 - margin)
    ctx.lineTo(-shape.width / 2 + margin, +shape.height / 2 - margin)
    ctx.lineTo(-shape.width / 2 + margin, -shape.height / 2 + margin)
    ctx.fill()
    ctx.stroke()
}

function drawDiamond(ctx, shape) {
    ctx.moveTo(0, -shape.height / 2)
    ctx.lineTo(shape.width / 2, 0)
    ctx.lineTo(0, shape.height / 2)
    ctx.lineTo(-shape.width / 2, 0)
    ctx.lineTo(0, -shape.height / 2)
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
}

function drawPentagon(ctx, shape) {
    const radius = shape.height / 2 // Radius of the pentagon
    const angle = (2 * Math.PI) / 5 // The angle between the vertices
    // Move to the starting point (the top of the pentagon)
    ctx.moveTo(radius * Math.sin(0), -radius * Math.cos(0))
    // Draw the sides of the pentagon
    for (let i = 1; i <= 5; i++) {
        ctx.lineTo(radius * Math.sin(angle * i), -radius * Math.cos(angle * i))
    }
    ctx.fill()
    ctx.stroke()
}

function drawCan(ctx, shape) {
    ctx.beginPath()
    ctx.ellipse(
        0,
        ((1 - shape.offset) * shape.height) / 2,
        shape.width / 2,
        (shape.height * shape.offset) / 2,
        0,
        0,
        Math.PI
    )
    ctx.fill()
    ctx.stroke()
    ctx.closePath()
    ctx.fillRect(
        -shape.width / 2,
        ((shape.offset - 1) * shape.height) / 2,
        shape.width,
        (1 - shape.offset) * shape.height + 1
    )
    ctx.beginPath()
    ctx.moveTo(-shape.width / 2, ((shape.offset - 1) * shape.height) / 2)
    ctx.lineTo(-shape.width / 2, ((1 - shape.offset) * shape.height) / 2)
    ctx.stroke()
    ctx.closePath()
    ctx.beginPath()
    ctx.moveTo(shape.width / 2, ((shape.offset - 1) * shape.height) / 2)
    ctx.lineTo(shape.width / 2, ((1 - shape.offset) * shape.height) / 2)
    ctx.stroke()
    ctx.closePath()
    ctx.beginPath()
    ctx.ellipse(
        0,
        ((shape.offset - 1) * shape.height) / 2,
        shape.width / 2,
        (shape.height * shape.offset) / 2,
        0,
        0,
        2 * Math.PI
    )
    ctx.fill()
    ctx.stroke()
    ctx.closePath()
}

export const isCanvasBlank = (canvas) => {
    const context = canvas.getContext('2d', { willReadFrequently: true })
    try {
        let imageData = context.getImageData(0, 0, canvas.width, canvas.height)
        const pixelBuffer = new Uint32Array(imageData.data.buffer)
        return !pixelBuffer.some((color) => color !== 0)
    } catch (e) {
        //console.log("Bad image in PowerPoint, skipping.")
        return false // or handle the error as needed
    }
}

export function drawShape(canvas, shape) {
    const shapeOffset = shape.edgeOffset ? shape.edgeOffset : 0
    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    const drawObject = function (ctx, shape) {
        var rotation = {
            radians: shape.rotation ? (shape.rotation * Math.PI) / 180 : 0,
            centerX: getWidth(canvas, shape) / 2,
            centerY: getHeight(canvas, shape) / 2,
        }
        var translateX =
            Math.abs(Math.cos(rotation.radians) * rotation.centerX) +
            Math.abs(Math.sin(rotation.radians) * rotation.centerY)
        var translateY =
            Math.abs(Math.sin(rotation.radians) * rotation.centerX) +
            Math.abs(Math.cos(rotation.radians) * rotation.centerY)
        ctx.translate(translateX + shapeOffset, translateY + shapeOffset)
        ctx.rotate(rotation.radians)
        ctx.scale(shape.flipHorizontal ? -1 : 1, shape.flipVertical ? -1 : 1)
        if (!shape.lineWidth || shape.lineWidth === 0) {
            ctx.strokeStyle = 'transparent'
        } else {
            ctx.lineWidth = shape.lineWidth
            ctx.strokeStyle = shape.lineColor ? shape.lineColor : 'black'
        }
        // Convert below into an onload that is called by setFillStyle
        if (shape.shapeType === 'path') {
            eval(shape.canvasCode)
        } else {
            if (shape.shapeType === 'rect') {
                drawRect(ctx, shape)
            } else if (shape.shapeType === 'line') {
                drawLine(ctx, canvas, shape)
            } else if (shape.shapeType === 'roundRect') {
                drawRoundRect(ctx, shape)
            } else if (shape.shapeType === 'trapezoid') {
                drawTrapezoid(ctx, shape, rotation)
            } else if (shape.shapeType.endsWith('Arrow')) {
                drawArrow(ctx, shape)
            } else if (shape.shapeType === 'triangle') {
                drawTriangle(ctx, shape)
            } else if (shape.shapeType === 'rtTriangle') {
                drawRightTriangle(ctx, shape)
            } else if (shape.shapeType === 'parallelogram') {
                drawParallelogram(ctx, shape)
            } else if (shape.shapeType === 'diamond') {
                drawDiamond(ctx, shape)
            } else if (shape.shapeType === 'pentagon') {
                drawPentagon(ctx, shape)
            } else if (shape.shapeType === 'can') {
                drawCan(ctx, shape)
            } else if (shape.shapeType === 'ellipse') {
                drawEllipse(ctx, shape)
            } else if (shape.shapeType === 'chevron') {
                drawChevron(ctx, shape)
            } else if (shape.shapeType === 'homePlate') {
                drawHomePlate(ctx, shape)
            }
        }
    }
    setFillStyle(ctx, shape, drawObject)
}
