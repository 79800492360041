import { Box, Typography } from '@mui/material'
import Button from '../../../common/Button/Button'
import Sigma from '../../../../assets/icons/Sigma.svg'
import { CalculatedColumnsDialog } from '../../../Source/CalculatedColumnsDialog'
import { useState } from 'react'
export const EmptyCalculatedColumns = ({ inboxID }) => {
    const [openDialog, setOpenDialog] = useState(false)
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: ' 15% 25%',
                }}
            >
                <img src={Sigma} alt={'sigma'} />
                <Box>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '14px',
                            color: '#2B1630',
                            fontWeight: 600,
                        }}
                    >
                        Calculated Columns
                    </Typography>
                    {/*
                    <Typography sx={{textAlign:'center', fontSize:'14px', color:'#979099'}}>Lorem ipsum dolor sit amet consectetur. Venenatis at adipiscing sapien fames integer eu scelerisque orci. Ipsum mauris tortor semper sed lectus orci.</Typography>
*/}
                </Box>
                <Button className={'button-grey small'} onClick={() => setOpenDialog(true)}>
                    Create Calculated Columns
                </Button>
            </Box>
            <CalculatedColumnsDialog open={openDialog} setOpen={setOpenDialog} inboxID={inboxID} />
        </>
    )
}
