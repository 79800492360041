import { useQuery } from '@tanstack/react-query'
import { getCategoryValues } from './categoryValuesApi'

export const useCategoryValues = (columnName, likeValue, reportSeriesTableID) => {
    const { data, isLoading, refetch } = useQuery({
        queryKey: ['categoryValues', columnName, likeValue, reportSeriesTableID],
        queryFn: () => getCategoryValues(columnName, likeValue, reportSeriesTableID),
        enabled: !!reportSeriesTableID && !!columnName,
    })

    return {
        data: data?.values || [],
        isLoading,
        refetch,
    }
}
