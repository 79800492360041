import { mobileAPIRequest } from '../../api'

export const listProcesses = async () => {
    const action = {
        action: 'listProcesses',
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}

export const getReportSeriesData = async () => {
    const action = {
        action: 'getReportSeriesMetadata',
        includeColumnMetadata: false,
    }
    return await mobileAPIRequest({
        method: 'post',
        data: action,
    })
}
