import Dialog from '../../../common/Dialog/Dialog'
import { Accordion, Box, Typography } from '@mui/material'
import { TypeBox } from './TypeBox'
import { useEffect, useState } from 'react'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { AccordionConfig } from '../ConfigDialog'

export const SelectType = ({
    setStep,
    onClose,
    setType,
    isSnapshotDefault,
    stepBack,
    createInbox,
    handleCreateInbox,
    isLoading,
    setDescription,
    handleContinue,
    disableSpreadsheet,
    disableAdvanced,
    continueButton,
}) => {
    const [active, setActive] = useState('')
    const [inboxName, setInboxName] = useState('')
    const [expanded, setExpanded] = useState(false)
    const [isMostRecent, setIsMostRecent] = useState(false)
    const [isIncremental, setIsIncremental] = useState(false)
    const [isMultipleLoads, setIsMultipleLoads] = useState(false)
    const [workbookRange, setWorkbookRange] = useState('')

    useEffect(() => {
        if (isSnapshotDefault) setActive('Snapshot')
        else setActive('Transactional')
    }, [isSnapshotDefault])

    return (
        <Dialog
            open
            onClose={() => {
                onClose()
                setStep && setStep(0)
            }}
            actions={
                <>
                    <Button
                        className={'button-grey small'}
                        onClick={() => {
                            setType(active)
                            setStep && setStep(0)
                            stepBack && stepBack()
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!active || (createInbox && !inboxName) || isLoading}
                        className={'button-purple small'}
                        onClick={() => {
                            createInbox && handleCreateInbox(inboxName, active)
                            setType(active)
                            !createInbox &&
                                handleContinue({
                                    isIncremental,
                                    isMostRecent,
                                    isMultipleLoads,
                                    isSnapshot: active,
                                })
                        }}
                    >
                        {continueButton}
                    </Button>
                </>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                }}
            >
                <Typography sx={{ color: '#201024', fontSize: '24px', fontWeight: 600 }}>
                    Create New Dataset
                </Typography>
                <Typography
                    sx={{
                        display: 'inline',
                        textAlign: 'center',
                        width: '75%',
                        color: '#635566',
                    }}
                >
                    Select the type of Dataset you’d like to create. For more information,{' '}
                    <a
                        href="https://www.scoopanalytics.com/help"
                        target="_blank"
                        style={{ color: '#BF0A46' }}
                        rel="noreferrer"
                    >
                        click here for help.
                    </a>
                </Typography>
                {createInbox && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            width: '100%',
                        }}
                    >
                        <Input
                            onChange={(e) => setInboxName(e.target.value)}
                            value={inboxName}
                            fullWidth
                            padding={'8px 16px'}
                            label={'Dataset Name'}
                        />
                        <Input
                            label={'Description (optional)'}
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Box>
                )}
                {!createInbox && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}
                    >
                        <TypeBox
                            title={'Transactional'}
                            description={
                                'Accept all new reports as transactions, i.e. as additional records to the existing database. All records are cumulative.'
                            }
                            active={active}
                            setActive={setActive}
                            enabled={true}
                        ></TypeBox>
                        <TypeBox
                            title={'Snapshot'}
                            description={
                                'Snapshots allow tracking of individual items and their state over time. The unique key in each report is used to track changes for each item. '
                            }
                            active={active}
                            setActive={setActive}
                            enabled={true}
                        ></TypeBox>
                        {!disableAdvanced && (
                            <AccordionConfig
                                isIncremental={isIncremental}
                                setIsIncremental={setIsIncremental}
                                isMostRecent={isMostRecent}
                                setIsMostRecent={setIsMostRecent}
                                isMultipleLoads={isMultipleLoads}
                                setIsMultipleLoads={setIsMultipleLoads}
                                workbookRange={workbookRange}
                                setWorkbookRange={setWorkbookRange}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                isTransactional={active === 'Transactional'}
                                disableSpreadsheet={disableSpreadsheet}
                            />
                        )}
                    </Box>
                )}
                {isLoading && <ScoopLoader size={72} />}
            </Box>
        </Dialog>
    )
}
