import React, { useCallback, useEffect, useState } from 'react'
import './MetricDialog.css'
import Dialog from '../../common/Dialog/Dialog'
import { Tab } from '../../common/Tab/Tab'
import {
    DEFAULT_METRIC,
    DERIVED_METRIC_TABS,
    getColumn,
    getInbox,
    getPeriodPlural,
    getTable,
    METRIC_PROPS,
    METRIC_TABS,
    NO_FILTER,
    removeFilter,
} from './MetricDialogUtils'
import { ScoopFormat } from '../../common/ScoopFormat/ScoopFormat'
import Input from '../../common/Input/Input'
import { cloneDeep, debounce, merge } from 'lodash'
import { Box, Chip, MenuItem, Tooltip, Typography } from '@mui/material'
import Button from '../../common/Button/Button'
import Selector from '../../common/Selector/Selector'
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg'
import IconButton from '@mui/material/IconButton'
import { FilterCreator } from '../../FilterCreator/FilterCreator'
import { getStringForFilter, unpackFilters } from '../Filter'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import { useSelector } from 'react-redux'
import { useWorkspaceMetadata } from '../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'

export const NewMetricDialog = ({
    open,
    setOpen,
    derived,
    editMetric,
    setEditMetric,
    deleteMetric,
    setDeleteMetric,
}) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const [selectedTab, setSelectedTab] = useState('')
    const [filterCreation, setFilterCreation] = useState(false)
    const [columnSource, setColumnSource] = useState(null)
    const [metricFilters, setMetricFilters] = useState(null)
    const [metric, setMetric] = useState(cloneDeep(DEFAULT_METRIC))
    const [metricName, setMetricName] = useState('')
    const [metricNameError, setMetricNameError] = useState('')
    const [formulaErrorMessage, setFormulaErrorMessage] = useState('')
    const [formulaCalculatedValue, setFormulaCalculatedValue] = useState(0)
    const {
        saveKPI,
        saveKPIPending,
        deleteKPI,
        deleteKPIPending,
        workspaceMetadata,
        validateFormula,
        validateFormulaPending: formulaLoading,
    } = useWorkspaceMetadata()
    const metricNameDebounce = useCallback(
        debounce((value) => {
            if (
                workspaceMetadata.kpis.some(
                    (m) => m.metricName.toLowerCase() === value.toLowerCase().trim()
                )
            ) {
                setMetricNameError('Metric name already in use')
            } else {
                if (metricNameError) setMetricNameError('')
                const newMetric = cloneDeep(metric)
                newMetric.metricName = value
                setMetric(newMetric)
            }
        }, 200),
        [workspaceMetadata, metric, metricNameError]
    )

    useEffect(() => {
        if (open) {
            setSelectedTab(derived ? METRIC_PROPS.FORMULA : METRIC_PROPS.SOURCE)
            if (editMetric) {
                const tempMetric = merge(cloneDeep(DEFAULT_METRIC), editMetric)
                workspaceMetadata.inboxes.forEach((inbox) => {
                    inbox.tables.forEach((table) => {
                        if (table.reportSeriesTableID === tempMetric.reportSeriesTableID) {
                            const colSource = { inbox: inbox, table: table }
                            table.columns.forEach((column) => {
                                if (column.columnName === editMetric.measureName)
                                    colSource.column = column
                            })
                            if (!colSource.column && editMetric.measureName === 'Count') {
                                colSource.isCount = true
                            }
                            setColumnSource(colSource)
                        }
                    })
                })
                setMetricFilters(tempMetric.filter)
                setMetric(tempMetric)
                setMetricName(editMetric.metricName)
            }
        }
    }, [open])

    const handleMetricPropChange = (prop, value) => {
        const newMetric = cloneDeep(metric)
        switch (prop) {
            case 'metricName':
                setMetricName(value)
                metricNameDebounce(value)
                break
            case 'format':
                newMetric.format.formatString = value
                setMetric(newMetric)
                break
            case 'aggRule':
                if (value.startsWith('Cumulative')) {
                    newMetric.cumulative = true
                    if (value === 'CumulativeCount') newMetric.aggRule = 'Count'
                    if (value === 'CumulativeSum') newMetric.aggRule = 'Sum'
                } else {
                    newMetric.cumulative = false
                    newMetric.aggRule = value
                }
                setMetric(newMetric)
                break
            case 'shiftPeriod':
                newMetric.shiftPeriod = value === 'Current' ? null : value
                setMetric(newMetric)
                break
            case 'numPeriodsShifted':
                const parsedValue = parseInt(value, 10)
                newMetric.numPeriodsShifted = Number.isInteger(parsedValue) ? -parsedValue : 0
                setMetric(newMetric)
                break
            default:
                newMetric[prop] = value
                setMetric(newMetric)
                break
        }
    }

    const handleSourceChange = (prop, value) => {
        const newColSource = cloneDeep(columnSource)
        switch (prop) {
            case 'inbox':
                const inbox = getInbox(value, workspaceMetadata)
                setColumnSource({
                    inbox: inbox,
                    table: inbox.tables[0],
                    column: inbox.tables[0].columns[0],
                })
                break
            case 'table':
                const table = getTable(value, newColSource.inbox.inboxID, workspaceMetadata)
                newColSource.table = table
                newColSource.column = table.columns[0]
                setColumnSource(newColSource)
                break
            case 'column':
                const column = getColumn(
                    value,
                    newColSource.table.reportSeriesTableID,
                    newColSource.inbox.inboxID,
                    workspaceMetadata
                )
                newColSource.column = column
                setColumnSource(newColSource)
                break
            case 'dateKey':
                newColSource.dateKey = value
                setColumnSource(newColSource)
                break
        }
    }

    const handleDeleteMetricFilter = (filter) => {
        setMetricFilters((prev) => removeFilter(filter, prev))
    }

    const handleValidateFormula = () => {
        validateFormula({
            formula: metric.formula,
            format: metric.format.formatString,
            onSuccess: (data) => {
                if (data.error) {
                    setFormulaErrorMessage(data.error)
                    setFormulaCalculatedValue(null)
                } else if (data.value) {
                    setFormulaErrorMessage(null)
                    setFormulaCalculatedValue(data.value)
                } else if (data.success) {
                    setFormulaErrorMessage(null)
                    setFormulaCalculatedValue(data.success)
                }
            },
        })
    }

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            setSelectedTab('')
            setFilterCreation(false)
            setColumnSource(null)
            setMetricFilters(null)
            setMetric(cloneDeep(DEFAULT_METRIC))
            setMetricName('')
            setMetricNameError('')
            setFormulaErrorMessage('')
            setFormulaCalculatedValue(0)
            setEditMetric(null)
            setDeleteMetric(false)
        }, 1000)
    }

    const handleSave = () => {
        const metricToSave = cloneDeep(metric)
        if (!metricToSave.derived) {
            metricToSave.reportSeriesTableID = columnSource.table.reportSeriesTableID
            metricToSave.measureName = columnSource.isCount
                ? 'Count'
                : columnSource.column.columnName
            if (metricFilters) metricToSave.filter = metricFilters
            metricToSave.dateKeyIndex = columnSource.dateKey ? columnSource.dateKey : 0
        } else {
            metricToSave.measureName = undefined
            metricToSave.reportSeriesTableID = 'Metric'
        }
        metricToSave.workspaceID = workspaceID
        saveKPI({
            metric: metricToSave,
            onSuccess: handleClose,
        })
    }

    const handleDelete = () => {
        deleteKPI({
            metricID: metric.metricKey,
            onSuccess: handleClose,
        })
    }

    const actions = (
        <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} gap={'8px'}>
            <Button onClick={handleClose} text={'Cancel'} className={'primary-button'} />
            {deleteMetric ? (
                <Button
                    disabled={deleteKPIPending}
                    onClick={handleDelete}
                    className={'primary-button button-purple'}
                >
                    {deleteKPIPending ? (
                        <ScoopLoader />
                    ) : (
                        <Typography className={'inter'}>Delete</Typography>
                    )}
                </Button>
            ) : (
                <Button
                    disabled={
                        !derived
                            ? !columnSource ||
                              !metricName ||
                              filterCreation ||
                              metricNameError ||
                              saveKPIPending
                            : !metricName || !metric.formula
                    }
                    onClick={handleSave}
                    className={'primary-button button-purple'}
                >
                    {saveKPIPending ? (
                        <ScoopLoader />
                    ) : (
                        <Typography className={'inter'}>Save</Typography>
                    )}
                </Button>
            )}
        </Box>
    )

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={
                deleteMetric ? 'Delete KPI' : editMetric ? 'Edit KPI definition' : 'Create new KPI'
            }
            actions={actions}
            style={{ width: '50%', maxWidth: '50%' }}
        >
            {deleteMetric ? (
                <>
                    <Typography className={'inter'}>
                        Are you sure you want to delete {metric.metricName} ?
                    </Typography>
                </>
            ) : filterCreation ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            mb: '10px',
                        }}
                    >
                        <IconButton onClick={() => setFilterCreation(false)} sx={{ mr: '10px' }}>
                            <img src={ArrowLeft} alt={'back'} width={20} />
                        </IconButton>
                        <Typography className={'inter'} sx={{ fontWeight: 600 }}>
                            Create new filter
                        </Typography>
                    </Box>
                    <Box sx={{ height: 'calc(50vh + 65px)' }}>
                        <FilterCreator
                            source={columnSource}
                            metricFilters={metricFilters}
                            setMetricFilters={setMetricFilters}
                            setFilterCreation={setFilterCreation}
                            metric={metric}
                            setMetric={setMetric}
                        />
                    </Box>
                </>
            ) : (
                <>
                    <Input
                        value={metricName}
                        label={'KPI name'}
                        onChange={(e) => handleMetricPropChange('metricName', e.target.value)}
                    />
                    {metricNameError && (
                        <Typography
                            className={'Inter'}
                            sx={{ color: 'red', fontSize: '12px', mt: '-10px' }}
                        >
                            {metricNameError}
                        </Typography>
                    )}
                    <Tab
                        tabs={derived ? DERIVED_METRIC_TABS : METRIC_TABS}
                        value={selectedTab}
                        onChange={(e, v) => {
                            setFilterCreation(false)
                            setSelectedTab(v)
                        }}
                        noPadding
                    />
                    <Box sx={{ width: '100%', height: '50vh', overflow: 'auto' }}>
                        {selectedTab === METRIC_PROPS.FORMULA && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Define a formula to calculate new KPI
                                </Typography>
                                <Input
                                    label="Formula"
                                    multiline
                                    sx={{ width: '100%' }}
                                    containerSx={{ mb: '10px' }}
                                    onChange={(e) =>
                                        handleMetricPropChange('formula', e.target.value)
                                    }
                                    value={metric.formula || ''}
                                />
                                {formulaLoading ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'grid',
                                            placeContent: 'center',
                                        }}
                                    >
                                        <ScoopLoader size={40} />
                                    </Box>
                                ) : (
                                    <>
                                        {formulaErrorMessage && (
                                            <Typography
                                                className={'inter'}
                                                sx={{
                                                    fontStyle: 'italic',
                                                    color: 'red',
                                                    mb: '10px',
                                                }}
                                            >
                                                {formulaErrorMessage}
                                            </Typography>
                                        )}
                                        {!!formulaCalculatedValue && (
                                            <Typography className={'inter'} sx={{ mb: '10px' }}>
                                                Value: {formulaCalculatedValue}
                                            </Typography>
                                        )}
                                    </>
                                )}
                                <Button
                                    className={'button-purple small'}
                                    onClick={handleValidateFormula}
                                >
                                    Validate and test
                                </Button>
                            </>
                        )}
                        {selectedTab === METRIC_PROPS.FORMAT && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Define the correct format for this KPI
                                </Typography>
                                <ScoopFormat
                                    format={metric.format.formatString}
                                    onChange={(s) => handleMetricPropChange('format', s)}
                                />
                            </>
                        )}
                        {selectedTab === METRIC_PROPS.SOURCE && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Specify the source column to be aggregated for this KPI
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Source dataset
                                    </Typography>
                                    <Selector
                                        value={columnSource?.inbox?.inboxID || ''}
                                        onChange={(e) =>
                                            handleSourceChange('inbox', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        {workspaceMetadata?.inboxes?.map((inbox) => {
                                            if (inbox.tables.length === 0) return null
                                            return (
                                                <MenuItem key={inbox.inboxID} value={inbox.inboxID}>
                                                    <Typography className={'inter'}>
                                                        {inbox.label}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Selector>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Source table
                                    </Typography>
                                    <Selector
                                        value={columnSource?.table?.reportSeriesTableID || ''}
                                        onChange={(e) =>
                                            handleSourceChange('table', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        {columnSource?.inbox?.tables?.map((t) => {
                                            if (t.columns.length === 0) return null
                                            return (
                                                <MenuItem
                                                    key={t.reportSeriesTableID}
                                                    value={t.reportSeriesTableID}
                                                >
                                                    {t.tableName}
                                                </MenuItem>
                                            )
                                        })}
                                    </Selector>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Source column
                                    </Typography>
                                    <Selector
                                        value={columnSource?.column?.columnName || ''}
                                        onChange={(e) =>
                                            handleSourceChange('column', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        {columnSource?.table?.columns?.map((c) => (
                                            <MenuItem key={c.columnName} value={c.columnName}>
                                                {c.columnName}
                                            </MenuItem>
                                        ))}
                                    </Selector>
                                </Box>
                                {columnSource?.table?.dates && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            mb: '10px',
                                        }}
                                    >
                                        <Typography
                                            className={'inter'}
                                            sx={{ fontSize: '14px', fontWeight: 600 }}
                                        >
                                            Report date
                                        </Typography>
                                        <Selector
                                            value={columnSource.dateKey ?? 0}
                                            onChange={(e) =>
                                                handleSourceChange('dateKey', e.target.value)
                                            }
                                            sx={{ height: '32px !important' }}
                                        >
                                            <MenuItem key={0} value={0}>
                                                Snapshot Date
                                            </MenuItem>
                                            {columnSource.table.dates.map((dateName, index) => (
                                                <MenuItem key={index + 1} value={index + 1}>
                                                    {dateName}
                                                </MenuItem>
                                            ))}
                                        </Selector>
                                    </Box>
                                )}
                            </>
                        )}
                        {selectedTab === METRIC_PROPS.AGGREGATION && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Define how the column in the table should be aggregated for this
                                    KPI
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Aggregation Type
                                    </Typography>
                                    <Selector
                                        value={
                                            (metric.cumulative
                                                ? 'Cumulative' + metric.aggRule
                                                : metric.aggRule) || ''
                                        }
                                        onChange={(e) =>
                                            handleMetricPropChange('aggRule', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        <MenuItem value={'Count'}>Count</MenuItem>
                                        <MenuItem value={'CountDistinct'}>Count Distinct</MenuItem>
                                        {columnSource?.column?.isMeasure && [
                                            <MenuItem value={'Sum'}>Sum</MenuItem>,
                                            <MenuItem value={'Avg'}>Average</MenuItem>,
                                            <MenuItem value={'Min'}>Min</MenuItem>,
                                            <MenuItem value={'Max'}>Max</MenuItem>,
                                            <MenuItem value={'CumulativeSum'}>
                                                CumulativeSum
                                            </MenuItem>,
                                            <MenuItem value={'CumulativeCount'}>
                                                CumulativeCount
                                            </MenuItem>,
                                        ]}
                                    </Selector>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Aggregation Period
                                    </Typography>
                                    <Selector
                                        value={metric.period || ''}
                                        onChange={(e) =>
                                            handleMetricPropChange('period', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        <MenuItem value={'Daily'}>Daily</MenuItem>
                                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                        <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                                        <MenuItem value={'Annually'}>Annually</MenuItem>
                                    </Selector>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Period End
                                    </Typography>
                                    <Selector
                                        value={metric.calendarType || ''}
                                        onChange={(e) =>
                                            handleMetricPropChange('calendarType', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        <MenuItem value={'Rolling'}>Rolling</MenuItem>
                                        <MenuItem value={'Calendar'}>Calendar</MenuItem>
                                    </Selector>
                                </Box>
                            </>
                        )}
                        {selectedTab === METRIC_PROPS.FILTERS && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Apply filters to limit the aggregation to specific items
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: '10px',
                                    }}
                                >
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Applied filters
                                    </Typography>
                                    <Box className={'applied-filters'}>
                                        {metric.savedFilterID || metricFilters ? (
                                            <>
                                                {workspaceMetadata?.filters?.length > 0 &&
                                                    unpackFilters(
                                                        workspaceMetadata.filters.find(
                                                            (f) =>
                                                                f.savedFilterKey ===
                                                                metric.savedFilterID
                                                        )?.filter
                                                    ).map((f) => {
                                                        const s = getStringForFilter(f)
                                                        return (
                                                            <Tooltip
                                                                title={`SAVED FILTER | ${s}`}
                                                                arrow
                                                            >
                                                                <Chip
                                                                    key={s}
                                                                    label={s}
                                                                    onDelete={() =>
                                                                        handleMetricPropChange(
                                                                            'savedFilterID',
                                                                            null
                                                                        )
                                                                    }
                                                                    sx={{ m: 0.5 }}
                                                                />
                                                            </Tooltip>
                                                        )
                                                    })}
                                                {metricFilters &&
                                                    unpackFilters(metricFilters).map((f) => {
                                                        const s = getStringForFilter(f)
                                                        return (
                                                            <Tooltip
                                                                title={`METRIC FILTER | ${s}`}
                                                                arrow
                                                            >
                                                                <Chip
                                                                    key={s}
                                                                    label={s}
                                                                    onDelete={() =>
                                                                        handleDeleteMetricFilter(f)
                                                                    }
                                                                    sx={{ m: 0.5 }}
                                                                />
                                                            </Tooltip>
                                                        )
                                                    })}
                                            </>
                                        ) : (
                                            <Typography
                                                className={'inter'}
                                                sx={{ fontSize: '14px', color: '#979099' }}
                                            >
                                                Create a filter or select an existing one to apply
                                                to the metric
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: '10px',
                                    }}
                                >
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Saved Filter
                                    </Typography>
                                    <Selector
                                        value={metric.savedFilterID || NO_FILTER}
                                        onChange={(e) => {
                                            const v = e.target.value
                                            handleMetricPropChange(
                                                'savedFilterID',
                                                v === NO_FILTER ? null : v
                                            )
                                        }}
                                        sx={{ height: '32px !important' }}
                                    >
                                        <MenuItem value={NO_FILTER}>No filter</MenuItem>
                                        {workspaceMetadata?.filters?.map((filter) => {
                                            return (
                                                <MenuItem
                                                    key={filter.filterName}
                                                    value={filter.savedFilterKey}
                                                >
                                                    {filter.filterName}
                                                </MenuItem>
                                            )
                                        })}
                                    </Selector>
                                </Box>
                                <Button
                                    className={'button-purple small'}
                                    onClick={() => setFilterCreation(true)}
                                    disabled={!columnSource}
                                    sx={{ mb: '10px' }}
                                >
                                    Create new filter
                                </Button>
                            </>
                        )}
                        {selectedTab === METRIC_PROPS.PERIOD && (
                            <>
                                <Typography className={'inter kpi-tab-subtext'}>
                                    Specify whether this metric refers to the value in the current
                                    period or a value from a prior period
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '10px' }}>
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: '14px', fontWeight: 600 }}
                                    >
                                        Prior period
                                    </Typography>
                                    <Selector
                                        value={metric.shiftPeriod ? metric.shiftPeriod : 'Current'}
                                        onChange={(e) =>
                                            handleMetricPropChange('shiftPeriod', e.target.value)
                                        }
                                        sx={{ height: '32px !important' }}
                                    >
                                        <MenuItem value={'Current'}>Current</MenuItem>
                                        {metric.period === 'Daily' && (
                                            <MenuItem value={'Daily'}>
                                                A number of days ago
                                            </MenuItem>
                                        )}
                                        {(metric.period === 'Daily' ||
                                            metric.period === 'Weekly') && (
                                            <MenuItem value={'Weekly'}>
                                                A number of weeks ago
                                            </MenuItem>
                                        )}
                                        {(metric.period === 'Daily' ||
                                            metric.period === 'Weekly' ||
                                            metric.period === 'Monthly') && (
                                            <MenuItem value={'Monthly'}>
                                                A number of months ago
                                            </MenuItem>
                                        )}
                                        {(metric.period === 'Daily' ||
                                            metric.period === 'Weekly' ||
                                            metric.period === 'Monthly' ||
                                            metric.period === 'Quarterly') && (
                                            <MenuItem value={'Quarterly'}>
                                                A number of quarters ago
                                            </MenuItem>
                                        )}
                                        <MenuItem value={'Annually'}>
                                            A number of years ago
                                        </MenuItem>
                                    </Selector>
                                </Box>
                                {metric.shiftPeriod && (
                                    <>
                                        <Input
                                            label={
                                                'Number of ' +
                                                getPeriodPlural(metric.shiftPeriod) +
                                                ' ago'
                                            }
                                            value={
                                                -(metric.numPeriodsShifted
                                                    ? metric.numPeriodsShifted
                                                    : 0)
                                            }
                                            onChange={(e) =>
                                                handleMetricPropChange(
                                                    'numPeriodsShifted',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                mb: '10px',
                                                mt: '10px',
                                            }}
                                        >
                                            <Typography
                                                className={'inter'}
                                                sx={{ fontSize: '14px', fontWeight: 600 }}
                                            >
                                                Value to use
                                            </Typography>
                                            <Selector
                                                value={metric.metricType || ''}
                                                onChange={(e) =>
                                                    handleMetricPropChange(
                                                        'metricType',
                                                        e.target.value
                                                    )
                                                }
                                                sx={{ height: '32px !important' }}
                                            >
                                                <MenuItem value={'KPI'}>
                                                    Value in prior period
                                                </MenuItem>
                                                <MenuItem value={'KPIDifferenceFromPriorPeriod'}>
                                                    Difference between current and prior period
                                                </MenuItem>
                                                <MenuItem value={'KPIPercentChangeFromPriorPeriod'}>
                                                    % Difference between current and prior period
                                                </MenuItem>
                                            </Selector>
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </>
            )}
        </Dialog>
    )
}
