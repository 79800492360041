import {
    Box,
    Button,
    css,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material'
import SourceIcon from '@mui/icons-material/Source'
import TableChartIcon from '@mui/icons-material/TableChart'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import './Source.css'

export function CompareDialog({ open, setOpen, inbox, server, compareResult }) {
    function handleClose(event) {
        setOpen(false)
    }

    // maxWidth={false} to set to fullWidth, and fullWidth={true}
    return (
        <Box sx={{ width: '100%' }}>
            {compareResult && (
                <Dialog open={open} onClose={handleClose} maxWidth={800}>
                    <DialogTitle sx={{ color: 'text.secondary' }}>{inbox.label}</DialogTitle>
                    <DialogContent sx={{ width: '100%' }}>
                        <DialogContentText>Compare Report Formats in Dataset</DialogContentText>
                        <DataGrid
                            rows={compareResult.rows}
                            columns={compareResult.columns}
                            rowHeight={24}
                            autoHeight
                            disableColumnMenu={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    )
}
