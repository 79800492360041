// SheetletElement.js
import React, { useEffect, useState } from 'react'
import SheetletPlaceholder from './Sheetlet-placeholder.png' // Import the PNG file
import Input from '@mui/material/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateSheetletPosition,
    updateSheetletSize,
    updateSheetletTitle,
} from '../../../store/actions'
import SheetletEdit from './SheetletEdit'
import { ServerContext } from '../../Source/SheetState'
import Sheetlet from '../../Source/Sheetlet'
import { Server } from '../../../api/Server'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'
import AppsIcon from '@mui/icons-material/Apps'
import { renderToString } from 'react-dom/server'
import { OBJECT_TYPES } from '../types'
import { IconButton } from '@mui/material'
import { togglePromptObject } from '../../../store/actions/promptActions'
import Link from '../../../assets/icons/Link.svg'
import LinkBreak from '../../../assets/icons/LinkBreak.svg'
import { ElementInfo } from '../../common/ElementInfo/ElementInfo'

const SheetletElement = ({
    id,
    title,
    initialPosition,
    initialSize,
    content,
    shouldHideGrid,
    shouldHideHeaders,
    presentationScale,
    workspaceID,
    userID,
    token,
    multiProps,
    inboxes,
}) => {
    const sheetletId = `SheetletElement-${id}`
    const worksheetID = content.worksheetID
    const worksheetRange = content.worksheetRange
    const worksheetNum = content.worksheetNum
    const worksheetName = content.worksheetName
    const worksheetURL = content.worksheetURL
    const ariaLabel = { 'aria-label': 'description' }
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token))
    const dispatch = useDispatch()
    const [isSheetletASkeleton, setIsSheetletASkeleton] = React.useState(true) //when there is no Sheetlet data to display
    const [inputTitle, setInputTitle] = useState(title) // Local state for the input title
    const [isEditMode, setIsEditMode] = useState(false) // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [editSheetletOpen, setEditSheetletOpen] = useState(false)
    const [serverContext, setServerContext] = useState(new ServerContext(server))
    const [sheetletContent, setSheetletContent] = useState(null)
    const [wrapperSize, setWrapperSize] = useState(initialSize)
    const [isGridVisible, setIsGridVisible] = useState(!shouldHideGrid || true)
    const [isHeadersVisible, setIsHeadersVisible] = useState(!shouldHideHeaders || true)
    const promptSelectingObjects = useSelector((state) => state.prompt.promptSelectingObjects)
    const promptSelecting = useSelector((state) => state.prompt.promptSelecting)
    const objects = useSelector((state) => state.objects)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const guestPrompts = useSelector((state) => state.auth.guestPrompts)
    const updatedStyles = useSelector(
        (state) => state.objects.find((obj) => obj.id === id)?.wrapperStyles || {}
    )
    const activeMode = useSelector((state) => state.ui.activeMode)

    const getSheetletPrompts = () => {
        let prompts
        if (isGuestMode && guestPrompts.length > 0) prompts = guestPrompts
        else prompts = objects.filter((obj) => obj.type === OBJECT_TYPES.PROMPT)
        return prompts
            .filter((prompt) => prompt.promptProps?.objects.includes(sheetletId))
            .map((prompt) => {
                let result = prompt.promptProps.prompt
                if (prompt.promptProps.refreshInputQueries) {
                    result.refreshInputQueries = true
                }
                return result
            })
            .filter((prompt) => prompt !== null)
    }

    useEffect(() => {
        setIsGridVisible(!shouldHideGrid)
        setIsHeadersVisible(!shouldHideHeaders)
    }, [shouldHideGrid, shouldHideHeaders])

    useEffect(() => {
        if (worksheetID && (worksheetNum !== null || worksheetName !== null || worksheetRange)) {
            // console.log("worksheetRange,worksheetNum, worksheetName ,worksheetURL:",worksheetRange,worksheetNum, worksheetName ,worksheetURL )
            if (!worksheetRange) {
                setSheetletContent({
                    sheetType: 'LiveSheet',
                    worksheetID: worksheetID,
                    sheetNum: worksheetNum,
                    sheetName: worksheetName || null,
                })
            } else {
                setSheetletContent({
                    sheetType: 'LiveSheet',
                    worksheetID: worksheetID,
                    rangeName: worksheetRange,
                })
            }
            setIsSheetletASkeleton(false)
        }
    }, [worksheetID, worksheetNum, worksheetName, worksheetRange])

    useEffect(() => {
        setInputTitle(title)
    }, [title])

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value)
        dispatch(updateSheetletTitle(id, e.target.value)) // Dispatch the UPDATE_SHEETLET_TITLE event
    }

    React.useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [userID, token, workspaceID])

    // Gabe commented this out -- this nullifies serverContext.serverData
    React.useEffect(() => {
        // Use the functional form of setState to ensure the latest server value is used
        // setServerContext(prevServerContext => new ServerContext(prevServerContext.server));
    }, [server])

    const updateSheetletPos = (position) => {
        dispatch(updateSheetletPosition(id, position))
    }

    const updateSheetletSz = (size) => {
        dispatch(updateSheetletSize(id, size))
    }

    const promptSelected = promptSelectingObjects.includes(sheetletId)

    return (
        <ElementWrapper
            id={sheetletId}
            key={sheetletId}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialSize={initialSize}
            initialPosition={initialPosition}
            updateElementPosition={updateSheetletPos}
            updateElementSize={updateSheetletSz}
            setWrapperSizeTrack={setWrapperSize}
            type={'sheetlet'}
            {...multiProps}
            objectId={id}
        >
            {promptSelecting && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        className={'prompt-link-button'}
                        onClick={() => dispatch(togglePromptObject(sheetletId))}
                        sx={{
                            backgroundColor: promptSelected
                                ? '#6191F2 !important'
                                : '#E8EFFC !important',
                        }}
                    >
                        {promptSelected ? (
                            <img src={Link} alt={'linked'} />
                        ) : (
                            <img src={LinkBreak} alt={'unlinked'} />
                        )}
                    </IconButton>
                </div>
            )}
            {(isEditMode || activeMode === 'cursor') && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                    >
                        <SheetletEdit
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            isSheetletASkeleton={isSheetletASkeleton}
                            setIsSheetletASkeleton={setIsSheetletASkeleton}
                            server={server}
                            title={title}
                            id={id}
                            worksheetID={worksheetID}
                            worksheetRange={worksheetRange}
                            worksheetNum={worksheetNum}
                            worksheetName={worksheetName}
                            worksheetURL={worksheetURL}
                            handleTitleChange={handleTitleChange}
                            editSheetletOpen={editSheetletOpen}
                            setEditSheetletOpen={setEditSheetletOpen}
                            shouldHideGrid={!isGridVisible}
                            shouldHideHeaders={!isHeadersVisible}
                            inboxes={inboxes}
                            isCursorMode={activeMode === 'cursor'}
                            isEditMode={isEditMode}
                        />
                    </div>
                    {isEditMode && activeMode === 'edit' && (
                        <ElementInfo id={id} type={'Sheetlet'} title={title ?? 'Untitled'} />
                    )}
                </>
            )}
            {updatedStyles.showTitle && (
                <div
                    style={{
                        height: '40px',
                        width: '50%',
                        paddingTop: 10,
                        paddingLeft: 10,
                    }}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder={title}
                        inputProps={ariaLabel}
                        margin="dense"
                        value={inputTitle}
                        onChange={handleTitleChange}
                    />
                </div>
            )}
            {isSheetletASkeleton && (
                <div
                    style={{
                        height: '80%',
                        width: '100%',
                        backgroundImage: `url(${SheetletPlaceholder})`, // Use the imported PNG as a background image
                        backgroundSize: 'contain', // Scale the image to fit within the container
                        backgroundRepeat: 'no-repeat', // Prevent image from repeating
                        backgroundPosition: 'center', // Center the image
                    }}
                />
            )}
            {!isSheetletASkeleton && (
                <div
                    style={{
                        width: '100%',
                        height: updatedStyles.showTitle ? 'calc(100% - 40px)' : '100%',
                        backgroundColor: 'transparent',
                        overflow: 'hidden',
                    }}
                >
                    <Sheetlet
                        presentationScale={presentationScale}
                        sheetletId={sheetletId}
                        serverContext={serverContext}
                        sheetRange={sheetletContent}
                        embeddedSizeProps={{
                            left: 0,
                            height: updatedStyles.showTitle ? wrapperSize.height - 40 : '100%',
                        }}
                        shouldHideGrid={!isGridVisible}
                        shouldHideHeaders={!isHeadersVisible}
                        activePrompts={getSheetletPrompts()}
                        cornerHTML={
                            '<a href="https://docs.google.com/spreadsheets/d/' +
                            worksheetID +
                            '" target="_blank">' +
                            renderToString(<AppsIcon />) +
                            '</a>'
                        }
                    />
                </div>
            )}
        </ElementWrapper>
    )
}

export default SheetletElement
