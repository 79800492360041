import { Box, Divider } from '@mui/material'
import { mainToolbarItems } from './toolbarItems'
import React, { useEffect, useState } from 'react'
import './ToolBar.css'
import ToolBarIcon from './ToolBarIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
    toggleSnap,
    setBackgroundColor,
    setShowFrameDrawer,
    setShowEditElementWrapper,
} from '../../store/actions/uiActions'
import FlowArrow from '../../assets/icons/FlowArrow.svg'
import TextAa from '../../assets/icons/TextAa.svg'
import SelectionInverse from '../../assets/icons/SelectionInverse.svg'
import SelectionSlash from '../../assets/icons/SelectionSlash.svg'
import ImageIcon from '../../assets/icons/Image.svg'
import VideoIcon from '../../assets/icons/FilmSlate.svg'
import CameraIcon from '../../assets/icons/Camera.svg'
import PencilSimple from '../../assets/icons/PencilSimple.svg'
import { OBJECT_TYPES } from '../Objects/types'
import { ScoopColorPicker } from '../common/ScoopColorPicker/ScoopColorPicker'
import html2canvas from 'html2canvas'
import sha256 from 'crypto-js/sha256'
import { useParams } from 'react-router-dom'
import { useApi } from '../../api/api'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import SidebarRight from '../../assets/icons/SidebarRight.svg'
import { Toast } from '../common/Toast/Toast'

const ToolBar = ({ setItemToAdd, itemToAdd, inboxes }) => {
    const dispatch = useDispatch()
    const objects = useSelector((state) => state.objects)
    const snap = useSelector((state) => state.ui.snap)
    const zoom = useSelector((state) => state.ui.zoom)
    const presentationID = useSelector((state) => state.ui.presentationID)
    const selectedBackgroundColor = useSelector((state) => state.ui.backgroundColor || '#FFFFFF')
    const lastSaved = useSelector((state) => state.ui.lastSaved)
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const canvasName = useSelector((state) => state.auth.canvasName)
    const { postData } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2'
    )
    const [objectsMenuOpen, setObjectsMenuOpen] = useState(false)
    const [hoveredBox, setHoveredBox] = useState(null)
    const [thumbLoading, setThumbLoading] = useState(false)
    const { canvasID } = useParams()
    const [toast, setToast] = useState(false)

    const handleMouseEnter = (boxId) => {
        setHoveredBox(boxId)
    }

    const handleMouseLeave = () => {
        setHoveredBox(null)
    }

    const handleItemClick = (option) => {
        if (option === 'Objects') {
            setObjectsMenuOpen(true)
            return
        }
        itemToAdd && itemToAdd === option ? setItemToAdd(null) : setItemToAdd(option)
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.id === 'scoop-canvas') {
                setObjectsMenuOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const captureGenericThumbnail = () => {
        const frames = objects.filter((o) => o.type === OBJECT_TYPES.FRAME)
        if (frames.length > 0) {
            setThumbLoading(true)
            frames.sort((a, b) => a.presentationIndex - b.presentationIndex)
            const firstFrame = frames[0]
            const frameElement = document.getElementById(`FrameElement-${firstFrame.id}`)
            const scoopCanvas = document.getElementById('scoop-canvas')
            const canvasScrollable = document.getElementById('canvas-scrollable')
            if (!frameElement || !scoopCanvas) return
            const frameX = firstFrame.x + canvasScrollable.scrollLeft
            const frameY = firstFrame.y - 115 + canvasScrollable.scrollTop
            const frameWidth = firstFrame.width
            const frameHeight = firstFrame.height
            html2canvas(scoopCanvas, {
                useCORS: true,
                allowTaint: true,
                x: frameX * zoom,
                y: frameY * zoom,
                width: frameWidth * zoom,
                height: frameHeight * zoom,
            })
                .then((capturedCanvas) => {
                    let thumbnail
                    const scaleWidthLimit = 1920
                    // perform image resize if it is too big
                    if (capturedCanvas.width > scaleWidthLimit) {
                        const canvasAspectRatio = capturedCanvas.width / capturedCanvas.height
                        const tempCanvas = document.createElement('canvas')
                        const newWidth = scaleWidthLimit
                        const newHeight = scaleWidthLimit / canvasAspectRatio
                        tempCanvas.setAttribute('width', newWidth)
                        tempCanvas.setAttribute('height', newHeight)
                        const ctx = tempCanvas.getContext('2d')
                        ctx.drawImage(
                            capturedCanvas,
                            0,
                            0,
                            capturedCanvas.width,
                            capturedCanvas.height,
                            0,
                            0,
                            newWidth,
                            newHeight
                        )
                        thumbnail = tempCanvas.toDataURL('image/png')
                    } else {
                        thumbnail = capturedCanvas.toDataURL('image/png')
                    }
                    const checksum = sha256(JSON.stringify(objects)).toString()
                    const action = {
                        action: 'saveCanvas',
                        userID: userID,
                        workspaceID: workspaceID,
                        canvasID: canvasID,
                        canvasName: canvasName,
                        canvasObjects: objects,
                        canvasImage: thumbnail,
                        zoom: zoom,
                        lastSaved: lastSaved,
                        background: selectedBackgroundColor,
                        presentationID: presentationID,
                        checksum: checksum,
                        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                    }
                    postData(action).then(() => {
                        setToast(true)
                        setThumbLoading(false)
                    })
                })
                .catch((error) => console.error('Error capturing frame:', error))
        }
    }

    return (
        <>
            {toast && (
                <Toast
                    alert={{
                        message: 'Thumbnail generated successfully!',
                        severity: 'success',
                    }}
                    onClose={() => setToast(false)}
                />
            )}
            <Box className={'toolbar-container'} onMouseLeave={handleMouseLeave}>
                {mainToolbarItems.map((item, index) => (
                    <ToolBarIcon
                        key={index}
                        label={item.text}
                        icon={item.icon}
                        tooltipTitle={item.text}
                        tooltipPlacement={'right'}
                        onClick={() => {
                            handleMouseLeave()
                            handleItemClick(item.text)
                        }}
                        tooltipOpen={hoveredBox}
                        onMouseEnter={() => handleMouseEnter(item.text)}
                        selected={item.text === itemToAdd}
                    />
                ))}

                <Divider />

                <ToolBarIcon
                    key={'edit-element-wrappers'}
                    label={'Edit wrappers'}
                    icon={PencilSimple}
                    tooltipTitle={'Edit wrappers'}
                    tooltipPlacement={'right'}
                    onClick={() => dispatch(setShowEditElementWrapper([true, undefined]))}
                    tooltipOpen={hoveredBox}
                    onMouseEnter={() => handleMouseEnter('Edit wrappers')}
                />

                <ToolBarIcon
                    key={'snap-to-grid'}
                    label={'Snap to grid'}
                    icon={snap ? SelectionInverse : SelectionSlash}
                    tooltipTitle={snap ? 'Snap to grid on' : 'Snap to grid off'}
                    tooltipPlacement={'right'}
                    onClick={() => dispatch(toggleSnap())}
                    tooltipOpen={hoveredBox}
                    onMouseEnter={() => handleMouseEnter('Snap to grid')}
                />

                {thumbLoading ? (
                    <ScoopLoader size={30} />
                ) : (
                    <ToolBarIcon
                        key={'thumbnail'}
                        label={'thumbnail'}
                        icon={CameraIcon}
                        tooltipTitle={'Capture thumbnail'}
                        tooltipPlacement={'right'}
                        onClick={captureGenericThumbnail}
                        tooltipOpen={hoveredBox}
                        onMouseEnter={() => handleMouseEnter('thumbnail')}
                    />
                )}

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ScoopColorPicker
                        height={20}
                        width={20}
                        value={selectedBackgroundColor}
                        origin={'right'}
                        onChange={(color) => dispatch(setBackgroundColor(color))}
                        tooltip
                    />
                </Box>

                <ToolBarIcon
                    key={'frame-panel'}
                    label={'frame-panel'}
                    icon={SidebarRight}
                    tooltipTitle={'Manage frames'}
                    tooltipPlacement={'right'}
                    onClick={() => dispatch(setShowFrameDrawer(true))}
                    tooltipOpen={hoveredBox}
                    onMouseEnter={() => handleMouseEnter('frame-panel')}
                />

                {objectsMenuOpen && (
                    <Box id={'objects-menu'} className={'objects-menu-container'}>
                        <div style={{ color: '#2B1630' }}>Objects</div>
                        <div style={{ color: '#979099' }}>Add text, shapes, images and videos</div>
                        <Box className={'objects-icons-container'}>
                            <ToolBarIcon
                                selected={itemToAdd === 'Text Editor'}
                                icon={TextAa}
                                onClick={() => handleItemClick('Text Editor')}
                            />
                            <ToolBarIcon
                                selected={itemToAdd === 'Arrow'}
                                icon={FlowArrow}
                                onClick={() => handleItemClick('Arrow')}
                            />
                            <ToolBarIcon
                                selected={itemToAdd === 'Image'}
                                icon={ImageIcon}
                                onClick={() => handleItemClick('Image')}
                            />
                            <ToolBarIcon
                                selected={itemToAdd === 'Video'}
                                icon={VideoIcon}
                                onClick={() => handleItemClick('Video')}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}
export default ToolBar
