import { Box, Typography } from '@mui/material'
import Button from '../common/Button/Button'
import * as React from 'react'
import Dialog from '../common/Dialog/Dialog'
import { renderSelector } from '../screens/Explorer/DrawerTabs/Style/utils'
import { useEffect, useState } from 'react'
import Input from '../common/Input/Input'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import { Server } from '../../api/Server'
import { useSelector } from 'react-redux'
import { logEvent } from '../../api/hooks/logs/api'

const typeOptions = [
    { label: 'Column', value: 'column' },
    { label: 'Line', value: 'line' },
    { label: 'Bar', value: 'bar' },
    { label: 'Pie', value: 'pie' },
    { label: 'Table', value: 'table' },
    { label: 'Kpi', value: 'kpi' },
]

export const GenerateInsightDialog = ({
    open,
    onClose,
    inboxes,
    onGenerateSuccess,
    onGenerateError,
    generateInsight,
    workspaceMetadata,
}) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const [selectedInbox, setSelectedInbox] = useState(inboxes?.[0]?.inboxID)
    const [selectedInboxObject, setSelectedInboxObject] = useState()
    const [selectedType, setSelectedType] = useState('column')
    const [numSlides, setNumSlides] = useState(5)
    const [prompt, setPrompt] = useState('')
    const [isGenerateInsightLoading, setIsGenerateInsightLoading] = useState(false)
    const [maxSlidesExceeded, setMaxSlidesExceeded] = useState(false)
    const [server, setServer] = useState(new Server(workspaceID, userID, token))
    const [title, setTitle] = useState('Presentation')

    const maxSlides = workspaceMetadata && workspaceMetadata.workspaceName.includes('Tour') ? 5 : 10

    useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [workspaceID, userID, token])

    useEffect(() => {
        setSelectedInbox(inboxes?.[0]?.inboxID)
    }, [inboxes])

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={onClose} text={'Cancel'} />
            <Button
                disabled={
                    isGenerateInsightLoading ||
                    !selectedInbox ||
                    (generateInsight && !selectedType) ||
                    (!generateInsight && numSlides > maxSlides) ||
                    !prompt
                }
                onClick={() => {
                    logEvent('NewAIPrompt', [
                        { title: 'User typed AI prompt.', prompt: JSON.stringify(prompt) },
                    ])
                    handleGenerateInsight(
                        selectedInbox,
                        selectedType,
                        prompt,
                        title,
                        Number(numSlides)
                    )
                }}
                text={isGenerateInsightLoading ? <ScoopLoader /> : 'Generate'}
                className={'button-purple'}
            />
        </Box>
    )

    const handleSlidesNumberChange = (e) => {
        const value = e.target.value
        if (value <= maxSlides && value > 0) {
            setNumSlides(value)
        }
    }

    const handleGenerateInsight = (inboxID, type, prompt, title, numSlides) => {
        setIsGenerateInsightLoading(true)
        const action = {
            action: generateInsight ? 'generateAIInsight' : 'generateAIPresentation',
            inboxID,
            prompt,
            title,
            ...(generateInsight ? { type } : { numSlides }),
        }
        server.sheetPostData(action, (response) => {
            if (response.status === 'error') {
                onGenerateError(response?.error)
            } else if (response.error) {
                onGenerateError(response.error)
            } else if (response.message) {
                onGenerateError(response.message)
            } else {
                onGenerateSuccess(response)
            }
            setIsGenerateInsightLoading(false)
            onClose()
        })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={`Generate a New ${generateInsight ? 'Summary' : 'Presentation'} With AI`}
            actions={actions}
            style={{ width: 600 }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {/*<Typography>
                    Generate a new {generateInsight ? 'summary' : 'presentation'} with AI related to
                    the selected dataset. Type a prompt instructing AI to generate a{' '}
                    {generateInsight ? 'summary' : 'presentation'} leveraging the data in the
                    selected dataset below.
                </Typography>*/}
                {generateInsight ? (
                    <Typography>
                        Generate a new summary with AI related to the selected dataset. Type a
                        prompt instructing AI to generate a summary leveraging the data in the
                        selected dataset below.
                    </Typography>
                ) : (
                    <Typography>
                        Type a prompt instructing our AI to generate a presentation leveraging the
                        selected dataset below.
                    </Typography>
                )}
                <Box sx={{ height: '50px', display: 'flex', gap: '16px' }}>
                    {renderSelector(
                        'Select Dataset',
                        selectedInbox,
                        (inbox) => {
                            setSelectedInbox(inbox)
                            let newSelectedInboxObject =
                                inbox && inboxes
                                    ? inboxes.filter((inbox) => inbox.inboxID === selectedInbox)
                                    : null
                            setSelectedInboxObject(newSelectedInboxObject)
                        },
                        inboxes
                            .filter(
                                (inbox) =>
                                    inbox.label !== 'Pipeline Analysis' ||
                                    (workspaceMetadata &&
                                        !workspaceMetadata.workspaceName.includes('Tour'))
                            )
                            .map((inbox) => ({
                                label: inbox.label,
                                value: inbox.inboxID,
                            }))
                    )}
                    <Box sx={{ width: generateInsight ? '70%' : '50%' }}>
                        {generateInsight ? (
                            renderSelector(
                                'Select Chart Type',
                                selectedType,
                                (type) => setSelectedType(type),
                                typeOptions
                            )
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Input
                                    value={numSlides}
                                    onChange={(e) => handleSlidesNumberChange(e)}
                                    type={'number'}
                                    label={'Number of Slides'}
                                    fullWidth
                                />
                                {maxSlidesExceeded && (
                                    <p style={{ color: 'red', fontSize: '10px', margin: '0' }}>
                                        {maxSlides} is the maximum number allowed
                                    </p>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
                {selectedInboxObject &&
                    selectedInbox &&
                    selectedInboxObject.length > 0 &&
                    !selectedInboxObject[0].hasSemantics && (
                        <Typography color={'red'}>
                            Note: The dataset you selected doesn’t include detailed information
                            about its content. The AI will make its best effort to infer meaning
                            based on column names, row headings, and other visible context. For
                            improved results, try adding more descriptive labels or notes to your
                            dataset to provide additional clarity.
                        </Typography>
                    )}
                {selectedInboxObject &&
                    selectedInboxObject.length > 0 &&
                    selectedInboxObject[0].description && (
                        <>
                            <Box
                                sx={{ mt: 1, mb: 0 }}
                                style={{ fontSize: '12px', fontWeight: '600' }}
                                className={'inter'}
                            >
                                Dataset Description
                            </Box>
                            <Box sx={{ mt: 0, mb: 1 }}>{selectedInboxObject[0].description}</Box>
                        </>
                    )}
                {!generateInsight && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <Box
                            sx={{ mt: 0, mb: 0 }}
                            style={{ fontSize: '12px', fontWeight: '600' }}
                            className={'inter'}
                        >
                            Presentation Title
                        </Box>
                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            padding={0.3}
                        />
                    </Box>
                )}
                <Box>
                    <p
                        style={{ fontSize: '12px', fontWeight: '600', margin: '0 0 4px 0' }}
                        className={'inter'}
                    >
                        Type your prompt here
                    </p>
                    <Input
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        multiline
                        rows={4}
                        placeholder={'Start typing your prompt here...'}
                        fullWidth
                        padding={'0'}
                    />
                </Box>
            </Box>
        </Dialog>
    )
}
