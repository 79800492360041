import React, { useEffect, useState } from 'react'
import Dialog from '../../../common/Dialog/Dialog'
import Selector from '../../../common/Selector/Selector'
import Button from '../../../common/Button/Button'
import {
    Box,
    Typography,
    Modal,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    InputLabel,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'
import { Label } from '@mui/icons-material'

// import styles from "./filters.module.scss";

const max_checkboxes_to_show = 20

const moment = require('moment')

const Filters = ({
    isOpen,
    toggle,
    applyFilters,
    dropdownFilter,
    // selectedFilters,
    selectedFiltersV2,
    selectedFilter,
    // setSelectedFilters,
    setSelectedFiltersV2,
    setSelectedFilter,
    preliminaryData,
    selectedReportSeriesTable,
    filterSnapshotDate,
    setFilterSnapshotDate,
}) => {
    const [typeahead, setTypeahead] = useState('')
    const [needTypeAhead, setNeedTypeAhead] = useState(false)
    const currentDate = new Date()

    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth() + 1)
    const [selectedDay, setSelectedDay] = useState(currentDate.getDate())
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear())
    const [isOptionalChecked, setIsOptionalChecked] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [checkboxState, setCheckboxState] = useState([])

    const [filterTexts, setFilterTexts] = useState({})

    useEffect(() => {
        if (preliminaryData && preliminaryData.length > 0) {
            const updatedState = preliminaryData.map((item) => ({
                ...item,
                changeColumns: item.changeColumns.map((column) => ({
                    ...column,
                    values: column.values.map((value) => ({
                        name: value,
                        // Check if the value is in the selected filters
                        isChecked:
                            selectedFiltersV2?.some(
                                (filter) =>
                                    filter.key === value &&
                                    filter.selectedFilter === column.columnName
                            ) || false,
                    })),
                })),
            }))
            setCheckboxState(updatedState)
        }
    }, [preliminaryData, selectedFiltersV2]) // Include selectedFiltersV2 in the dependency array

    const handleFilterTextChange = (text, columnName) => {
        setFilterTexts((prev) => ({ ...prev, [columnName]: text }))
    }

    const months = [
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ]

    const days = Array.from({ length: 31 }, (_, i) => ({
        value: `${i + 1}`,
        label: `${i + 1}`,
    }))

    const years = Array.from({ length: 2 }, (_, i) => {
        const year = new Date().getFullYear()
        return { value: `${year - i}`, label: `${year - i}` }
    })

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    }

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value)
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value)
    }

    const handleOptionalCheckboxChange = () => {
        setIsOptionalChecked(!isOptionalChecked)
    }

    useEffect(() => {
        if (isOptionalChecked) {
            const formattedDate = moment(
                `${selectedYear}-${selectedMonth}-${selectedDay}`,
                'YYYY-MM-DD'
            ).format('YYYY-MM-DD')
            setFilterSnapshotDate(formattedDate)
            console.log('debug> formattedDate: ', formattedDate)
        } else {
            setFilterSnapshotDate(null)
        }
    }, [selectedDay, selectedMonth, selectedYear, isOptionalChecked])

    useEffect(() => {
        if (filterSnapshotDate !== null) {
            const [year, month, day] = filterSnapshotDate.split('-')
            setSelectedMonth(parseInt(month, 10))
            setSelectedDay(parseInt(day, 10))
            setSelectedYear(parseInt(year, 10))
            setIsOptionalChecked(true)
        }
    }, [filterSnapshotDate])

    //filter selection changed, so show listed of values for this filter
    // const filterSelected = (optionSelected, setFieldValue, updatedTypeahead) => {
    //     if (preliminaryData) {
    //         console.log("debug> selectedReportSeriesTable: ", selectedReportSeriesTable);
    //         let report = preliminaryData.filter(
    //             (d) => d.reportSeriesTableID == selectedReportSeriesTable
    //         );
    //         if (report.length) {
    //             let found = report[0].changeColumns.filter(
    //                 (r) => r.columnName == optionSelected.value
    //             );
    //             if (found.length > 0) {
    //                 setNeedTypeAhead(false);
    //                 // console.log("debug> typeahead: ", typeahead);
    //                 let tempSelectedFilters = [];
    //                 let i = 0;
    //                 found[0].values.map((v) => {
    //                     if (i < max_checkboxes_to_show && v.indexOf(updatedTypeahead) > -1) {
    //                         // determine if this checkbox should be checked
    //                         let isChecked = false;
    //                         const tempFilters = _.cloneDeep(selectedFiltersV2);
    //                         tempFilters.filter((x) => {
    //                             if (x.name == v && x.selectedFilter == optionSelected.value) {
    //                                 isChecked = true;
    //                             }
    //                         });
    //                         tempSelectedFilters.push({
    //                             isChecked: isChecked,
    //                             key: `${v}`,
    //                             name: `${v}`,
    //                             filterVal: `${v}`,
    //                             selectedFilter: optionSelected.value,
    //                         });
    //                         i++;
    //                     } else {
    //                         setNeedTypeAhead(true);
    //                     }
    //                 });
    //                 // console.log("debug> setting seelctedFilters to: ", tempSelectedFilters);
    //                 setSelectedFiltersV2(tempSelectedFilters);
    //             }
    //         }
    //         // setFieldValue("selectedFilter", optionSelected);
    //         setSelectedFilter(optionSelected.value);
    //     }
    // };

    // const handleCheckboxChange = ({ target }) => {
    //     const tempFilters = _.cloneDeep(selectedFiltersV2);
    //     const index = tempFilters.findIndex((x) => x.name === target.name);
    //     // checkbox filter item found, so assume user is turning it off and remove from selectedFiltersv2
    //     if (index !== -1) {
    //         // remove from selectedFiltersv2
    //         tempFilters.splice(index, 1);
    //         setSelectedFiltersV2(tempFilters);
    //         // set checkbox to false in selectedFilters
    //         const tempSelectedFilters = _.cloneDeep(selectedFiltersV2);
    //         tempSelectedFilters.map((x) => {
    //             if (x.name == target.name) {
    //                 x.isChecked = false;
    //             }
    //         });
    //         setSelectedFiltersV2(tempSelectedFilters);

    //     } else {
    //         tempFilters.push({
    //             isChecked: true,
    //             key: `${target.name}`,
    //             name: `${target.name}`,
    //             filterVal: `${target.name}`,
    //             selectedFilter: selectedFilter,
    //         });
    //         setSelectedFiltersV2(tempFilters);
    //         // set checkbox to true in selectedFilters
    //         const tempSelectedFilters = _.cloneDeep(selectedFiltersV2);
    //         tempSelectedFilters.map((x) => {
    //             if (x.name == target.name) {
    //                 x.isChecked = true;
    //             }
    //         });
    //         setSelectedFiltersV2(tempSelectedFilters);
    //     }
    // };

    const countSelectedCheckboxes = (columnName) => {
        let count = 0
        count = selectedFiltersV2.filter((x) => x.selectedFilter == columnName).length

        return count > 0 ? (
            <>
                <span style={{ fontSize: 'small', color: 'blue' }}> ({count} selected)</span>
            </>
        ) : (
            <></>
        )
    }

    const handleCheckboxChange = (event, columnName, valueName) => {
        // Update the local state
        const updatedState = checkboxState.map((item) => ({
            ...item,
            changeColumns: item.changeColumns.map((column) => ({
                ...column,
                values: column.values.map((value) =>
                    value.name === valueName ? { ...value, isChecked: event.target.checked } : value
                ),
            })),
        }))

        setCheckboxState(updatedState)

        // Update selectedFiltersV2
        if (event.target.checked) {
            setSelectedFiltersV2((prev) =>
                Array.isArray(prev)
                    ? [
                          ...prev,
                          {
                              isChecked: true,
                              key: valueName,
                              name: valueName,
                              filterVal: valueName,
                              selectedFilter: columnName,
                          },
                      ]
                    : [
                          {
                              isChecked: true,
                              key: valueName,
                              name: valueName,
                              filterVal: valueName,
                              selectedFilter: columnName,
                          },
                      ]
            )
        } else {
            setSelectedFiltersV2((prev) =>
                Array.isArray(prev) ? prev.filter((filter) => filter.key !== valueName) : []
            )
        }
    }

    const handleTypeaheadChange = (event) => {
        setTypeahead(event.target.value)
    }

    // TO-do: used for filter debugging but delete eventually
    useEffect(() => {
        // console.log("1) selectedFilters: ", selectedFilters)
        // console.log("2) selectedFiltersV2: ", selectedFiltersV2)
        // console.log("3) filterSnapshotDate: ", filterSnapshotDate)
    }, [selectedFiltersV2, filterSnapshotDate])

    // Actions for the modal
    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={toggle} className="primary-button" text="Cancel" />
            <Button
                onClick={applyFilters}
                className="primary-button button-purple"
                text="Apply Filters"
            />
        </Box>
    )

    return (
        <Dialog onClose={toggle} open={isOpen} title="Apply Filters" actions={actions} fullWidth>
            <Box
                sx={
                    {
                        /* Add your modal styling here */
                    }
                }
            >
                <Box component="form">
                    {/* Accordion for filters */}
                    {checkboxState?.map((dataItem, dataIndex) =>
                        dataItem.changeColumns.map((column, columnIndex) => {
                            // Create a string of selected item names
                            const selectedItems = column.values
                                .filter((value) => value.isChecked)
                                .map((value) => value.name)
                                .join(', ')

                            return (
                                <Accordion key={`${dataIndex}-${columnIndex}`}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{column.columnName}</Typography>
                                        {/* Displaying selected items */}
                                        {selectedItems && (
                                            <Box
                                                sx={{
                                                    marginLeft: 2,
                                                    color: 'blue',
                                                    fontSize: 'small',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                ({selectedItems})
                                            </Box>
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {column.values.length > 10 && (
                                            <TextField
                                                placeholder={`Filter ${column.columnName}`}
                                                value={filterTexts[column.columnName] || ''}
                                                onChange={(e) =>
                                                    handleFilterTextChange(
                                                        e.target.value,
                                                        column.columnName
                                                    )
                                                }
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                            />
                                        )}
                                        {column.values
                                            .filter(
                                                (value) =>
                                                    !filterTexts[column.columnName] ||
                                                    value.name
                                                        .toLowerCase()
                                                        .includes(
                                                            filterTexts[
                                                                column.columnName
                                                            ].toLowerCase()
                                                        )
                                            )
                                            .slice(0, 10)
                                            .map((value, valueIndex) => (
                                                <FormControlLabel
                                                    key={`${dataIndex}-${columnIndex}-${valueIndex}`}
                                                    control={
                                                        <Checkbox
                                                            checked={value.isChecked || false}
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    e,
                                                                    column.columnName,
                                                                    value.name
                                                                )
                                                            }
                                                            name={value.name}
                                                        />
                                                    }
                                                    label={value.name}
                                                />
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    )}

                    {/* Date Selection */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 2,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOptionalChecked}
                                    onChange={handleOptionalCheckboxChange}
                                />
                            }
                            label="Snapshot Date (optional)"
                        />
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            disabled={!isOptionalChecked}
                        >
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            value={selectedDay}
                            onChange={handleDayChange}
                            disabled={!isOptionalChecked}
                        >
                            {days.map((day) => (
                                <MenuItem key={day.value} value={day.value}>
                                    {day.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            value={selectedYear}
                            onChange={handleYearChange}
                            disabled={!isOptionalChecked}
                        >
                            {years.map((year) => (
                                <MenuItem key={year.value} value={year.value}>
                                    {year.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default Filters
