import { Box, Menu, MenuItem, Popover, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dialog from '../../../common/Dialog/Dialog'
import { Drawer } from '../../../common/Drawer/Drawer'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import Button from '../../../common/Button/Button'
import Key from '../../../../assets/icons/Key.svg'
import { DataGrid } from '../../../common/DataGrid/DataGrid'
import { PropertiesDialog } from './PropertiesDialog'
import DeleteDialog from '../../../common/Dialog/DeleteDialog'
import DotsThree from '../../../../assets/icons/DotsThree.svg'
import TextAa from '../../../../assets/icons/TextAa.svg'
import TrashRed from '../../../../assets/icons/TrashRed.svg'
import Input from '../../../common/Input/Input'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { useReportSeriesMetadata } from '../../../../api/hooks/datasource/hook'

const getGridColType = (scoopType) => {
    switch (scoopType) {
        case 'String':
            return 'string'
        case 'Decimal':
            return 'number'
        case 'DateTime':
            return 'dateTime'
        case 'Currency':
            return 'number'
        case 'Boolean':
            return 'boolean'
        default:
            return 'string'
    }
}
const transform = (ctype, val) => {
    if (ctype) {
        if (ctype === 'DateTime') {
            return val === null ? null : new Date(val)
        }
        return val
    }
    return val
}

const ServerDay = (props) => {
    const { ingestions = [], day, outsideCurrentMonth, ...other } = props
    const propDateStr = props.day.format('MM/DD/YYYY')
    const isSelected = ingestions.indexOf(propDateStr) >= 0

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '🌚' : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    )
}

export const TableBox = ({ table, server, setAlert, getMetadata, readOnly }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openTable, setOpenTable] = useState(false)
    const [rows, setRows] = useState([])
    const [openRawTableData, setOpenRawTableData] = useState(false)
    const [previewColumns, setPreviewColumns] = useState()
    const [previewRows, setPreviewRows] = useState()
    const [ingestions, setIngestions] = useState()
    const [ingestionMap, setIngestionMap] = useState()
    const [dateValue, setDateValue] = useState(null)
    const [openPropertiesDialog, setOpenPropertiesDialog] = useState(false)
    const [ignoreTable, setIgnoreTable] = useState(false)
    const [userTableName, setUserTableName] = useState(null)
    const [profileColumn, setProfileColumn] = useState(null)
    const [profileData, setProfileData] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openRenameDialog, setOpenRenameDialog] = useState(false)
    const [newTableName, setNewTableName] = useState(table?.tableName)

    const staticColumns = [
        {
            field: 'identifier',
            flex: 8,
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {params.value && <img src={Key} alt={'Key'} />}
                </Box>
            ),
            renderHeader: (params) => <Box className="tableHeader">Key</Box>,
        },

        {
            field: 'name',
            flex: 15,
            renderHeader: (params) => <Box className="tableHeader">Column Name</Box>,
        },
        {
            field: 'measure',
            align: 'center',
            headerAlign: 'center',
            flex: 15,
            valueGetter: ({ row }) => {
                if (row.isMeasure) {
                    return 'Measure'
                }
                return 'Attribute'
            },
            renderHeader: (params) => <Box className="tableHeader">Measure/Attribute</Box>,
        },
        {
            field: 'type',
            align: 'center',
            headerAlign: 'center',
            flex: 10,
            renderHeader: (params) => <Box className="tableHeader">Type</Box>,
        },
        {
            field: 'profile',
            align: 'center',
            headerAlign: 'center',
            flex: 9,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            fontWeight: 600,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setProfileColumn(params.row.name)
                            setOpenPropertiesDialog(true)
                        }}
                    >
                        {'View Profile'}
                    </Box>
                )
            },
            renderHeader: (params) => <Box className="tableHeader" />,
        },
    ]

    const [columns, setColumns] = useState(staticColumns)

    const {
        reportSeriesMetadata: tableMetadata,
        deleteReportSeries,
        renameReportSeries,
        isRenameLoading,
    } = useReportSeriesMetadata(table.reportSeriesTableID)

    const getIQ = () => {
        let iq = {
            useLatestLoad: true,
            tableID: tableMetadata.reportSeriesID,
        }
        return iq
    }
    const processPreviewData = (previewData) => {
        if (!previewData) return
        var newPreviewColumns = []
        var newPreviewRows = []
        for (var cnum = 0; cnum < previewData.rows[0].length - 1; cnum++) {
            var minWidth = 8
            var cName = previewData.rows[0][cnum]
            var cType = 'string'
            if (cnum < tableMetadata.columns.length) {
                if (tableMetadata.columns[cnum].type !== 'String') {
                    minWidth = 15
                } else {
                    for (var rnum = 1; rnum < previewData.rows.length; rnum++) {
                        if (previewData.rows[rnum][cnum].length > minWidth) {
                            minWidth = previewData.rows[rnum][cnum].length
                        }
                    }
                }
                if (
                    tableMetadata.columns[cnum].type === 'String' &&
                    tableMetadata.columns[cnum].name.length > minWidth
                ) {
                    minWidth = tableMetadata.columns[cnum].name.length
                }
                cName = tableMetadata.columns[cnum].name
                cType = getGridColType(tableMetadata.columns[cnum].type)
            } else {
                minWidth = 20
            }
            if (minWidth > 35) {
                minWidth = 35
            }
            newPreviewColumns.push({
                field: cName,
                width: minWidth * 11,
                type: cType,
                sortable: false,
                editable: false,
                headerAlign: 'center',
                renderHeader: (params) => <Box className="tableHeader">{params.field}</Box>,
            })
        }
        for (rnum = 1; rnum < previewData.rows.length; rnum++) {
            var newRow = { id: rnum }
            for (cnum = 0; cnum < previewData.rows[0].length; cnum++) {
                let index = 0
                for (; index < tableMetadata.columns.length; index++) {
                    if (tableMetadata.columns[index].name === previewData.rows[0][cnum]) {
                        break
                    }
                }
                var x = previewData.rows[rnum][cnum]
                if (index < tableMetadata.columns.length) {
                    newRow[tableMetadata.columns[index].name] = transform(
                        tableMetadata.columns[index].type,
                        x
                    )
                } else {
                    newRow[previewData.rows[0][cnum]] = previewData.rows[rnum][cnum]
                }
            }
            newPreviewRows.push(newRow)
        }
        setPreviewColumns(newPreviewColumns)
        setPreviewRows(newPreviewRows)
        var newIngestions = []
        var newIngestionMap = new Map()
        for (let i = 0; i < previewData.ingestions.length; i++) {
            newIngestions.push(previewData.ingestions[i].date)
            newIngestionMap.set(previewData.ingestions[i].date, previewData.ingestions[i].key)
        }
        setIngestions(newIngestions)
        if (!dateValue) {
            setDateValue(
                newIngestions.length === 0
                    ? dayjs()
                    : dayjs(newIngestions[newIngestions.length - 1])
            )
        }
        setIngestionMap(newIngestionMap)
    }
    const updatePreviewData = (iq) => {
        server.postData(
            {
                action: 'reportSeriesTablePreviewData',
                inputQuery: iq,
            },
            processPreviewData
        )
    }
    const processTableContent = (result) => {
        const cleanedUrl = result?.url?.replace(/\s+/g, '')
        window.open(cleanedUrl, '_blank', 'noreferrer')
    }
    const handleTableContent = (rstiKey) => {
        server.postData(
            {
                action: 'getTableContent',
                rstiKey: rstiKey,
            },
            processTableContent
        )
    }
    const handleOpen = () => {
        setOpenRawTableData(true)
        updatePreviewData(getIQ())
    }

    useEffect(() => {
        if (tableMetadata) {
            let AuxRows = []
            for (let i = 0; i < tableMetadata.columns.length; i++) {
                if (!tableMetadata.columns[i].changeCol && !tableMetadata.columns[i].dateKey) {
                    AuxRows.push(tableMetadata.columns[i])
                }
            }
            setRows(AuxRows)
        }
    }, [tableMetadata])

    useEffect(() => {
        if (tableMetadata) {
            setUserTableName(tableMetadata.userTableName)
            setIgnoreTable(tableMetadata.tablemetadata.ignoreTable)
        }
    }, [tableMetadata])

    const handleClose = (event) => {
        if (
            tableMetadata.userTableName !== userTableName ||
            tableMetadata.tablemetadata.ignored !== ignoreTable
        ) {
            server.postData(
                {
                    action: 'getObject',
                    class: 'scoop.reportseriestable.ReportSeriesTable',
                    key: tableMetadata.reportSeriesID,
                },
                (reportSeriesTable) => {
                    reportSeriesTable.metadata.tableName = userTableName
                    reportSeriesTable.metadata.ignoreTable = ignoreTable
                    server.postData(
                        {
                            action: 'putObject',
                            class: 'scoop.reportseriestable.ReportSeriesTable',
                            key: tableMetadata.reportSeriesID,
                            value: reportSeriesTable,
                        },
                        () => {}
                    )
                }
            )
        }
        setOpenPropertiesDialog(false)
        setProfileColumn(null)
        setProfileData(null)
        setPreviewRows(null)
        setPreviewColumns(null)
    }

    const handleDeleteTable = async () => {
        setOpenDeleteDialog(false)
        deleteReportSeries({
            reportSeriesTableID: table.reportSeriesTableID,
            onSuccess: getMetadata,
        })
    }

    const handleSaveRename = async () => {
        renameReportSeries({
            newName: newTableName,
            ignoreTable,
            onSuccess: () => {
                getMetadata()
                setOpenRenameDialog(false)
            },
        })
    }

    return (
        <>
            <Box
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #E6E4E6',
                    display: 'flex',
                    minWidth: '350px',
                    padding: '16px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    cursor: 'pointer',
                }}
                onClick={() => setOpenTable(true)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: ' #412747',
                            textOverflow: 'ellipsis',
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                    >
                        {table.tableName +
                            (table.version > 1 ? ' (version ' + table.version + ')' : '')}
                    </Typography>
                    <img
                        onClick={(e) => {
                            e.stopPropagation()
                            setAnchorEl(e.target)
                        }}
                        src={DotsThree}
                        alt={'Dots'}
                    />
                </Box>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        color: '#635566',
                        textOverflow: 'ellipsis',
                        fontSize: ' 12px',
                    }}
                >{`${rows.length} Columns `}</Typography>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        color: '#635566',
                        textOverflow: 'ellipsis',
                        fontSize: ' 12px',
                    }}
                >
                    {`Scooped ${tableMetadata?.stats.numIngestions} times`},{' '}
                    {Intl.NumberFormat('en-En').format(tableMetadata?.stats?.rows)} rows
                </Typography>
            </Box>
            <Drawer open={openTable} onClose={() => setOpenTable(false)} anchor="right">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '24px',
                        height: '95%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            height: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: ' #000',
                                fontSize: '24px',
                                fontWeight: 600,
                            }}
                        >
                            {table.tableName}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '32px' }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#635566',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Columns
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#2B1630',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {rows?.length}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#635566',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {' '}
                                    Number of Rows Ingested
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#2B1630',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {tableMetadata?.stats.numIngestions}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#635566',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Scooped
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#2B1630',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >{`${tableMetadata?.stats.numIngestions} times`}</Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#635566',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Rows
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#2B1630',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {Intl.NumberFormat('en-En').format(tableMetadata?.stats?.rows)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ maxHeight: '80%' }}>
                            {rows && columns && (
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    disableColumnMenu={true}
                                    pagination={false}
                                    hideFooter
                                />
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={'button-purple small'} onClick={() => handleOpen()}>
                            View Table Data
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <Dialog
                open={openRawTableData && previewRows}
                onClose={() => {
                    setPreviewRows(null)
                    setOpenRawTableData(false)
                }}
                title={`Raw table data from ${table.tableName}`}
                actions={
                    <>
                        <Button
                            className={'button-grey small'}
                            onClick={() => {
                                setPreviewRows(null)
                                setOpenRawTableData(false)
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            className={'button-purple small'}
                            onClick={() => {
                                const dateStr = dateValue.format('MM/DD/YYYY')
                                const index = ingestions.indexOf(dateStr)
                                if (index >= 0) {
                                    handleTableContent(ingestionMap.get(ingestions[index]))
                                }
                            }}
                        >
                            Download CSV
                        </Button>
                    </>
                }
                style={{ width: '1000px' }}
                PaperProps={{
                    sx: {
                        maxWidth: 'none',
                    },
                }}
            >
                {previewRows && (
                    <Box>
                        {ingestions.length > 0 && (
                            <Box
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dateValue}
                                        slots={{
                                            day: ServerDay,
                                        }}
                                        slotProps={{
                                            day: {
                                                ingestions,
                                            },
                                        }}
                                        shouldDisableDate={(day) => {
                                            const propDateStr = day.format('MM/DD/YYYY')
                                            const isSelected = ingestions.indexOf(propDateStr) >= 0
                                            return !isSelected
                                        }}
                                        onChange={(day, context) => {
                                            const propDateStr = day.format('MM/DD/YYYY')
                                            const isSelected = ingestions.indexOf(propDateStr) >= 0
                                            if (isSelected) {
                                                setDateValue(day)
                                                var iq = getIQ()
                                                iq.rstiKey = ingestionMap.get(propDateStr)
                                                updatePreviewData(iq)
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        )}
                        {previewRows && previewColumns && (
                            <DataGrid
                                filterMode="server"
                                rows={previewRows}
                                columns={previewColumns}
                                disableColumnMenu={true}
                                pagination={false}
                                hideFooter
                                style={{ maxHeight: '450px' }}
                            />
                        )}
                    </Box>
                )}
            </Dialog>
            <PropertiesDialog
                open={openPropertiesDialog}
                handleClose={handleClose}
                profileColumn={profileColumn}
                tableMetadata={tableMetadata}
                server={server}
                setAlert={setAlert}
            />
            {openDeleteDialog && (
                <DeleteDialog
                    handleDelete={handleDeleteTable}
                    handleCancel={() => setOpenDeleteDialog(false)}
                    open={openDeleteDialog}
                    title={'Table'}
                    description={`Are you sure you want to delete ${table.tableName}?`}
                />
            )}
            {openRenameDialog && (
                <Dialog
                    style={{ width: 500 }}
                    onClose={() => setOpenRenameDialog(false)}
                    open={openRenameDialog}
                    title={'Rename Table'}
                    actions={
                        <>
                            <Button
                                className={'button-grey small'}
                                onClick={() => setOpenRenameDialog(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    newTableName === table.tableName ||
                                    !newTableName ||
                                    isRenameLoading
                                }
                                className={'button-purple small'}
                                onClick={handleSaveRename}
                            >
                                {isRenameLoading ? <ScoopLoader size={24} /> : 'Save'}
                            </Button>
                        </>
                    }
                >
                    <Input
                        value={newTableName}
                        padding={'8px'}
                        onChange={(e) => setNewTableName(e.target.value)}
                        placeholder={'Enter new table name'}
                    />
                </Dialog>
            )}
            {Boolean(anchorEl) && !readOnly && (
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {
                            setOpenRenameDialog(true)
                            setAnchorEl(null)
                        }}
                    >
                        <img src={TextAa} alt={'text'} style={{ height: 18, width: 18 }} />
                        <Typography className={'inter'} sx={{ ml: '8px', fontSize: '14px' }}>
                            Rename
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setOpenDeleteDialog(true)
                            setAnchorEl(null)
                        }}
                    >
                        <img src={TrashRed} alt={'trash'} style={{ height: 18, width: 18 }} />
                        <Typography
                            className={'inter'}
                            sx={{
                                ml: '8px',
                                fontSize: '14px',
                                color: '#EB382A',
                            }}
                        >
                            Delete
                        </Typography>
                    </MenuItem>
                </Menu>
            )}
        </>
    )
}
