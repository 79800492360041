import { Box, DialogContentText, FormControlLabel, FormGroup, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { BarChart } from '@mui/x-charts/BarChart'
import { Switch } from '../../../common/Switch/Switch'
import React, { useEffect, useState } from 'react'
import Dialog from '../../../common/Dialog/Dialog'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import DeleteDialog from '../../../common/Dialog/DeleteDialog'

export const PropertiesDialog = ({
    open,
    handleClose,
    profileColumn,
    tableMetadata,
    server,
    setAlert,
}) => {
    const [profileData, setProfileData] = useState(null)
    const [exploreUnique, setExploreUnique] = useState(false)
    const [column, setColumn] = useState(null)
    const [confirmMessage, setConfirmMessage] = useState(null)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [userTableName, setUserTableName] = useState('')

    useEffect(() => {
        var auxColumn = null
        if (tableMetadata) {
            for (let i = 0; i < tableMetadata.columns.length; i++) {
                if (tableMetadata.columns[i].name === profileColumn) {
                    auxColumn = tableMetadata.columns[i]
                }
            }
            setColumn(auxColumn)
        }
    }, [tableMetadata, profileData])

    useEffect(() => {
        if (tableMetadata) {
            setUserTableName(tableMetadata.userTableName)
            //setIgnoreTable(tableMetadata.tablemetadata.ignoreTable);
        }
    }, [tableMetadata])

    useEffect(() => {
        if (profileColumn && open) getProfileData(profileColumn)
    }, [open, profileColumn])
    const getProfileData = (columnName, exploreUnique) => {
        let action = {
            action: 'profileColumn',
            reportSeriesTableID: tableMetadata.reportSeriesID,
            columnName: columnName,
        }
        if (exploreUnique) {
            action.nonUnique = true
        }
        server.postData(action, processProfileData)
    }

    const processProfileData = (result) => {
        setExploreUnique(result.unique)
        setProfileData(result)
    }

    const chartSetting = {
        xAxis: [
            {
                label: 'count',
            },
        ],
        width: 650,
        height: 500,
        margin: { left: 200, bottom: 30 },
    }

    const handleDelete = () => {
        setConfirmDeleteOpen(false)
        handleClose()
        server.postData(
            {
                action: 'delete',
                reportSeriesTableID: tableMetadata.reportSeriesID,
                deleteAll: true,
            },
            (r) => {
                console.log(r)
                setAlert({
                    message: 'Table deleted successfully',
                    severity: 'success',
                })
            }
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                maxWidth={800}
                title={'Scoop Table Properties'}
                PaperProps={{ sx: { minHeight: '90vh', width: '55vw' } }}
                style={{ maxHeight: '90vh', minWidth: '70vw' }}
                actions={
                    <>
                        <Button onClick={() => handleClose()} className={'button-grey small'}>
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                setConfirmMessage(
                                    'Are you sure that you want to delete this data table and all reports that have been loaded into it from Scoop?'
                                )
                                setConfirmDeleteOpen(true)
                            }}
                            className={'button-purple small'}
                        >
                            Delete Table
                        </Button>
                    </>
                }
            >
                {profileColumn && (
                    <>
                        <Box sx={{ display: 'flex' }}>
                            <Input
                                label={'Table Name'}
                                value={userTableName}
                                sx={{ width: '100%' }}
                                onChange={(event) => {
                                    setUserTableName(event.currentTarget.value)
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mt: 2 }}>
                                <strong>Data Profile for Column:</strong>&nbsp;
                                {profileColumn}
                            </Typography>
                            {column && !column.isMeasure && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography>Analyze Uniqueness</Typography>
                                    <Switch
                                        onClick={() =>
                                            getProfileData(profileColumn, !exploreUnique)
                                        }
                                        checked={exploreUnique}
                                    />
                                </Box>
                            )}
                            {profileData && exploreUnique && (
                                <Box sx={{ mt: 2 }}>
                                    {profileData.data.length === 0 && (
                                        <Box>This column is unique across all snapshots</Box>
                                    )}
                                    {profileData.data.length !== 0 && (
                                        <Box sx={{ mb: 2 }}>
                                            <Grid
                                                container
                                                sx={{
                                                    width: '100%',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <Grid xs={3}>Snapshot Date</Grid>
                                                <Grid xs={7}>Value</Grid>
                                                <Grid xs={2} sx={{ textAlign: 'right' }}>
                                                    Count
                                                </Grid>
                                            </Grid>
                                            <Box
                                                sx={{
                                                    padding: '8px',
                                                    maxHeight: '400px',
                                                    maxWidth: '100%',
                                                    overflowY: 'scroll',
                                                }}
                                            >
                                                {profileData.data.map((row) => {
                                                    return (
                                                        <Grid container>
                                                            <Grid xs={3}>{row.snapshotDate}</Grid>
                                                            <Grid xs={7}>{row.value}</Grid>
                                                            <Grid
                                                                xs={2}
                                                                sx={{
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {row.count}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            )}
                            {profileData && !exploreUnique && (
                                <Box sx={{ mt: 2 }}>
                                    {(profileData.min || profileData.max) && (
                                        <Box>
                                            <strong>Min:</strong>&nbsp;
                                            {profileData.min}, <strong>Max:</strong>&nbsp;
                                            {profileData.max},<strong>Avg:</strong>&nbsp;
                                            {profileData.avg},<strong>Std:</strong>&nbsp;
                                            {profileData.std}
                                        </Box>
                                    )}
                                    <Box sx={{ maxHeight: '530px' }}>
                                        <BarChart
                                            dataset={profileData.data}
                                            yAxis={[
                                                {
                                                    scaleType: 'band',
                                                    dataKey: 'value',
                                                },
                                            ]}
                                            series={[
                                                {
                                                    dataKey: 'count',
                                                    label: 'Count of Column Values',
                                                },
                                            ]}
                                            layout="horizontal"
                                            {...chartSetting}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </Dialog>
            <DeleteDialog
                title={'Are you sure?'}
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                maxWidth={'600px'}
                handleCancel={() => setConfirmDeleteOpen(false)}
                handleDelete={handleDelete}
            >
                {confirmMessage}
            </DeleteDialog>
        </>
    )
}
