import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@mui/x-data-grid'
import KeyIcon from '@mui/icons-material/Key'
import { LicenseInfo } from '@mui/x-license-pro'
import AssessmentIcon from '@mui/icons-material/Assessment'
import './Source.css'
import { BarChart } from '@mui/x-charts/BarChart'
import Grid from '@mui/material/Unstable_Grid2'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DatePicker } from '@mui/x-date-pickers'

LicenseInfo.setLicenseKey(
    '4a581f8916b04de3f28663d28cc2f028Tz03NzIxOSxFPTE3Mjk2NjcxOTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

export function TableDialog({ open, setOpen, tableMetadata, server, getWorkspaceMetadata }) {
    const [value, setValue] = React.useState(0)
    const [previewColumns, setPreviewColumns] = React.useState()
    const [previewRows, setPreviewRows] = React.useState()
    const [ingestions, setIngestions] = React.useState()
    const [ingestionMap, setIngestionMap] = React.useState()
    const [profileColumn, setProfileColumn] = React.useState(null)
    const [profileData, setProfileData] = React.useState(null)
    const [exploreUnique, setExploreUnique] = React.useState(false)
    const [dateValue, setDateValue] = React.useState(null)
    const [userTableName, setUserTableName] = React.useState(null)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
    const [confirmMessage, setConfirmMessage] = React.useState(null)
    const [ignoreTable, setIgnoreTable] = React.useState(false)
    React.useEffect(() => {
        if (tableMetadata) {
            setUserTableName(tableMetadata.userTableName)
            setIgnoreTable(tableMetadata.tablemetadata.ignoreTable)
        }
    }, [tableMetadata])

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    function handleClose(event) {
        if (
            tableMetadata.userTableName !== userTableName ||
            tableMetadata.tablemetadata.ignored !== ignoreTable
        ) {
            server.postData(
                {
                    action: 'getObject',
                    class: 'scoop.reportseriestable.ReportSeriesTable',
                    key: tableMetadata.reportSeriesID,
                },
                (reportSeriesTable) => {
                    reportSeriesTable.metadata.tableName = userTableName
                    reportSeriesTable.metadata.ignoreTable = ignoreTable
                    server.postData(
                        {
                            action: 'putObject',
                            class: 'scoop.reportseriestable.ReportSeriesTable',
                            key: tableMetadata.reportSeriesID,
                            value: reportSeriesTable,
                        },
                        () => {
                            getWorkspaceMetadata(server)
                        }
                    )
                }
            )
        }
        setOpen(false)
        setValue(0)
        setProfileColumn(null)
        setProfileData(null)
        setPreviewRows(null)
        setPreviewColumns(null)
    }

    function getIQ() {
        let iq = {
            useLatestLoad: true,
            tableID: tableMetadata.reportSeriesID,
        }
        return iq
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 1) {
            updatePreviewData(getIQ())
        }
    }

    function ServerDay(props) {
        const { ingestions = [], day, outsideCurrentMonth, ...other } = props
        const propDateStr = props.day.format('MM/DD/YYYY')
        const isSelected = ingestions.indexOf(propDateStr) >= 0

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🌚' : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        )
    }

    function transform(ctype, val) {
        if (ctype) {
            if (ctype === 'DateTime') {
                return val === null ? null : new Date(val)
            }
            return val
        }
        return val
    }

    function getGridColType(scoopType) {
        switch (scoopType) {
            case 'String':
                return 'string'
            case 'Decimal':
                return 'number'
            case 'DateTime':
                return 'dateTime'
            case 'Currency':
                return 'number'
            case 'Boolean':
                return 'boolean'
            default:
                return 'string'
        }
    }

    function processPreviewData(previewData) {
        if (!previewData) return
        var newPreviewColumns = []
        var newPreviewRows = []
        for (var cnum = 0; cnum < previewData.rows[0].length - 1; cnum++) {
            var minWidth = 8
            var cName = previewData.rows[0][cnum]
            var cType = 'string'
            if (cnum < tableMetadata.columns.length) {
                if (tableMetadata.columns[cnum].type !== 'String') {
                    minWidth = 15
                } else {
                    for (var rnum = 1; rnum < previewData.rows.length; rnum++) {
                        if (previewData.rows[rnum][cnum].length > minWidth) {
                            minWidth = previewData.rows[rnum][cnum].length
                        }
                    }
                }
                if (
                    tableMetadata.columns[cnum].type === 'String' &&
                    tableMetadata.columns[cnum].name.length > minWidth
                ) {
                    minWidth = tableMetadata.columns[cnum].name.length
                }
                cName = tableMetadata.columns[cnum].name
                cType = getGridColType(tableMetadata.columns[cnum].type)
            } else {
                minWidth = 20
            }
            if (minWidth > 35) {
                minWidth = 35
            }
            newPreviewColumns.push({
                field: cName,
                width: minWidth * 11,
                type: cType,
                sortable: false,
                editable: false,
                headerAlign: 'center',
                renderHeader: (params) => <Box className="tableHeader">{params.field}</Box>,
            })
        }
        for (rnum = 1; rnum < previewData.rows.length; rnum++) {
            var newRow = { id: rnum }
            for (cnum = 0; cnum < previewData.rows[0].length; cnum++) {
                let index = 0
                for (; index < tableMetadata.columns.length; index++) {
                    if (tableMetadata.columns[index].name === previewData.rows[0][cnum]) {
                        break
                    }
                }
                var x = previewData.rows[rnum][cnum]
                if (index < tableMetadata.columns.length) {
                    newRow[tableMetadata.columns[index].name] = transform(
                        tableMetadata.columns[index].type,
                        x
                    )
                } else {
                    newRow[previewData.rows[0][cnum]] = previewData.rows[rnum][cnum]
                }
            }
            newPreviewRows.push(newRow)
        }
        setPreviewColumns(newPreviewColumns)
        setPreviewRows(newPreviewRows)
        var newIngestions = []
        var newIngestionMap = new Map()
        for (let i = 0; i < previewData.ingestions.length; i++) {
            newIngestions.push(previewData.ingestions[i].date)
            newIngestionMap.set(previewData.ingestions[i].date, previewData.ingestions[i].key)
        }
        setIngestions(newIngestions)
        if (!dateValue) {
            setDateValue(
                newIngestions.length === 0
                    ? dayjs()
                    : dayjs(newIngestions[newIngestions.length - 1])
            )
        }
        setIngestionMap(newIngestionMap)
    }

    function updatePreviewData(iq) {
        server.postData(
            {
                action: 'reportSeriesTablePreviewData',
                inputQuery: iq,
            },
            processPreviewData
        )
    }

    function processProfileData(result) {
        setExploreUnique(result.unique)
        setProfileData(result)
    }

    function getProfileData(columnName, exploreUnique) {
        let action = {
            action: 'profileColumn',
            reportSeriesTableID: tableMetadata.reportSeriesID,
            columnName: columnName,
        }
        if (exploreUnique) {
            action.nonUnique = true
        }
        server.postData(action, processProfileData)
    }

    let rows = null
    if (tableMetadata) {
        rows = []
        for (let i = 0; i < tableMetadata.columns.length; i++) {
            if (!tableMetadata.columns[i].changeCol && !tableMetadata.columns[i].dateKey) {
                rows.push(tableMetadata.columns[i])
            }
        }
    }

    const columns = [
        {
            field: 'identifier',
            width: 20,
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {params.value && <KeyIcon fontSize="small" />}
                </Box>
            ),
            renderHeader: (params) => <Box className="tableHeader">Key</Box>,
        },

        {
            field: 'name',
            width: 300,
            renderHeader: (params) => <Box className="tableHeader">Column Name</Box>,
        },
        {
            field: 'measure',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            valueGetter: ({ row }) => {
                if (row.isMeasure) {
                    return 'Measure'
                }
                return 'Attribute'
            },
            renderHeader: (params) => <Box className="tableHeader">Measure/Attribute</Box>,
        },
        {
            field: 'type',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderHeader: (params) => <Box className="tableHeader">Type</Box>,
        },
        {
            field: 'profile',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={(event) => {
                            setProfileColumn(params.row.name)
                            getProfileData(params.row.name)
                        }}
                    >
                        <AssessmentIcon fontSize="small" />
                    </IconButton>
                )
            },
            renderHeader: (params) => <Box className="tableHeader">Profile</Box>,
        },
    ]

    const chartSetting = {
        xAxis: [
            {
                label: 'count',
            },
        ],
        width: 700,
        height: 600,
        margin: { left: 200, bottom: 30 },
    }

    var column = null
    if (tableMetadata) {
        for (let i = 0; i < tableMetadata.columns.length; i++) {
            if (tableMetadata.columns[i].name === profileColumn) {
                column = tableMetadata.columns[i]
            }
        }
    }

    function processTableContent(result) {
        const cleanedUrl = result?.url?.replace(/\s+/g, '')
        window.open(cleanedUrl, '_blank', 'noreferrer')
    }

    function handleTableContent(rstiKey) {
        server.postData(
            {
                action: 'getTableContent',
                rstiKey: rstiKey,
            },
            processTableContent
        )
    }

    function handleDelete() {
        setConfirmDeleteOpen(false)
        setOpen(false)
        server.postData(
            {
                action: 'delete',
                reportSeriesTableID: tableMetadata.reportSeriesID,
                deleteAll: true,
            },
            () => {
                getWorkspaceMetadata(server)
            }
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            {tableMetadata && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={800}
                    PaperProps={{ sx: { minHeight: '90vh', minWidth: '70vh' } }}
                >
                    <DialogTitle sx={{ color: 'text.secondary' }}>
                        <Typography sx={{ fontWeight: 'bold', mb: 2, fontSize: 22 }}>
                            Scoop Table Properties
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '200px',
                                }}
                            >
                                Table Name:
                            </Box>
                            <TextField
                                value={userTableName}
                                sx={{ width: '100%' }}
                                onChange={(event) => {
                                    setUserTableName(event.currentTarget.value)
                                }}
                            />
                        </Box>
                    </DialogTitle>

                    <DialogContent sx={{ width: '100%' }}>
                        <DialogContentText>Explore Scoop table properties</DialogContentText>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Properties" {...a11yProps(0)} />
                                <Tab label="Data" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {!profileColumn && (
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                                        Column Details
                                    </Typography>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        rowHeight={22}
                                        disableColumnMenu={true}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={ignoreTable}
                                                onChange={() => {
                                                    setIgnoreTable(!ignoreTable)
                                                }}
                                            />
                                        }
                                        label="Ignore table (columns and data from this table will be hidden in Scoop)"
                                    />
                                </Box>
                            )}
                            {profileColumn && (
                                <Box>
                                    <Button onClick={() => setProfileColumn(null)}>Back</Button>
                                    <Typography sx={{ mt: 2 }}>
                                        <strong>Data Profile for Column:</strong>
                                        &nbsp;{profileColumn}
                                    </Typography>
                                    {column && !column.isMeasure && (
                                        <Box>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={exploreUnique}
                                                            onChange={() => {
                                                                getProfileData(
                                                                    profileColumn,
                                                                    !exploreUnique
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label="Analyze Uniqueness"
                                                />
                                            </FormGroup>
                                        </Box>
                                    )}
                                    {profileData && exploreUnique && (
                                        <Box sx={{ mt: 2 }}>
                                            {profileData.data.length === 0 && (
                                                <Box>
                                                    This column is unique across all snapshots
                                                </Box>
                                            )}
                                            {profileData.data.length !== 0 && (
                                                <Box>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            width: '100%',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <Grid xs={3}>Snapshot Date</Grid>
                                                        <Grid xs={7}>Value</Grid>
                                                        <Grid
                                                            xs={2}
                                                            sx={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            Count
                                                        </Grid>
                                                    </Grid>
                                                    {profileData.data.map((row) => {
                                                        return (
                                                            <Grid container>
                                                                <Grid xs={3}>
                                                                    {row.snapshotDate}
                                                                </Grid>
                                                                <Grid xs={7}>{row.value}</Grid>
                                                                <Grid
                                                                    xs={2}
                                                                    sx={{
                                                                        textAlign: 'right',
                                                                    }}
                                                                >
                                                                    {row.count}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                    {profileData && !exploreUnique && (
                                        <Box sx={{ mt: 2 }}>
                                            {(profileData.min || profileData.max) && (
                                                <Box>
                                                    <strong>Min:</strong>&nbsp;
                                                    {profileData.min}, <strong>Max:</strong>&nbsp;
                                                    {profileData.max},<strong>Avg:</strong>&nbsp;
                                                    {profileData.avg},<strong>Std:</strong>&nbsp;
                                                    {profileData.std}
                                                </Box>
                                            )}
                                            <BarChart
                                                dataset={profileData.data}
                                                yAxis={[
                                                    {
                                                        scaleType: 'band',
                                                        dataKey: 'value',
                                                    },
                                                ]}
                                                series={[
                                                    {
                                                        dataKey: 'count',
                                                        label: 'Count of Column Values',
                                                    },
                                                ]}
                                                layout="horizontal"
                                                {...chartSetting}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {previewRows && (
                                <Box>
                                    {ingestions.length > 0 && (
                                        <Box
                                            sx={{
                                                mb: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    mr: 3,
                                                }}
                                            >
                                                Snapshot/Load Date:
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dateValue}
                                                    slots={{
                                                        day: ServerDay,
                                                    }}
                                                    slotProps={{
                                                        day: {
                                                            ingestions,
                                                        },
                                                    }}
                                                    shouldDisableDate={(day) => {
                                                        const propDateStr = day.format('MM/DD/YYYY')
                                                        const isSelected =
                                                            ingestions.indexOf(propDateStr) >= 0
                                                        return !isSelected
                                                    }}
                                                    onChange={(day, context) => {
                                                        const propDateStr = day.format('MM/DD/YYYY')
                                                        const isSelected =
                                                            ingestions.indexOf(propDateStr) >= 0
                                                        if (isSelected) {
                                                            setDateValue(day)
                                                            var iq = getIQ()
                                                            iq.rstiKey =
                                                                ingestionMap.get(propDateStr)
                                                            updatePreviewData(iq)
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        const dateStr =
                                                            dateValue.format('MM/DD/YYYY')
                                                        const index = ingestions.indexOf(dateStr)
                                                        if (index >= 0) {
                                                            handleTableContent(
                                                                ingestionMap.get(ingestions[index])
                                                            )
                                                        }
                                                    }}
                                                >
                                                    Source
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                    <DataGrid
                                        filterMode="server"
                                        rows={previewRows}
                                        columns={previewColumns}
                                        getRowHeight={() => 20}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    page: 0,
                                                    pageSize: 30,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[30, 100]}
                                        disableColumnMenu={true}
                                        columnHeaderHeight={60}
                                        sx={{
                                            '& .column--header': {
                                                lineHeight: 1,
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                        </CustomTabPanel>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setConfirmMessage(
                                    'Are you sure that you want to delete this data table and all reports that have been loaded into it from Scoop?'
                                )
                                setConfirmDeleteOpen(true)
                            }}
                        >
                            Delete Table
                        </Button>
                        <Button variant="contained" onClick={handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}{' '}
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                maxWidth={'600px'}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDelete()
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
