export const getCalendarOverrides = (backgroundColor, fontColor) => {
    return `
        .react-calendar {
            border: none;
            font-family: Inter, sans-serif;
            background-color: ${backgroundColor};
        }

        .react-calendar__navigation__label__labelText {
            font-family: Inter, sans-serif;
            font-weight: 600;
            font-size: 14px;
            color: ${fontColor};
        }
        
        .react-calendar__month-view__weekdays__weekday abbr {
            color: ${fontColor};
            text-decoration: none !important;
        }
        
        .react-calendar__month-view__days__day {
            color: ${fontColor};
        }
        
        .react-calendar__month-view__days__day--neighboringMonth {
            color: #D0CDD1;
        }
        
        .react-calendar__navigation {
            height: 25px;
            margin-bottom: 5px;
        }
        
        .react-calendar__navigation button {
            border-radius: 8px;
            color: ${fontColor};
        }
        
        .react-calendar__navigation button:nth-child(3) {
            pointer-events: none;
        }
        
        .react-calendar__navigation button {
            min-width: 30px;
        }
        
        .react-calendar__tile:hover {
            border-radius: 8px;
            background-color: #FCE7EE;
            color: ${fontColor === 'white' ? 'black' : 'white'};
        }
        
        .react-calendar__tile--now {
            border-radius: 50%;
            background-color: #F2F2F2;
            color: ${fontColor === 'white' ? 'black' : 'white'};
        }
        
        .react-calendar__tile--now:hover {
            border-radius: 50%;
        }
        
        .react-calendar__tile--active {
            color: #E50B54;
            background-color: #FCE7EE;
            border-radius: 0;
        }
        
        .react-calendar__tile--rangeEnd {
            background-color: #E50B54 !important;
            color: white !important;
            border-radius: 0 8px 8px 0 !important;
        }
        
        .react-calendar__tile--rangeStart {
            background-color: #E50B54 !important;
            color: white !important;
            border-radius: 8px 0 0 8px !important;
        }
        
        .react-calendar__tile--rangeBothEnds {
            background-color: #E50B54 !important;
            color: white !important;
            border-radius: 8px !important;
        }
        
        .react-calendar__tile--active:enabled:hover {
            background-color: #FCE7EE;
            border-radius: 0;
        }
        
        .react-calendar__tile--now:enabled:hover {
            background-color: #F2F2F2;
        }
        `
}
