import DialogContentText from '@mui/material/DialogContentText'
import Dialog from './Dialog'
import React, { useEffect } from 'react'
import { Box, Checkbox, DialogContent, FormControlLabel, MenuItem, Typography } from '@mui/material'
import Button from '../Button/Button'
import Selector from '../Selector/Selector'
import { useApi } from '../../../api/api'

const SelectRange = ({
    open,
    onClose,
    handleSelect,
    handleCancel,
    workspaceID,
    worksheetID,
    namedRange,
    fitGridToData,
    ...props
}) => {
    const { postData } = useApi()
    const [availableSheets, setAvailableSheets] = React.useState(null)
    const [worksheet, setWorksheet] = React.useState(null)
    const [worksheetNamedRanges, setWorksheetNamedRanges] = React.useState([])
    const [selectedWorksheetRange, setSelectedWorksheetRange] = React.useState('')
    const [fitData, setFitData] = React.useState(fitGridToData || false)

    useEffect(() => {
        if (!availableSheets && workspaceID) {
            setAvailableSheets([])
            postData({
                action: 'getObjects',
                class: 'scoop.worksheet.Worksheet',
                attributeName: 'workspaceID',
                attributeValue: workspaceID,
            }).then(({ objects: result }) => {
                let availableWorksheets = result.filter(
                    (wi) => wi.sheetType === 'DynamicSheet' || wi.sheetType === 'CalculatedSource'
                )
                availableWorksheets = availableWorksheets.sort((a, b) => {
                    if (a.label === null) return 1 // Push nulls to the end
                    if (b.label === null) return -1 // Keep non-nulls at the beginning
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                })
                setAvailableSheets(availableWorksheets)
                if (worksheetID) {
                    availableWorksheets.map((worksheet) => {
                        if (worksheet.worksheetID === worksheetID) {
                            setWorksheet(worksheet)
                            setSheetRanges(worksheetID)
                        }
                    })
                }
            })
        }
    }, [availableSheets, workspaceID])

    const selectActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleCancel} text={'Cancel'} className={'primary-button'} />
            <Button
                onClick={() => {
                    handleSelect(worksheet, selectedWorksheetRange, fitData)
                }}
                autoFocus
                text={'Select'}
                className={'primary-button button-purple'}
            />
        </Box>
    )

    const setSheetRanges = (worksheetID) => {
        postData({
            action: 'getSheetMetadata',
            worksheetID: worksheetID,
        }).then((result) => {
            if (result.namedRanges) {
                setWorksheetNamedRanges(result.namedRanges)
                if (namedRange) {
                    setSelectedWorksheetRange(namedRange)
                } else {
                    setSelectedWorksheetRange(result.namedRanges[0]) // Pre-select the first named range
                }
            }
        })
    }

    const handleSelectWorksheet = (worksheetID) => {
        for (let i = 0; i < availableSheets.length; i++) {
            if (availableSheets[i].worksheetID === worksheetID) {
                setWorksheet(availableSheets[i])
                setSheetRanges(worksheetID)
                break
            }
        }
    }

    return (
        <Dialog
            id={'select-range'}
            open={open}
            onClose={handleCancel}
            title={`Select Range`}
            actions={selectActions}
            {...props}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select a Live Sheet range to fill a presentation table
                </DialogContentText>
                {availableSheets && (
                    <Box sx={{ mt: 2 }}>
                        <Selector
                            value={worksheet?.worksheetID || ''}
                            labelClassName={'selector-label-bold'}
                            onChange={(event) => handleSelectWorksheet(event.target.value)}
                            label={'Live Sheet Name'}
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                        >
                            {availableSheets.map((worksheet) => (
                                <MenuItem key={worksheet.worksheetID} value={worksheet.worksheetID}>
                                    <Typography
                                        className={'inter'}
                                        sx={{
                                            fontSize: '14px',
                                            width: 300,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {worksheet.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Selector>
                        <Selector
                            value={selectedWorksheetRange}
                            labelClassName={'selector-label-bold'}
                            onChange={(event) => {
                                setSelectedWorksheetRange(event.target.value)
                            }}
                            label="Named Range"
                            sx={{
                                height: 38,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '14px',
                            }}
                        >
                            {worksheetNamedRanges.map((namedRange) => (
                                <MenuItem key={namedRange} value={namedRange}>
                                    <Typography
                                        className={'inter'}
                                        sx={{
                                            fontSize: '14px',
                                            width: 300,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {namedRange}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Selector>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fitData}
                                    onChange={(e) => setFitData(e.target.checked)}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    Make table fit the size of the data in the named range
                                </Typography>
                            }
                        />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default SelectRange
