import React from 'react'
import { Checkbox as MUICheckbox } from '@mui/material'

const Checkbox = ({ checked, onClick, props, disabled, size = 'medium', padding = '0px' }) => {
    return (
        <MUICheckbox
            size={size}
            disabled={disabled}
            checked={checked}
            onClick={onClick}
            sx={{
                color: '#C7C5C7',
                '&.Mui-checked': {
                    color: '#E50B54',
                },
                '&.MuiCheckbox-root': {
                    padding: padding,
                },
            }}
            {...props}
        />
    )
}

export default Checkbox
