import { canvasClientRequest } from '../../api'

export const getCanvases = async () => {
    const action = {
        action: 'listCanvases',
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }
    return await canvasClientRequest({
        method: 'post',
        data: action,
    })
}

export const createCanvasRequest = async ({ canvasName }) => {
    const action = {
        action: 'createCanvas',
        canvasName: canvasName,
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }

    return await canvasClientRequest({
        method: 'post',
        data: action,
    })
}

export const deleteCanvasRequest = async ({ canvasID }) => {
    const action = {
        action: 'deleteCanvas',
        canvasID: canvasID,
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }

    return await canvasClientRequest({
        method: 'post',
        data: action,
    })
}

export const renameCanvasRequest = async ({ canvasID, canvasName }) => {
    const action = {
        action: 'renameCanvas',
        canvasID: canvasID,
        canvasName: canvasName,
        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
    }

    return await canvasClientRequest({
        method: 'post',
        data: action,
    })
}
