// CalculatedColumnsDialog.js
import React, { useState } from 'react'
import {
    Box,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import { renderToString } from 'react-dom/server'
import AppsIcon from '@mui/icons-material/Apps'
import { DataGrid } from '@mui/x-data-grid'
import Sheetlet from './Sheetlet'
import Grid from '@mui/material/Unstable_Grid2'
import DefineInputQuery from '../InputQuery/DefineInputQuery'
import {
    getFilterString,
    getFilterStringForColumnFromList,
    unpackFilters,
} from '../InputQuery/Filter'
import { Switch } from '../common/Switch/Switch'
import { InfoTooltip } from '../common/InfoTooltop/InfoTooltip'
import Dialog from '../common/Dialog/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '../common/Button/Button'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'

export function CalculatedSourceDialog({ open, setOpen, inbox, serverContext, metadata }) {
    const [initialized, setInitialized] = useState(false)
    let [outputDefinition, setOutputDefinition] = useState(null)
    const [previewData, setPreviewData] = useState(null)
    const [defineQuery, setDefineQuery] = useState(null)
    const [selectSource, setSelectSource] = useState(false)
    const [sourceTable, setSourceTable] = useState(null)
    const [index, setIndex] = useState(0)
    const [sheetRange, setSheetRange] = useState(null)
    const [singleQuery, setSingleQuery] = useState(false)

    function getNewQuery(tableID) {
        var tinbox = null
        var table = null
        for (let i = 0; i < metadata.inboxes.length; i++) {
            for (let j = 0; j < metadata.inboxes[i].tables.length; j++) {
                if (metadata.inboxes[i].tables[j].reportSeriesTableID === tableID) {
                    tinbox = metadata.inboxes[i]
                    table = metadata.inboxes[i].tables[j]
                    break
                }
            }
        }
        return {
            sheetName: inbox.inboxLabel + ' ' + (index + 1),
            useLatestLoad: !tinbox.transactional,
            startDate: null,
            dateKey: 0,
            inbox: inbox.inboxLabel,
            inboxID: inbox.inboxID,
            tableID: table.reportSeriesTableID,
            columns: [],
            changes: false,
            aggregate: true,
            aggregateLevel: 'Daily',
            filter: null,
            isNew: true,
        }
    }

    function handleClose(event) {
        if (event.code === 'Escape') return
        setOpen(false)
        setPreviewData(null)
        setDefineQuery(null)
        setSourceTable(null)
        setSelectSource(false)
        setOutputDefinition(null)
        setInitialized(false)
    }

    function handleSave(event) {
        setOpen(false)
        serverContext.server.sheetPostData(
            {
                action: 'flushWorksheetCache',
                sheetRange: {
                    inboxID: inbox.inboxID,
                },
            },
            () => {}
        )
        setPreviewData(null)
        setDefineQuery(null)
        setSourceTable(null)
        setSelectSource(false)
        setOutputDefinition(null)
        setInitialized(false)
    }

    function handleCancel(event) {
        setOpen(false)
        serverContext.server.sheetPostData(
            {
                action: 'clearWorksheetCache',
                sheetRange: {
                    inboxID: inbox.inboxID,
                },
            },
            () => {}
        )
        setPreviewData(null)
        setInitialized(false)
        setDefineQuery(null)
        setSourceTable(null)
        setSelectSource(false)
        setOutputDefinition(null)
        setInitialized(false)
    }

    function processOutputDefinition(results) {
        if (!results.queries) {
            return
        }
        setOutputDefinition(results)
        if (results.queries.length === 1) {
            setSingleQuery(true)
        }
        setSheetRange({
            sheetType: 'CalculatedSource',
            inboxID: inbox?.inboxID,
        })
    }

    function getOutputDefinition() {
        serverContext.server.sheetPostData(
            {
                action: 'getCSWDefinition',
                inboxID: inbox?.inboxID,
            },
            processOutputDefinition
        )
    }

    if (!initialized && open) {
        getOutputDefinition()
        setInitialized(true)
    }

    function getColumns(columnArray) {
        var result = ''
        for (let i = 0; i < columnArray.length; i++) {
            if (result.length > 0) result += ','
            result += columnArray[i].columnName
        }
        return result
    }

    function InputQuerySelectSource({ index }) {
        if (!defineQuery) {
            return (
                <Box>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            mt: 2,
                            fontSize: 20,
                            color: 'text.secondary',
                        }}
                    >
                        Select the Scoop Source Table for this Query
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel>Scoop Source Table for Query</InputLabel>
                        <Select
                            value={sourceTable}
                            label="Scoop Source Table for Query"
                            onChange={(event) => {
                                setSourceTable(event.target.value)
                            }}
                        >
                            {metadata.inboxes?.map((minbox) => {
                                return minbox.tables.map((table) => {
                                    return (
                                        <MenuItem value={table.reportSeriesTableID}>
                                            Dataset: {inbox.inboxLabel}, Table: {table.tableName}
                                        </MenuItem>
                                    )
                                })
                            })}
                        </Select>
                    </FormControl>
                </Box>
            )
        } else {
            var table = null
            for (let i = 0; i < metadata.inboxes.length; i++) {
                for (let j = 0; j < metadata.inboxes[i].tables.length; j++) {
                    if (metadata.inboxes[i].tables[j].reportSeriesTableID === defineQuery.tableID) {
                        table = metadata.inboxes[i].tables[j]
                        break
                    }
                }
            }
            return (
                <DefineInputQuery
                    inputQuery={defineQuery}
                    inbox={inbox}
                    table={table}
                    server={serverContext.server}
                    inAddOn={false}
                    afterClose={afterClose}
                    index={index}
                />
            )
        }
    }

    function InputQuery({ outputDefinition, index }) {
        return (
            <Box sx={{ width: '100%' }}>
                <Grid container sx={{ mb: 2 }}>
                    <Card
                        sx={{
                            borderRadius: 4,
                            borderShadow: 2,
                            height: '100%',
                            width: '100%',
                            m: 3,
                        }}
                    >
                        <CardContent
                            sx={{
                                color: 'text.secondary',
                                textAlign: 'left',
                                mb: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontHeight: 30,
                                        mb: 2,
                                        color: 'text.secondary',
                                    }}
                                >
                                    Input Query {index === 0 ? 'One' : 'Two'}
                                </Typography>
                                <Button
                                    onClick={() => {
                                        setDefineQuery(outputDefinition.queries[index])
                                        setSourceTable(outputDefinition.queries[index].tableID)
                                        setSelectSource(true)
                                        setIndex(index)
                                    }}
                                >
                                    edit
                                </Button>
                            </Box>
                            <Grid container>
                                <Grid xs={2.5} sx={{ fontWeight: 'bold' }}>
                                    Inbox:
                                </Grid>
                                <Grid xs={9.5}>{outputDefinition.queries[index].inbox}</Grid>
                                <Grid xs={2.5} sx={{ fontWeight: 'bold' }}>
                                    Columns:
                                </Grid>
                                <Grid xs={9.5}>
                                    {getColumns(outputDefinition.queries[index].columns)}
                                </Grid>
                                {outputDefinition.queries[index].filter && (
                                    <>
                                        <Grid xs={2.5} sx={{ fontWeight: 'bold' }}>
                                            Filter:
                                        </Grid>
                                        <Grid xs={9.5}>
                                            {getFilterString(
                                                outputDefinition.queries[index].filter
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
        )
    }

    function processPreviewData(results) {
        if (results && results.rows && results.columns) {
            setPreviewData(results)
        }
    }

    function updatePreviewData() {
        if (
            outputDefinition &&
            outputDefinition.queries &&
            (outputDefinition.queries.length === 2 ||
                (outputDefinition.queries.length === 1 && singleQuery))
        ) {
            serverContext.server.sheetPostData(
                {
                    action: 'getCSWPreviewData',
                    inboxID: inbox?.inboxID,
                    singleQuery: singleQuery,
                },
                processPreviewData
            )
        }
    }

    function afterClose(saved) {
        setDefineQuery(null)
        setSourceTable(null)
        setSelectSource(false)
        if (saved) {
            getOutputDefinition()
        }
    }

    if (!previewData && open) {
        updatePreviewData()
    }
    return (
        <Dialog
            open={open}
            maxWidth={false}
            onClose={handleClose}
            title={'Calculated Dataset Properties'}
            style={{ width: '1000px', minHeight: '400px' }}
            actions={
                <>
                    {sourceTable && !defineQuery && (
                        <>
                            <Button className={'button-grey small'} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                className={'button-purple small'}
                                onClick={(event) => {
                                    var iq = getNewQuery(sourceTable)
                                    setDefineQuery(iq)
                                }}
                            >
                                Select
                            </Button>
                        </>
                    )}
                    {!sourceTable && (
                        <>
                            <Button className={'button-grey small'} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button className={'button-purple small'} onClick={handleSave}>
                                Save
                            </Button>
                        </>
                    )}
                </>
            }
        >
            <DialogContentText>
                Create a new source table by combining the results of two Scoop queries using
                spreadsheet formula. This allows a user to create a completely new table leveraging
                existing data from other Scoop sources.
            </DialogContentText>
            {outputDefinition && !selectSource && (
                <Grid container sx={{ width: '100%', mt: 3, alignItems: 'center' }}>
                    {(outputDefinition.queries.length === 0 ||
                        outputDefinition.queries[0] === null) && (
                        <Grid xs={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    className={'button-purple small'}
                                    onClick={() => {
                                        setSelectSource(true)
                                        setIndex(0)
                                    }}
                                    style={{ padding: '8px' }}
                                >
                                    Define Input Query One
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {outputDefinition.queries.length > 0 && (
                        <Grid item xs={10}>
                            <Box>
                                <InputQuery outputDefinition={outputDefinition} index={0} />
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        {(outputDefinition.queries.length < 2 ||
                            outputDefinition.queries[1] === null) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <Switch
                                    checked={!singleQuery}
                                    onChange={(event) => {
                                        setSingleQuery(!singleQuery)
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Join with Second Query
                                    </Typography>
                                    <InfoTooltip
                                        title={
                                            'Create a new source by joining the results of the first input query to the results of the second one'
                                        }
                                    />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {(outputDefinition.queries.length < 2 ||
                            outputDefinition.queries[1] === null) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {!singleQuery && (
                                    <Button
                                        style={{ padding: '8px' }}
                                        className={'button-purple small'}
                                        onClick={() => {
                                            setSelectSource(true)
                                            setIndex(1)
                                        }}
                                    >
                                        Define Input Query Two
                                    </Button>
                                )}
                            </Box>
                        )}
                        {outputDefinition.queries.length === 2 && (
                            <Box>
                                <InputQuery outputDefinition={outputDefinition} index={1} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            )}
            {outputDefinition &&
                !sourceTable &&
                (outputDefinition.queries.length === 2 ||
                    (outputDefinition.queries.length === 1 && singleQuery)) && (
                    <Box>
                        {sheetRange.inboxID && (
                            <Sheetlet
                                serverContext={serverContext}
                                sheetRange={sheetRange}
                                locked={true}
                                height={400}
                                cornerHTML={
                                    '<a href="https://docs.google.com/spreadsheets/d/' +
                                    outputDefinition.worksheetID +
                                    '" target="_blank">' +
                                    renderToString(<AppsIcon />) +
                                    '</a>'
                                }
                                addNew={getOutputDefinition}
                            />
                        )}
                        <Box
                            sx={{
                                mt: 2,
                                mb: 1,
                                color: 'text.secondary',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Typography>Preview Results:</Typography>{' '}
                            {previewData && (
                                <Typography
                                    sx={{
                                        color: 'text.secondary',
                                        ml: 1,
                                        fontSize: 14,
                                    }}
                                >
                                    Input query 1 row count: {previewData.inputResults[0].count}
                                    {previewData.inputResults.length === 2
                                        ? ', Input query 2 row count: ' +
                                          previewData.inputResults[1].count
                                        : ''}
                                </Typography>
                            )}
                            <Button
                                className={'button-purple small'}
                                onClick={() => {
                                    updatePreviewData()
                                }}
                            >
                                Update Preview
                            </Button>
                        </Box>
                        {previewData && (
                            <Box>
                                <DataGrid
                                    rowHeight={20}
                                    rows={previewData.rows}
                                    columns={previewData.columns}
                                    sx={{
                                        color: 'text.secondary',
                                        '& div.MuiListItemIcon-root': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            </Box>
                        )}
                        {!previewData && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <ScoopLoader />
                            </Box>
                        )}
                    </Box>
                )}
            {selectSource && <InputQuerySelectSource index={index} />}
        </Dialog>
    )
}
