// Database.jsx

import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../../../../api/api'
import { useSelector } from 'react-redux'
import Dialog from '../../../common/Dialog/Dialog'
import { Typography, Box, Tabs, Tab } from '@mui/material'
import { FormControl, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material'

import DialogContent from '@mui/material/DialogContent'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import './Database.css'
import Button from '../../../common/Button/Button'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import datasourceDefs from '../../../../api/datasourceConfig'
import Input from '../../../common/Input/Input'
import Selector from '../../../common/Selector/Selector'
import { set } from 'lodash'
import { styled } from '@mui/material/styles'
import { TabContext, TabPanel } from '@mui/lab'

const bot = datasourceDefs.find((b) => b.name === 'Database')

const driverClassMapping = {
    MariaDB: 'org.mariadb.jdbc.Driver',
    MySQL: 'com.mysql.cj.jdbc.Driver',
    Oracle: 'oracle.jdbc.driver.OracleDriver',
    PostgreSQL: 'org.postgresql.Driver',
    SQLServer: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
    Snowflake: 'net.snowflake.client.jdbc.SnowflakeDriver',
}

function getKeyByValue(mapping, value) {
    return Object.keys(mapping).find((key) => mapping[key] === value)
}

function generateShort_UUID() {
    return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const CustomTabs = styled(Tabs)({
    '& .MuiButtonBase-root': {
        textTransform: 'none',
    },
    '& .MuiButtonBase-root.Mui-selected': {
        color: '#E50B54',
        fontWeight: 400,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#E50B54',
    },
})

export const Database = ({
    open,
    onClose,
    stepBack,
    type,
    setAlert,
    initialDatasetDetails,
    basicConfiguration,
}) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const navigate = useNavigate()
    const { postData } = useApi(bot.API_URL)
    const { postData: postDataMobileAPI } = useApi()
    const [connectBy, setConnectBy] = useState('Host')
    const [url, setUrl] = useState(null)
    const [serverHost, setServerHost] = useState('')
    const [port, setPort] = useState('3306')
    const [database, setDatabase] = useState(null)
    const [driver, setDriver] = useState({
        friendlyName: 'MariaDB', // Default to MySQL
        className: driverClassMapping['MariaDB'], // Default class name
    })
    const [dbUser, setDbUser] = useState('admin')
    const [dbPassword, setDBPassword] = useState('')
    const [sqlQuery, setSqlQuery] = useState('SELECT * from ...')

    const [isUrlValid, setIsUrlValid] = useState(true)
    const [reports, setReports] = useState([])
    const [selectedReport, setSelectedReport] = useState('')
    const [isReportTransactional, setIsReportTransactional] = useState(false)
    const [allowMutipleLoads, setAllowMutipleLoads] = useState(false)
    const [reportName, setReportName] = useState('')
    const [reportDescription, setReportDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [nextStep, setNextStep] = useState(false)
    const [tabManager, setTabManager] = useState('0')
    const [queryVerified, setQueryVerified] = useState(false) // Set to true if the query is verified and user should be allowed to create bot

    const gridRef = useRef()
    const [columnDefs, setColumnDefs] = useState([])
    const [rowData, setRowData] = useState([])

    // set if user is editing vs creating new
    const [isEditMode, setIsEditMode] = useState(false)
    const [inboxName, setInboxName] = useState(null) //used only if inboxName is passed in as part of update call
    const [inboxID, setInboxID] = useState(null) //used only if inboxID is passed in as part of update call

    useEffect(() => {
        if (initialDatasetDetails) {
            setIsEditMode(true)
            const JDBCProps = JSON.parse(initialDatasetDetails.reportLink)
            setServerHost(JDBCProps.server)
            setPort(JDBCProps.port)
            setDatabase(JDBCProps.database)
            setDriver({
                friendlyName: getKeyByValue(driverClassMapping, JDBCProps.driverClass),
                className: JDBCProps.driverClass,
            })
            setUrl(JDBCProps.url)
            setDBPassword(JDBCProps.password)
            setDbUser(JDBCProps.username)
            setSqlQuery(JDBCProps.query)
            setReportDescription(initialDatasetDetails.description)
            setReportName(initialDatasetDetails.label)
            setInboxName(initialDatasetDetails.inboxName)
            setIsReportTransactional(initialDatasetDetails.isTransactional || false)
            setAllowMutipleLoads(initialDatasetDetails.enableMultipleSameDayLoads || false)
            setInboxID(initialDatasetDetails.inboxID)
        }
    }, [initialDatasetDetails])

    const handleDriverChange = (event) => {
        const selectedFriendlyName = event.target.value
        const selectedClassName = driverClassMapping[selectedFriendlyName]
        setDriver({
            friendlyName: selectedFriendlyName,
            className: selectedClassName,
        })
    }

    const handleUrlChange = (e) => {
        const newUrl = e.target.value
        setUrl(newUrl)

        // Validate URL
        setIsUrlValid(bot.urlValidation.test(newUrl))
    }

    const testJDBConnection = useCallback(async () => {
        if (!userID) return
        const action = {
            action: 'testJDBC',
            driverClass: driver.className,
            server: serverHost,
            url: url,
            port: port,
            database: database,
            driver: driver.friendlyName,
            username: dbUser,
            password: dbPassword,
        }
        const response = await postDataMobileAPI(action)
        const { result } = response
        if (result) return result
    }, [postDataMobileAPI, userID])

    const handleTestJDBC = () => {
        setIsLoading(true)
        testJDBConnection().then((result) => {
            if (result == 'Success') {
                setAlert({
                    message: 'Connected to your DB successfully!',
                    severity: 'success',
                })
            } else {
                setAlert({
                    message:
                        'DB Connection failed. Please ensure both that your DB settings are correct; and, that your DB is accessible to Scoop.',
                    severity: 'error',
                })
            }
        })
        setIsLoading(false)
    }

    const testJDBCQuery = useCallback(async () => {
        if (!userID) return
        const action = {
            action: 'testJDBCQuery',
            driverClass: driver.className,
            server: serverHost,
            url: url,
            username: dbUser,
            password: dbPassword,
            database: database,
            driver: driver.friendlyName,
            port: port,
            query: sqlQuery,
        }
        const result = await postDataMobileAPI(action)

        // Return an object that indicates whether the operation was successful and includes the message
        if (result?.columns && result?.rows) {
            return { success: true, response: result }
        } else {
            return { success: false, response: result.error }
        }
        return { success: false, message: 'An unknown error occurred' }
    }, [postDataMobileAPI, userID, driver.className, serverHost, dbUser, dbPassword])

    const handleJDBCQuery = () => {
        setIsLoading(true)
        testJDBCQuery()
            .then(({ success, response }) => {
                if (success) {
                    setQueryVerified(true)
                    // Transform columns for AG Grid
                    const transformedColumnDefs = response.columns.map((col) => ({
                        headerName: col.columnName,
                        field: col.columnName,
                        sortable: true,
                        filter: true,
                    }))

                    // Directly use rows as rowData
                    const transformedRowData = response.rows.map((row) => {
                        return response.columns.reduce((acc, col, index) => {
                            acc[col.columnName] = row[index]
                            return acc
                        }, {})
                    })

                    setColumnDefs(transformedColumnDefs)
                    setRowData(transformedRowData)

                    setAlert({
                        message: 'Your query ran successfully, see preview of your data!',
                        severity: 'success',
                    })
                } else {
                    setAlert({
                        message: `Oops, unable to run your SQL query (${response}). Please check its syntax, edit, and try again.`,
                        severity: 'error',
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // this is custom specific to the JDBC bot!
    const runBot = async () => {
        const action = {
            action: 'runJDBCBot',
            inboxID: inboxID,
            userID: userID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        return result
    }

    const handleRunBot = () => {
        runBot().then((results) => {
            if (results?.status === 'success')
                setAlert({
                    message: 'App Connector ran successfully!',
                    severity: 'success',
                })
            else if (results?.status === 'error')
                setAlert({
                    message:
                        results?.message ??
                        'The process is taking some time, your new data will become available soon.',
                    severity: 'information',
                })
            setNextStep(false)
            onClose()
        })
    }

    function createInboxName(userID, reportName) {
        // Combine userID and label with an underscore
        let combinedName = `${userID}_${reportName.substring(0, 30)}_${generateShort_UUID()}`

        // Replace characters not in the allowed list with an underscore
        // Allowed characters: alphanumeric, plus (+), minus (-), equals (=), dot (.), comma (,), underscore (_), colon (:), at (@)
        let scrubbedName = combinedName.replace(/[^a-zA-Z0-9\+\-\=\.\,\_\:\@]/g, '_')

        return scrubbedName
    }

    const createBot = async () => {
        const JDBCObject = {
            driverClass: driver.className,
            server: serverHost,
            url: url,
            port: port,
            username: dbUser,
            password: dbPassword,
            database: database,
            query: sqlQuery,
        }
        const JDBC = JDBCObject
        const action = {
            action: 'createRobot',
            inboxName: createInboxName(dbUser, reportName),
            label: reportName,
            description: reportDescription,
            keepOnlyCurrent: basicConfiguration.isMostRecent,
            incremental: basicConfiguration.isIncremental,
            enableMultipleSameDayLoads: basicConfiguration.isMultipleLoads,
            workspaceID: workspaceID,
            reportURL: JDBC,
            isTransactional: type === 'Transactional',
            userName: dbUser,
            service: bot.service,
            botUID: bot.botUID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        return result
    }

    const updateBot = async () => {
        const JDBCObject = {
            driverClass: driver.className,
            server: serverHost,
            url: url,
            port: port,
            username: dbUser,
            password: dbPassword,
            database: database,
            query: sqlQuery,
        }
        const JDBC = JDBCObject
        const action = {
            action: 'updateRobot',
            inboxName: inboxName,
            inboxID: inboxID,
            label: reportName,
            description: reportDescription,
            workspaceID: workspaceID,
            reportURL: JDBC,
            isTransactional: isReportTransactional,
            userName: dbUser,
            service: bot.service,
            botUID: bot.botUID,
            enableMultipleSameDayLoads: allowMutipleLoads,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postData(action)
        return result
    }

    const handleCreateBot = () => {
        createBot().then((results) => {
            if (results?.status === 'success')
                setAlert({
                    message: 'Database bot created successfully',
                    severity: 'success',
                })
            else if (results?.status === 'error')
                setAlert({
                    message: results?.message ?? 'Failed to create Database Bot',
                    severity: 'error',
                })
            setNextStep(false)
            stepBack()
            onClose()
        })
    }

    const handleUpdateBot = () => {
        updateBot().then((results) => {
            if (results?.status === 'success')
                setAlert({
                    message: 'Database connector updated successfully',
                    severity: 'success',
                })
            else if (results?.status === 'error')
                setAlert({
                    message: results?.message ?? 'Failed to update Database connector',
                    severity: 'error',
                })
            setNextStep(false)
            onClose()
        })
    }

    const handleSQLQueryChange = (event) => {
        setSqlQuery(event.target.value)
    }

    return (
        <>
            {!nextStep ? (
                <Dialog
                    sx={{
                        '& .MuiDialogContent-root': {
                            paddingTop: '4px !important',
                        },
                    }}
                    open={open}
                    title={'Database connector'}
                    icon={bot.icon}
                    onClose={onClose}
                    maxWidth="lg"
                    actions={
                        <>
                            {
                                <Button className={'button-grey small'} onClick={stepBack}>
                                    Back
                                </Button>
                            }
                            {isEditMode && (
                                <Button className={'button-purple small'} onClick={handleRunBot}>
                                    Run Now
                                </Button>
                            )}
                            <Button
                                className={'button-purple small'}
                                onClick={() => setNextStep(true)}
                                disabled={!queryVerified}
                            >
                                I've done this
                            </Button>
                        </>
                    }
                >
                    <DialogContent style={{ width: '800px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    color: '#635566',
                                    padding: '0px !important',
                                }}
                            >
                                Provide your database connection information
                            </Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="connect-by"
                                    name="connectBy"
                                    value={connectBy}
                                    onChange={(e) => setConnectBy(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Host"
                                        control={
                                            <Radio
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#E50B54',
                                                    },
                                                }}
                                            />
                                        }
                                        label="Host"
                                    />
                                    <FormControlLabel
                                        value="URL"
                                        control={
                                            <Radio
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#E50B54',
                                                    },
                                                }}
                                            />
                                        }
                                        label="URL connection string"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box>
                            {connectBy === 'URL' && (
                                <Box sx={{ marginBottom: '12px' }}>
                                    <Input
                                        padding="8px"
                                        label="URL"
                                        fullWidth
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                </Box>
                            )}
                            {connectBy === 'Host' && (
                                <>
                                    <Grid sx={{ marginBottom: '12px' }} container spacing={2}>
                                        <Grid item xs={10}>
                                            <Input
                                                padding="8px"
                                                label="Server Host"
                                                fullWidth
                                                value={serverHost}
                                                onChange={(e) => setServerHost(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Input
                                                padding="8px"
                                                label="Port"
                                                fullWidth
                                                value={port}
                                                onChange={(e) => setPort(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginBottom: '12px' }} container spacing={2}>
                                        <Grid item xs={6}>
                                            <Selector
                                                style={{ height: '42px' }}
                                                padding="8px"
                                                labelClassName="selector-label-bold selector-label-large"
                                                labelId="driver-name-label"
                                                id="driver-name-select"
                                                value={driver.friendlyName}
                                                label="Driver Name"
                                                onChange={handleDriverChange}
                                                fullWidth
                                            >
                                                <MenuItem value="MariaDB">Maria DB</MenuItem>
                                                <MenuItem value="MySQL">MySQL</MenuItem>
                                                <MenuItem value="Oracle">Oracle</MenuItem>
                                                <MenuItem value="PostgreSQL">PostgreSQL</MenuItem>
                                                <MenuItem value="SQLServer">SQL Server</MenuItem>
                                                <MenuItem value="Snowflake">Snowflake</MenuItem>
                                            </Selector>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                padding="8px"
                                                label="Database"
                                                variant="outlined"
                                                fullWidth
                                                value={database}
                                                onChange={(e) => setDatabase(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <FormControl component="fieldset" fullWidth margin="normal">
                                <Grid container spacing={2} alignItems="flex-end">
                                    <Grid item xs={6}>
                                        <Input
                                            padding="8px"
                                            label="User"
                                            fullWidth
                                            value={dbUser}
                                            onChange={(e) => setDbUser(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            padding="8px"
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            value={dbPassword}
                                            onChange={(e) => setDBPassword(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button className="button-purple small" onClick={handleTestJDBC}>
                                    Test Connection
                                </Button>
                            </Box>
                        </Box>
                        <TabContext value={tabManager}>
                            <CustomTabs
                                value={tabManager}
                                onChange={(e, newValue) => setTabManager(newValue)}
                            >
                                <Tab value={'0'} label="SQL Query" />
                                <Tab value={'1'} label="Preview Results" />
                            </CustomTabs>
                            <TabPanel
                                sx={{
                                    '&.MuiTabPanel-root': { padding: '8px' },
                                }}
                                value={'0'}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#635566',
                                        padding: '0px !important',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Write a SQL query to fetch the data you need
                                </Typography>
                                <Input
                                    fullWidth
                                    value={sqlQuery}
                                    multiline
                                    rows={4}
                                    onChange={handleSQLQueryChange}
                                />
                                <Box sx={{ marginTop: '10px' }}>
                                    <Button
                                        className="button-purple small"
                                        onClick={handleJDBCQuery}
                                    >
                                        Test SQL
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel
                                sx={{
                                    '&.MuiTabPanel-root': { padding: '8px' },
                                }}
                                value={'1'}
                            >
                                <div
                                    className="ag-theme-alpine"
                                    style={{ height: 400, width: '100%' }}
                                >
                                    <AgGridReact
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        animateRows={true} // Optional, for row animation
                                    ></AgGridReact>
                                </div>
                            </TabPanel>
                        </TabContext>
                        {/* Additional steps can be added similarly */}

                        {isLoading && (
                            <LinearProgress
                                variant="indeterminate"
                                style={{ marginTop: '20px', height: '10px' }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            ) : (
                <Dialog
                    open={open}
                    title={'New Connector from Database'}
                    icon={bot.icon}
                    onClose={onClose}
                    actions={
                        <>
                            <Button
                                className={'button-grey small'}
                                onClick={() => setNextStep(false)}
                            >
                                Back
                            </Button>
                            {isEditMode && (
                                <Button
                                    className={'button-purple small'}
                                    onClick={() => handleUpdateBot()}
                                >
                                    {' '}
                                    Update Connector{' '}
                                </Button>
                            )}
                            {!isEditMode && (
                                <Button
                                    className={'button-purple small'}
                                    onClick={() => handleCreateBot()}
                                >
                                    {' '}
                                    Create Connector{' '}
                                </Button>
                            )}
                        </>
                    }
                    style={{ width: '500px' }}
                >
                    <Box
                        sx={{
                            padding: '12px 16px',
                            fontSize: '14px',
                            background: '#F9F9F9',
                            borderRadius: '5px',
                            color: '#635566',
                        }}
                    >
                        <Typography> data will be Scooped from this database daily</Typography>
                    </Box>
                    <Input
                        sx={{ width: '100%' }}
                        label={'Connector name'}
                        onChange={(event) => {
                            setReportName(event.target.value)
                        }}
                        value={reportName}
                    />
                    <Input
                        sx={{ width: '100%' }}
                        multiline
                        rows={2}
                        label={'Connector description'}
                        onChange={(event) => {
                            setReportDescription(event.target.value)
                        }}
                        value={reportDescription}
                    />
                </Dialog>
            )}
        </>
    )
}
