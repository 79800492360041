import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Stack,
} from '@mui/material'
import './Dialog.css'
import CloseIcon from '../../../assets/icons/CloseIcon.svg'

const Dialog = ({
    open,
    onClose,
    title,
    children,
    actions,
    icon,
    style,
    extraWide,
    containerStyle,
    contentStyle,
    formControlStyles,
    showCloseButton = true, // GJ -- added this optional prop in case we want dialogs open w/o close button
    ...props
}) => {
    // Conditionally apply styles based on extraWide prop
    const dialogPaperStyle = extraWide ? { ...style, maxWidth: '1500px', width: '80%' } : style

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            maxWidth="sm" // Keep your default maxWidth or adjust as needed
            PaperProps={{
                style: dialogPaperStyle,
            }}
            {...props}
            className={`dialog`}
        >
            <div
                className={'dialog-content-container'}
                id={'dialog-content-container'}
                style={containerStyle}
            >
                {/* Dialog structure */}
                {icon ? (
                    <Stack direction="row" spacing={4} sx={{ width: '100%' }}>
                        <img
                            src={icon}
                            alt="Icon"
                            style={{
                                cursor: 'pointer',
                                maxWidth: '64px',
                                alignContent: 'center',
                            }}
                        />
                        <DialogTitle className={'dialog-title'}>
                            {title}
                            {showCloseButton && (
                                <Box onClick={onClose} className={'dialog-xButton-w-image'}>
                                    <img src={CloseIcon} alt={'closeIcon'} />
                                </Box>
                            )}
                        </DialogTitle>
                    </Stack>
                ) : title ? (
                    <DialogTitle className={'dialog-title'}>
                        {title}
                        {showCloseButton && (
                            <Box onClick={onClose} className={'dialog-xButton'}>
                                <img src={CloseIcon} alt={'closeIcon'} />
                            </Box>
                        )}
                    </DialogTitle>
                ) : (
                    showCloseButton && (
                        <Box
                            onClick={onClose}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <img src={CloseIcon} alt={'closeIcon'} />
                        </Box>
                    )
                )}
                <DialogContent className={'dialog-content'} style={contentStyle}>
                    <FormControl fullWidth sx={{ gap: '16px', ...formControlStyles }}>
                        {children}
                    </FormControl>
                </DialogContent>
                {actions && <DialogActions>{actions}</DialogActions>}
            </div>
        </MuiDialog>
    )
}

export default Dialog
