import Dialog from '../../common/Dialog/Dialog'
import { useUserInfo } from '../../../api/hooks/user/useUserInfo'
import { Box } from '@mui/material'
import ScoopLogo from '../../../assets/logo/NewLogo.svg'
import Typography from '@mui/material/Typography'
import PlusIconRed from '../../../assets/icons/PlusRed.svg'
import ShapesRed from '../../../assets/icons/ShapesRed.svg'
import { WorkspaceBox } from '../Login/CreateWorkspace'
import { useState } from 'react'
import Button from '../../common/Button/Button'
import RedSheet from '../../../assets/icons/RedSheet.svg'

export const OnboardingModal = ({ handleContinue }) => {
    const [selectedOption, setSelectedOption] = useState(null)

    const { user } = useUserInfo()

    return (
        <Dialog
            extraWide
            open={true}
            onClose={() => {}}
            showCloseButton={false}
            actions={
                <Button
                    disabled={selectedOption === null}
                    className={'button-purple'}
                    onClick={() => handleContinue(selectedOption)}
                >
                    Continue
                </Button>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '40px',
                    alignItems: 'center',
                    padding: '40px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        alignItems: 'center',
                    }}
                >
                    <img alt={'Scoop Logo'} src={ScoopLogo} />
                    <Typography
                        sx={{
                            fontSize: '32px',
                            fontWeight: 400,
                            lineHeight: '35px',
                            color: '#201024',
                            fontFamily: 'Inter',
                        }}
                    >
                        Hey {user?.firstName}, welcome to Scoop!
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            color: '#201024',
                            fontFamily: 'Inter',
                        }}
                    >
                        How would you like to begin?
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '24px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkspaceBox
                            onClick={() => setSelectedOption(0)}
                            icon={RedSheet}
                            title={'Explore with demo data'}
                            subtitle={
                                'See what Scoop can do right away with demo data in our Tour workspace'
                            }
                            selected={selectedOption === 0}
                            className="workspace-demo-data"
                        />
                        <WorkspaceBox
                            onClick={() => setSelectedOption(1)}
                            icon={ShapesRed}
                            title={'Start with a recipe'}
                            subtitle={
                                'Get inspired with one of our recipes. Simply connect your data and see it come to life in pre-made canvases'
                            }
                            selected={selectedOption === 1}
                            className="workspace-recipe"
                        />
                        <WorkspaceBox
                            onClick={() => setSelectedOption(2)}
                            icon={PlusIconRed}
                            title={'Start working freely'}
                            subtitle={
                                'Create a blank Workspace and explore your data in your own way'
                            }
                            selected={selectedOption === 2}
                            className="workspace-blank"
                        />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}
