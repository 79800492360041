import React, { useState, useRef, useEffect } from 'react'
import 'handsontable/dist/handsontable.full.min.css'
import { registerAllModules } from 'handsontable/registry'
import { Server } from '../../api/Server'
import { ServerContext } from './SheetState'
import {
    Box,
    createTheme,
    Typography,
    Card,
    CardContent,
    Button,
    Menu,
    MenuItem,
    IconButton,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    LinearProgress,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import EmailIcon from '@mui/icons-material/Email'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import CalculateIcon from '@mui/icons-material/Calculate'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsIcon from '@mui/icons-material/Settings'
import ReplayIcon from '@mui/icons-material/Replay'
import DeleteIcon from '@mui/icons-material/Delete'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FunctionsIcon from '@mui/icons-material/Functions'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { CalculatedColumnsDialog } from './CalculatedColumnsDialog'
import { TableDialog } from './TableDialog'
import { ReportDialog } from './ReportDialog'
import './Source.css'
import { EventLogDialog } from './EventLogDialog'
import { InboxPropertiesDialog } from './InboxProperties'
import { CalculatedSourceDialog } from './CalculatedSourceDialog'
import SubNav from '../common/SubNav/SubNav'
import { useSelector } from 'react-redux'
import { NewSourceDialog } from './NewSourceDialog'
import { CompareDialog } from './CompareDialog'

registerAllModules()

const darkTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            paper: '#EEE',
            default: '#27142c',
        },
        text: {
            primary: '#222',
            header: '#FDF5FF',
            secondary: '#46505A',
            card: '#24112e',
            //            card: '#5b4466'
        },
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    width: '120px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: '2px solid #592f64',
                    borderRadius: '10px',
                },
            },
        },
    },
})

export function Source() {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)

    const [server, setServer] = useState(new Server(workspaceID, userID, token))
    const [metadata, setMetadata] = useState({ inboxes: [] })
    const [eventLog, setEventLog] = useState(null)
    const [serverContext, setServerContext] = useState(new ServerContext(server))
    const [cfwDialogOpen, setCfwDialogOpen] = useState(false)
    const [cswDialogOpen, setCswDialogOpen] = useState(false)
    const [tableDialogOpen, setTableDialogOpen] = useState(false)
    const [reportDialogOpen, setReportDialogOpen] = useState(false)
    const [compareDialogOpen, setCompareDialogOpen] = useState(false)
    const [eventLogOpen, setEventLogOpen] = useState(false)
    const [inboxPropsOpen, setInboxPropsOpen] = useState(false)
    const [newSourceOpen, setNewSourceOpen] = useState(false)
    const [inbox, setInbox] = useState(null)
    const [tableMetadata, setTableMetadata] = useState(null)
    const [ingestionMetadata, setIngestionMetadata] = useState(null)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [deleteInbox, setDeleteInbox] = useState(null)
    const [confirmMessage, setConfirmMessage] = useState(null)
    const [processStatus, setProcessStatus] = useState(null)
    const [compareResult, setCompareResult] = useState(null)

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')
    const [isBeingProcessed, setIsBeingProcessed] = useState(false)

    let polling = useRef(false)
    let interval = useRef(null)
    useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [userID, workspaceID, token])

    useEffect(() => {
        setServerContext(new ServerContext(server))
    }, [server])

    // This is the processing control bit
    useEffect(() => {
        if (processStatus) {
            let status = Array.from(processStatus.values())
            status.map((s) => {
                let curStep = s?.curStep
                let totalSteps = s?.totalSteps
                // processing started
                if (s.curStep) {
                    if (curStep == -1) {
                        window.setTimeout(checkStatus, 10000)
                    } else {
                        window.setTimeout(checkStatus, 30000)
                    }
                }
            })
            if (!isBeingProcessed) {
                setIsBeingProcessed(true)
                showSnackbar('Processing started. Stay tuned.', 'info')
            }
            if (status.length == 0) {
                console.log('done!')
                showSnackbar('Processing completed!', 'success')
                getWorkspaceMetadata(server)
                setIsBeingProcessed(false)
            }
        }
    }, [processStatus])

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setOpenSnackbar(true)
    }

    function processStatusResult(result) {
        var psMap = new Map()
        for (let i = 0; i < result.inboxStatus.length; i++) {
            psMap.set(result.inboxStatus[i].inboxID, result.inboxStatus[i])
        }
        setProcessStatus(psMap)
    }

    function checkStatus() {
        if (!polling.current) return
        if (server.token) {
            server.postData(
                {
                    action: 'getProcessStatus',
                    workspaceID: workspaceID,
                },
                processStatusResult
            )
        } else {
            console.log('checkStatus() called, but server.token is null')
        }
    }

    function processMetadata(results) {
        setMetadata(results)
        if (results && results.processing) {
            // console.log("154 processMetadata called!")
            // GJ --  not sure this function is related to reprocessing, but it invokes checxkStatus() which creates a loop
            // checkStatus();
        }
        polling.current = true
    }

    function processEventLog(results) {
        if (results && results.eventLogItems && results.eventLogItems.length > 0) {
            var eventLogMap = new Map()
            for (let i = 0; i < results.eventLogItems.length; i++) {
                var inboxLog = eventLogMap.get(results.eventLogItems[i].inboxID)
                if (!inboxLog) {
                    inboxLog = { items: [], errorCount: 0 }
                    eventLogMap.set(results.eventLogItems[i].inboxID, inboxLog)
                }
                results.eventLogItems[i].date = new Date(results.eventLogItems[i].timeStamp)
                if (results.eventLogItems[i].error) {
                    inboxLog.errorCount++
                }
                if (inboxLog.items.length < 40) {
                    inboxLog.items.push(results.eventLogItems[i])
                }
            }
            setEventLog(eventLogMap)
        }
    }

    function getWorkspaceMetadata(server) {
        if (server.token) {
            server.postData(
                {
                    action: 'getWorkspaceMetadata',
                    workspaceID: workspaceID,
                    userID: userID,
                    tableStats: true,
                },
                processMetadata
            )
            server.postData(
                {
                    action: 'getEventLogItems',
                    workspaceID: workspaceID,
                    userID: userID,
                },
                processEventLog
            )
        } else {
            console.log('getWorkspaceMetadata() called, but server.token is null')
        }
    }

    useEffect(() => {
        // When userID or token changes, update the server instance
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer)
        getWorkspaceMetadata(newServer)
    }, [userID, token, workspaceID])

    function processTableMetadata(result) {
        setTableMetadata(result)
    }

    function getTableMetadata(reportSeriesTableID) {
        server.postData(
            {
                action: 'getReportSeriesMetadata',
                expandDateKeys: false,
                addCount: false,
                reportSeriesTableID: reportSeriesTableID,
            },
            processTableMetadata
        )
    }

    function handleDelete() {
        setConfirmDeleteOpen(false)
        if (deleteInbox) {
            server.postData(
                {
                    action: 'delete',
                    inboxID: deleteInbox,
                    deleteAll: true,
                },
                () => {
                    getWorkspaceMetadata(server)
                }
            )
        }
        setDeleteInbox(null)
    }

    function setReportDialogOpenStatus(open) {
        if (!open) {
            polling.current = true
        } else {
            polling.current = false
        }
        setReportDialogOpen(open)
    }

    function setTableDialogOpenStatus(open) {
        if (!open) {
            polling.current = true
        } else {
            polling.current = false
        }
        setTableDialogOpen(open)
    }

    function CardMenu({ inbox, worksheetID }) {
        const [anchorEl, setAnchorEl] = useState(null)
        const open = Boolean(anchorEl)
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        }

        const handleEditCalculatedColumn = (event) => {
            window.clearInterval(interval.current)
            setAnchorEl(null)
            setInbox(inbox)
            setCfwDialogOpen(true)
        }
        const handleEditCalculatedSource = (event) => {
            window.clearInterval(interval.current)
            setAnchorEl(null)
            setInbox(inbox)
            setCswDialogOpen(true)
        }
        const handleClose = (event) => {
            setAnchorEl(null)
        }

        const handleReprocess = (event) => {
            setAnchorEl(null)
            server.sheetPostData(
                {
                    action: 'reProcess',
                    inboxID: inbox.inboxID,
                },
                () => {
                    checkStatus()
                }
            )
        }

        const handleDeleteInbox = (event) => {
            setAnchorEl(null)
            setDeleteInbox(inbox.inboxID)
            setConfirmMessage('Are you sure that you want to delete this dataset from Scoop?')
            setConfirmDeleteOpen(true)
        }

        return (
            <Box sx={{ m: 0 }}>
                <IconButton onClick={handleClick} sx={{ color: '#FFF' }}>
                    <MenuIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                        sx={{ color: 'text.secondary' }}
                        onClick={() => {
                            setInboxPropsOpen(true)
                            setInbox(inbox)
                        }}
                    >
                        <ListItemIcon sx={{ color: 'text.secondary' }}>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        View Dataset Properties
                    </MenuItem>
                    {inbox && inbox.inboxType !== 'calculated' && inbox.tables.length > 0 && (
                        <MenuItem
                            sx={{ color: 'text.secondary' }}
                            onClick={handleEditCalculatedColumn}
                            value={'CFW'}
                        >
                            <ListItemIcon sx={{ color: 'text.secondary' }}>
                                <CalculateIcon fontSize="small" />
                            </ListItemIcon>
                            Create/Edit Calculated Columns
                        </MenuItem>
                    )}
                    {inbox && inbox.inboxType === 'calculated' && (
                        <MenuItem
                            sx={{ color: 'text.secondary' }}
                            onClick={handleEditCalculatedSource}
                            value={'CSW'}
                        >
                            <ListItemIcon sx={{ color: 'text.secondary' }}>
                                <CalculateIcon fontSize="small" />
                            </ListItemIcon>
                            Create/Edit Calculated Dataset Definition
                        </MenuItem>
                    )}
                    <MenuItem
                        sx={{ color: 'text.secondary' }}
                        onClick={handleReprocess}
                        disabled={isBeingProcessed} // Disable if isBeingProcessed is true
                    >
                        <ListItemIcon sx={{ color: 'text.secondary' }}>
                            <ReplayIcon fontSize="small" />
                        </ListItemIcon>
                        Reprocess Data from Raw Reports
                    </MenuItem>

                    <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDeleteInbox}>
                        <ListItemIcon sx={{ color: 'text.secondary' }}>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete Inbox
                    </MenuItem>
                </Menu>
            </Box>
        )
    }

    function processIngestionMetadata(result) {
        setIngestionMetadata(result)
    }

    function getIngestionMetadata(inboxID) {
        server.postData(
            {
                action: 'getIngestionMetadata',
                inboxID: inboxID,
            },
            processIngestionMetadata
        )
    }

    function processInboxTableCompare(result) {
        setCompareDialogOpen(true)
        setCompareResult(result)
    }

    function compareInboxTables(inboxID) {
        server.postData(
            {
                action: 'compareReportSeriesTables',
                inboxID: inboxID,
            },
            processInboxTableCompare
        )
    }
    return (
        <Box className="screen-container">
            <SubNav>
                <Typography>Sources</Typography>
            </SubNav>
            <Box
                sx={{
                    position: 'absolute',
                    minHeight: '100vh',
                    minWidth: '100vw',
                    padding: '120px 8%',
                    backgroundColor: '#220F48',
                }}
            >
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {metadata.inboxes?.map((inbox) => {
                        return (
                            <Grid xs={4} key={inbox.inboxID}>
                                <Card
                                    sx={{
                                        borderRadius: 4,
                                        borderShadow: 2,
                                        height: '100%',
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            color: 'text.secondary',
                                            textAlign: 'left',
                                            mb: 0,
                                        }}
                                    >
                                        <Box sx={{ height: '230px' }}>
                                            <Grid
                                                container
                                                sx={{
                                                    background: '#3c1c4d',
                                                    color: '#DDD',
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <Grid
                                                    xs={1}
                                                    sx={{
                                                        mt: 1,
                                                        ml: 1,
                                                        minWidth: 30,
                                                    }}
                                                >
                                                    {inbox.inboxType === 'email' && <EmailIcon />}
                                                    {inbox.inboxType === 'robot' && (
                                                        <SmartToyIcon />
                                                    )}
                                                    {inbox.inboxType === 'calculated' && (
                                                        <FunctionsIcon />
                                                    )}
                                                </Grid>
                                                <Grid
                                                    xs
                                                    sx={{
                                                        mt: 0,
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#DDD',
                                                            fontWeight: 'bold',
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {inbox.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    xs={1}
                                                    sx={{
                                                        minWidth: 45,
                                                        display: 'inline-flex',
                                                    }}
                                                >
                                                    {eventLog && eventLog.get(inbox.inboxID) && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setInbox(inbox)
                                                                setEventLogOpen(true)
                                                            }}
                                                            sx={{
                                                                color: '#DDD',
                                                                width: 20,
                                                            }}
                                                        >
                                                            <NotificationsIcon />
                                                        </IconButton>
                                                    )}
                                                    {eventLog && eventLog.get(inbox.inboxID) && (
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                color:
                                                                    eventLog.get(inbox.inboxID)
                                                                        .errorCount > 0
                                                                        ? 'red'
                                                                        : 'lightgreen',
                                                            }}
                                                        >
                                                            {
                                                                eventLog.get(inbox.inboxID).items
                                                                    .length
                                                            }
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid xs={1} sx={{ minWidth: 35 }}>
                                                    <CardMenu
                                                        inbox={inbox}
                                                        worksheetID={inbox.calculatedWorksheetID}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {processStatus &&
                                                inbox.inboxType !== 'calculated' &&
                                                processStatus.get(inbox.inboxID) && (
                                                    <LinearProgress
                                                        style={{
                                                            height: '10px',
                                                            borderRadius: '5px',
                                                            marginTop: '5px',
                                                        }} // Set the height to 20px
                                                        variant={
                                                            processStatus.get(inbox.inboxID)
                                                                .curStep === -1
                                                                ? 'indeterminate'
                                                                : 'determinate'
                                                        }
                                                        value={
                                                            processStatus.get(inbox.inboxID)
                                                                .curStep === -1
                                                                ? 0
                                                                : (100 *
                                                                      processStatus.get(
                                                                          inbox.inboxID
                                                                      ).curStep) /
                                                                  processStatus.get(inbox.inboxID)
                                                                      .totalSteps
                                                        }
                                                    />
                                                )}

                                            {inbox.description && (
                                                <Typography
                                                    sx={{
                                                        mt: 1,
                                                    }}
                                                >
                                                    {inbox.description}
                                                </Typography>
                                            )}
                                            <Grid container sx={{ mt: 2, mb: 0.5 }}>
                                                <Grid
                                                    xs={9.5}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 15,
                                                            fontWeight: 'bold',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        Discovered Tables
                                                    </Typography>
                                                    {inbox.tables?.length > 1 && (
                                                        <Button
                                                            onClick={() => {
                                                                setInbox(inbox)
                                                                compareInboxTables(inbox.inboxID)
                                                            }}
                                                        >
                                                            compare
                                                        </Button>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    xs={2.5}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'right',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 15,
                                                            fontWeight: 'bold',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        Reports
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Box
                                                sx={{
                                                    height: '140px',
                                                    overflow: 'auto',
                                                }}
                                            >
                                                {inbox.tables?.map((inboxTable, index) => {
                                                    return (
                                                        <Grid container key={index} sx={{ m: 0 }}>
                                                            <Grid xs={9}>
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        textTransform: 'none',
                                                                        p: 0.25,
                                                                        pl: 1,
                                                                        pr: 1,
                                                                        m: 0.5,
                                                                        width: '100%',
                                                                    }}
                                                                    onClick={() => {
                                                                        getTableMetadata(
                                                                            inboxTable.reportSeriesTableID
                                                                        )
                                                                        setTableDialogOpenStatus(
                                                                            true
                                                                        )
                                                                    }}
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        m: 0,
                                                                        fontSize: 13,
                                                                        color: '#555',
                                                                    }}
                                                                >
                                                                    {inboxTable.tableName}
                                                                </Button>
                                                            </Grid>
                                                            <Grid xs={0.5}></Grid>
                                                            <Grid xs={2.5}>
                                                                <Box
                                                                    sx={{
                                                                        fontSize: 13,
                                                                        height: '100%',
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'right',
                                                                    }}
                                                                >
                                                                    {inboxTable.ingestions}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                        {inbox.tables.length > 0 && (
                                            <Button
                                                variant="contained"
                                                sx={{ width: '100%' }}
                                                onClick={() => {
                                                    setReportDialogOpenStatus(true)
                                                    getIngestionMetadata(inbox?.inboxID)
                                                }}
                                            >
                                                Report Load History
                                            </Button>
                                        )}
                                        {inbox.lastIngestion && (
                                            <Typography
                                                sx={{
                                                    fontSize: 13,
                                                    mt: 1,
                                                    mb: 0,
                                                }}
                                            >
                                                Last loaded: {inbox.lastIngestion}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}
                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        width={'33%'}
                    >
                        <IconButton
                            onClick={() => {
                                setNewSourceOpen(true)
                            }}
                        >
                            <AddCircleIcon sx={{ color: 'white', fontSize: '50pt' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <CalculatedColumnsDialog
                open={cfwDialogOpen}
                setOpen={setCfwDialogOpen}
                serverContext={serverContext}
                inboxID={inbox?.inboxID}
            />
            <CalculatedSourceDialog
                open={cswDialogOpen}
                setOpen={setCswDialogOpen}
                serverContext={serverContext}
                inbox={inbox}
                metadata={metadata}
            />
            <TableDialog
                open={tableDialogOpen}
                setOpen={setTableDialogOpenStatus}
                tableMetadata={tableMetadata}
                server={server}
                getWorkspaceMetadata={getWorkspaceMetadata}
            />
            <ReportDialog
                open={reportDialogOpen}
                setOpen={setReportDialogOpenStatus}
                ingestionMetadata={ingestionMetadata}
                metadata={metadata}
                server={server}
                getIngestionMetadata={getIngestionMetadata}
                getWorkspaceMetadata={getWorkspaceMetadata}
            />
            <EventLogDialog
                open={eventLogOpen}
                setOpen={setEventLogOpen}
                eventLog={eventLog}
                inboxID={inbox?.inboxID}
                server={server}
            />
            <InboxPropertiesDialog
                open={inboxPropsOpen}
                setOpen={setInboxPropsOpen}
                metadata={metadata}
                setMetadata={setMetadata}
                inboxID={inbox?.inboxID}
                server={server}
            />
            <NewSourceDialog
                open={newSourceOpen}
                setOpen={setNewSourceOpen}
                serverContext={serverContext}
                getWorkspaceMetadata={getWorkspaceMetadata}
            />
            <CompareDialog
                open={compareDialogOpen}
                setOpen={setCompareDialogOpen}
                server={server}
                inbox={inbox}
                compareResult={compareResult}
            />
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                metadata={metadata}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            handleDelete()
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}
