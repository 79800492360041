import { useState } from 'react'
import { login } from '../../../../api/auth'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthenticated } from '../../../../store/actions/authActions'
import { ROUTES } from '../../../../router/routes'

const useSignIn = (setShowSnackbar) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [signInUser, setSignInUser] = useState({ email: '', password: '' })
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSignInInputChange = (fieldName, value) => {
        setSignInUser((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }))
    }

    const resetSignInForm = () => {
        setSignInUser({ email: '', password: '' })
    }

    const validateSignIn = () => {
        if (!signInUser.email) {
            setError('Please enter your email address.')
            return false
        }
        if (!signInUser.password) {
            setError('Please enter your password.')
            return false
        }
        setError(null)
        return true
    }

    const handleSignIn = () => {
        if (validateSignIn()) {
            setIsLoading(true)
            login({
                username: signInUser.email,
                password: signInUser.password,
            })
                .then((res) => {
                    resetSignInForm()
                    dispatch(setAuthenticated(true, res))

                    // record user login in db
                    const body = {
                        action: 'login',
                        userID: res.username,
                        isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                    }
                    fetch(
                        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/ui_information',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${res.signInUserSession.idToken.jwtToken}`,
                            },
                            body: JSON.stringify(body),
                        }
                    )
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok')
                            }
                            return response.json() // Or handle the response in the way you need if you care about it
                        })
                        .catch((error) => {
                            console.error('Error recording login', error)
                        })

                    navigate(ROUTES.CANVAS)
                })
                .catch((error) => {
                    setError(error.message)
                    setShowSnackbar({ msg: error.message, severity: 'error' })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setShowSnackbar({ msg: error, severity: 'error' })
        }
    }

    return {
        signInUser,
        handleSignInInputChange,
        handleSignIn,
        isSignInLoading: isLoading,
    }
}

export default useSignIn
