import React, { useCallback, useEffect, useRef, useState } from 'react'
import './FilterCreator.css'
import { Box, Checkbox, checkboxClasses, ListItem, MenuItem, Typography } from '@mui/material'
import Selector from '../common/Selector/Selector'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import Button from '../common/Button/Button'
import { packFilter, unpackFilters } from '../Insights/Filter'
import { cloneDeep, debounce } from 'lodash'
import Input from '../common/Input/Input'
import { useCategoryValues } from '../../api/hooks/categoryValues/useCategoryValues'
import { useWorkspaceMetadata } from '../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import InfiniteScroll from 'react-infinite-scroll-component'

export const FilterCreator = ({
    source,
    metricFilters,
    setMetricFilters,
    setFilterCreation,
    metric,
    setMetric,
}) => {
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [operator, setOperator] = useState('Equals')
    const [selectedCategoryValues, setSelectedCategoryValues] = useState([])
    const [saveAsSavedFilter, setSaveAsSavedFilter] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [nameError, setNameError] = useState('')
    const [likeValue, setLikeValue] = useState(null)
    const [debouncedLikeValue, setDebouncedLikeValue] = useState('')
    const [renderedCategoryValues, setRenderedCategoryValues] = useState([])
    const scrollableTarget = useRef()
    const { isLoading: categoryValuesLoading, data: categoryValues } = useCategoryValues(
        selectedColumn?.columnName,
        likeValue,
        source.table.reportSeriesTableID
    )
    const {
        workspaceMetadata,
        saveFilter,
        saveFilterPending: savedFilterLoading,
        isFetching: workspaceMetadataLoading,
    } = useWorkspaceMetadata()
    const checkUsedName = useCallback(
        debounce((v) => {
            if (
                workspaceMetadata.filters.some(
                    (f) => f.filterName.toLowerCase() === v.toLowerCase().trim()
                )
            ) {
                setNameError('Filter name already in use')
            } else {
                if (nameError) setNameError('')
            }
        }, 200),
        [nameError, workspaceMetadata]
    )
    const debounceLikeValueChange = useCallback(
        debounce((v) => {
            if (v) setLikeValue(v)
            else setLikeValue(null)
        }, 300),
        []
    )

    useEffect(() => {
        if (categoryValues.length > 0) {
            setRenderedCategoryValues(categoryValues.slice(0, 50))
        }
    }, [categoryValues])

    const handleCategoryValueToggle = (value) => {
        if (selectedCategoryValues.includes(value)) {
            setSelectedCategoryValues(selectedCategoryValues.filter((v) => v !== value))
        } else {
            setSelectedCategoryValues((prev) => [...prev, value])
        }
    }

    const handleSaveFilter = () => {
        const filterList = saveAsSavedFilter ? [] : unpackFilters(metricFilters)
        if (operator === 'Between') {
            const startFilter = {
                attributeName: selectedColumn.columnName,
                operator: 'GreaterThanOrEquals',
                filterValue: { values: [selectedCategoryValues[0]] },
            }
            filterList.push(startFilter)
            const endFilter = {
                attributeName: selectedColumn.columnName,
                operator: 'LessThanOrEquals',
                filterValue: { values: [selectedCategoryValues[1]] },
            }
            filterList.push(endFilter)
        } else {
            const attributeFilter = {
                attributeName: selectedColumn.columnName,
                operator: operator,
                filterValue: { values: selectedCategoryValues },
            }
            filterList.push(attributeFilter)
        }
        const filterToSave = packFilter(filterList)
        if (saveAsSavedFilter) {
            saveFilter({
                filterName: filterName.trim(),
                filter: filterToSave,
                onSuccess: (workspaceMetadata) => {
                    const newFilter = workspaceMetadata.filters.find(
                        (f) => f.filterName === filterName.trim()
                    )
                    const newMetric = cloneDeep(metric)
                    newMetric.savedFilterID = newFilter.savedFilterKey
                    setMetric(newMetric)
                    setFilterCreation(false)
                },
            })
        } else {
            setMetricFilters(filterToSave)
            setFilterCreation(false)
        }
    }

    const handleNameChange = (v) => {
        checkUsedName(v)
        setFilterName(v)
    }

    const handleNextScroll = () => {
        const currentLength = renderedCategoryValues.length
        setRenderedCategoryValues(categoryValues.slice(0, currentLength + 50))
    }

    const handleSearchChange = (v) => {
        setDebouncedLikeValue(v)
        debounceLikeValueChange(v)
    }

    const isNumeric =
        selectedColumn &&
        (selectedColumn.columnType === 'Decimal' ||
            selectedColumn.columnType === 'Currency' ||
            selectedColumn.columnType === 'Integer')

    const isDate = selectedColumn && selectedColumn.columnType === 'DateTime'

    return (
        <>
            <Box className={'filter-creator-selectors-container'}>
                <Box className={'filter-creator-selector'}>
                    <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Filter by
                    </Typography>
                    <Selector
                        value={selectedColumn}
                        onChange={(e) => setSelectedColumn(e.target.value)}
                        sx={{ height: '32px !important' }}
                    >
                        {source?.table?.columns?.map((col) => (
                            <MenuItem className={'inter'} value={col} key={col.columnName}>
                                {col.columnName}
                            </MenuItem>
                        ))}
                    </Selector>
                </Box>
                <Box className={'filter-creator-selector'}>
                    <Typography className={'inter'} sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Filter type
                    </Typography>
                    <Selector
                        value={operator}
                        onChange={(e) => setOperator(e.target.value)}
                        sx={{ height: '32px !important' }}
                    >
                        <MenuItem value={'Equals'}>Equals</MenuItem>
                        <MenuItem value={'NotEquals'}>Not Equals</MenuItem>
                        {(isNumeric || isDate) && (
                            <>
                                <MenuItem value={'GreaterThan'}>Greater Than</MenuItem>
                                <MenuItem value={'GreaterThanOrEquals'}>
                                    Greater Than or Equals
                                </MenuItem>
                                <MenuItem value={'LessThan'}>Less Than</MenuItem>
                                <MenuItem value={'LessThanOrEquals'}>Less Than or Equals</MenuItem>
                            </>
                        )}
                        {!isNumeric && !isDate && <MenuItem value={'Like'}>Like</MenuItem>}
                        {isDate && <MenuItem value={'Between'}>Between</MenuItem>}
                    </Selector>
                </Box>
                <Input
                    label={'Search'}
                    value={debouncedLikeValue}
                    onChange={(e) => handleSearchChange(e.target.value)}
                />
            </Box>
            <Box
                className={'filter-creator-category-values'}
                sx={{
                    height: `calc(100% - ${saveAsSavedFilter ? (nameError ? 175 : 165) : 110}px)`,
                }}
                ref={scrollableTarget}
            >
                {categoryValuesLoading ? (
                    <Box className={'filter-creator-loader-container'}>
                        <ScoopLoader size={60} />
                    </Box>
                ) : categoryValues.length > 0 ? (
                    <InfiniteScroll
                        scrollableTarget={scrollableTarget.current}
                        dataLength={renderedCategoryValues.length}
                        next={handleNextScroll}
                        hasMore={renderedCategoryValues.length < categoryValues.length}
                        loader={<ScoopLoader />}
                    >
                        {renderedCategoryValues.map((v) => (
                            <ListItem sx={{ padding: '2px 2px 2px 0', width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        sx={{
                                            height: 10,
                                            width: 10,
                                            '&.MuiCheckbox-root': {
                                                color: '#C7C5C7',
                                            },
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: '#E50B54',
                                            },
                                        }}
                                        onClick={() => handleCategoryValueToggle(v)}
                                        checked={selectedCategoryValues.includes(v)}
                                        size={'medium'}
                                    />
                                    <Typography
                                        className={'inter'}
                                        sx={{ fontSize: 14, marginLeft: '5px' }}
                                    >
                                        {v}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </InfiniteScroll>
                ) : (
                    <Typography className={'inter'} sx={{ color: '#979099' }}>
                        {likeValue
                            ? 'No values found for current search criteria'
                            : 'Select a column to filter by'}
                    </Typography>
                )}
            </Box>
            {saveAsSavedFilter && (
                <>
                    <Input
                        label={'Filter name'}
                        value={filterName}
                        onChange={(e) => handleNameChange(e.target.value)}
                    />
                    {nameError && (
                        <Typography className={'inter'} sx={{ color: 'red', fontSize: '12px' }}>
                            {nameError}
                        </Typography>
                    )}
                </>
            )}
            <Box
                className={'filter-creator-footer-container'}
                sx={{ paddingTop: nameError ? 0 : '10px' }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox
                        sx={{
                            height: 10,
                            width: 10,
                            '&.MuiCheckbox-root': {
                                color: '#C7C5C7',
                            },
                            [`&, &.${checkboxClasses.checked}`]: {
                                color: '#E50B54',
                            },
                        }}
                        onClick={() => setSaveAsSavedFilter(!saveAsSavedFilter)}
                        checked={saveAsSavedFilter}
                        size={'medium'}
                    />
                    <Typography className={'inter'} sx={{ fontSize: '14px', marginLeft: '5px' }}>
                        Save as saved filter and replace current selection
                    </Typography>
                </Box>
                <Button
                    disabled={
                        nameError ||
                        savedFilterLoading ||
                        workspaceMetadataLoading ||
                        !selectedColumn ||
                        selectedCategoryValues.length === 0 ||
                        (saveAsSavedFilter ? !filterName : false)
                    }
                    onClick={handleSaveFilter}
                    className={'primary-button button-purple save-filter-button'}
                >
                    {savedFilterLoading || workspaceMetadataLoading ? (
                        <ScoopLoader />
                    ) : (
                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                            Save filter
                        </Typography>
                    )}
                </Button>
            </Box>
        </>
    )
}
