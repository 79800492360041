import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Presentation.css'
import ArrowRight from '../../assets/icons/ArrowRight.svg'
import ArrowLeft from '../../assets/icons/ArrowLeft.svg'
import CaretDownBlack from '../../assets/icons/CaretDownBlack.svg'
import { Box, IconButton, MenuItem, Popover, Typography } from '@mui/material'
import { OBJECT_TYPES } from '../Objects/types'
import ImageElement from '../Objects/Image/ImageElement'
import VideoElement from '../Objects/Video/VideoElement'
import InsightElement from '../Objects/Insight/InsightElement'
import SheetletElement from '../Objects/Sheetlet/SheetletElement'
import TextEditorElement from '../Objects/TextEditor/TextEditorElement'
import { ArrowElement } from '../Objects/Arrow/ArrowElement'
import ProcessDiagramElement from '../Objects/ProcessDiagram/ProcessDiagramElement'
import { PromptElement } from '../Objects/Prompt/PromptElement'
import Button from '../common/Button/Button'
import { GenericShapeElement } from '../Objects/GenericShape/GenericShapeElement'
import CornersOut from '../../assets/icons/CornersOut.svg'
import { Server } from '../../api/Server'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { setActiveMode } from '../../store/actions/uiActions'
import { drawShape, isCanvasBlank } from '../CanvasGrid/DrawShapes'
import ScoopLogo from '../../assets/logo/ScoopLogo.svg'

export const Presentation = () => {
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const activeMode = useSelector((state) => state.ui.activeMode)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slideId, canvasId } = useParams()
    const server = new Server(workspaceID, userID, token)
    const [objects, setObjects] = useState(null)
    const [workspaceMetadata, setWorkspaceMetadata] = useState(null)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const presentationRef = useRef()
    const slideSelector = useRef()
    const [currentSlide, setCurrentSlide] = useState(parseInt(slideId) || 0)
    const [slideSelectorOpen, setSlideSelectorOpen] = useState(false)
    const [showControls, setShowControls] = useState(true)

    useEffect(() => {
        const handleEsc = () => {
            if (!document.fullscreenElement) {
                navigate(
                    window.location.pathname.split('/presentation')[0] + window.location.search
                )
                dispatch(setActiveMode('cursor'))
            }
        }
        window.addEventListener('fullscreenchange', handleEsc)
        return () => window.removeEventListener('fullscreenchange', handleEsc)
    }, [])

    const slideExists = (slideId) => {
        return slideId >= 0 && Array.isArray(slides) && slideId < slides.length
    }

    useEffect(() => {
        navigate(`/canvas/${canvasId}/presentation/${currentSlide}` + window.location.search, {
            replace: true,
        })
    }, [currentSlide, navigate, canvasId])

    useEffect(() => {
        const handleClick = (event) => {
            const target = event.target
            if (target.tagName === 'A') {
                const href = target.getAttribute('href')
                event.preventDefault()
                navigate(href + window.location.search)
                setCurrentSlide(parseInt(href.split('/').pop()))
            }
        }
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [navigate])

    useEffect(() => {
        getObjects()
        getWorkspaceMetadata()
    }, [userID, workspaceID, canvasId])

    const getObjects = () => {
        if (!userID || !workspaceID || !canvasId) return
        server.postData(
            {
                action: 'loadCanvasWithThemes',
                userID: userID,
                workspaceID: workspaceID,
                canvasID: canvasId,
            },
            (result) => {
                if (result.error) return
                setObjects(result.canvas.canvasObjects)
            }
        )
    }

    const getWorkspaceMetadata = () => {
        if (!userID || !workspaceID) return
        server.postData(
            {
                action: 'getWorkspaceMetadata',
            },
            (result) => {
                setWorkspaceMetadata(result)
            }
        )
    }

    const isIntersecting = (frame, child) => {
        if (child.type === OBJECT_TYPES.ARROW) {
            const minX = Math.min(child.startInitialPosition.x, child.endInitialPosition.x)
            const minY = Math.min(child.startInitialPosition.y, child.endInitialPosition.y)
            const maxX = Math.max(child.startInitialPosition.x, child.endInitialPosition.x)
            const maxY = Math.max(child.startInitialPosition.y, child.endInitialPosition.y)
            const arrowAABB = {
                x: minX,
                y: minY,
                width: maxX - minX,
                height: maxY - minY,
            }
            const horizontalOverlap = Math.max(
                0,
                Math.min(arrowAABB.x + arrowAABB.width, frame.x + frame.width) -
                    Math.max(arrowAABB.x, frame.x)
            )
            const verticalOverlap = Math.max(
                0,
                Math.min(arrowAABB.y + arrowAABB.height, frame.y + frame.height) -
                    Math.max(arrowAABB.y, frame.y)
            )
            return horizontalOverlap > 0 && verticalOverlap > 0
        } else {
            const horizontalOverlap = Math.max(
                0,
                Math.min(child.x + child.width, frame.x + frame.width) - Math.max(child.x, frame.x)
            )
            const verticalOverlap = Math.max(
                0,
                Math.min(child.y + child.height, frame.y + frame.height) -
                    Math.max(child.y, frame.y)
            )
            return horizontalOverlap > 0 && verticalOverlap > 0
        }
    }

    const getFrameDimensions = (frame, screen) => {
        const frameWidth = frame.width
        const frameHeight = frame.height
        const screenWidth = screen.width
        const screenHeight = screen.height
        const frameAspectRatio = frameWidth / frameHeight
        const screenAspectRatio = screenWidth / screenHeight
        // screen width > height
        if (screenAspectRatio > 1) {
            if (frameAspectRatio > 1) {
                if ((screenHeight / frameHeight) * frameWidth > screenWidth) {
                    return {
                        width: screenWidth,
                        height: (screenWidth / frameWidth) * frameHeight,
                    }
                } else {
                    return {
                        width: (screenHeight / frameHeight) * frameWidth,
                        height: screenHeight,
                    }
                }
            } else if (frameAspectRatio < 1) {
                return {
                    width: (frameHeight / screenHeight) * frameWidth,
                    height: screenHeight,
                }
            } else {
                return {
                    width: (frameHeight / screenHeight) * frameWidth,
                    height: screenHeight,
                }
            }
            // screen height > width
        } else if (screenAspectRatio < 1) {
            if (frameAspectRatio > 1) {
                return {
                    width: screenWidth,
                    height: (screenWidth / frameWidth) * frameHeight,
                }
            } else if (frameAspectRatio < 1) {
                if ((screenWidth / frameWidth) * frameHeight > screenHeight) {
                    return {
                        width: (screenHeight / frameHeight) * frameWidth,
                        height: screenHeight,
                    }
                } else {
                    return {
                        width: screenWidth,
                        height: (screenWidth / frameWidth) * frameHeight,
                    }
                }
            } else {
                return {
                    width: screenWidth,
                    height: (screenWidth / frameWidth) * frameHeight,
                }
            }
            // square screen
        } else {
            if (frameAspectRatio > 1) {
                return {
                    width: screenWidth,
                    height: (screenWidth / frameWidth) * frameHeight,
                }
            } else if (frameAspectRatio < 1) {
                return {
                    width: (screenHeight / frameHeight) * frameWidth,
                    height: screenHeight,
                }
            } else {
                return {
                    width: (screenHeight / frameHeight) * frameWidth,
                    height: screenHeight,
                }
            }
        }
    }

    const constructFrameSlides = () => {
        const frames = objects?.filter((obj) => obj.type === OBJECT_TYPES.FRAME)
        const rest = objects?.filter((obj) => obj.type !== OBJECT_TYPES.FRAME)
        if (!frames) return []
        return frames
            ?.map((frame) => {
                const slideObjects = []
                rest.forEach((obj) => {
                    if (isIntersecting(frame, obj))
                        if (obj.type === OBJECT_TYPES.ARROW) {
                            slideObjects.push({
                                ...obj,
                                slideStartInitialPosition: {
                                    x: obj.startInitialPosition.x - frame.x,
                                    y: obj.startInitialPosition.y - frame.y,
                                },
                                slideEndInitialPosition: {
                                    x: obj.endInitialPosition.x - frame.x,
                                    y: obj.endInitialPosition.y - frame.y,
                                },
                            })
                        } else {
                            slideObjects.push({
                                ...obj,
                                slideX: obj.x - frame.x,
                                slideY: obj.y - frame.y,
                            })
                        }
                })
                let frameDimensions
                if (isGuestMode) {
                    frameDimensions = getFrameDimensions(frame, {
                        width: window.innerWidth,
                        height: window.innerHeight,
                    })
                } else {
                    frameDimensions = getFrameDimensions(frame, window.screen)
                }
                return { frame, slideObjects, ...frameDimensions }
            })
            .sort((a, b) => a.frame.presentationIndex - b.frame.presentationIndex)
            .filter((slide) => !slide.frame.hidden)
    }

    const slides = useMemo(() => objects && objects.length > 1 && constructFrameSlides(), [objects])

    const constructObject = (obj) => {
        const horizontalScale = slides[currentSlide].width / slides[currentSlide].frame.width
        const verticalScale = slides[currentSlide].height / slides[currentSlide].frame.height
        const avgScale = (horizontalScale + verticalScale) / 2
        switch (obj.type) {
            case OBJECT_TYPES.IMAGE:
                return (
                    <ImageElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        url={obj.url?.replace(
                            'https://scoop-image-uploads.s3.us-west-2.amazonaws.com',
                            'https://d3lkv74tdz6isd.cloudfront.net'
                        )}
                        content={obj.content}
                    />
                )
            case OBJECT_TYPES.VIDEO:
                return (
                    <VideoElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        url={obj.url}
                        content={obj.content}
                    />
                )
            case OBJECT_TYPES.INSIGHT:
                return (
                    <InsightElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        content={obj.content}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        workspaceMetadata={workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.SHEETLET:
                const sheetWidth = obj.width * horizontalScale
                const sheetHeight = obj.height * verticalScale
                return (
                    <SheetletElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{ width: sheetWidth, height: sheetHeight }}
                        content={obj.content}
                        shouldHideGrid={obj.shouldHideGrid}
                        shouldHideHeaders={obj.shouldHideHeaders}
                        presentationScale={
                            sheetHeight > sheetWidth ? horizontalScale : verticalScale
                        }
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                    />
                )
            case OBJECT_TYPES.TEXT:
                let resultContent = obj.content
                if (obj.content.includes('font-size')) {
                    let scaledContent = obj.content.split('font-size:')
                    scaledContent.forEach((split, i) => {
                        if (i > 0) {
                            const tempStr = split.split(';')
                            const fontSize = tempStr[0]
                            let fontValue = fontSize.match(/[-.0-9]/g).join('')
                            fontValue = parseFloat(fontValue) * avgScale
                            const fontUnit = fontSize.match(/[a-zA-Z]/g).join('')
                            tempStr.splice(0, 1)
                            scaledContent[i] = fontValue + fontUnit + ';' + tempStr.join(';')
                        }
                    })
                    resultContent = scaledContent.join('font-size:')
                }
                return (
                    <TextEditorElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        value={resultContent}
                        replacement={obj?.replacement}
                        initialBorder={obj.border}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        canvasID={canvasId}
                        slides={objects?.filter((o) => o.type === OBJECT_TYPES.FRAME)}
                    />
                )
            case OBJECT_TYPES.ARROW:
                return (
                    <ArrowElement
                        id={obj.id}
                        key={obj.id}
                        arrowProps={obj.arrowProps}
                        startInitialPosition={{
                            x: obj.slideStartInitialPosition.x * horizontalScale,
                            y: obj.slideStartInitialPosition.y * verticalScale,
                        }}
                        endInitialPosition={{
                            x: obj.slideEndInitialPosition.x * horizontalScale,
                            y: obj.slideEndInitialPosition.y * verticalScale,
                        }}
                        multiProps={{}}
                    />
                )
            case OBJECT_TYPES.PROCESS:
                return (
                    <ProcessDiagramElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        content={obj.content}
                        workspaceMetadata={workspaceMetadata}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                    />
                )
            case OBJECT_TYPES.PROMPT:
                return (
                    <PromptElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        promptProps={obj.promptProps}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        workspaceMetadata={workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.GENERIC_SHAPE:
                return (
                    <GenericShapeElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale,
                        }}
                        initialSize={{
                            width: obj.width * horizontalScale,
                            height: obj.height * verticalScale,
                        }}
                        content={obj.content}
                        shapeType={obj.shapeType}
                    />
                )
            default:
                return null
        }
    }

    const handlePrevious = () => {
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1)
    }

    const handleNext = () => {
        if (currentSlide < slides.length - 1) setCurrentSlide(currentSlide + 1)
    }

    useEffect(() => {
        const handleArrowPress = (e) => {
            switch (e.key) {
                case 'ArrowLeft':
                    handlePrevious()
                    break
                case 'ArrowRight':
                    handleNext()
                    break
                default:
                    break
            }
        }
        window.addEventListener('keydown', handleArrowPress)
        return () => window.removeEventListener('keydown', handleArrowPress)
    }, [slides, currentSlide])

    const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
            navigate(window.location.pathname.split('/presentation')[0] + window.location.search)
            dispatch(setActiveMode('cursor'))
        } else {
            presentationRef.current?.requestFullscreen()
            setShowControls(false)
        }
    }

    useEffect(() => {
        if (slides && slideExists(currentSlide)) {
            const drawObjects = document.getElementsByClassName('drawObject')
            const verticalScale = slides[currentSlide].height / slides[currentSlide].frame.height
            const horizontalScale = slides[currentSlide].width / slides[currentSlide].frame.width
            const avgScale = (verticalScale + horizontalScale) / 2
            if (drawObjects.length > 0) {
                const drawArray = [...drawObjects]
                drawArray.forEach((canvas) => {
                    if (isCanvasBlank(canvas)) {
                        let shape = JSON.parse(canvas.dataset.shape)
                        if (shape) {
                            drawShape(canvas, shape)
                            // SCALE CANVAS
                            canvas.style.transformOrigin = 'top left'
                            canvas.style.transform = `scale(${avgScale})`
                        }
                    }
                })
            }
        }
    }, [slides, currentSlide])

    const handleGoToCursor = () => {
        navigate(window.location.pathname.split('/presentation')[0] + window.location.search)
        dispatch(setActiveMode('cursor'))
    }

    return Array.isArray(slides) && slides.length > 0 && slideExists(currentSlide) ? (
        <>
            <Box
                id={'slide-container'}
                sx={{
                    backgroundColor: 'black',
                    width: '100%',
                    height: '100%',
                    display: 'grid',
                    placeContent: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
                ref={presentationRef}
            >
                <Box
                    id={'slide-content-container'}
                    sx={{
                        backgroundColor: slides[currentSlide].frame.backgroundColor,
                        backgroundImage: `url(${slides[currentSlide].frame.backgroundImage?.replace('https://scoop-image-uploads.s3.us-west-2.amazonaws.com', 'https://d3lkv74tdz6isd.cloudfront.net')})`,
                        backgroundSize: '100% 100%',
                        width: slides[currentSlide].width,
                        height: slides[currentSlide].height,
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    {slides[currentSlide].slideObjects.map(constructObject)}
                </Box>
                <Box
                    className={'presentation-controls-container'}
                    sx={{ bottom: showControls ? '4px' : '-48px' }}
                >
                    <Box className={'presentation-controls'}>
                        <Box
                            className={'toggle-controls'}
                            onClick={() => setShowControls(!showControls)}
                        >
                            <img
                                src={CaretDownBlack}
                                alt={'toggle'}
                                style={{
                                    height: 15,
                                    transform: showControls ? '' : 'rotate(180deg)',
                                    transition: 'all 300ms ease',
                                }}
                            />
                        </Box>
                        <IconButton onClick={handlePrevious} disabled={currentSlide === 0}>
                            <img src={ArrowLeft} alt={'previous'} />
                        </IconButton>
                        <Box
                            className={'slide-title-button'}
                            onClick={() => setSlideSelectorOpen(true)}
                            ref={slideSelector}
                        >
                            <Typography
                                className={'inter'}
                                sx={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {slides[currentSlide].frame.title}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={handleNext}
                            disabled={currentSlide === slides.length - 1}
                        >
                            <img src={ArrowRight} alt={'next'} />
                        </IconButton>
                        {activeMode === 'presentation' ? (
                            <Button
                                className={`${isGuestMode && document.fullscreenElement ? '' : 'button-purple'} end-button`}
                                onClick={handleFullscreenChange}
                            >
                                {document.fullscreenElement ? (
                                    'End'
                                ) : (
                                    <img src={CornersOut} alt={'enter-fullscreen'} />
                                )}
                            </Button>
                        ) : (
                            !document.fullscreenElement && (
                                <IconButton onClick={handleFullscreenChange}>
                                    <img src={CornersOut} alt={'enter-fullscreen'} />
                                </IconButton>
                            )
                        )}
                        {activeMode !== 'presentation' && (
                            <Button
                                className={`${isGuestMode ? '' : 'button-purple'} end-button`}
                                onClick={() =>
                                    navigate(
                                        window.location.pathname.split('/presentation')[0] +
                                            window.location.search
                                    )
                                }
                            >
                                Exit
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
            <Popover
                open={slideSelectorOpen}
                anchorEl={slideSelector.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                container={presentationRef.current}
                onClose={() => setSlideSelectorOpen(false)}
                slotProps={{
                    paper: {
                        sx: {
                            marginTop: '-10px',
                            boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)',
                            width: 200,
                        },
                    },
                }}
            >
                {slides.map((slide, index) => (
                    <MenuItem
                        key={'slide' + index}
                        onClick={() => {
                            setCurrentSlide(index)
                            setSlideSelectorOpen(false)
                        }}
                        sx={{
                            backgroundColor: currentSlide === index ? '#FCE7EE' : '',
                            color: currentSlide === index ? '#E50B54' : '',
                        }}
                    >
                        <Typography
                            className={'inter'}
                            sx={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {index + 1 + '. ' + slide.frame.title}
                        </Typography>
                    </MenuItem>
                ))}
            </Popover>
        </>
    ) : !slideExists(currentSlide) && Array.isArray(slides) ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '300px',
                    textAlign: 'center',
                    gap: 2,
                }}
            >
                <img alt={'logo'} src={ScoopLogo} style={{ width: '250px', height: 'auto' }} />
                <Typography
                    sx={{ fontSize: '36px', fontWeight: 'bold', color: '#333' }}
                    className={'inter'}
                >
                    Slide Not Found
                </Typography>
                <Typography
                    sx={{ fontSize: '16px', color: '#777', maxWidth: '400px', padding: '0 20px' }}
                >
                    The slide you’re looking for doesn’t exist. Please check the URL or go back to
                    the previous page.
                </Typography>
                <Button className={'button-purple'} onClick={handleGoToCursor} sx={{ mt: 2 }}>
                    Go Back
                </Button>
            </Box>
        </Box>
    ) : (
        <></>
    )
}
