import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom' // Import useSearchParams
import { ROUTES } from '../../../router/routes'
import { logout } from '../../../store/actions/authActions' // Assuming combined import is correct
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../api/api'
import { Server } from '../../../api/Server'
import datasourceDefs from '../../../api/datasourceConfig'

export const Setup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams() // Use useSearchParams to access query params
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const { postData: postDataDefault } = useApi()
    const [server, setServer] = useState(null)

    // Check for undefined workspaceID, userID, or token and logout, then navigate to login
    useEffect(() => {
        if (
            typeof workspaceID === 'undefined' ||
            typeof userID === 'undefined' ||
            typeof token === 'undefined'
        ) {
            dispatch(logout())
            navigate(ROUTES.LOGIN)
        }
    }, [workspaceID, userID, token, navigate, dispatch])

    // Initialize server instance when dependencies change
    useEffect(() => {
        if (workspaceID && userID && token) {
            const newServer = new Server(workspaceID, userID, token)
            setServer(newServer)
        }
    }, [workspaceID, userID, token])

    const retrieveInboxInformation = useCallback(
        async (inboxID) => {
            const action = {
                action: 'getObjects',
                class: 'scoop.ingest.ReportInbox',
                attributeName: 'inboxID',
                attributeValue: inboxID,
            }
            const { objects: result } = await postDataDefault(action)
            if (result && result.length > 0) return result[0]
        },
        [postDataDefault]
    )

    useEffect(() => {
        if (server) {
            const fetchInbox = async () => {
                // Retrieve the inboxID from the URL search parameters
                const inboxID = searchParams.get('inboxID')
                if (inboxID) {
                    const inboxInfo = await retrieveInboxInformation(inboxID)

                    if (!inboxInfo) {
                        alert('Invalid URL or Inbox provided. Sorry about that.')
                        navigate(ROUTES.CANVAS_DASHBOARD)
                    }

                    // determine whether it's a bot and which bot, which determines the next screen
                    if (typeof inboxInfo?.botService !== 'undefined') {
                        datasourceDefs?.map((datasource) => {
                            if (datasource.service === inboxInfo.botService) {
                                console.log('datasource', datasource)
                                switch (datasource.name) {
                                    case 'Gmail':
                                        navigate(ROUTES.GMAIL, {
                                            state: { inboxInfo: inboxInfo },
                                        })
                                        break
                                }
                            }
                        })
                    }
                }
            }

            fetchInbox()
        }
    }, [server, retrieveInboxInformation, searchParams])

    // Component does not render anything or return null
    return null // Here you can return some JSX if needed for your component
}
