import { Box } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import './ToolBar.css'
import Fab from '@mui/material/Fab'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
const ToolBarIcon = ({
    icon,
    label,
    tooltipTitle,
    tooltipPlacement,
    tooltipOpen,
    onClick,
    onMouseEnter,
    selected,
}) => {
    // console.log(tooltipOpen, label)
    const LightTooltip = styled(({ className, children, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} children={children} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: '#14092A',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            aspectRatio: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 80,
        },
    }))
    return (
        <LightTooltip
            title={tooltipTitle}
            placement={tooltipPlacement}
            open={tooltipOpen === label}
            key={label}
        >
            <Box
                key={label}
                onClick={onClick}
                className={`toolbar-button-icon ${selected && 'selected-icon'}`}
                onMouseEnter={onMouseEnter}
            >
                <img src={icon} alt={label} />
            </Box>
        </LightTooltip>
    )
}
export default ToolBarIcon
