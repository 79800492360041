import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Prompt.css'
import { ElementWrapper } from '../../common/ElementWrapper/ElementWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
    deletePrompt,
    updatePromptPosition,
    updatePromptProps,
    updatePromptSize,
} from '../../../store/actions'
import IconButton from '@mui/material/IconButton'
import DotsThree from '../../../assets/icons/DotsThree.svg'
import DotsThreeWhite from '../../../assets/icons/DotsThreeWhite.svg'
import {
    Box,
    Typography,
    Button,
    MenuItem,
    Chip,
    Menu,
    TextField,
    FormControl,
} from '@mui/material'
import {
    setLastEditedPrompt,
    setPromptSelecting,
    setPromptSelectingObjects,
} from '../../../store/actions/promptActions'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import { ScoopDatePicker } from '../../common/DatePicker/ScoopDatePicker'
import Check from '../../../assets/icons/Check.svg'
import Selector from '../../common/Selector/Selector'
import { useApi } from '../../../api/api'
import PencilSimple from '../../../assets/icons/PencilSimple.svg'
import TrashRed from '../../../assets/icons/TrashRed.svg'
import { debounce } from 'lodash'
import { NumericRange } from '../../common/NumericRange/NumericRange'
import { Toast } from '../../common/Toast/Toast'

export const PromptElement = ({
    id,
    initialSize,
    initialPosition,
    promptProps,
    workspaceMetadata,
    multiProps,
}) => {
    const promptId = `PromptElement-${id}`
    const dispatch = useDispatch()
    const { postData } = useApi()
    const menuButton = useRef()
    const activeMode = useSelector((state) => state.ui.activeMode)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const guestPrompts = useSelector((state) => state.auth.guestPrompts)
    const [isEditMode, setIsEditMode] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [categoryValues, setCategoryValues] = useState([])
    const [categoryValuesLoading, setCategoryValuesLoading] = useState(false)
    const [singleSelectValue, setSingleSelectValue] = useState('')
    const [multipleSelectValue, setMultipleSelectValue] = useState([])
    const [dateValue, setDateValue] = useState(null)
    const [numericRange, setNumericRange] = useState(null)
    const [singleValue, setSingleValue] = useState(0)
    const [alert, setAlert] = useState(false)
    const selectDebounce = useCallback(
        debounce((value) => {
            const newPrompt = { ...promptProps }
            newPrompt.value = value
            if (promptProps.type === 'multi-select') {
                newPrompt.prompt.filterValue.values =
                    value.length === categoryValues.length ? ['All'] : value
            } else {
                newPrompt.prompt.filterValue.values[0] = value
            }
            dispatch(updatePromptProps(id, newPrompt))
            dispatch(setLastEditedPrompt(newPrompt))
        }, 1000),
        [promptProps]
    )
    const singleValueDebounce = useCallback(
        debounce((value) => {
            const newPrompt = { ...promptProps }
            newPrompt.value = value
            newPrompt.prompt.filterValue.values[0] = value
            dispatch(updatePromptProps(id, newPrompt))
            dispatch(setLastEditedPrompt(newPrompt))
        }, 1000),
        [promptProps]
    )

    const getTheme = () => {
        let th = undefined
        if (workspaceMetadata?.themes) {
            workspaceMetadata.themes.forEach((t) => {
                if (t.themeID === promptProps.theme) th = t
            })
        }
        return th
    }

    const theme = getTheme()

    const getCategoryValues = (tableID, worksheetID, rangeName, columnName, likeValue) => {
        setCategoryValuesLoading(true)
        const action = {
            action: 'getCategoryValues',
            columnName: columnName,
            like: likeValue,
        }
        if (tableID) {
            action.reportSeriesTableID = tableID
        } else if (worksheetID) {
            action.worksheetID = worksheetID
            action.rangeName = rangeName
        }
        postData(action)
            .then((r) => {
                setCategoryValuesLoading(false)
                setCategoryValues(r.values)
            })
            .catch(() => {
                setCategoryValuesLoading(false)
                setCategoryValues([])
                setAlert(true)
            })
    }

    useEffect(() => {
        if (
            !isGuestMode &&
            (promptProps.dataSourceId || (promptProps.worksheetId && promptProps.rangeName)) &&
            promptProps.fieldName &&
            !['data-range', 'single-date'].includes(promptProps.type)
        ) {
            getCategoryValues(
                promptProps.dataSourceId,
                promptProps.worksheetId,
                promptProps.rangeName,
                promptProps.fieldName
            )
        }
    }, [promptProps.fieldName, promptProps.dataSourceId, isGuestMode])

    useEffect(() => {
        let props
        if (isGuestMode && guestPrompts.length > 0)
            props = guestPrompts.filter((p) => p.id === id)[0].promptProps
        else props = promptProps
        switch (props.type) {
            case 'single-select':
                setSingleSelectValue(props.value || '')
                break
            case 'multi-select':
                setMultipleSelectValue(props.value || [])
                break
            case 'single-date':
                setDateValue(props.value ? new Date(props.value) : null)
                break
            case 'date-range':
                setDateValue(
                    props.value ? [new Date(props.value[0]), new Date(props.value[1])] : null
                )
                break
            case 'numeric-range':
                setNumericRange(props.value || null)
                break
            case 'single-value':
                setSingleValue(props.value || 0)
                break
        }
    }, [promptProps, isGuestMode, guestPrompts])

    const updateInsightPos = (position) => {
        dispatch(updatePromptPosition(id, position))
    }

    const updateInsightSz = (size) => {
        dispatch(updatePromptSize(id, size))
    }

    const handleDeletePrompt = () => {
        dispatch(deletePrompt(id))
        dispatch(setLastEditedPrompt(promptProps))
        setDeleteOpen(false)
    }

    const handleEdit = () => {
        dispatch(setPromptSelecting(true, promptId))
        dispatch(setPromptSelectingObjects(promptProps.objects))
        setOpenMenu(false)
    }

    const renderMultipleValues = (values) => {
        return values.map((value) => (
            <Chip
                key={value}
                label={value}
                onMouseDown={(e) => {
                    if (['svg', 'path'].includes(e.target.tagName)) e.stopPropagation()
                }}
                onDelete={() => {
                    if (!isGuestMode) {
                        let newValues = [...multipleSelectValue]
                        newValues.splice(newValues[newValues.indexOf(value)], 1)
                        setMultipleSelectValue(newValues)
                        selectDebounce(newValues)
                    }
                }}
                sx={{
                    maxWidth: 150,
                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                    '& .MuiSvgIcon-root': {
                        color: theme?.colorScheme?.darkTheme
                            ? theme?.colorScheme?.darkTheme
                                ? 'white'
                                : ''
                            : '',
                    },
                    border: theme?.colorScheme?.darkTheme
                        ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                        : '',
                }}
            />
        ))
    }

    const handleDateChange = (value) => {
        setDateValue(value)
        const newPrompt = { ...promptProps }
        if (newPrompt.type === 'date-range') {
            newPrompt.value = [value[0].toString(), value[1].toString()]
            newPrompt.prompt[0].filterValue.values[0] = value[0].toISOString().split('T')[0]
            newPrompt.prompt[1].filterValue.values[0] = value[1].toISOString().split('T')[0]
        } else {
            newPrompt.value = value.toString()
            newPrompt.prompt.filterValue.values[0] = value.toISOString().split('T')[0]
        }
        dispatch(updatePromptProps(id, newPrompt))
        dispatch(setLastEditedPrompt(newPrompt))
    }

    const handleNumericChange = (value) => {
        setNumericRange(value)
        const newPrompt = { ...promptProps }
        newPrompt.value = value
        newPrompt.prompt[0].filterValue.values[0] = value[0]
        newPrompt.prompt[1].filterValue.values[0] = value[1]
        dispatch(updatePromptProps(id, newPrompt))
        dispatch(setLastEditedPrompt(newPrompt))
    }

    const handleSingleValueChange = (value) => {
        setSingleValue(value)
        singleValueDebounce(value)
    }

    const handleSelectChange = (value) => {
        setSingleSelectValue(value)
        selectDebounce(value)
    }

    const handleMultipleSelect = (e) => {
        if (e.target.value.includes('Select all')) {
            setMultipleSelectValue([...categoryValues])
            selectDebounce([...categoryValues])
        } else if (e.target.value.includes('Clear all')) {
            setMultipleSelectValue([])
            selectDebounce([])
        } else {
            setMultipleSelectValue(e.target.value)
            selectDebounce(e.target.value)
        }
    }

    const getPromptContent = () => {
        if (
            (promptProps.dataSourceId || (promptProps.worksheetId && promptProps.rangeName)) &&
            promptProps.fieldName &&
            promptProps.type
        ) {
            switch (promptProps.type) {
                case 'single-select':
                    return (
                        <Box sx={{ width: '100%', marginTop: '-20px' }}>
                            {categoryValues.length > 0 && (
                                <Selector
                                    value={singleSelectValue || ''}
                                    renderValue={(v) => v}
                                    sx={{
                                        height: 38,
                                        fontFamily: 'Inter, sans-serif',
                                        backgroundColor: theme?.colorScheme?.backgroundColor || '',
                                        fontSize: '14px',
                                        color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                        border: theme?.colorScheme?.darkTheme
                                            ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                                            : '',
                                        '& .MuiSvgIcon-root': {
                                            color: theme?.colorScheme?.darkTheme
                                                ? theme?.colorScheme?.darkTheme
                                                    ? 'white'
                                                    : ''
                                                : '',
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            maxHeight: 350,
                                            '& .MuiPaper-root': {
                                                backgroundColor:
                                                    theme?.colorScheme?.backgroundColor || '',
                                                backdropFilter:
                                                    theme?.colorScheme?.backgroundColor ===
                                                    '#00000000'
                                                        ? 'blur(20px)'
                                                        : '',
                                            },
                                        },
                                        container: document.getElementById('slide-container'),
                                    }}
                                    disabled={isGuestMode}
                                >
                                    {categoryValues.map((value) => (
                                        <MenuItem
                                            key={value}
                                            onClick={() => handleSelectChange(value)}
                                            sx={{
                                                justifyContent: 'space-between',
                                            }}
                                            value={value}
                                        >
                                            <Typography
                                                className={'inter'}
                                                sx={{
                                                    fontSize: '14px',
                                                    width: 300,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    color: theme?.colorScheme?.darkTheme
                                                        ? 'white'
                                                        : '',
                                                }}
                                            >
                                                {value}
                                            </Typography>
                                            {singleSelectValue === value && (
                                                <img src={Check} alt={'check'} />
                                            )}
                                        </MenuItem>
                                    ))}
                                </Selector>
                            )}
                        </Box>
                    )
                case 'multi-select':
                    return (
                        <Box sx={{ width: '100%', marginTop: '-20px' }}>
                            <Selector
                                value={multipleSelectValue || ''}
                                onChange={handleMultipleSelect}
                                renderValue={renderMultipleValues}
                                labelClassName={'selector-label-bold'}
                                sx={{
                                    height: 38,
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    backgroundColor: theme?.colorScheme?.backgroundColor || '',
                                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                    border: theme?.colorScheme?.darkTheme
                                        ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                                        : '',
                                    '& .MuiSvgIcon-root': {
                                        color: theme?.colorScheme?.darkTheme
                                            ? theme?.colorScheme?.darkTheme
                                                ? 'white'
                                                : ''
                                            : '',
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '2px !important',
                                        gap: '2px',
                                    },
                                    '& .MuiFormControl-root': {
                                        marginTop: '0px !important',
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        maxHeight: 350,
                                        '& .MuiPaper-root': {
                                            backgroundColor:
                                                theme?.colorScheme?.backgroundColor || '',
                                        },
                                    },
                                    container: document.getElementById('slide-container'),
                                }}
                                disabled={isGuestMode}
                                multiple
                            >
                                {[
                                    multipleSelectValue.length === categoryValues.length
                                        ? 'Clear all'
                                        : 'Select all',
                                    ...categoryValues,
                                ].map((value) => {
                                    const selected = multipleSelectValue.includes(value)
                                    return (
                                        <MenuItem
                                            key={value}
                                            value={value}
                                            sx={{
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                className={'inter'}
                                                sx={{
                                                    fontSize: '14px',
                                                    width: 300,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    color: theme?.colorScheme?.darkTheme
                                                        ? 'white'
                                                        : '',
                                                }}
                                            >
                                                {value}
                                            </Typography>
                                            {selected && <img src={Check} alt={'check'} />}
                                        </MenuItem>
                                    )
                                })}
                            </Selector>
                        </Box>
                    )
                case 'single-date':
                case 'date-range':
                    return (
                        <ScoopDatePicker
                            value={dateValue}
                            onChange={handleDateChange}
                            range={promptProps.type === 'date-range'}
                            isGuestMode={isGuestMode}
                            theme={theme}
                        />
                    )
                case 'numeric-range':
                    return (
                        <NumericRange
                            value={numericRange}
                            onChange={handleNumericChange}
                            max={parseInt(categoryValues[categoryValues.length - 1])}
                            min={categoryValues[0] !== null ? parseInt(categoryValues[0]) : 0}
                            isGuestMode={isGuestMode}
                            theme={theme}
                        />
                    )
                case 'single-value':
                    return (
                        <TextField
                            className={'prompt-label-input'}
                            value={singleValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                handleSingleValueChange(e.target.value.replace(/\D/g, ''))
                            }
                            inputProps={{
                                type: 'number',
                                sx: {
                                    color: theme?.colorScheme?.darkTheme ? 'white' : '',
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    border: theme?.colorScheme?.darkTheme
                                        ? `1px solid ${theme?.colorScheme?.darkTheme ? 'white' : ''}`
                                        : '',
                                },
                            }}
                        />
                    )
            }
        } else {
            return (
                <Button className={'config-button'} onClick={handleEdit} disabled={isGuestMode}>
                    Configure
                </Button>
            )
        }
    }

    return (
        <ElementWrapper
            type={'Prompt'}
            id={promptId}
            key={promptId}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementPosition={updateInsightPos}
            updateElementSize={updateInsightSz}
            minSize={{ width: 300, height: 100 }}
            backgroundColor={theme ? theme.colorScheme.backgroundColor : undefined}
            withTheme={!!theme}
            {...multiProps}
            objectId={id}
        >
            <Box className={'prompt-container'}>
                <Box className={'prompt-header'}>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: theme?.colorScheme?.darkTheme ? 'white' : '',
                        }}
                        className={'inter'}
                    >
                        {promptProps.label || 'Untitled prompt'}
                    </Typography>
                    {activeMode === 'edit' && (
                        <IconButton
                            ref={menuButton}
                            size={'small'}
                            sx={{ borderRadius: '5px' }}
                            onClick={() => setOpenMenu(true)}
                        >
                            {theme?.colorScheme?.darkTheme ? (
                                <img src={DotsThreeWhite} alt={'filter'} />
                            ) : (
                                <img src={DotsThree} alt={'filter'} />
                            )}
                        </IconButton>
                    )}
                </Box>
                {getPromptContent()}
            </Box>
            <Menu open={openMenu} anchorEl={menuButton.current} onClose={() => setOpenMenu(false)}>
                <MenuItem onClick={handleEdit}>
                    <img src={PencilSimple} alt={'text'} style={{ height: 18, width: 18 }} />
                    <Typography className={'inter'} sx={{ ml: '8px', fontSize: '14px' }}>
                        Edit
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDeleteOpen(true)
                        setOpenMenu(false)
                    }}
                >
                    <img src={TrashRed} alt={'trash'} style={{ height: 18, width: 18 }} />
                    <Typography
                        className={'inter'}
                        sx={{ ml: '8px', fontSize: '14px', color: '#EB382A' }}
                    >
                        Delete
                    </Typography>
                </MenuItem>
            </Menu>
            <DeleteDialog
                handleCancel={() => setDeleteOpen(false)}
                title={'Prompt'}
                open={deleteOpen}
                handleDelete={handleDeletePrompt}
                type={'prompt'}
            />
            {alert && (
                <Toast
                    alert={{
                        message: 'Error fetching values, try again later.',
                        severity: 'error',
                    }}
                    onClose={() => setAlert(false)}
                />
            )}
        </ElementWrapper>
    )
}
