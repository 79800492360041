import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { FONT_FAMILIES, FONT_WEIGHTS, KPI_DEFAULT_VALUES } from '../styleConsts'
import { Slider } from '../../../../../common/Slider/Slider'
import { propExistsOnObject, renderColorPicker, renderSelector } from '../utils'
import { debounce, cloneDeep } from 'lodash'

export const KPITextConfig = ({ kpiProp, config, setConfig, chartPreferences }) => {
    const [fontSize, setFontSize] = useState(KPI_DEFAULT_VALUES[kpiProp].fontSize)

    useEffect(() => {
        setFontSize(getKPITextPropValue('fontSize'))
    }, [config])

    const debouncedFunc = useCallback(
        debounce((prop, value) => {
            const newConfig = cloneDeep(config)
            newConfig.styleOverrides.kpi[kpiProp][prop] = value
            if (prop === 'fontSize') {
                newConfig.styleOverrides.kpi[kpiProp].fontScaleFactor = {
                    x: value / 200,
                    y: value / 200,
                }
            }
            setConfig(newConfig)
        }, 200),
        [config, kpiProp]
    )

    const getKPITextPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.kpi[kpiProp], prop))
            return config.styleOverrides.kpi[kpiProp][prop]
        if (chartPreferences.kpi && propExistsOnObject(chartPreferences.kpi[kpiProp], prop))
            return chartPreferences.kpi[kpiProp][prop]
        return KPI_DEFAULT_VALUES[kpiProp][prop]
    }

    const handleDebounceKPITitlePropChange = (prop, value, setter) => {
        debouncedFunc(prop, value)
        setter(value)
    }

    const handleKPITitlePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.kpi[kpiProp][prop] = value
        setConfig(newConfig)
    }

    return (
        <Box className={'column'} sx={{ gap: '16px' }}>
            {renderSelector(
                'Font family',
                getKPITextPropValue('fontFamily'),
                (v) => handleKPITitlePropChange('fontFamily', v),
                FONT_FAMILIES
            )}
            {renderSelector(
                'Font weight',
                getKPITextPropValue('fontWeight'),
                (v) => handleKPITitlePropChange('fontWeight', v),
                FONT_WEIGHTS
            )}
            <Slider
                label={`Font size: ${fontSize}px`}
                value={fontSize}
                min={8}
                max={64}
                step={1}
                onChange={(v) => handleDebounceKPITitlePropChange('fontSize', v, setFontSize)}
            />
            {renderColorPicker('Font color', getKPITextPropValue('fontColor'), (v) =>
                handleKPITitlePropChange('fontColor', v)
            )}
            {kpiProp === 'compare' && (
                <>
                    {renderColorPicker(
                        'Positive color',
                        getKPITextPropValue('positiveColor'),
                        (v) => handleKPITitlePropChange('positiveColor', v)
                    )}
                    {renderColorPicker(
                        'Negative color',
                        getKPITextPropValue('negativeColor'),
                        (v) => handleKPITitlePropChange('negativeColor', v)
                    )}
                </>
            )}
        </Box>
    )
}
