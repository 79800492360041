import { Box, DialogContentText, Typography } from '@mui/material'
import * as React from 'react'
import { getStringForFilter } from './Filter'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import Input from '../common/Input/Input'
import { useCallback, useState } from 'react'
import { debounce } from 'lodash'

export function SavedFilterDialog({
    filterToSave,
    setFilterToSave,
    workspaceMetadata,
    server,
    getWorkspaceMetadata,
}) {
    let [name, setName] = React.useState('')
    let [isConfirmDelete, setIsConfirmDelete] = React.useState(false)
    const [error, setError] = useState('')
    const debounceFn = useCallback(
        debounce((v) => {
            if (
                workspaceMetadata.filters.some(
                    (f) => f.filterName.toLowerCase() === v.toLowerCase().trim()
                )
            ) {
                setError('Filter name already in use')
            } else {
                if (error) setError('')
            }
        }, 200)
    )

    function handleClose(event) {
        setFilterToSave(null)
        setIsConfirmDelete(false)
    }

    function handleSave(event) {
        if (filterToSave) {
            setFilterToSave(null)
            setIsConfirmDelete(false)
            server.postData(
                {
                    action: 'putObject',
                    class: 'scoop.queryfilter.SavedFilter',
                    value: {
                        filterName: name,
                        workspaceID: server.workspaceID,
                        filter: filterToSave,
                    },
                },
                (result) => {
                    getWorkspaceMetadata()
                }
            )
        }
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'} />
            <Button
                onClick={handleSave}
                className={'button-purple'}
                text={'Save'}
                disabled={name.length === 0 || !!error}
            />
        </Box>
    )

    const debounceFilterName = (value) => {
        setName(value)
        debounceFn(value)
    }

    const renderFiltersToSave = () => {
        if (filterToSave.boperator) {
            return filterToSave.filters.map((f) => (
                <Typography className={'inter'}>{getStringForFilter(f)}</Typography>
            ))
        } else {
            return <Typography className={'inter'}>{getStringForFilter(filterToSave)}</Typography>
        }
    }

    if (workspaceMetadata && filterToSave) {
        return (
            <Dialog
                open={true}
                onClose={handleClose}
                maxWidth={'800px'}
                title={isConfirmDelete ? 'Delete Saved Filter' : 'Save a filter for later use '}
                actions={actions}
            >
                {isConfirmDelete && (
                    <>
                        <DialogContentText>
                            Are you sure you want to delete the saved filter:
                        </DialogContentText>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                m: 5,
                            }}
                        >
                            {filterToSave.filterName}
                        </Box>
                    </>
                )}
                {!isConfirmDelete && (
                    <>
                        <Box sx={{ minWidth: 700 }}>
                            <Input
                                label={'New Filter Name'}
                                onChange={(e) => debounceFilterName(e.target.value)}
                                value={name}
                            />
                            {error && (
                                <Typography
                                    className={'Inter'}
                                    sx={{ color: 'red', fontSize: '12px' }}
                                >
                                    {error}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: 3,
                                    flexDirection: 'column',
                                }}
                            >
                                {renderFiltersToSave()}
                            </Box>
                        </Box>
                    </>
                )}
            </Dialog>
        )
    } else return null
}
