export const getToken = () => {
    return localStorage.getItem('userToken')
}

export const getWorkspaceID = () => {
    return localStorage.getItem('workspaceID')
}

export const getUserID = () => {
    return localStorage.getItem('userID')
}

export const setWorkspaceID = (workspaceID) => {
    localStorage.setItem('workspaceID', workspaceID)
}
