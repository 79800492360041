export const SORTING = {
    ASC: 'asc',
    DESC: 'desc',
    NAT: 'natural',
}

export const SORTING_OPTIONS = [
    { label: 'Ascending', value: SORTING.ASC },
    { label: 'Descending', value: SORTING.DESC },
    { label: 'Natural', value: SORTING.NAT },
]

export const DEFAULT_SORTING = [
    {
        order: SORTING.NAT,
        column: '',
    },
]
