// botConfig.js is a file that contains the configuration for each bot.
import GMailIcon from '../assets/images/gmail_icon.png'
import HubspotIcon from '../assets/images/hubspot-logo.png'
import GMailButton from '../assets/images/btn_google_signin_dark_normal_web.png'
import SalesforceIcon from '../assets/icons/Salesforce.svg'
import AirtableIcon from '../assets/icons/Airtable.svg'
import JiraIcon from '../assets/icons/Jira.svg'
import DatabaseIcon from '../assets/icons/DatabaseBlack.svg'
import LinkedInIcon from '../assets/icons/LinkedIn.svg'
import AttioIcon from '../assets/icons/attio.png'
import GSheetsIcon from '../assets/icons/GoogleSheets.svg'
import GoogleDataStudio from '../assets/icons/GoogleDataStudio.svg'
import SharetribeIcon from '../assets/icons/Sharetribe.svg'
import PipedriveIcon from '../assets/icons/PipedriveIcon.png'
import MondayIcon from '../assets/icons/Monday.png'
import CloseIcon from '../assets/icons/close.svg'
import ApolloIcon from '../assets/icons/apollo.svg'
import GoogleAnalyticsIcon from '../assets/icons/google-analytics.svg'

const datasourceDefs = [
    {
        name: 'Hubspot',
        icon: HubspotIcon,
        service: 'R17',
        botUID: 'appconnect@scoop-analytics.com', // for now, users must share their hubspot with this Scoop account
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: true, // if true, the user must share their hubspot with the Scoop account
        urlValidation: /^https:\/\/app\.hubspot\.com\/reports-list\/.*$/, // regex to validate the url
        isSnapshotDefault: true,
    },
    {
        name: 'LinkedIn',
        icon: LinkedInIcon,
        service: 'R20',
        botUID: 'appconnect@scoop-analytics.com', // for now, users must share their LinkedIn with this Scoop account
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: true, // if true, the user must share their LinkedIn with the Scoop account
        urlValidation:
            /^https:\/\/www\.linkedin\.com\/campaignmanager\/accounts\/\d+\/campaign-groups.*/, // regex to validate the url
        isSnapshotDefault: false,
    },
    {
        name: 'Gmail',
        icon: GMailIcon,
        service: 'R16',
        button: GMailButton,
        GMAIL_API_URL:
            'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https://www.googleapis.com/auth/gmail.readonly%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&client_id=193840168882-pplhel3cbmmqt33g97h6fqv6msbrvdv2.apps.googleusercontent.com&redirect_uri=REDIRECT_URI&response_type=code&prompt=consent',
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/gmailoauth',
        BOT_API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
    },
    {
        name: 'Salesforce',
        icon: SalesforceIcon,
        service: 'email',
        isSnapshotDefault: true,
    },
    {
        name: 'Airtable',
        icon: AirtableIcon,
        service: 'R18',
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: false, // if true, the user must share their hubspot with the Scoop account
        urlValidation: /^https:\/\/airtable\.com\/.*$/, // regex to validate the url
        isSnapshotDefault: true,
    },
    {
        name: 'Database',
        icon: DatabaseIcon,
        service: 'R19',
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: false, // if true, the user must share their hubspot with the Scoop account
        urlValidation: /^https:\/\/airtable\.com\/.*$/, // regex to validate the url
        isSnapshotDefault: true,
    },
    {
        name: 'GoogleDataStudio',
        icon: GoogleDataStudio,
        service: 'R1',
        botUID: 'appconnect@scoop-analytics.com', // for now, users must share their GSheets with this Scoop account
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: true, // if true, the user must share their GSheets with the Scoop account
        urlValidation: /^https:\/.*/, // regex to validate the url
        isSnapshotDefault: false,
    },
    {
        name: 'Jira',
        icon: JiraIcon,
        service: 'R9',
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: false, // if true, the user must share their hubspot with the Scoop account
        urlValidation: /^https:\/\/[^.]+\.atlassian\.net(\/.*)?$/, // regex to validate the url
        isSnapshotDefault: true,
    },
    {
        name: 'Sharetribe',
        icon: SharetribeIcon,
        service: 'R21',
        botUID: 'appconnect@scoop-analytics.com', // for now, users must share their GSheets with this Scoop account
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: true, // if true, the user must share their GSheets with the Scoop account
        urlValidation: /^https:\/\/console\.sharetribe\.com(\/.*)?$/, // regex to validate the url
        isSnapshotDefault: false,
    },
    {
        name: 'Apollo',
        icon: ApolloIcon,
        service: 'R22',
        API_URL: 'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions',
        shouldShareWithScoop: false, // if true, the user must share their GSheets with the Scoop account
        urlValidation: /^https:\/\/app\.apollo\.io(\/.*)?$/, // regex to validate the url
        isSnapshotDefault: false,
    },
    {
        name: 'Pipedrive',
        icon: PipedriveIcon,
        isSnapshotDefault: true,
    },
    {
        name: 'Attio',
        icon: AttioIcon,
        isSnapshotDefault: true,
    },
    {
        name: 'Close',
        icon: CloseIcon,
        isSnapshotDefault: true,
    },
    {
        name: 'GoogleAnalytics',
        icon: GoogleAnalyticsIcon,
        isSnapshotDefault: true,
    },
    {
        name: 'Monday',
        icon: MondayIcon,
        isSnapshotDefault: false,
    },
]

export default datasourceDefs
