import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import './Source.css'
import Grid from '@mui/material/Unstable_Grid2'
import { Checkbox } from '@mui/joy'

export function InboxPropertiesDialog({ open, setOpen, metadata, setMetadata, inboxID, server }) {
    const [inbox, setInbox] = React.useState(null)
    const [changed, setChanged] = React.useState(null)
    React.useEffect(() => {
        if (inbox === null) {
            for (let i = 0; i < metadata.inboxes.length; i++) {
                if (metadata.inboxes[i].inboxID === inboxID) {
                    setInbox({ ...metadata.inboxes[i] })
                }
            }
        }
    }, [inboxID])

    function handleClose(event) {
        setOpen(false)
        setInbox(null)
    }

    function afterSave(result) {
        setMetadata({ ...metadata })
    }

    function handleSave(event) {
        setOpen(false)
        setInbox(null)
        if (changed) {
            setChanged(false)
            for (let i = 0; i < metadata.inboxes.length; i++) {
                if (metadata.inboxes[i].inboxID === inbox.inboxID) {
                    metadata.inboxes[i] = inbox
                    break
                }
            }
            var saveInbox = { ...inbox }
            saveInbox.tables = undefined
            server.postData(
                {
                    action: 'putObject',
                    class: 'scoop.ingest.ReportInbox',
                    value: saveInbox,
                },
                afterSave
            )
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <DialogTitle sx={{ color: 'text.secondary', p: 1 }}></DialogTitle>
            <DialogContent sx={{ width: '100%' }}>
                <DialogContentText>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 24, mb: 3 }}>
                        Scoop Dataset Properties
                    </Typography>
                </DialogContentText>
                {inbox && (
                    <>
                        <Grid
                            container
                            rowSpacing={2}
                            sx={{
                                width: 800,
                                flexWrap: 'wrap',
                                p: 0,
                                alignItems: 'center',
                            }}
                        >
                            <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                Dataset name:
                            </Grid>
                            <Grid xs={8.5} sx={{ p: 0 }}>
                                <TextField
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    value={inbox.label}
                                    onChange={(event) => {
                                        inbox.label = event.currentTarget.value
                                        setInbox({ ...inbox })
                                        setChanged(true)
                                    }}
                                />
                            </Grid>
                            <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                Dataset description:
                            </Grid>
                            <Grid xs={8.5}>
                                <TextField
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                    value={inbox.description}
                                    onChange={(event) => {
                                        inbox.description = event.currentTarget.value
                                        setInbox({ ...inbox })
                                        setChanged(true)
                                    }}
                                />
                            </Grid>
                            <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                Dataset Type:
                            </Grid>
                            <Grid xs={8.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {inbox.transactional ? (
                                    <span>
                                        <strong>Transactional</strong>: Accept all new reports as
                                        transactions, i.e. as additional records to the existing
                                        database. All records are cumulative.
                                    </span>
                                ) : (
                                    <span>
                                        <strong>Snapshot</strong>: Treat each report as a new
                                        snapshot of an existing set of items, i.e. each report lists
                                        the current state of a set of objects. The unique key in
                                        each report is used to track changes for each item.
                                        Snapshots allow tracking of individual items and their state
                                        over time.
                                    </span>
                                )}
                            </Grid>
                            <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                Keep data from the most recent report only:
                            </Grid>
                            <Grid xs={8.5} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={inbox.keepOnlyCurrent}
                                    onChange={(event) => {
                                        inbox.keepOnlyCurrent = !inbox.keepOnlyCurrent
                                        setInbox({ ...inbox })
                                        setChanged(true)
                                    }}
                                />
                                <Typography sx={{ ml: 2 }}>
                                    When selected, Scoop will retain only the data from the most
                                    recently loaded report. Previous data is deleted.
                                </Typography>
                            </Grid>
                            {!inbox.transactional && (
                                <>
                                    <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                        Incremental:
                                    </Grid>
                                    <Grid
                                        xs={8.5}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            checked={inbox.incremental}
                                            onChange={(event) => {
                                                inbox.incremental = !inbox.incremental
                                                setInbox({ ...inbox })
                                                setChanged(true)
                                            }}
                                        />
                                        <Typography sx={{ ml: 2 }}>
                                            When selected, Scoop will interpret each new report as
                                            only the changed rows in the snapshot. It will
                                            incorporate these rows with all the unchanged rows from
                                            the prior snapshot. This allows for efficient loading in
                                            very large datasets where most rows don't change daily.
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {!inbox.transactional && (
                                <>
                                    <Grid xs={3.5} sx={{ fontWeight: 'bold' }}>
                                        Multiple Loads Per Day:
                                    </Grid>
                                    <Grid
                                        xs={8.5}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            checked={inbox.multipleSameDayLoads}
                                            onChange={(event) => {
                                                inbox.multipleSameDayLoads =
                                                    !inbox.multipleSameDayLoads
                                                setInbox({ ...inbox })
                                                setChanged(true)
                                            }}
                                        />
                                        <Typography sx={{ ml: 2 }}>
                                            Normally, if two reports are processed on the same day,
                                            Scoop retains only the most recent. When checked, this
                                            allows all reports on a day to be retained - allowing
                                            different parts of a dataset to be sent in different
                                            reports.
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
