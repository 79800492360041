import { mobileAPIRequest } from '../../api'

export const getUserInfo = async (userID) => {
    const action = {
        action: 'getObjects',
        attributeName: 'userID',
        attributeValue: userID,
        class: 'scoop.user.User',
    }
    const result = await mobileAPIRequest({
        method: 'post',
        data: action,
    })
    if (result && Array.isArray(result.objects)) {
        return result.objects[0]
    }
}
