import { Box } from '@mui/material'
import './SubNav.css'

const SubNav = ({ children, ...props }) => {
    return (
        <Box className={'sub-nav'} {...props}>
            {children}
        </Box>
    )
}
export default SubNav
