import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import SalesforceIcon from '../../../../assets/icons/Salesforce.svg'
import LinkedIn from '../../../../assets/icons/LinkedIn.svg'
import GoogleSheets from '../../../../assets/icons/GoogleSheets.svg'
import GoogleAnalytics from '../../../../assets/icons/GoogleAnalytics.svg'
import JiraIcon from '../../../../assets/icons/Jira.svg'
import HubspotIcon from '../../../../assets/icons/Hubspot.svg'
import AirtableIcon from '../../../../assets/icons/Airtable.svg'
import AWS from '../../../../assets/icons/AWS.svg'
import GoogleDataStudio from '../../../../assets/icons/GoogleDataStudio.svg'
import { useState } from 'react'
import Button from '../../../common/Button/Button'

export const SelectSource = ({ selectSource, source, goNext }) => {
    const sources = [
        { name: 'Salesforce', icon: SalesforceIcon },
        { name: 'LinkedIn', icon: LinkedIn },
        { name: 'Google Sheets', icon: GoogleSheets },
        { name: 'Google Analytics', icon: GoogleAnalytics },
        { name: 'Jira', icon: JiraIcon },
        { name: 'Hubspot', icon: HubspotIcon },
        { name: 'Airtable', icon: AirtableIcon },
        { name: 'AWS', icon: AWS },
        { name: 'Google Data Studio', icon: GoogleDataStudio },
        //{name: 'Database', icon: DatabaseBlack}
    ]

    const sourceSteps = [
        'Connect to salesforce and select the report.',
        'Prepare your data for Scoop.',
        'Scoop your data into a new dataset',
        'Match Fields from your source report to your dataset fields',
        'Complete the setup and start using your new dataset',
    ]

    //Steps can be 0 or 1
    const [step, setStep] = useState(0)

    const renderSourceSelection = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    paddingTop: '48px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    <Typography
                        sx={{
                            color: '#635566',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                            letterSpacing: '-0.28px',
                        }}
                    >
                        Start by selecting your source report application
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                            maxWidth: '684px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {sources.map((source, index) => (
                            <Box
                                key={index}
                                className={'source-box'}
                                index={index}
                                onClick={() => {
                                    setStep(1)
                                    selectSource(source)
                                }}
                            >
                                <img
                                    src={source.icon}
                                    alt={source.name}
                                    className={'source-icon'}
                                    style={{ backgroundColor: '#00A1E00D' }}
                                />
                                <Typography sx={{ fontSize: '14px' }}>{source.name}</Typography>
                            </Box>
                        ))}
                    </Box>
                </>
            </Box>
        )
    }

    const renderStepsToComplete = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    padding: '48px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    <Typography
                        sx={{
                            color: '#635566',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                            letterSpacing: '-0.28px',
                        }}
                    >
                        To make the most out of this recipe, you’ll need to prepare your data in{' '}
                        {source.name}.<br />
                        Follow this step by step guide on how to prep your data for this recipe.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '48px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '70%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {sourceSteps.map((step, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px',
                                        width: '316px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: '24px',
                                            height: '24px',
                                            padding: '8px',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            borderRadius: '50px',
                                            backgroundColor: '#F2F2F2',
                                        }}
                                    >
                                        <Typography>{index}</Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: '#635566',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '140%',
                                            letterSpacing: '-0.28px',
                                        }}
                                    >
                                        {step}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Button onClick={() => {}} className={'button-grey small'}>
                                Open {source.name}
                            </Button>
                            <Button onClick={goNext} className={'button-purple small'}>
                                I've done this
                            </Button>
                        </Box>
                    </Box>
                </>
            </Box>
        )
    }
    const renderSourceConnection = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: '#635566',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%',
                        letterSpacing: '-0.28px',
                    }}
                >
                    Great, your data is now ready to Scoop! It’s now time to auto-forward your
                    Salesforce
                    <br />
                    report emails to Scoop
                </Typography>
            </Box>
        )
    }

    return <Box>{step === 0 ? renderSourceSelection() : renderStepsToComplete()}</Box>
}
