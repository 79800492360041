import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useDragSelection = (delta, onSelectionChange, shouldStartSelecting, multiSelect) => {
    const zoom = useSelector((state) => state.ui.zoom)
    const selectedObject = useSelector((state) => state.ui.selectedObject)
    const [size, setSize] = useState({ height: 0, width: 0 })
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [showSelectionBox, setShowSelectionBox] = useState(false)
    const [isSelecting, setIsSelecting] = useState(false)
    const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 })
    const [mouseDown, setMouseDown] = useState(false)
    const [resizeOffset, setResizeOffset] = useState({ x: 0, y: 0 })
    const canvasScrollable = document.getElementById('canvas-scrollable')

    const handleMouseDown = useCallback(
        (e) => {
            if ((shouldStartSelecting(e.target) || !selectedObject) && multiSelect.length === 0) {
                setMouseDown(true)
                const x = (e.pageX + canvasScrollable.scrollLeft) / zoom
                const y = (e.pageY - 115 + canvasScrollable.scrollTop) / zoom
                setMouseDownPosition({ x, y })
                const offsetX = e.clientX - size.width / zoom
                const offsetY = e.clientY - size.height / zoom
                setResizeOffset({ x: offsetX, y: offsetY })
            }
        },
        [canvasScrollable, zoom, selectedObject, multiSelect]
    )

    const handleMouseUp = useCallback(() => {
        setMouseDown(false)
        setShowSelectionBox(false)
        setIsSelecting(false)
        setSize({ width: 0, height: 0 })
    }, [])

    const handleMove = useCallback(
        (e) => {
            if (!isSelecting && mouseDown) {
                const diffX = Math.abs(e.pageX - mouseDownPosition.x)
                const diffY = Math.abs(e.pageY - mouseDownPosition.y)
                if (diffX >= delta || diffY >= delta) {
                    setIsSelecting(true)
                    setShowSelectionBox(true)
                    setPosition(mouseDownPosition)
                }
            }
        },
        [isSelecting, mouseDown]
    )

    const handleSelectionMove = useCallback(
        (e) => {
            if (isSelecting && mouseDown) {
                // DETERMINE MOUSE QUADRANT REFERENCING MOUSE DOWN POSITION
                let mouseQuadrant = 'bottom-right'
                const mouseX = e.clientX + canvasScrollable.scrollLeft
                const mouseY = e.clientY + canvasScrollable.scrollTop - 115
                const zoomedX = mouseDownPosition.x * zoom
                const zoomedY = mouseDownPosition.y * zoom
                if (zoomedX < mouseX && zoomedY < mouseY) mouseQuadrant = 'bottom-right'
                if (zoomedX > mouseX && zoomedY < mouseY) mouseQuadrant = 'bottom-left'
                if (zoomedX < mouseX && zoomedY > mouseY) mouseQuadrant = 'top-right'
                if (zoomedX > mouseX && zoomedY > mouseY) mouseQuadrant = 'top-left'
                // RESIZE AND MOVE SELECTION
                switch (mouseQuadrant) {
                    case 'bottom-right':
                        const brWidth = (e.clientX - resizeOffset.x) / zoom
                        const brHeight = (e.clientY - resizeOffset.y) / zoom
                        onSelectionChange({
                            top: position.y,
                            left: position.x,
                            width: brWidth,
                            height: brHeight,
                        })
                        setSize({ width: brWidth, height: brHeight })
                        break
                    case 'bottom-left':
                        const blWidth = Math.abs(e.clientX - resizeOffset.x) / zoom
                        const blHeight = (e.clientY - resizeOffset.y) / zoom
                        const blX =
                            (mouseDownPosition.x * zoom + (e.clientX - resizeOffset.x)) / zoom
                        onSelectionChange({
                            top: position.y,
                            left: blX,
                            width: blWidth,
                            height: blHeight,
                        })
                        setSize({ width: blWidth, height: blHeight })
                        setPosition((pos) => ({ ...pos, x: blX }))
                        break
                    case 'top-right':
                        const trWidth = (e.clientX - resizeOffset.x) / zoom
                        const trHeight = Math.abs(e.clientY - resizeOffset.y) / zoom
                        const trY =
                            (mouseDownPosition.y * zoom + (e.clientY - resizeOffset.y)) / zoom
                        onSelectionChange({
                            top: trY,
                            left: position.x,
                            width: trWidth,
                            height: trHeight,
                        })
                        setSize({ width: trWidth, height: trHeight })
                        setPosition((pos) => ({ ...pos, y: trY }))
                        break
                    case 'top-left':
                        const tlWidth = Math.abs(e.clientX - resizeOffset.x) / zoom
                        const tlHeight = Math.abs(e.clientY - resizeOffset.y) / zoom
                        const tlX =
                            (mouseDownPosition.x * zoom + (e.clientX - resizeOffset.x)) / zoom
                        const tlY =
                            (mouseDownPosition.y * zoom + (e.clientY - resizeOffset.y)) / zoom
                        onSelectionChange({
                            top: tlY,
                            left: tlX,
                            width: tlWidth,
                            height: tlHeight,
                        })
                        setSize({ width: tlWidth, height: tlHeight })
                        setPosition((pos) => ({ x: tlX, y: tlY }))
                        break
                }
            }
        },
        [isSelecting, mouseDown, multiSelect]
    )

    const intersects = useCallback((box1, box2) => {
        const box1Right = box1.left + box1.width
        const box1Bottom = box1.top + box1.height
        const box2Right = box2.left + box2.width
        const box2Bottom = box2.top + box2.height
        const intersectHorizontally = box1Right > box2.left && box1.left < box2Right
        const intersectVertically = box1Bottom > box2.top && box1.top < box2Bottom
        return intersectHorizontally && intersectVertically
    }, [])

    useEffect(() => {
        if (isSelecting) document.addEventListener('mousemove', handleSelectionMove)
        return () => document.removeEventListener('mousemove', handleSelectionMove)
    }, [isSelecting, handleSelectionMove])

    useEffect(() => {
        if (mouseDown) document.addEventListener('mousemove', handleMove)
        return () => document.removeEventListener('mousemove', handleMove)
    }, [mouseDown])

    return {
        position,
        size,
        showSelectionBox,
        handleMouseDown,
        handleMouseUp,
        intersects,
    }
}
