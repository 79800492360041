import * as React from 'react'
import Box from '@mui/material/Box'
import { Stepper as MuiStepper } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'

export default function Stepper({
    steps,
    activeStep = 0,
    direction = 'horizontal',
    width,
    showChecked = false,
}) {
    const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
        '& .MuiStepIcon-root': {
            color: active ? '#E50B54 !important' : '#979099',
        },
        '& .MuiStepIcon-text': {
            fill: 'white !important',
        },
        '& .MuiStepLabel-label': {
            color: active ? '#E50B54 !important' : '#979099',
            fontSize: '12px',
            fontWeight: '400',
        },
        '& .MuiStepLabel-completed': {
            color: '# !important',
            fontWeight: '400',
        },
        '& .MuiSvgIcon-root.Mui-completed': {
            // Corrected selector
            color: showChecked && '#009262 !important',
        },
        '& .Mui-completed': {
            color: showChecked && 'green !important',
        },
    }))

    return (
        <Box sx={{ width: width ? width : '100%' }}>
            <MuiStepper direction={direction} activeStep={activeStep}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <CustomStepLabel active={activeStep === index}>{label}</CustomStepLabel>
                        </Step>
                    )
                })}
            </MuiStepper>
        </Box>
    )
}
