import Dialog from '../../../common/Dialog/Dialog'
import Button from '../../../common/Button/Button'
import React, { useCallback, useState } from 'react'
import Input from '../../../common/Input/Input'
import { Box } from '@mui/material'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { useDispatch } from 'react-redux'
import { switchWorkspace } from '../../../../store/actions/authActions'
import { useWorkspaces } from '../../../../api/hooks/workspaces/useWorkspaces'

export const DeleteWorkspaceModal = ({ open, close, workspaceName, workspaceID }) => {
    const [inputValue, setInputValue] = useState('')
    const dispatch = useDispatch()

    const { remove, isRemoving } = useWorkspaces()

    const handleDelete = useCallback(() => {
        remove({
            workspaceID: workspaceID,
            onSuccess: (response) => {
                dispatch(switchWorkspace(response[0].workspaceID, response[0].name))
                close()
            },
        })
    }, [workspaceID])

    const deleteActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={close} text={'Cancel'} />
            <Button
                onClick={handleDelete}
                text={isRemoving ? <ScoopLoader /> : 'Delete Workspace'}
                className={'button-purple'}
                disabled={inputValue !== workspaceName || isRemoving}
            />
        </Box>
    )

    return (
        <Dialog
            style={{ width: '600px' }}
            open={open}
            title={
                <div>
                    Delete workspace "<span style={{ fontStyle: 'italic' }}>{workspaceName}</span>"?
                </div>
            }
            actions={deleteActions}
            onClose={close}
        >
            <p>
                Please make sure you understand this action is non-reversible and will delete not
                only this workspace, but also all associated assets such as canvases, charts, KPIs,
                etc., that are part of this workspace.
            </p>
            <p>To confirm deletion, enter the name of the workspace in the text input field.</p>
            <Input
                label={'Workspace name'}
                placeholder={workspaceName}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ fontStyle: 'italic' }}
            />
        </Dialog>
    )
}
