import * as React from 'react'
import { ColumnHeader } from './ColumnHeader'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import { Button, FormLabel, MenuItem, Paper, Radio, RadioGroup, Select } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import { CustomInput } from './CustomInput'
import { FilterModal } from './FilterModal'
import { DataGrid, useGridApiEventHandler, useGridApiContext } from '@mui/x-data-grid'
import { packFilter, unpackFilters } from './Filter'
import { FormControl } from '@mui/base'
import { createRoot } from 'react-dom/client'

export default function DefineInputQuery({
    server,
    inbox,
    inputQuery,
    table,
    inAddOn,
    afterClose,
    index,
}) {
    const [currentColumns, setCurrentColumns] = React.useState(() => {
        let curColumns = []
        for (let j = 0; j < inputQuery.columns.length; j++) {
            curColumns.push(inputQuery.columns[j].columnName)
        }
        return curColumns
    })
    const [selectedColumn, setSelectedColumn] = React.useState('')
    const [advanced, setAdvanced] = React.useState(
        inputQuery.changes || inputQuery.dateKey > 0 || inputQuery.aggregateLevel !== 'Daily'
    )
    const [filterList, setFilterList] = React.useState(unpackFilters(inputQuery.filter))
    const [previewColumns, setPreviewColumns] = React.useState([])
    const [previewRows, setPreviewRows] = React.useState([])
    const [previewResultsColumns, setPreviewResultsColumns] = React.useState([])
    const [previewResultsRows, setPreviewResultsRows] = React.useState([])
    const [sheetName, setSheetName] = React.useState(inputQuery.sheetName)
    const [useLatest, setUseLatest] = React.useState(inputQuery.useLatestLoad)
    const parsedDate =
        inputQuery.startDate === null ? new Date() : new Date(Date.parse(inputQuery.startDate))
    const [startDate, setStartDate] = React.useState(
        parsedDate.getUTCFullYear() +
            '-' +
            (parsedDate.getUTCMonth() < 9 ? '0' : '') +
            (parsedDate.getUTCMonth() + 1) +
            '-' +
            (parsedDate.getUTCDate() < 10 ? '0' : '') +
            parsedDate.getUTCDate()
    )
    const [groupByDate, setGroupByDate] = React.useState(inputQuery.dateKey)
    const [snapshotChanges, setSnapshotChanges] = React.useState(inputQuery.changes)
    const [aggregate, setAggregate] = React.useState(inputQuery.aggregate && !inputQuery.changes)
    const [timeLevel, setTimeLevel] = React.useState(inputQuery.aggregateLevel)
    const [usePeriod, setUsePeriod] = React.useState(
        inputQuery.usePeriod ? inputQuery.usePeriod : inputQuery.useLatestLoad ? 'latest' : 'since'
    )
    const [open, setOpen] = React.useState(false)
    const [operator, setOperator] = React.useState('Equals')
    const [items, setItems] = React.useState([])
    const [selectedItems, setSelectedItems] = React.useState([])
    const [error, setError] = React.useState(null)
    const [resultType, setResultType] = React.useState('sourcePreview')

    function getGridColType(scoopType) {
        switch (scoopType) {
            case 'String':
                return 'string'
            case 'Decimal':
                return 'number'
            case 'DateTime':
                return 'dateTime'
            case 'Currency':
                return 'number'
            case 'Boolean':
                return 'boolean'
            case 'Integer':
                return 'number'
            default:
                return 'string'
        }
    }

    function transform(ctype, val) {
        if (ctype) {
            if (ctype === 'DateTime') {
                return new Date(val)
            }
            return val
        }
        return val
    }

    function setCategoryValues({ values: categories }) {
        var newItems = []
        for (var i = 0; i < categories.length; i++) {
            if (categories[i] === null) continue
            var row = {
                id: categories[i],
                value: categories[i],
            }
            newItems.push(row)
        }
        setItems(newItems)
    }

    function processPreviewData(previewData) {
        if (!previewData) return
        var newPreviewColumns = []
        var newPreviewRows = []
        for (var cnum = 0; cnum < previewData.rows[0].length - 1; cnum++) {
            var minWidth = 8
            var cName = previewData.rows[0][cnum]
            var cType = 'string'
            if (cnum < table.columns.length) {
                if (table.columns[cnum].columnType !== 'String') {
                    minWidth = 15
                } else {
                    for (var rnum = 1; rnum < previewData.rows.length; rnum++) {
                        if (previewData.rows[rnum][cnum].length > minWidth) {
                            minWidth = previewData.rows[rnum][cnum].length
                        }
                    }
                }
                if (
                    table.columns[cnum].columnType === 'String' &&
                    table.columns[cnum].columnName.length > minWidth
                ) {
                    minWidth = table.columns[cnum].columnName.length
                }
                cName = table.columns[cnum].columnName
                cType = getGridColType(table.columns[cnum].columnType)
            } else {
                minWidth = 20
            }
            if (minWidth > 35) {
                minWidth = 35
            }
            newPreviewColumns.push({
                field: cName,
                headerName: cName,
                width: minWidth * 11,
                type: cType,
                sortable: false,
                editable: false,
                headerAlign: 'center',
                headerClassName: 'column--header',
            })
        }
        for (rnum = 1; rnum < previewData.rows.length; rnum++) {
            var newRow = { id: rnum }
            for (cnum = 0; cnum < previewData.rows[0].length; cnum++) {
                var x = previewData.rows[rnum][cnum]
                if (cnum < table.columns.length) {
                    newRow[table.columns[cnum].columnName] = transform(
                        table.columns[cnum].columnType,
                        x
                    )
                } else {
                    newRow[previewData.rows[0][cnum]] = previewData.rows[rnum][cnum]
                }
            }
            newPreviewRows.push(newRow)
        }
        setPreviewColumns(newPreviewColumns)
        setPreviewRows(newPreviewRows)
    }

    function updatePreviewData(iq) {
        server.sheetPostData(
            {
                action: 'addOn',
                addOnAction: 'preview',
                inputQuery: iq,
                numRows: 15,
                sheetID: inbox.calculatedWorksheetID,
            },
            processPreviewData
        )
    }

    function processPreviewResultsData(previewData) {
        if (!previewData) return
        var newPreviewResultsColumns = []
        var newPreviewResultsRows = []
        for (var cnum = 0; cnum < previewData.columns.length; cnum++) {
            var minWidth = 8
            if (previewData.columns[cnum] !== 'date') {
                if (previewData.columns[cnum].columnType !== 'String') {
                    minWidth = 15
                } else {
                    for (var rnum = 0; rnum < previewData.data.length; rnum++) {
                        if (
                            previewData.data[rnum][cnum] &&
                            previewData.data[rnum][cnum].length > minWidth
                        ) {
                            minWidth = previewData.data[rnum][cnum].length
                        }
                    }
                }
            }
            if (minWidth > 35) {
                minWidth = 35
            }
            if (previewData.columns[cnum] === 'date') {
                newPreviewResultsColumns.push({
                    field: 'date',
                    headerName: 'date',
                    width: 100,
                    type: 'string',
                    sortable: false,
                    editable: false,
                    headerAlign: 'center',
                    headerClassName: 'column--header',
                })
            } else {
                newPreviewResultsColumns.push({
                    field: previewData.columns[cnum].columnName,
                    headerName: previewData.columns[cnum].columnName,
                    width: minWidth * 11,
                    type: getGridColType(previewData.columns[cnum].columnType),
                    sortable: false,
                    editable: false,
                    headerAlign: 'center',
                    headerClassName: 'column--header',
                })
            }
        }

        for (rnum = 0; rnum < previewData.data.length; rnum++) {
            var newRow = { id: rnum }
            for (cnum = 0; cnum < previewData.columns.length; cnum++) {
                var x = previewData.data[rnum][cnum]
                if (previewData.columns[cnum] === 'date') {
                    newRow['date'] = x
                } else if (previewData.columns[cnum].columnType) {
                    newRow[previewData.columns[cnum].columnName] = transform(
                        previewData.columns[cnum].columnType,
                        x
                    )
                }
            }
            newPreviewResultsRows.push(newRow)
        }
        setPreviewResultsColumns(newPreviewResultsColumns)
        setPreviewResultsRows(newPreviewResultsRows)
    }

    function updatePreviewResultsData(iq) {
        server.sheetPostData(
            {
                action: 'addOn',
                addOnAction: 'previewResults',
                inputQuery: iq,
                sheetID: inbox.calculatedWorksheetID,
            },
            processPreviewResultsData
        )
    }

    function getInputQuery() {
        inputQuery.sheetName = sheetName
        inputQuery.dateKey = parseInt(groupByDate)
        const pdate = new Date(Date.parse(startDate))
        inputQuery.aggregate = aggregate
        inputQuery.changes = snapshotChanges
        inputQuery.aggregateLevel = timeLevel
        inputQuery.columns = []
        for (let i = 0; i < currentColumns.length; i++) {
            let newCol = { columnName: currentColumns[i] }
            inputQuery.columns.push(newCol)
        }
        if ((useLatest && table.snapshot) || usePeriod === 'latest') {
            if (table.snapshot === true) {
                inputQuery.startDate = undefined
            }
            inputQuery.useLatestLoad = true
            inputQuery.usePeriod = undefined
        } else {
            delete inputQuery.usePeriod
            if (usePeriod === 'since') {
                inputQuery.startDate =
                    (pdate.getUTCMonth() < 9 ? '0' : '') +
                    (pdate.getUTCMonth() + 1) +
                    '/' +
                    (pdate.getUTCDate() < 10 ? '0' : '') +
                    pdate.getUTCDate() +
                    '/' +
                    pdate.getUTCFullYear()
                delete inputQuery.usePeriod
            } else {
                inputQuery.usePeriod = usePeriod
            }
            inputQuery.useLatestLoad = false
        }
        inputQuery.filter = packFilter(filterList)
        return inputQuery
    }

    function handleDelete() {
        server.sheetPostData(
            {
                action: 'addOn',
                addOnAction: 'saveInputQuery',
                inputQuery: null,
                index: index,
                refresh: true,
                sheetID: inbox.calculatedWorksheetID,
            },
            finishSave
        )
    }

    function handleSave() {
        server.sheetPostData(
            {
                action: 'addOn',
                addOnAction: 'saveInputQuery',
                inputQuery: getInputQuery(),
                index: index,
                refresh: true,
                sheetID: inbox.calculatedWorksheetID,
            },
            finishSave
        )
    }

    function finishSave(saveResult) {
        if (saveResult && saveResult.error) {
            setError(saveResult.error.message)
        } else {
            /*global google*/
            if (typeof google === 'undefined') {
            } else {
                google.script.host.close()
            }
        }
        if (afterClose) {
            afterClose(true)
        }
    }

    if (previewColumns.length === 0) {
        updatePreviewData(inputQuery)
    }

    function MyColumnHeaders(props) {
        const [left, setLeft] = React.useState(0)
        const apiRef = useGridApiContext()
        const hRef = React.useRef()
        const [leftMargin, setLeftMargin] = React.useState(-100)

        const handleEvent = (
            params // GridScrollParams
        ) => {
            if (hRef.current) {
                setLeft(-params.left)
                hRef.current.style.left = -params.left + 'px'
            }
        }

        useGridApiEventHandler(apiRef, 'scrollPositionChange', handleEvent)
        let w = 0
        if (props.columnPositions && props.columnPositions.length > 0) {
            w =
                props.columnPositions[props.columnPositions.length - 1] +
                props.visibleColumns[props.visibleColumns.length - 1].width
        }
        return (
            <Box
                ref={hRef}
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    width: w,
                    position: 'relative',
                    minHeight: '60px',
                    left: { left } + 'px',
                }}
            >
                {props.visibleColumns.map((col) => (
                    <Box sx={{ width: col.width }}>
                        <ColumnHeader
                            sheetID={inbox.calculatedWorksheetID}
                            headerName={col.headerName}
                            inputQuery={inputQuery}
                            table={table}
                            currentColumns={currentColumns}
                            setCurrentColumns={setCurrentColumns}
                            setOpen={setOpen}
                            setSelectedColumn={setSelectedColumn}
                            filterList={filterList}
                            setOperator={setOperator}
                            setItems={setItems}
                            setCategoryValues={setCategoryValues}
                            setSelectedItems={setSelectedItems}
                            previewColumns={previewColumns}
                            setPreviewColumns={setPreviewColumns}
                            server={server}
                        ></ColumnHeader>
                    </Box>
                ))}
            </Box>
        )
    }

    return (
        <div>
            <AppBar key="bar" position="static" sx={{ bgcolor: '#88458d' }}>
                <Toolbar>
                    {error != null && (
                        <Box width="100%">
                            <Typography
                                sx={{
                                    color: '#FF0000',
                                    bgcolor: 'white',
                                    mr: 30,
                                    ml: 5,
                                    borderRadius: '7px',
                                    textAlign: 'center',
                                }}
                            >
                                {error}
                            </Typography>
                        </Box>
                    )}
                    {error == null && <Box width="100%"></Box>}
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={advanced}
                                        onChange={(event) => setAdvanced(!advanced)}
                                    />
                                }
                                label="Advanced"
                            />
                        </FormGroup>
                    </div>
                    {inAddOn && (
                        <IconButton
                            onClick={handleSave}
                            enabled={currentColumns.length > 0}
                            size="large"
                            aria-label="display more actions"
                            edge="end"
                            color="inherit"
                        >
                            <SaveIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Paper sx={{ m: 1, p: 1 }}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        m: 0,
                        width: 1,
                    }}
                >
                    <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Query name</Typography>
                    </Grid>
                    <Grid xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                        <CustomInput
                            name="worksheetName"
                            value={sheetName}
                            onChange={(event) => setSheetName(event.target.value)}
                            placeholder="Type something…"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        m: 0,
                        width: 1,
                    }}
                >
                    {table.snapshot && (
                        <>
                            <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Use Latest snapshot
                                </Typography>
                            </Grid>
                            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Switch
                                    checked={useLatest}
                                    onChange={(event) => setUseLatest(!useLatest)}
                                />{' '}
                                {!useLatest && (
                                    <>
                                        <Typography sx={{ fontWeight: 'bold', mr: 1 }}>
                                            Start:{' '}
                                        </Typography>
                                        <CustomInput
                                            name="startDate"
                                            type="date"
                                            value={startDate}
                                            onChange={(event) => {
                                                setStartDate(event.target.value)
                                                let iq = getInputQuery()
                                                iq.startDate = Date.parse(event.target.value)
                                                setStartDate(iq.startDate)
                                                iq.usePeriod = 'since'
                                                setUsePeriod(iq.usePeriod)
                                                updatePreviewData(iq)
                                            }}
                                        />
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    {!table.snapshot && (
                        <>
                            <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Start date</Typography>
                            </Grid>
                            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    value={usePeriod}
                                    onChange={(event) => {
                                        setUsePeriod(event.target.value)
                                        let iq = getInputQuery()
                                        if (event.target.value === 'since') {
                                            iq.usePeriod = undefined
                                            var pdate = new Date(Date.parse(startDate))
                                            inputQuery.startDate =
                                                (pdate.getUTCMonth() < 9 ? '0' : '') +
                                                (pdate.getUTCMonth() + 1) +
                                                '/' +
                                                (pdate.getUTCDate() < 10 ? '0' : '') +
                                                pdate.getUTCDate() +
                                                '/' +
                                                pdate.getUTCFullYear()
                                            iq.useLatestLoad = false
                                        } else if (event.target.value === 'latest') {
                                            iq.useLatestLoad = true
                                            iq.startDate = undefined
                                            iq.usePeriod = undefined
                                        } else {
                                            iq.usePeriod = event.target.value
                                            iq.startDate = undefined
                                            iq.useLatestLoad = false
                                        }
                                        updatePreviewData(iq)
                                    }}
                                    sx={{ height: 40, width: 150, mr: 1 }}
                                >
                                    <MenuItem value="since">Since Date</MenuItem>
                                    <MenuItem value="latest">Most Recent</MenuItem>
                                    <MenuItem value="Daily">Last Day</MenuItem>
                                    <MenuItem value="Weekly">Last Week</MenuItem>
                                    <MenuItem value="Monthly">Last Month</MenuItem>
                                    <MenuItem value="Quarterly">Last Quarter</MenuItem>
                                    <MenuItem value="Annually">Last Year</MenuItem>
                                    <MenuItem value="all">All Time</MenuItem>
                                </Select>
                                {usePeriod === 'since' && (
                                    <CustomInput
                                        name="startDate"
                                        type="date"
                                        value={startDate}
                                        onChange={(event) => {
                                            setStartDate(event.target.value)
                                            let iq = getInputQuery()
                                            iq.startDate = Date.parse(event.target.value)
                                            updatePreviewData(iq)
                                        }}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                    {!advanced && (
                        <>
                            <Grid xs={6}></Grid>
                        </>
                    )}
                    {advanced && (
                        <>
                            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Aggregate data by selected attributes
                                </Typography>
                            </Grid>
                            <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Switch
                                    checked={aggregate}
                                    disabled={snapshotChanges}
                                    onChange={(event) => {
                                        inputQuery = getInputQuery()
                                        inputQuery.aggregate = !aggregate
                                        setAggregate(!aggregate)
                                        updatePreviewData(inputQuery)
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    {advanced && (
                        <>
                            <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Date to group by
                                </Typography>
                            </Grid>
                            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    sx={{ height: 32 }}
                                    ena
                                    value={groupByDate}
                                    onChange={(event) => {
                                        inputQuery = getInputQuery()
                                        inputQuery.dateKey = parseInt(groupByDate)
                                        setGroupByDate(event.target.value)
                                        updatePreviewData(inputQuery)
                                    }}
                                >
                                    <MenuItem value={0}>Load Date</MenuItem>
                                    {table.dates &&
                                        table.dates.map((dateName, index) => (
                                            <MenuItem value={index + 1}>{dateName}</MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            {table.snapshot && (
                                <>
                                    {' '}
                                    <Grid
                                        xs={4}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 25,
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            Retrieve change data vs. raw data
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 25,
                                        }}
                                    >
                                        <Switch
                                            checked={snapshotChanges}
                                            onChange={() => {
                                                inputQuery = getInputQuery()
                                                inputQuery.changes = !snapshotChanges
                                                inputQuery.aggregate = false
                                                setAggregate(false)
                                                setSnapshotChanges(!snapshotChanges)
                                                updatePreviewData(inputQuery)
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {!table.snapshot && <Grid xs={6}></Grid>}
                            <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Aggregate by time period
                                </Typography>
                            </Grid>
                            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    sx={{ height: 32 }}
                                    value={timeLevel}
                                    onChange={(event) => {
                                        inputQuery = getInputQuery()
                                        inputQuery.aggregateLevel = event.target.value
                                        setTimeLevel(event.target.value)
                                        updatePreviewData(inputQuery)
                                    }}
                                >
                                    <MenuItem value="Daily">Daily</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                            </Grid>
                            <Grid xs={6}></Grid>
                        </>
                    )}
                </Grid>
            </Paper>
            <Box sx={{ mt: 1, mb: 1 }}>
                <FormControl>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormLabel sx={{ fontWeight: 'bold', mr: 2 }} id="previewTypeID">
                            Select data to preview
                        </FormLabel>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            value={resultType}
                            onChange={(event) => {
                                setResultType(event.currentTarget.value)
                                inputQuery = getInputQuery()
                                if (event.currentTarget.value === 'sourcePreview') {
                                    updatePreviewData(inputQuery)
                                } else if (event.currentTarget.value === 'resultPreview') {
                                    updatePreviewResultsData(inputQuery)
                                }
                            }}
                        >
                            <FormControlLabel
                                value="sourcePreview"
                                control={<Radio />}
                                label="Source data (filtered)"
                            />
                            <FormControlLabel
                                value="resultPreview"
                                control={<Radio />}
                                label="Results preview (aggregated, filtered and only selected columns)"
                            />
                        </RadioGroup>
                    </Box>
                </FormControl>{' '}
            </Box>
            <FilterModal
                table={table}
                inputQuery={inputQuery}
                filterList={filterList}
                setFilterList={setFilterList}
                open={open}
                setOpen={setOpen}
                selectedColumn={selectedColumn}
                operator={operator}
                setOperator={setOperator}
                items={items}
                setCategoryValues={setCategoryValues}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                updatePreviewData={updatePreviewData}
                server={server}
            />
            <div
                style={{
                    height: inAddOn ? (advanced ? 480 : 580) : advanced ? 430 : 520,
                    width: '100%',
                }}
            >
                {resultType === 'sourcePreview' && (
                    <DataGrid
                        filterMode="server"
                        rows={previewRows}
                        columns={previewColumns}
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                            },
                        }}
                        pageSizeOptions={[25, 100]}
                        disableColumnMenu
                        columnHeaderHeight={90}
                        slots={{
                            columnHeaders: MyColumnHeaders,
                        }}
                        sx={{
                            '& .column--header': {
                                lineHeight: 1,
                            },
                        }}
                    />
                )}
                {resultType === 'resultPreview' && (
                    <DataGrid
                        filterMode="server"
                        rows={previewResultsRows}
                        columns={previewResultsColumns}
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[25, 100]}
                        disableColumnMenu
                        columnHeaderHeight={40}
                        sx={{
                            '& .column--header': {
                                lineHeight: 1,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                )}
            </div>
            {!inAddOn && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ ml: 2, mt: 2 }}
                        onClick={() => {
                            afterClose(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" sx={{ ml: 2, mt: 2 }} onClick={handleDelete}>
                        Delete Query
                    </Button>
                    <Button variant="contained" sx={{ ml: 2, mt: 2 }} onClick={handleSave}>
                        OK
                    </Button>
                </Box>
            )}
        </div>
    )
}

export var renderFunction = ({ server, inbox, inputQuery, table }) => {
    var root = createRoot(document.getElementById('root'))
    root.render(
        <DefineInputQuery
            inputQuery={inputQuery}
            inbox={inbox}
            table={table}
            server={server}
            inAddOn={true}
            index={-1}
        />
    )
}
